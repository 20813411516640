import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import swal from "sweetalert2";
import Toasted from "vue-toasted";
import VueTelInput from 'vue-tel-input';
import axios from "axios";
import Request from "./Request";
import MugenScroll from 'vue-mugen-scroll'
import VueLazyload from 'vue-lazyload'
import { ClientTable, Event } from 'vue-tables-2';
import { ValidationProvider, extend, ValidationObserver } from 'vee-validate';
import { required, email, max, numeric, length, regex } from 'vee-validate/dist/rules';
import Paginate from 'vuejs-paginate';
import VueBarcodeScanner from 'vue-barcode-scanner'
import InfiniteLoading from 'vue-infinite-loading';
import VueHtmlToPaper from 'vue-html-to-paper';
import PincodeInput from 'vue-pincode-input';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
// import socketio from 'socket.io'
// import VueSocketIO from 'vue-socket.io'
//stylesheet
import "../src/assets/css/layout.css"
import "../src/assets/css/index.css"
import "../src/assets/css/variables.css"
import "../src/assets/css/responsive.css"

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

//javascript
import "bootstrap";
import "jquery";
import Popper from "popper.js";
global.Popper = Popper;
import "bootstrap/dist/css/bootstrap.min.css";

import VueWorker from 'vue-worker'

// export const SocketInstance = socketio('http://localhost:4113');
// Vue.use(VueSocketIO, SocketInstance)

Vue.use(VueWorker)


Vue.prototype.$request = Request;
Vue.prototype.$swal = swal;
Vue.prototype.$toasted = Toasted;
Vue.use(ClientTable, {
    perPage: 50,
    perPageValues: [10, 25, 50, 100, 300, 500],
    theme: 'bootstrap4',
    skin: ''
});
Vue.use(InfiniteLoading, {
    slots: {
        // keep default styles
        noResults: 'No results message',

        // remove default styles
        noMore: 'No more',


    },
});
const options = {
    name: '_blank',
    specs: [
        'fullscreen=yes',
        'titlebar=yes',
        'scrollbars=no'
    ]

}

Vue.component("DatePicker", DatePicker);
Vue.use(VueHtmlToPaper, options);
Vue.use(VueBarcodeScanner)
Vue.use(VueTelInput);
Vue.use(Toasted, {
    theme: "bubble",
    position: "top-right",
    duration: 5000,
    iconPack: "fontawesome",

    router
});
import loader from '../src/assets/img/lazyLoader.gif'
import './registerServiceWorker'
Vue.use(VueLazyload, {
    preLoad: 1.3,
    loading: loader,
    attempt: 1,
    listenEvents: ['scroll'],
})
Vue.component('paginate', Paginate)
Vue.component('v-select', vSelect)
Vue.component('PincodeInput', PincodeInput);
// No message specified.
extend('email', {
    ...email,
    message: 'This field must be an email'
});

// Override the default message.
extend('required', {
    ...required,
    message: 'This field is required'
});
extend('numeric', {
    ...numeric,
    message: 'This field must include numbers only'
});
extend('regex', {
    ...regex,
    message: 'This field must include phone numbers only'
});

extend('length', {
    ...length,
    message: 'This field length is incorrect'
});
extend('confirmedBy', {
    params: ['target'],
    // Target here is the value of the target field
    validate(value, { target }) {
        return value === target;
    },
    // here it is its name, because we are generating a message
    message: 'The {_field_} does not match the {target}'
});
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('mugen-scroll', MugenScroll)
Vue.config.productionTip = false



Vue.mixin({
    methods: {
        //   formatPrice: function (price) {
        //     if(price){
        //         var str = price.toString().split(".");
        //         if (str[0].length >= 3) {
        //             str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
        //         }
        //         if (!str[1]) {
        //             str[1] = "00";
        //         }
        //         let l = str[1].length; 
        //         let c =  l > 1 ? str[1] : str[1]+'0'
        //         return str[0]+ "." +c.substring(0,2);
        //     }
        //   },
        // formatPrice (price, count = 2) {

        //   // price = price ? price : 0;
        //   // return Number(parseFloat(price).toFixed(count)).toLocaleString("en-US");
        //   if(price){
        //             var str = price.toString().split(".");
        //             if (str[0].length >= 3) {
        //                 str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
        //             }
        //     }
        //   price = price ? price : 0;
        //   return parseFloat(price).toFixed(count);
        // },
        formatPrice(price, count) {
            var str = price.toString().split(".");
            if (str[0].length >= 3) {
                str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
            }
            if (!count) {
                if (!str[1]) {
                    str[1] = "00";
                }
            }
            let l = str[1].length;

            let c = l > 1 ? str[1] : str[1] + '0'
            return str[0] + "." + c.substring(0, 2);
        },

        formatDate(date, format) {
            let d = new Date(date)
            if (format == 'time') {
                return d.toLocaleTimeString('en-US');
            } else if (format == 'date') {
                return d.toDateString();
            } else {
                return d.toDateString() + ' ' + d.toLocaleTimeString('en-US');
            }
        },

        is401(x) {
            if (x == '401') {
                localStorage.clear();
                location.href = '/?sessionexpire=' + true;
            }
            if (x == '500') {
                this.$swal.fire('Error', 'Server error, please contact Admin.', 'error')
            }
        },
    },
})

router.beforeEach(async(to, from, next) => {
    await store.restored
    const isPublic = to.matched.some(record => record.meta.public);
    const isRedirect = to.matched.some(record => record.meta.redirect_link);
    const pageNotKitchen = to.matched.some(record => record.meta.notkitchen);

    const onlyWhenLoggedOut = to.matched.some(
        record => record.meta.onlyWhenLoggedOut
    );
    const loggedIn = store.getters.isLoggedIn;
    // || (loggedIn && onlyWhenLoggedOut && !isNaN(store.getters.getBranchID))
    if ((!isPublic && !loggedIn)) {
        return next({
            path: "/",
            // query: { redirect: to.fullPath }
            // Store the full path to redirect the user to after login
        });
    }


    // Do not allow user to visit login page or register page if they are logged in
    if (loggedIn && onlyWhenLoggedOut) {
        if (isPublic) {
            return next("/orders");
        }
        return next("/orders");
    }

    if (store.getters.user !== null) {
        //access control for kitchen staff
        const isKitchenStaff = store.getters.businesscategory === 'Food' && store.getters.user.role === 'kitchen' ? true : false;
        if (isKitchenStaff && pageNotKitchen) {
            return next("/kitchen");
        }
        //access control for dispatch

    }

    // await store.restored
    //allow access only on redirect
    // if (isRedirect) {
    //     if (from.name !== null) return next()
    //     else return next("/orders");
    // }

    next();
});

export const eventBus = new Vue();
new Vue({
    router,
    store,
    created() {
        // store.commit('initializeStore');
    },

    render: h => h(App)
}).$mount('#app')