<template>
  <div id="history">
    <Sidemenu />
    <div id="main">
      <Topmenu />

      <div class="filter-container ">
        <div class="row h-100 mx-1">
          <div class="col-xl-5 col-lg-4 my-auto filter-title">
            <div class="d-flex">
              <h5 class="title mr-2">Queue</h5>
              <p class="subtitle pl-2">{{queuedata.length}} Pending Orders</p>
            </div>
          </div>
          <div class="col-xl-7 col-lg-8 my-auto filter-form">

          </div>
        </div>
      </div>

      <div class="main-content mr-sm-3 my-4">
        <div class="card m-sm-4 p-2">

          <v-client-table
            :data="queuedata"
            :columns="columns"
            :options="options"
            slots="slots"
            @row-click="viewOrder"
          >

            <span
              slot="sn"
              slot-scope="props"
              class=""
            >{{props.index}}</span>

            <p
              slot="orderstatus"
              slot-scope="props"
            >
              <span
                v-if="props.row.orderstatus == 'completed'"
                class="badge badge-pill badge-delivered"
              > {{props.row.orderstatus}}</span>
              <span
                v-else-if="props.row.orderstatus == 'paylater'"
                class="badge badge-pill badge-pending"
              > {{props.row.orderstatus}}</span>
              <span
                v-else-if="props.row.orderstatus == 'prepared'"
                class="badge badge-pill badge-delayed"
              > {{props.row.orderstatus}}</span>

              <span
                v-else
                class="badge badge-pill"
              > {{props.row.orderstatus}}</span>

            </p>

            <span
              slot="transactiondate"
              slot-scope="props"
            >{{formatDate(props.row.transactiondate, 'date')}}</span>
            <p slot="action">
              <i
                @click="viewOrder"
                class="fa fa-eye"
                style="font-size:18px"
                title="Open Order"
              ></i>
            </p>
          </v-client-table>

        </div>
      </div>
    </div>

    <!-- order modal -->
    <div
      v-if="Object.keys(order).length >0"
      id="ordermodal"
      class="modal"
      tabindex="-1"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header  mx-sm-5">
            <h5 class="modal-title ">Order Details

              <span
                v-if="order.orderstatus == 'completed'"
                class="badge badge-pill badge-delivered"
              > {{order.orderstatus}}</span>
              <span
                v-else-if="order.orderstatus == 'paylater'"
                class="badge badge-pill badge-pending"
              > {{order.orderstatus}}</span>
              <span
                v-else-if="order.orderstatus == 'prepared'"
                class="badge badge-pill badge-delayed"
              > {{order.orderstatus}}</span>

              <span
                v-else
                class="badge badge-pill"
              > {{order.orderstatus}}</span>

            </h5>

            <img
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              src="../assets/img/close.png"
              alt=""
            >
          </div>
          <div class="modal-body mx-sm-5">
            <div class="orderinfo d-flex justify-content-left flex-wrap my-4">
              <h5 class="heading">Order Code</h5>
              <p class="id ml-2">{{order.ordercode}}</p>

            </div>
            <div class="orderinfo d-flex justify-content-left flex-wrap my-4">
              <h5 class="heading">Order Time</h5>
              <p class="id ml-2">{{formatDate(order.transactiondate)}}</p>

            </div>

            <table class="table my-5">

              <tbody>
                <tr
                  v-for="(row, ix) in order.orderitems"
                  v-bind:key=ix
                  class="items"
                >

                  <td class>
                    <img
                      v-lazy="row.photo"
                      alt=""
                      class="orderimg img-fluid img-responsive"
                    >
                  </td>
                  <td>
                    <h5 class="product">{{row.productname}}</h5>
                  </td>
                  <td>
                    <p class="qty">{{row.quantity}}</p>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
          <div class="modal-footer mx-sm-5 py-4">

            <button
              @click.prevent="processOrder"
              type="button"
              class="btn ml-2 my-auto"
            >Process Order</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import * as $ from "jquery";
import Sidemenu from '@/components/sidemenu'
import Topmenu from '@/components/topmenu'
import { createNamespacedHelpers } from 'vuex'
const { mapActions, mapGetters } = createNamespacedHelpers('orders')
export default {
  name: 'Queue',
  components: {
    Sidemenu, Topmenu
  },
  data () {
    return {
      search: '',
      queuedata: [],
      order: {},
      columns: ["sn", 'ordercode', "orderstatus", 'transactiondate', 'action'],
      options: {

        headings: {
          sn: 's/n',
          ordercode: 'Order Code',
          orderstatus: 'Order Status',
          transactiondate: "Order Time",

        },

        sortable: ["sn", 'ordercode', "orderstatus", 'transactiondate'],
        filterable: ["sn", 'ordercode', "orderstatus", 'transactiondate'],
        saveState: true,
        sortIcon:
        {
          base: 'fa',
          is: 'fa-sort',
          up: 'fa-long-arrow-up',
          down: 'fa-long-arrow-down'
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      'queuedata': 'queue',
      // 'riders' : 'riders'
      // 'host' : 'host'
    }),
  },
  created () {
    this.fetchQueue();
    this.businesscategory = this.$store.getters.businesscategory
  },


  methods: {
    ...mapActions([
      'getInstoreOrders',
    ]),
    fetchQueue () {
      let req = {
        what: "queue",
        useToken: true,
        params: {
          merchID: this.$store.getters.user.merchID,
          branchID: this.$store.getters.user.branchID,
          orderstatus: 'paylater'
        }
      }
      this.getInstoreOrders(req)
        .then(res => {
          // if (res.type == 'queue') {
          //   this.queuedata = res.data.data
          // }
          if (req.showLoader == undefined || req.showLoader){
              this.$swal.close()
            }
        })
        .catch(error => {
          this.is401(error.response.status);
          console.log(error)
          this.$swal.fire("Error", error.message, "error");
        });
    },
    viewOrder (event) {
      $("#ordermodal").modal("show");
      this.order = event.row;
    },
    processOrder () {
      let cart = [];
      for (let row of this.order.orderitems) {
        cart.push({
          name: row.productname,
          price: row.unitprice,
          productid: row.productID,
          quantity: row.quantity,
          // discount: row.discount,
          discount: row.discountprice,
          image: row.photo,
          totalamount: row.totalprice
        })
      }
      this.$store.dispatch('updateCart', cart)
        .then(() => {
          $(".modal").modal("hide");
          this.$router.push('/walk-in')
        })
    },
    formatPrice (price) {
      var str = price.toString().split(".");
      if (str[0].length >= 3) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
      }
      if (!str[1]) {
        str[1] = "00";
      }
      return str.join(".");
    },
    formatDate (date, format) {
      if (date !== null) {
        let d = new Date(date)
        if (format == 'time') {

          return d.toLocaleTimeString('en-US');
        }
        else if (format == 'date') {
          return d.toDateString().replace(' ', ', ');
        }
        else {
          return d.toDateString().replace(' ', ', ') + ' ' + d.toLocaleTimeString('en-US');
        }
      }
      else {
        return null
      }
    },

  }
}
</script>
<style scoped>
.cus-img {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  border: 1px solid #dee2e6;
  /* padding: 3px; */
}
.cus-img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
@media screen and (max-width: 425px) {
  .main-content .card {
    margin: 0 10px;
  }
}
tr.VueTables__row {
  cursor: pointer;
}
.VueTables table.table-striped tbody tr:nth-of-type(odd) {
  background-color: transparent;
}
</style>
