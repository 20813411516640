<template>
  <div
    id="auto-logout"
    class="modal"
    tabindex="-1"
    data-backdrop="false"
  >
    <div class="modal-dialog ">
      <div class="modal-content">
        <div class="modal-header  ">
          <h5 class="modal-title mx-sm-5">Your session is about to expire in {{ timerCount }} s
          </h5>

          <img
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            src="../assets/img/close.png"
            alt=""
          >
        </div>
        <div class="modal-body mx-sm-5">
          <p>You can click Don't Log me out to keep your session alive for another 45 mins</p>
          <button
            data-dismiss="modal"
            type="button"
            @click="logoutUser"
            class="cancel-close btn my-auto"
          >
          Log out
          </button>
          <button
            type="button"
            class="btn ml-2 my-auto"
            @click="dontLogout"
          >Don't log me out!</button>
        </div>
      </div>
    </div>
    <div class="modal-footer mx-5 py-4 border-0">

    </div>
  </div>

  <!-- </div> -->

  <!-- </div> -->

  <!-- </div> -->
</template>

<script>
import * as $ from "jquery";
export default {
  name: 'autologout',
  data: () => ({
    events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'],
    warningTimer: null,
    logoutTimer: null,
    setInterval_global: null,
    setinterval5: null,
    warningZone: false,
    timerCount: 60
  }),
  methods: {
    dontLogout () {
      this.combineTimer()
      this.warningZone = false
      this.openAutoLogout()
      clearInterval(this.setInterval5)
      this.timerCount--;
      $(".modal").modal("hide");
    },
    combineTimer () {
      this.resetTimer()
      this.warningZone = false;
      this.setTimers()
    },
    setTimers: function () {
      let vm = this
      clearInterval(this.setInterval_global)
      vm.setInterval_global = setInterval(() => {
        if (!vm.warningZone) {
          vm.warningTimer--
          
        }
      }, 1000);
    },

    warningMessage () {
      this.events.forEach(function (event) {
      window.removeEventListener(event, this.combineTimer)
      }, this);
      this.warningZone = true;
      this.openAutoLogout()
      this.CountDownTimer()

    },

    logoutUser: function () {
      this.$store.dispatch('logoutUser')
        .then((result) => {
          this.$router.push('/')
          // location.reload()
        })

    },

    resetTimer () {
      this.setinterval5 = null
      this.warningTimer = 2700;
      this.timerCount = 60;
      clearInterval(this.setInterval_global)
      clearInterval(this.setinterval5)
    },

    CountDownTimer () {
      let vm = this
      clearInterval(this.setinterval5)
      this.setInterval5 = setInterval(() => {
        if (vm.warningZone) {
          vm.timerCount--
        }
      }, 1000)
    },

    openAutoLogout () {
      $('#auto-logout').modal("show")
    }
  },
  mounted () {
    this.resetTimer();

    this.setTimers();


    this.events.forEach(function (event) {
      window.addEventListener(event, this.combineTimer)
    }, this);

  },
  destroyed () {
    this.events.forEach(function (event) {
      window.removeEventListener(event, this.combineTimer)
    }, this);
    this.resetTimer()
  },
  watch: {
    timerCount: {
      handler (value) {
        if (value < 0) {
          this.resetTimer()
          this.logoutUser()

        }
      }
    },
    warningTimer: {
      handler (value) {
        if (value < 0) {
          this.resetTimer()
          this.warningMessage()

        }
      }
    }
  },

}

</script>
<style scoped>
button {
  color: #fff;
  margin-left: 10px;
}
</style>