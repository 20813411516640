<template>
  <div id="dispatch">
    <div class="">
      <div class="p-0">
        <Sidemenu />
        <div id="main">
          <Topmenu />

          <div class="main-content my-5">
            <div class="container">
              <div id="customer" class="card mb-4">
                <div class="card-header">
                  <h4 class="title">Select Vehicle</h4>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-6">
                      <div
                        class="type"
                        v-bind:class="
                          dispatch_data.vechicletype == 'bike'
                            ? 'selectedtype'
                            : ''
                        "
                        @click="dispatch_data.vechicletype = 'bike'"
                      >
                        <img src="../assets/img/bike.png" alt="" />
                        <h4 class="caption">Bike</h4>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div
                        class="type"
                        @click="dispatch_data.vechicletype = 'van'"
                        v-bind:class="
                          dispatch_data.vechicletype == 'van'
                            ? 'selectedtype'
                            : ''
                        "
                      >
                        <img src="../assets/img/van.png" alt="" />
                        <h4 class="caption">Van</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="container">
              <div id="customer" class="card">
                <div class="card-header">
                  <h4 class="title">Select Option</h4>
                  <p class="sub-title">I am the/a</p>
                </div>
                <div class="card-body">
                  <div
                    class="row options"
                    @click="option = 'sender'"
                    v-bind:class="option == 'sender' ? 'active' : ''"
                    data-toggle="modal"
                    data-target="#dispatchform"
                  >
                    <div class="col-3">
                      <img
                        src="../assets/img/sender.png"
                        alt=""
                        class="img-fluid"
                      />
                    </div>
                    <div class="col-9 d-flex">
                      <div>
                        <h5 class="label">Sender</h5>
                        <p class="content">
                          The dispatch rider will be picking up package from me
                        </p>
                      </div>
                      <div class="selected-img">
                        <img src="../assets/img/check-sm.png" alt="" />
                      </div>
                    </div>
                  </div>
                  <div
                    class="row options"
                    @click="option = 'receiver'"
                    v-bind:class="option == 'receiver' ? 'active' : ''"
                    data-toggle="modal"
                    data-target="#dispatchform"
                  >
                    <div class="col-3">
                      <img
                        src="../assets/img/receiver.jpg"
                        alt=""
                        class="img-fluid"
                      />
                    </div>
                    <div class="col-9 d-flex">
                      <div>
                        <h5 class="label">Receiver</h5>
                        <p class="content">
                          The dispatch rider will be delivering package to me
                        </p>
                      </div>
                      <div class="selected-img">
                        <img src="../assets/img/check-sm.png" alt="" />
                      </div>
                    </div>
                  </div>
                  <div
                    class="row options"
                    @click="option = 'thirdparty'"
                    v-bind:class="option == 'thirdparty' ? 'active' : ''"
                    data-toggle="modal"
                    data-target="#dispatchform"
                  >
                    <div class="col-3">
                      <img
                        src="../assets/img/thirdparty.png"
                        alt=""
                        class="img-fluid"
                      />
                    </div>
                    <div class="col-9 d-flex">
                      <div>
                        <h5 class="label">Third Party</h5>
                        <p class="content">
                          I am placing the order on someone' s behalf
                        </p>
                      </div>
                      <div class="selected-img">
                        <img src="../assets/img/check-sm.png" alt="" />
                      </div>
                    </div>
                  </div>

                  <!-- <div class="text-center">
                    <button
                      v-bind:disabled="option ==''"
                      data-toggle="modal"
                      data-target="#dispatchform"
                      type="button"
                      class="btn ml-2 my-auto"
                    >Proceed</button>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--dispatchform modal -->
    <div id="dispatchform" class="modal" tabindex="-1" data-backdrop="static">
      <div class="modal-dialog">
        <!-- delivery -->
        <div v-if="showlocationform" class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title mx-sm-5">Where are you delivering to?</h5>

            <img
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              src="../assets/img/close.png"
              alt=""
              @click.prevent="reset"
            />
          </div>
          <ValidationObserver v-slot="{ handleSubmit }">
            <div class="modal-body mx-sm-5">
              <div class="form-group">
                <label for="">Pickup Location</label>
                <validation-provider rules="required" v-slot="{ errors }">
                  <input
                    id="map"
                    type="text"
                    ref="search"
                    v-model="pickup_loc"
                    @change="getAddAddress(pickup_loc, 'pickup')"
                    class="form-control"
                    placeholder="Enter pickup location"
                  />
                  <img
                    id="pickup_gif"
                    class="loc_spinner"
                    src="../assets/img/processing.gif"
                    alt=""
                  />
                  <span class="err_msg">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <div class="form-group">
                <label for="">Dropoff Location</label>
                <validation-provider rules="required" v-slot="{ errors }">
                  <input
                    type="text"
                    @change="getAddAddress(dropoff_loc, 'delivery')"
                    v-model="dropoff_loc"
                    class="form-control"
                    placeholder="Enter dropoff location"
                  />
                  <img
                    id="dropoff_gif"
                    class="loc_spinner"
                    src="../assets/img/processing.gif"
                    alt=""
                  />
                  <span class="err_msg">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <div class="form-group">
                <label for="">Item Name</label>
                <validation-provider rules="required" v-slot="{ errors }">
                  <input
                    type="text"
                    v-model="dispatch_data.itemname"
                    class="form-control"
                    placeholder="Enter item name"
                  />
                  <span class="err_msg">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <div class="form-row">
                <div class="form-group col-sm-6">
                  <label for="">Quantity</label>
                  <validation-provider
                    rules="required|numeric"
                    v-slot="{ errors }"
                  >
                    <input
                      type="number"
                      v-model="dispatch_data.itemquantity"
                      placeholder="Enter quantity"
                      class="form-control"
                    />
                    <span class="err_msg">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
                <div class="form-group col-sm-6">
                  <label for="">Delivery Type</label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <select
                      v-model="dispatch_data.deliverytype"
                      class="form-control"
                    >
                      <option value="" selected disabled>
                        Select Delivery Type
                      </option>
                      <option value="express">Express</option>
                      <option value="pool">Pool</option>
                    </select>
                    <span class="err_msg">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
              </div>
              <div class="form-group">
                <label for="">Delivery Fee Type</label>
                <validation-provider rules="required" v-slot="{ errors }">
                  <select
                    v-model="dispatch_data.deliveryfeetype"
                    @change="calcFee(dispatch_data.deliveryfeetype)"
                    class="form-control"
                  >
                    <option value="" selected disabled>Select Fee Type</option>
                    <option value="distance">Distance(Km)</option>
                    <option value="weight">Weight(Kg)</option>
                    <option value="flat_rate">Flat Rate</option>
                  </select>
                  <img
                    id="delivery_gif"
                    class="loc_spinner"
                    src="../assets/img/processing.gif"
                    alt=""
                  />
                  <span class="err_msg">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <div
                class="form-group"
                v-show="dispatch_data.deliveryfeetype == 'weight'"
              >
                <label for="">Weight(kg)</label>
                <validation-provider rules="numeric" v-slot="{ errors }">
                  <input
                    type="number"
                    v-model="dispatch_data.weight"
                    @change="
                      isweightbased = 1;
                      isdistancebased = 0;
                      isflatbased = 0;
                      handleDelFee();
                    "
                    placeholder="Enter item weight(kg)"
                    class="form-control"
                  />
                  <span class="err_msg">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <!-- {{dispatch_data}} -->
              <div class="form-group">
                <label for="">Delivery Fee</label>
                <validation-provider rules="numeric" v-slot="{ errors }">
                  <input
                    type="number"
                    disabled
                    :value="dispatch_data.deliverycharge"
                    placeholder=""
                    class="form-control"
                  />
                  <span class="err_msg">{{ errors[0] }}</span>
                </validation-provider>
              </div>

              <div class="form-group">
                <label for="">Add Delivery Note <i>(optional)</i></label>
                <validation-provider>
                  <textarea
                    class="form-control"
                    v-model="dispatch_data.deliveryinstruction"
                  ></textarea>
                </validation-provider>
              </div>
            </div>
            <div class="modal-footer mx-5 py-4 border-0">
              <button
                data-dismiss="modal"
                type="button"
                class="cancel-close btn my-auto"
                @click.prevent="reset"
              >
                Cancel
              </button>
              <button
                v-bind:disabled="
                  dispatch_data.pickuplatitude == '' ||
                  dispatch_data.deliverylatitude == ''
                "
                @click.prevent="handleSubmit(handleFee)"
                type="button"
                class="btn ml-2 my-auto"
              >
                Next
              </button>
            </div>
          </ValidationObserver>
        </div>
      </div>
    </div>
    <!-- payment/delivery method -->
    <div id="paymentform" class="modal" tabindex="-1" data-backdrop="static">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <img
              class="close"
              @click.prevent="
                showlocationform = true;
                showmethod = false;
              "
              data-dismiss="modal"
              aria-label="Close"
              src="../assets/img/close.png"
              alt=""
            />
          </div>

          <div class="modal-body mx-sm-5">
            <h5 class="modal-title mx-sm-5 text-center mb-2">
              Select Fulfillment Method
            </h5>

            <div class="row">
              <div class="col-sm-6 border-right">
                <!-- <button
                    @click="dispatch_data.ordermethod = 'pickup'"
                    type="button"
                    class="btn ml-2 my-auto"
                 >Pickup</button>
                 <button
                    @click="dispatch_data.ordermethod = 'delivery'"
                    type="button"
                    class="btn ml-2 my-auto"
                 >Delivery</button> -->
                <div
                  title="Select Pickup"
                  class="ordermethod d-flex"
                  @click="dispatch_data.ordermethod = 'pickup'"
                  v-bind:class="
                    dispatch_data.ordermethod == 'pickup' ? 'active' : ''
                  "
                >
                  <div>
                    <!-- <h5 class="topic">
                  {{dispatch_data.ordermethod}}
                      </h5> -->
                    <div class="form-group">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          value="pickup"
                          id="gridCheck"
                          v-model="dispatch_data.ordermethod"
                        />
                        <label class="form-check-label" for="gridCheck">
                          Pickup
                        </label>
                      </div>
                    </div>
                    <p class="content">Pickup package from another location</p>
                  </div>
                  <div class="selected-img">
                    <img src="../assets/img/check-sm.png" alt="" />
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div
                  title="Select Delivery"
                  class="ordermethod d-flex"
                  @click="dispatch_data.ordermethod = 'delivery'"
                  v-bind:class="
                    dispatch_data.ordermethod == 'delivery' ? 'active' : ''
                  "
                >
                  <div>
                    <!-- <h5 class="topic">Delivery</h5> -->
                    <div class="form-group">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          value="delivery"
                          id="gridCheck"
                          v-model="dispatch_data.ordermethod"
                        />
                        <label class="form-check-label" for="gridCheck">
                          Delivery
                        </label>
                      </div>
                    </div>
                    <p class="content">Delivery to a location</p>
                  </div>
                  <div class="selected-img">
                    <img src="../assets/img/check-sm.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-6">
                <div class="d-flex">
                  <img src="../assets/img/currency.png" alt="" />
                  <h5 class="price my-auto">
                    <span style="font-size: 14px; opacity: 0.7">Price </span>
                    <br />
                    ₦ {{ formatPrice(dispatch_data.deliverycharge) }}
                  </h5>
                </div>
              </div>
              <div class="col-6 text-right">
                <h5 style="font: normal normal 600 18px/28px Source Sans Pro">
                  <strong>ETA</strong>
                  <span style="font-size: 13px" class="ml-2">{{
                    dispatch_data.ETA
                  }}</span>
                </h5>
              </div>
            </div>
            <hr />

            <div
              class="col-sm-12"
              v-if="dispatch_data.ordermethod == 'delivery'"
            >
              <h5 class="modal-title mx-sm-5 mt-3 mb-2 text-center">
                Select Pay on Delivery
              </h5>
              <div
                title="Select if you want to pay on delivery"
                class="ordermethod d-flex mb-2"
                @click="selectOption('pay-on-delivery')"
                v-bind:class="
                  paymentoption.includes('pay-on-delivery') ? 'active' : ''
                "
              >
                <div>
                  <h5 class="topic">Pay on Delivery</h5>
                  <p class="content">
                    Pay for the dispatch after the delivery is Completed
                  </p>
                </div>
                <div class="selected-img">
                  <img src="../assets/img/check-sm.png" alt="" />
                </div>
              </div>
            </div>
            <!-- payment options list -->
            <h5
              class="modal-title mx-sm-5 text-center"
              v-if="
                paymentoption.includes('pay-on-delivery') == false ||
                dispatch_data.ordermethod == 'pickup'
              "
            >
              Select Payment Method
            </h5>
            <div
              id="payment-option"
              class="my-3 px-3"
              v-if="
                paymentoption.includes('pay-on-delivery') == false ||
                dispatch_data.ordermethod == 'pickup'
              "
            >
              <div class="row">
                <div class="col-6 col-sm-6 col-xl-3 mb-1">
                  <div
                    class="options py-4"
                    @click="selectOption('cash')"
                    v-bind:class="
                      paymentoption.includes('cash') ? 'active' : ''
                    "
                  >
                    <img src="../assets/img/cash.png" alt="" />
                    <p>Cash</p>
                  </div>
                </div>
                <div class="col-6 col-sm-6 col-xl-3 mb-1">
                  <div
                    class="options py-4"
                    @click="selectOption('pos')"
                    v-bind:class="paymentoption.includes('pos') ? 'active' : ''"
                  >
                    <img src="../assets/img/pos.png" alt="" />
                    <p>POS</p>
                  </div>
                </div>
                <div class="col-6 col-sm-6 col-xl-3 mb-1">
                  <div
                    class="options py-4"
                    @click="selectOption('banktransfer')"
                    v-bind:class="
                      paymentoption.includes('banktransfer') ? 'active' : ''
                    "
                  >
                    <img src="../assets/img/transfer.png" alt="" />
                    <p>Bank Transfer</p>
                  </div>
                </div>
                <div class="col-6 col-sm-6 col-xl-3 mb-1">
                  <div
                    class="options py-4"
                    @click="selectOption('third_party')"
                    v-bind:class="
                      paymentoption.includes('third_party') ? 'active' : ''
                    "
                  >
                    <img src="../assets/img/cash.png" alt="" />
                    <p>3rd Party</p>
                  </div>
                </div>
              </div>
              <hr />
            </div>

            <!-- payment body -->
            <div
              id="payment-body"
              class="px-4"
              v-if="
                paymentoption.includes('pay-on-delivery') == false ||
                dispatch_data.ordermethod == 'pickup'
              "
            >
              <!-- cash payment -->
              <div v-if="paymentoption.includes('cash')" class="cash">
                <h4 class="title text-bold my-3">Cash</h4>
                <h5>How much did the customer give you?</h5>

                <div class="form-group">
                  <label class="sr-only" for="inlineFormInputGroup"
                    >Enter amount</label
                  >
                  <div class="input-group mb-2">
                    <div class="input-group-prepend">
                      <div class="input-group-text">₦</div>
                    </div>
                    <validation-provider rules="required" v-slot="{ errors }">
                      <input
                        type="text"
                        v-model="cash_amountpaid"
                        class="form-control"
                        id="inlineFormInputGroup"
                        placeholder="Enter amount"
                        v-on:keyup="formatInput"
                        @input="setTotalMoney()"
                      />
                      <span class="err_msg">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                  <span class="note"
                    >Always count and confirm money from customers</span
                  >
                </div>
              </div>
              <!-- pos payment -->
              <div v-if="paymentoption.includes('pos')" class="pos">
                <h4 class="title text-bold my-3">POS</h4>

                <h5>Receipt number</h5>

                <div class="form-group">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <input
                      type="text"
                      v-model="receiptnumber"
                      required
                      class="form-control"
                      placeholder="Type receipt number here"
                    />
                    <span class="note"
                      >Enter the receipt number on the POS printout</span
                    >
                    <span class="err_msg">{{ errors[0] }}</span>
                  </validation-provider>
                </div>

                <div class="form-group">
                  <label class="sr-only" for="inlineFormInputGroup"
                    >Enter amount</label
                  >
                  <div class="input-group mb-2">
                    <div class="input-group-prepend">
                      <div class="input-group-text">₦</div>
                    </div>
                    <validation-provider rules="required" v-slot="{ errors }">
                      <input
                        type="text"
                        v-model="pos_amountpaid"
                        class="form-control"
                        id="inlineFormInputGroup"
                        placeholder="Enter amount"
                        v-on:keyup="formatInputPos"
                        @input="setTotalMoney()"
                      />
                      <span class="err_msg">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                </div>
              </div>
              <!-- bank transfer payment -->
              <div
                v-if="paymentoption.includes('banktransfer')"
                class="transfer"
              >
                <h4 class="title text-bold my-3">Bank Transfer</h4>
                <div class="form-group">
                  <label class="sr-only" for="inlineFormInputGroup"
                    >Enter amount</label
                  >
                  <div class="input-group mb-2">
                    <div class="input-group-prepend">
                      <div class="input-group-text">₦</div>
                    </div>
                    <validation-provider rules="required" v-slot="{ errors }">
                      <input
                        type="text"
                        v-model="bank_amountpaid"
                        class="form-control"
                        id="inlineFormInputGroup"
                        placeholder="Enter amount"
                        v-on:keyup="formatInputBank"
                        @input="setTotalMoney()"
                      />
                      <span class="err_msg">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                </div>
                <!-- <div class="form-group">
                  <label for="">Account name</label>
                  <validation-provider
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <input
                      type="text"
                      v-model="bank.accountname"
                      class="form-control"
                      placeholder="Enter the customer’s account name"
                    >
                    <span class="err_msg">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
                <div class="form-group">
                  <label for="">Account number</label>
                  <validation-provider
                    rules="required|numeric|length:10"
                    v-slot="{ errors }"
                  >
                    <input
                      type="text"
                      v-model="bank.accountno"
                      class="form-control"
                      placeholder="Enter the customer’s account number"
                    >
                    <span class="err_msg">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
                <div class="form-group">
                  <label for="">Bank</label>
                  <validation-provider
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <select
                      name=""
                      id=""
                      v-model="bank.bankname"
                      class="form-control"
                    >
                      <option
                        value=""
                        selected
                        hidden
                      >select the customer's bank</option>
                      <option>Access Bank</option>
                      <option>Citibank</option>
                      <option>Ecobank</option>
                      <option>Fidelity Bank</option>
                      <option>First City Monument Bank (FCMB)</option>
                      <option>FSDH Merchant Bank</option>
                      <option>Guarantee Trust Bank (GTB)</option>
                      <option>Heritage Bank</option>
                      <option>Keystone Bank</option>
                      <option>Polaris Bank</option>
                      <option>Rand Merchant Bank</option>
                      <option>Stanbic IBTC Bank</option>
                      <option>Standard Chartered Bank</option>
                      <option>Sterling Bank</option>
                      <option>Suntrust Bank</option>
                      <option>Union Bank</option>
                      <option>United Bank for Africa (UBA)</option>
                      <option>Unity Bank</option>
                      <option>Wema Bank</option>
                      <option>Zenith Bank</option>
                    </select>
                    <span class="err_msg">{{ errors[0] }}</span>
                  </validation-provider>
                </div> -->
              </div>
              <!-- Third Party payment -->
              <div
                v-if="paymentoption.includes('third_party')"
                class="third_party"
              >
                <h4 class="title text-bold my-3">Third Party</h4>

                <h5>Thirdparty Gateway</h5>
                <div class="form-group">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <select
                      name=""
                      id=""
                      class="form-control"
                      v-model="thirdpartyobj.paymentaccountname"
                    >
                      <option>PayStack</option>
                      <option>Flutterwave</option>
                      <option>Remita</option>
                      <option>GlobalPay</option>
                      <option>PayPal</option>
                      <option>PalmPay</option>
                      <option>Opay</option>
                    </select>
                    <span class="err_msg">{{ errors[0] }}</span>
                  </validation-provider>
                </div>

                <h5>Amount</h5>
                <div class="form-group">
                  <label class="sr-only" for="inlineFormInputGroup"
                    >Enter amount</label
                  >
                  <div class="input-group mb-2">
                    <div class="input-group-prepend">
                      <div class="input-group-text">₦</div>
                    </div>
                    <validation-provider
                      rules="required"
                      v-slot="{ errors }"
                      style="width: calc(100% - 33px)"
                    >
                      <input
                        type="text"
                        v-model="thirdparty_amountpaid"
                        class="form-control"
                        id="inlineFormInputGroup"
                        placeholder="Enter amount"
                        v-on:keyup="formatInputThirdparty"
                        @input="setTotalMoney()"
                      />
                      <span class="err_msg">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                </div>
              </div>

              <!-- change cashback-->
              <div
                v-show="cash_amountpaid > 0 || paymentoption.includes('cash')"
                class="form-group"
              >
                <h5>Change</h5>
                <label class="sr-only" for="inlineFormInputGroup">Change</label>
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <div class="input-group-text">₦</div>
                  </div>
                  <validation-provider rules="" v-slot="{ errors }">
                    <input
                      v-if="cash_amountpaid == ''"
                      type="text"
                      disabled
                      :value="0"
                      class="form-control"
                      id="inlineFormInputGroup"
                      placeholder="Enter amount"
                    />
                    <input
                      v-else-if="
                        total_money - Number(dispatch_data.deliverycharge) > 0
                      "
                      type="text"
                      disabled
                      :value="
                        paymentoption.includes('cash')
                          ? formatPrice(
                              isNaN(total_money)
                                ? 0
                                : total_money -
                                    Number(dispatch_data.deliverycharge)
                            )
                          : 0
                      "
                      class="form-control"
                      id="inlineFormInputGroup"
                      placeholder="Enter amount"
                    />
                    <input
                      v-else
                      type="text"
                      disabled
                      :value="0"
                      class="form-control"
                      id="inlineFormInputGroup"
                      placeholder="Enter amount"
                    />
                    <span class="err_msg">{{ errors[0] }}</span>
                  </validation-provider>
                </div>

                <span v-if="cash_amountpaid == ''" class="note">
                  Customer balance is ₦ 0</span
                >

                <span
                  v-else-if="
                    total_money - Number(dispatch_data.deliverycharge) > 0
                  "
                  class="note"
                  >Customer balance is ₦
                  {{
                    paymentoption.includes("cash")
                      ? formatPrice(
                          isNaN(total_money)
                            ? 0
                            : total_money - Number(dispatch_data.deliverycharge)
                        )
                      : 0
                  }}
                </span>

                <span v-else class="note">Customer balance is ₦ 0</span>
              </div>

              <!-- end change -->
            </div>
          </div>
          <div class="modal-footer mx-5 py-4 border-0">
            <div class="col-sm-12">
              <small v-if="dispatch_data.ordermethod == ''" class="prompt"
                >You must select a fulfillment method(Pickup/Delivery) before
                you continue</small
              >
              <small
                v-if="dispatch_data.ordermethod == 'pickup'"
                class="text-success"
                >You preferred fulfillment method is Pickup</small
              >
              <small
                v-if="dispatch_data.ordermethod == 'delivery'"
                class="text-success"
                >You preferred fulfillment method is Delivery</small
              >
            </div>
            <button
              @click.prevent="goBack()"
              type="button"
              class="cancel-close btn my-auto"
              data-dismiss="modal"
              aria-label="Close"
            >
              Back
            </button>
            <button
              v-bind:disabled="checkpayment"
              @click.prevent="showRider()"
              type="button"
              class="btn ml-2 my-auto"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- delivery info -->

    <div id="riderform" class="modal" tabindex="-1" data-backdrop="static">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title mx-sm-5">Dispatch Info</h5>

            <img
              class="close"
              @click.prevent="
                showmethod = true;
                showinfo = false;
                searchresult = [];
                searchcustomer = '';
                customerdetails = {};
              "
              data-dismiss="modal"
              aria-label="Close"
              src="../assets/img/close.png"
              alt=""
            />
          </div>
          <ValidationObserver v-slot="{ handleSubmit }">
            <div class="modal-body mx-sm-5">
              <form @submit.prevent="fetchCustomers()">
                <div
                  v-if="option == 'sender' || option == 'receiver'"
                  class="form-group mb-5"
                >
                  <input
                    type="text"
                    id="searchtext"
                    v-model="searchcustomer"
                    required
                    class="form-control search"
                    placeholder="Search for customer"
                  />
                  <span
                    v-bind:class="searchcustomer == '' ? 'disable-click' : ''"
                    @click="fetchCustomers()"
                    class="fa fa-search"
                  ></span>
                  <span class="text-danger" style="font-size: 10px; margin: 0"
                    ><small>{{ search_err }}</small></span
                  >
                </div>
              </form>
              <div v-if="searchresult.length > 0">
                <ul id="cus-search" class="p-0">
                  <li
                    @click.prevent="setCustomer(row)"
                    v-for="(row, ix) in searchresult"
                    v-bind:key="ix"
                    class="dropdown-item"
                  >
                    {{ row.firstname }} {{ row.lastname }}
                    <div class="dropdown-divider"></div>
                  </li>
                </ul>
              </div>
              <fieldset>
                <!-- <h5 class="topic text-secondary">Sender Information</h5> -->
                <div class="form-group">
                  <label for="">Sender's full name</label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <input
                      type="text"
                      v-model="dispatch_data.sendername"
                      class="form-control"
                      placeholder="Enter Sender's name"
                    />

                    <span class="err_msg">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
                <div class="form-group">
                  <label for="">Sender's phone number</label>
                  <validation-provider
                    :rules="{
                      regex:
                        /^(\+234|234|0)(701|702|703|704|705|706|707|708|709|802|803|804|805|806|807|808|809|810|811|812|813|814|815|816|817|818|819|909|908|901|902|903|904|905|906|907)([0-9]{7})$/,
                    }"
                    v-slot="{ errors }"
                  >
                    <input
                      type="text"
                      v-model="dispatch_data.senderphone"
                      class="form-control"
                      placeholder="Enter Sender's  phone number"
                    />

                    <span class="err_msg">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
              </fieldset>
              <hr />
              <fieldset>
                <!-- <h5 class="topic text-secondary">Receiver Information</h5> -->
                <div class="form-group">
                  <label for="">Receiver's full name</label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <input
                      v-model="dispatch_data.receivername"
                      type="text"
                      class="form-control"
                      placeholder="Enter receiver's name"
                    />
                    <span class="err_msg">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
                <div class="form-group">
                  <label for="">Receiver's phone number</label>
                  <validation-provider
                    :rules="{
                      regex:
                        /^(\+234|234|0)(701|702|703|704|705|706|707|708|709|802|803|804|805|806|807|808|809|810|811|812|813|814|815|816|817|818|819|909|908|901|902|903|904|905|906|907)([0-9]{7})$/,
                    }"
                    v-slot="{ errors }"
                  >
                    <input
                      v-model="dispatch_data.receiverphonenumber"
                      type="text"
                      class="form-control"
                      placeholder="Enter Receiver's  phone number"
                    />
                    <span class="err_msg">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
              </fieldset>
              <!-- <div class="form-group">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value="PENDING"
                    id="gridCheck"
                    v-model="dispatch_data.paymentstatus"
                  >
                  <label
                    class="form-check-label"
                    for="gridCheck"
                  >
                    Payment on delivery

                  </label>
                </div>
              </div> -->
            </div>
            <div class="modal-footer mx-5 py-4 border-0">
              <button
                @click.prevent="handleSubmit(handleAssign)"
                type="button"
                class="btn ml-2 my-auto"
              >
                Assign Rider
              </button>
            </div>
          </ValidationObserver>
        </div>
      </div>
    </div>
    <!-- rider modal -->
    <div id="rider" class="modal" tabindex="-1" data-backdrop="static">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header border-0">
            <h5 class="modal-title mx-sm-5">Select a rider</h5>

            <img
              @click.prevent="goBack()"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              src="../assets/img/close.png"
              alt=""
            />
          </div>
          <div class="modal-body mx-5">
            <div class="card my-3">
              <div class="card-body">
                <h5 class="text-bold title mx-sm-5">
                  We found {{ riders.length }} available riders near you
                </h5>
                <div class="mt-5">
                  <div
                    v-for="(row, index) in riders"
                    v-bind:key="index"
                    @click="
                      rider = row;
                      dispatch_data.riderID = row.id;
                    "
                    class="row mt-3 riders px-sm-5 py-2"
                    v-bind:class="
                      dispatch_data.riderID == row.id ? 'active' : ''
                    "
                  >
                    <div class="col-8">
                      <div class="d-flex">
                        <div class="my-auto">
                          <div class="riderimg">
                            <img
                              class="img-fluid"
                              :src="row.riderphoto"
                              alt=""
                            />
                          </div>

                          <p class="rating">
                            <span>{{ row.riderrate }}</span>
                            <!-- <img
                            src="../assets/img/star.png"
                            alt=""
                          > -->
                          </p>
                        </div>
                        <div class="ml-4">
                          <h5 class="text-bold">
                            {{ row.riderfirstname }} {{ row.riderlastname }}
                          </h5>
                          <p>{{ row.riderphonenumber }}</p>
                          <p>{{ row.registrationnumber }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-4 my-auto d-flex">
                      <!-- <p class="stat">Rider is 5 min away</p> -->
                      <img
                        class="ml-3 selected"
                        src="../assets/img/check-sm.png"
                        alt=""
                      />
                    </div>
                    <hr class="col-12" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer mx-5 py-4 border-0">
            <button
              @click.prevent="goBack()"
              type="button"
              class="cancel-close btn my-auto"
            >
              Back
            </button>
            <!-- <button
              v-bind:disabled="dispatch_data.riderID ==''"
              @click.prevent="placeOrder"
              type="button"
              class="btn ml-2 my-auto"
            >Done</button> -->
            <button
              @click.prevent="handleSummary"
              type="button"
              class="btn ml-2 my-auto"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- order summary modal -->
    <div id="ordersummary" class="modal" tabindex="-1" data-backdrop="static">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header border-0">
            <h5 class="modal-title mx-sm-5">Order Summary</h5>

            <img
              @click.prevent="goBack()"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              src="../assets/img/close.png"
              alt=""
            />
          </div>
          <div class="modal-body mx-5">
            <div class="card my-3">
              <div class="card-body">
                <div class="row">
                  <div class="col-7">
                    <div class="d-flex">
                      <img src="../assets/img/currency.png" alt="" />
                      <h5 class="price my-auto">
                        <span style="font-size: 14px; opacity: 0.7"
                          >Price
                        </span>
                        <br />
                        ₦ {{ formatPrice(dispatch_data.deliverycharge) }}
                      </h5>
                    </div>
                    <hr />
                    <h5
                      style="font: normal normal 600 18px/28px Source Sans Pro"
                    >
                      <strong>ETA</strong>
                      <span class="ml-2">{{ dispatch_data.ETA }}</span>
                    </h5>
                  </div>
                  <div class="col-5">
                    <div
                      v-if="Object.keys(customerdetails).length > 0"
                      id="redeem"
                      data-target="#loyalty"
                      data-toggle="modal"
                      class="d-flex justify-content-around"
                    >
                      <div class="m-auto">
                        <img src="../assets/img/gift-icon.png" alt="" />
                      </div>
                      <span class="m-auto">Redeem Reward</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card my-3">
              <div class="card-body">
                <div>
                  <p class="topic">Pickup Location</p>
                  <p class="content">{{ dispatch_data.pickupaddress }}</p>
                </div>
                <hr />
                <div>
                  <p class="topic">Dropoff Location</p>
                  <p class="content">{{ dispatch_data.deliveryaddress }}</p>
                </div>
                <hr />
                <div>
                  <p class="topic">Delivery Type</p>
                  <p class="content">{{ dispatch_data.deliverytype }}</p>
                </div>
              </div>
            </div>
            <div class="card my-3">
              <div class="card-body">
                <div>
                  <p class="topic">Sender</p>
                  <p class="content">
                    {{ dispatch_data.sendername }} <br />{{
                      dispatch_data.senderphone
                    }}
                  </p>
                </div>
                <hr />
                <div>
                  <p class="topic">Receiver</p>
                  <p class="content">
                    {{ dispatch_data.receivername }} <br />{{
                      dispatch_data.receiverphonenumber
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer mx-5 py-4 border-0">
            <button
              @click.prevent="goBack()"
              type="button"
              class="cancel-close btn my-auto"
            >
              Back
            </button>
            <!-- <button
              v-bind:disabled="dispatch_data.riderID ==''"
              @click.prevent="placeOrder"
              type="button"
              class="btn ml-2 my-auto"
            >Done</button> -->
            <button
              @click.prevent="placeOrder"
              type="button"
              class="btn ml-2 my-auto"
            >
              Checkout
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--redeem loyalty points modal -->
    <div id="loyalty" class="modal" tabindex="-1" data-backdrop="static">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title mx-sm-5">
              Redeem Ntisa Points
              <small>Showing rewards within the available point range</small>
            </h5>

            <img
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              src="../assets/img/close.png"
              alt=""
            />
          </div>
          <div class="modal-body mx-sm-5">
            <div class="text-center">
              <h3>{{ customerdetails["Ntisa Points"] }}</h3>
              <p class="text-uppercase">Ntisa points</p>
            </div>

            <ul
              class="nav nav-tabs mt-3 justify-content-center"
              id="myTab"
              role="tablist"
            >
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="pricediscount-tab"
                  data-toggle="tab"
                  href="#pricediscount"
                  role="tab"
                  aria-controls="pricediscount"
                  aria-selected="true"
                  >Price Discount</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="percentdiscount-tab"
                  data-toggle="tab"
                  href="#percentdiscount"
                  role="tab"
                  aria-controls="percentdiscount"
                  aria-selected="false"
                  >Percentage Discount</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="freeitem-tab"
                  data-toggle="tab"
                  href="#freeitem"
                  role="tab"
                  aria-controls="freeitem"
                  aria-selected="false"
                  >Free Item</a
                >
              </li>
            </ul>
            <div
              v-if="rewards !== null"
              class="tab-content mt-3"
              id="myTabContent"
            >
              <!-- price discount -->
              <div
                class="tab-pane fade show active"
                id="pricediscount"
                role="tabpanel"
                aria-labelledby="pricediscount-tab"
              >
                <div class="row" v-if="rewards.priceorderdiscount.length > 0">
                  <div
                    v-for="(row, ix) in rewards.priceorderdiscount"
                    v-bind:key="ix"
                    class="col-md-4 col-sm-6 col-xs-12"
                  >
                    <div
                      class="loyalties p-3"
                      @click="
                        reward = row;
                        dispatch_data.discountpayment = 'priceorderdiscount';
                      "
                      v-bind:class="reward.id == row.id ? 'active' : ''"
                    >
                      <h5 class="mt-3">{{ row.title }}</h5>
                      <hr />
                      <p>{{ row.rewarddiscount }} points</p>
                      <div class="checked">
                        <img src="../assets/img/check-sm.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center mt-5" v-else>
                  <img
                    class="img-fluid"
                    src="../assets/img/no-result.png"
                    alt=""
                  />
                </div>
              </div>

              <!-- percentage discount -->
              <div
                class="tab-pane fade"
                id="percentdiscount"
                role="tabpanel"
                aria-labelledby="percentdiscount-tab"
              >
                <div
                  class="row"
                  v-if="rewards.percentageorderdiscount.length > 0"
                >
                  <div
                    v-for="(row, ix) in rewards.percentageorderdiscount"
                    v-bind:key="ix"
                    class="col-md-4 col-sm-6 col-xs-12"
                  >
                    <div
                      class="loyalties p-3"
                      @click="
                        reward = row;
                        dispatch_data.discountpayment =
                          'percentageorderdiscount';
                      "
                      v-bind:class="reward.id == row.id ? 'active' : ''"
                    >
                      <h5 class="mt-3">{{ row.title }}</h5>
                      <hr />
                      <p>{{ row.rewarddiscount }} points</p>
                      <div class="checked">
                        <img src="../assets/img/check-sm.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center mt-5" v-else>
                  <img
                    class="img-fluid"
                    src="../assets/img/no-result.png"
                    alt=""
                  />
                </div>
              </div>

              <!-- free item -->
              <div
                class="tab-pane fade"
                id="freeitem"
                role="tabpanel"
                aria-labelledby="freeitem-tab"
              >
                <div class="row" v-if="rewards.freeitemcheckout.length > 0">
                  <div
                    v-for="(row, ix) in rewards.freeitemcheckout"
                    v-bind:key="ix"
                    class="col-md-4 col-sm-6 col-xs-12"
                  >
                    <div
                      class="loyalties p-3"
                      @click="
                        reward = row;
                        dispatch_data.discountpayment = 'freeitemcheckout';
                      "
                      v-bind:class="reward.id == row.id ? 'active' : ''"
                    >
                      <h5 class="mt-3">{{ row.title }}</h5>
                      <hr />
                      <p>{{ row.rewarddiscount }} points</p>
                      <div class="checked">
                        <img src="../assets/img/check-sm.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center mt-5" v-else>
                  <img
                    class="img-fluid"
                    src="../assets/img/no-result.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer mx-5 py-4 border-0">
            <button
              data-dismiss="modal"
              type="button"
              class="cancel-close btn my-auto"
            >
              Cancel
            </button>
            <button
              v-bind:disabled="dispatch_data.riderID == ''"
              @click.prevent="redeemLoyalty"
              type="button"
              class="btn ml-2 my-auto"
            >
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as $ from "jquery";
import Sidemenu from "@/components/sidemenu";
import Topmenu from "@/components/topmenu";
import { mapActions } from "vuex";

export default {
  name: "Dispatch",
  components: {
    Sidemenu,
    Topmenu,
  },
  data() {
    return {
      order_tdata: {},

      thirdpartyobj: {},
      total_money: Number(0),
      option: "",
      paymentoption: [],
      showlocationform: true,
      showmethod: false,
      showinfo: false,
      showsummary: false,
      isdistancebased: 0,
      isweightbased: 0,
      isflatbased: 0,
      geoCode: {
        longitude: "", // 6.458985,
        latitude: "", //3.601521
      },
      pickup_loc: "",
      dropoff_loc: "",
      dispatch_data: {
        riderID: "",
        pickuplongitude: "",
        pickuplatitude: "",
        deliverylongitude: "",
        deliverylatitude: "",
        deliverycharge: "",
        ETA: "",
        ordermethod: "",
        paymentstatus: "",
        pickupaddress: "",
        deliveryaddress: "",
        sendername: "",
        senderphone: "",
        receivername: "",
        receiverphonenumber: "",
        deliveryinstruction: "",
        itemname: "",
        itemquantity: "",
        weight: "",
        vechicletype: "bike",
        discountpayment: "0.00",
        deliverytype: "",
      },
      searchcustomer: "",
      search_err: "",
      searchresult: [],
      notfound: false,
      customerdetails: {},
      riders: [],
      rewards: null,
      reward: {},
      receiptnumber: "",
      cash_amountpaid: "",
      pos_amountpaid: "",
      bank_amountpaid: "",
      thirdparty_amountpaid: "",
      bank: {
        accountname: "",
        accountno: "",
        bankname: "",
      },
    };
  },
  created() {
    this.fetchRewards();
  },
  mounted() {
    this.fetchRiders();
  },
  watch: {
    searchcustomer(val) {
      if (val !== "") this.fetchCustomers();
      else this.searchresult = [];
    },
  },
  computed: {
    // checkpayment () {
    //   let amountpaid = 0;
    //   if (this.paymentoption.includes('cash')) {
    //     amountpaid += Number(this.cash_amountpaid)
    //   }
    //   if (this.paymentoption.includes('pos')) {
    //     amountpaid += Number(this.pos_amountpaid)
    //   }
    //   if (this.paymentoption.includes('banktransfer')) {
    //     amountpaid += Number(this.bank_amountpaid)
    //   }
    //   if (this.dispatch_data.deliverycharge !== amountpaid || this.dispatch_data.ordermethod === '') {
    //     return true;
    //   }
    //   else return false;
    // },
    checkpayment() {
      let amountpaid = 0;
      if (this.paymentoption.includes("cash")) {
        amountpaid += parseFloat(this.cash_amountpaid.replace(/,/g, ""));
      }
      if (this.paymentoption.includes("pos")) {
        amountpaid += parseFloat(this.pos_amountpaid.replace(/,/g, ""));
      }
      if (this.paymentoption.includes("banktransfer")) {
        amountpaid += parseFloat(this.bank_amountpaid.replace(/,/g, ""));
      }
      if (this.paymentoption.includes("third_party")) {
        amountpaid += parseFloat(this.thirdparty_amountpaid.replace(/,/g, ""));
      }

      // if ((Number(this.total) + Number(this.calculateTaxTotal())) >= amountpaid) {
      //   return false;
      // }
      if (
        Number(this.dispatch_data.deliverycharge) == amountpaid &&
        this.dispatch_data.ordermethod !== ""
      ) {
        return false;
      } else if (
        this.paymentoption.includes("cash") == true &&
        this.paymentoption.length > 1 &&
        this.dispatch_data.ordermethod !== "" &&
        Number(this.dispatch_data.deliverycharge) <= amountpaid
      ) {
        return false;
      } else if (
        this.paymentoption.includes("cash") == true &&
        this.paymentoption.length == 1 &&
        this.dispatch_data.ordermethod !== "" &&
        amountpaid >= Number(this.dispatch_data.deliverycharge) &&
        this.dispatch_data.ordermethod !== ""
      ) {
        return false;
      } else if (
        this.paymentoption.includes("pay-on-delivery") &&
        this.dispatch_data.ordermethod !== ""
      ) {
        return false;
      } else return true;
    },
  },
  methods: {
    ...mapActions({
      getCustomers: "customers/index",
      saveCustomers: "customers/store",
      alreadyExists: "customers/alreadyExists",
      findCustomer: "customers/searchCustomer",
    }),
    setTotalMoney() {
      let total_money = isNaN(this.total_money) ? 0 : this.total_money;

      //let displaycash_amount = isNaN(this.cash_amountpaid) ? 0 :  this.cash_amountpaid.replace(/,/g, '')

      let displaycash_amount = this.cash_amountpaid.replace(/,/g, "");

      let displaypos_amountpaid = this.pos_amountpaid.replace(/,/g, "");

      let displaybank_amountpaid = this.bank_amountpaid.replace(/,/g, "");

      let displaythirdparty_amountpaid = this.thirdparty_amountpaid.replace(
        /,/g,
        ""
      );

      if (!isNaN(total_money)) {
        //this.total_money = parseFloat(this.cash_amountpaid.replace(/,/g, '')) + parseFloat(this.pos_amountpaid.replace(/,/g, '')) + parseFloat(this.bank_amountpaid.replace(/,/g, ''));
        this.total_money =
          Number(displaycash_amount) +
          Number(displaypos_amountpaid) +
          Number(displaybank_amountpaid) +
          Number(displaythirdparty_amountpaid);
      }
      //this.total_money = parseFloat(this.cash_amountpaid.replace(/,/g, '')) + Number(this.pos_amountpaid) + Number(this.bank_amountpaid)+ Number(this.thirdparty_amountpaid);
    },
    formatInput() {
      if (this.cash_amountpaid != "") {
        let num = this.cash_amountpaid.replace(/,/g, ""); //parseFloat(this.cash_amountpaid.replace(/,/g, '')) //remove any currency format;
        if (num.substr(num.length - 1) == ".") {
          this.cash_amountpaid = num;
        } else {
          this.cash_amountpaid = parseFloat(
            this.cash_amountpaid.replace(/,/g, "")
          ).toLocaleString();
        }
      } else if (this.cash_amountpaid == "NaN") {
        this.cash_amountpaid = 0;
      }
      // isNaN(num) ?  this.cash_amountpaid = '' :  this.cash_amountpaid = Number(num).toLocaleString();
    },

    formatInputPos() {
      if (this.pos_amountpaid != "") {
        let num = this.pos_amountpaid.replace(/,/g, ""); //parseFloat(this.cash_amountpaid.replace(/,/g, '')) //remove any currency format;
        if (num.substr(num.length - 1) == ".") {
          this.pos_amountpaid = num;
        } else {
          this.pos_amountpaid = parseFloat(
            this.pos_amountpaid.replace(/,/g, "")
          ).toLocaleString();
        }
      } else if (this.pos_amountpaid == "NaN") {
        this.pos_amountpaid = 0;
      }
    },

    formatInputBank() {
      if (this.bank_amountpaid != "") {
        let num = this.bank_amountpaid.replace(/,/g, ""); //parseFloat(this.cash_amountpaid.replace(/,/g, '')) //remove any currency format;
        if (num.substr(num.length - 1) == ".") {
          this.bank_amountpaid = num;
        } else {
          this.bank_amountpaid = parseFloat(
            this.bank_amountpaid.replace(/,/g, "")
          ).toLocaleString();
        }
      } else if (this.bank_amountpaid == "NaN") {
        this.bank_amountpaid = 0;
      }
    },

    formatInputThirdparty() {
      if (this.thirdparty_amountpaid != "") {
        let num = this.thirdparty_amountpaid.replace(/,/g, "");
        if (num.substr(num.length - 1) == ".") {
          this.thirdparty_amountpaid = num;
        } else {
          this.thirdparty_amountpaid = parseFloat(
            this.thirdparty_amountpaid.replace(/,/g, "")
          ).toLocaleString();
        }
      } else if (this.thirdparty_amountpaid == "NaN") {
        this.thirdparty_amountpaid = 0;
      }
    },
    async fetchCustomers() {
      this.showLoader = true;
      try {
        let res = await this.findCustomer(this.searchcustomer.toLowerCase());

        if (res == null || res.length == 0) {
          this.notfound = true;
          this.searchresult = [];
          this.search_err = "customer not found";
        } else {
          this.notfound = false;
          this.searchresult = res;
        }
        this.showLoader = false;
        // this.searchcustomer = '';
        // }
      } catch (error) {
        this.notfound = true;
        this.showLoader = false;
      }
    },
    setCustomer(row) {
      this.customerdetails = row;
      this.searchresult = [];
      this.searchcustomer = "";
      this.search_err = "";
      if (this.option == "sender") {
        this.dispatch_data.sendername = row.firstname + " " + row.lastname;
        this.dispatch_data.senderphone = row.phonenumber;
      } else if (this.option == "receiver") {
        this.dispatch_data.receivername = row.firstname + " " + row.lastname;
        this.dispatch_data.receiverphonenumber = row.phonenumber;
      } else {
      }
    },
    reset() {
      this.thirdpartyobj = {};
      this.total_money = Number(0);
      this.option = "";
      this.paymentoption = [];
      this.showlocationform = true;
      this.showmethod = false;
      this.showinfo = false;
      this.showsummary = false;
      this.geoCode = {
        longitude: "", // 6.458985,
        latitude: "", //3.601521
      };
      this.pickup_loc = "";
      this.dropoff_loc = "";
      this.dispatch_data = {
        riderID: "",
        pickuplongitude: "",
        pickuplatitude: "",
        deliverylongitude: "",
        deliverylatitude: "",
        deliverycharge: "",
        ETA: "",
        ordermethod: "",
        paymentstatus: "",
        pickupaddress: "",
        deliveryaddress: "",
        sendername: "",
        senderphone: "",
        receivername: "",
        receiverphonenumber: "",
        deliveryinstruction: "",
        itemname: "",
        itemquantity: "",
        weight: "",
        vechicletype: "bike",
        discountpayment: "0.00",
        deliverytype: "",
      };
      this.searchcustomer = "";
      this.search_err = "";
      this.searchresult = [];
      this.notfound = false;
      this.customerdetails = {};
      this.riders = [];
      this.rewards = null;
      this.reward = {};
      this.receiptnumber = "";
      this.cash_amountpaid = "";
      this.pos_amountpaid = "";
      this.bank_amountpaid = "";
      this.thirdparty_amountpaid = "";
      this.bank = {
        accountname: "",
        accountno: "",
        bankname: "",
      };
    },
    reload() {
      this.reset();
      $(".modal").modal("hide");
      $("#dispatchform").modal("hide");
      // $("#payment").modal("hide");
    },
    fetchRewards() {
      let req = {
        what: "getRewards",
        showLoader: false,
        useToken: true,
        params: {
          merchID: this.$store.getters.user.merchID,
          branchID: this.$store.getters.user.branchID,
          rewardtype: "percentageorderdiscount",
        },
      };
      this.$request
        .makeGetRequest(req)
        .then((res) => {
          if (res.type == "getRewards") {
            this.rewards = res.data.data;
            if (req.showLoader == undefined || req.showLoader) {
              this.$swal.close();
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.is401(error.response.status);
        });
    },
    fetchRiders() {
      let req = {
        what: "getRiders",
        useToken: true,
        params: {
          merchID: this.$store.getters.user.merchID,
          branchID: this.$store.getters.user.branchID,
        },
      };
      this.$request
        .makeGetRequest(req)
        .then((res) => {
          if (res.type == "getRiders") {
            this.riders = res.data.data.data;
            if (req.showLoader == undefined || req.showLoader) {
              this.$swal.close();
            }
          }
        })
        .catch((error) => {
          this.$toasted.show(error.message, {
            theme: "bubble",
            type: "error",
            position: "top-right",
            duration: 6000,
          });
          // this.$swal.fire("Error", error.message, "error");
          this.is401(error.response.status);
        });
    },
    calcFee(opt) {
      if (opt == "distance") {
        this.isdistancebased = 1;
        this.isweightbased = 0;
        this.isflatbased = 0;
        this.dispatch_data.weight = 0;
        this.handleDelFee();
      }
      if (opt == "flat_rate") {
        this.isdistancebased = 0;
        this.isweightbased = 0;
        this.isflatbased = 1;
        this.handleDelFee();
      }
    },
    // handleFee () {
    //   this.dispatch_data.pickupaddress = this.pickup_loc;
    //   this.dispatch_data.deliveryaddress = this.dropoff_loc;

    //   let data = {
    //     pickuplog: this.dispatch_data.pickuplongitude,
    //     pickuplat: this.dispatch_data.pickuplatitude,
    //     deliverylog: this.dispatch_data.deliverylongitude,
    //     deliverylat: this.dispatch_data.deliverylatitude,
    //     weight: this.dispatch_data.weight,
    //     merchID: this.$store.getters.user.merchID,
    //     branchID: this.$store.getters.user.branchID,
    //     deliverytype: this.dispatch_data.deliverytype,
    //     isdistancebased: this.isdistancebased,
    //     isweightbased: this.isweightbased,
    //     isflatbased: this.isflatbased
    //   }
    //   let req = {
    //     what: "calculatedelivery",
    //     useToken: true,
    //     showLoader: true,
    //     data
    //   }
    //   console.log(req.data)
    //   this.$request.makePostRequest(req)
    //     .then(res => {
    //       if (res.type == 'calculatedelivery') {
    //         this.dispatch_data.ETA = res.data.data.ETA;
    //         this.dispatch_data.deliverycharge = res.data.data.deliveryfee;
    //         this.showlocationform = false;
    //       }
    //       $('#paymentform').modal('show')
    //     })

    //     .catch(error => {
    //       this.is401(error.response.status);
    //       this.$swal.fire("Error", error.message, "error");
    //     });

    // },
    handleFee() {
      $("#paymentform").modal("show");
    },
    handleDelFee() {
      this.dispatch_data.deliverycharge = 0;
      $("#delivery_gif").show();
      this.dispatch_data.pickupaddress = this.pickup_loc;
      this.dispatch_data.deliveryaddress = this.dropoff_loc;

      let data = {
        pickuplog: this.dispatch_data.pickuplongitude,
        pickuplat: this.dispatch_data.pickuplatitude,
        deliverylog: this.dispatch_data.deliverylongitude,
        deliverylat: this.dispatch_data.deliverylatitude,
        weight:
          this.dispatch_data.weight !== "" ? this.dispatch_data.weight : 0,
        merchID: this.$store.getters.user.merchID,
        branchID: this.$store.getters.user.branchID,
        deliverytype: this.dispatch_data.deliverytype,
        isdistancebased: this.isdistancebased,
        isweightbased: this.isweightbased,
        isflatbased: this.isflatbased,
      };
      let req = {
        what: "calculatedelivery",
        useToken: true,
        showLoader: false,
        data,
      };
      this.$request
        .makePostRequest(req)
        .then((res) => {
          if (res.type == "calculatedelivery") {
            this.dispatch_data.ETA = res.data.data.ETA;
            $("#delivery_gif").hide();
            return (this.dispatch_data.deliverycharge =
              res.data.data.deliveryfee);
          }
        })
        .catch((error) => {
          this.is401(error.response.status);
          this.$swal.fire("Error", error.message, "error");
        });
    },
    showRider() {
      $(".modal").modal("hide");
      $("#riderform").modal("show");
    },
    getAddAddress(address, type) {
      if (type == "pickup") {
        $("#pickup_gif").show();
      } else {
        $("#dropoff_gif").show();
      }
      let service = new google.maps.places.PlacesService(
        document.getElementById("map")
      );
      const request = {
        query: address,
        fields: ["name", "formatted_address", "geometry"],
      };
      let vm = this;
      service.findPlaceFromQuery(request, function (response, status) {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          if (type == "pickup") {
            vm.dispatch_data.pickuplatitude =
              response[0].geometry.location.toJSON().lat;
            vm.dispatch_data.pickuplongitude =
              response[0].geometry.location.toJSON().lng;
            vm.pickup_loc = response[0].formatted_address;
            $("#pickup_gif").hide();
          } else {
            vm.dispatch_data.deliverylatitude =
              response[0].geometry.location.toJSON().lat;
            vm.dispatch_data.deliverylongitude =
              response[0].geometry.location.toJSON().lng;
            vm.dropoff_loc = response[0].formatted_address;
            $("#dropoff_gif").hide();
          }
        }
        return;
      });
    },
    formatPrice(price) {
      var str = price.toString().split(".");
      if (str[0].length >= 3) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
      }
      if (!str[1]) {
        str[1] = "00";
      }
      return str.join(".");
    },
    handleAssign() {
      $(".modal").modal("hide");
      $("#rider").modal("show");
    },
    handleSummary() {
      $(".modal").modal("hide");
      $("#ordersummary").modal("show");
    },

    placeOrder() {
      let ordercode =
        this.formatUnique(this.$store.getters.user.merchID) +
        Math.floor(10000 + Math.random() * 90000);
      let data = {
        ordercode: ordercode,
        ordertotal: this.dispatch_data.deliverycharge,
        merchID: this.$store.getters.user.merchID,
        branchID: this.$store.getters.user.branchID,
        riderID: this.dispatch_data.riderID,
        deliverycharge: this.dispatch_data.deliverycharge,
        ETA: this.dispatch_data.ETA,
        orderstatus:
          this.dispatch_data.riderID == null ? "pending" : "assigned",
        paymentstatus: this.dispatch_data.paymentstatus,
        ordertype: this.option,
        ordermethod: this.dispatch_data.ordermethod,
        ordercategory: this.$store.getters.businesscategory,
        pickupaddress: this.dispatch_data.pickupaddress,
        deliveryaddress: this.dispatch_data.deliveryaddress,
        sendername: this.dispatch_data.sendername,
        senderphone: this.dispatch_data.senderphone,
        receivername: this.dispatch_data.receivername,
        receiverphonenumber: this.dispatch_data.receiverphonenumber,
        pickuplongitude: this.dispatch_data.pickuplongitude,
        pickuplatitude: this.dispatch_data.pickuplatitude,
        deliverylongitude: this.dispatch_data.deliverylongitude,
        deliverylatitude: this.dispatch_data.deliverylatitude,
        deliveryinstruction: this.dispatch_data.deliveryinstruction,
        itemname: this.dispatch_data.itemname,
        itemquantity: this.dispatch_data.itemquantity,
        weight: this.dispatch_data.weight,
        discountype: this.dispatch_data.discountype,
        discountpayment: this.dispatch_data.discountpayment,
        vechicletype: this.dispatch_data.vechicletype,
        staffID: this.$store.getters.user.id,
        multiple: [],
      };
      data.customerID =
        this.customerdetails !== null &&
        Object.keys(this.customerdetails).length > 0
          ? this.customerdetails.id
          : "";
      data.paymentstatus = data.paymentstatus == "" ? "PAID" : "PENDING";
      if (this.paymentoption.includes("cash")) {
        let cash_amount = parseFloat(this.cash_amountpaid.replace(/,/g, ""));
        data.multiple.push({
          paymentreference: "",
          paymentaccountname: "",
          paymentamount: cash_amount,
          paymentaccountnumber: "",
          paymentaccountbank: "",
          paymentmethod: "cash",
        });
      }
      if (this.paymentoption.includes("pos")) {
        let pos_amount = parseFloat(this.pos_amountpaid.replace(/,/g, ""));
        data.multiple.push({
          paymentreference: this.receiptnumber,
          paymentaccountname: "",
          paymentamount: pos_amount,
          paymentaccountnumber: "",
          paymentaccountbank: "",
          paymentmethod: "pos",
        });
      }
      if (this.paymentoption.includes("banktransfer")) {
        let bank_amount = parseFloat(this.bank_amountpaid.replace(/,/g, ""));
        data.multiple.push({
          paymentreference: "",
          paymentaccountname: this.bank.accountname,
          paymentamount: bank_amount,
          paymentaccountnumber: this.bank.accountno,
          paymentaccountbank: this.bank.bankname,
          paymentmethod: "banktransfer",
        });
      }
      if (this.paymentoption.includes("third_party")) {
        let thirdparty_amount = parseFloat(
          this.thirdparty_amountpaid.replace(/,/g, "")
        );
        data.multiple.push({
          paymentreference: "",
          paymentaccountname: this.thirdpartyobj.paymentaccountname,
          paymentamount: thirdparty_amount,
          paymentaccountnumber: "",
          paymentaccountbank: "",
          paymentmethod: "thirdparty",
        });
      }
      if (this.paymentoption.includes("pay-on-delivery")) {
        data.multiple.push({
          paymentreference: "",
          paymentaccountname: "",
          paymentamount: "",
          paymentaccountnumber: "",
          paymentaccountbank: "",
          paymentmethod: "pay-on-delivery",
        });
      }
      this.order_tdata = data;
      this.$store.dispatch("setOrder", data);
      let req = {
        what: "deliveryorder",
        useToken: true,
        data,
      };
      let vm = this;
      this.$request
        .makePostRequest(req)
        .then((res) => {
          if (res.type == "deliveryorder") {
            this.$store.dispatch("setOrderCode", res.data.data.ordercode);
            // this.$store.dispatch('setOrder', this.order_tdata);
            this.$store.dispatch("updateCart", []);
            this.$store.dispatch("setOrder", this.order_tdata);
            // console.log('dispatch 1a - ',vm.$store.getters.order)

            this.$swal
              .fire({
                title: "Payment Completed",
                html: '<div style="height:60px;width:60px;margin: 0 auto;"><img style="width: 100%;" src="https://reveregolf.com/wp-content/uploads/2019/10/Thumbs-Up-icon-2.png" /></div>',
                confirmButtonColor: "#28A745",
                showCloseButton: true,
                showDenyButton: true,
                denyButtonText: "Send receipt",
                confirmButtonText: "Print receipt",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  $(".modal").modal("hide");
                  vm.$router.push("/receipt");
                }
                // else if (result.isDenied) {
                //   if (this.customerdetails.id !== '') {
                //     this.handleSendReceipt(res.data.data.id, '')
                //   }
                //   else {
                //     $(".modal").modal("hide");
                //     this.$swal.fire({
                //       title: 'Send Receipt as Email',
                //       html:
                //         '<input  type="email" id="email" style="font-size: 14px;" class="swal2-input form-control" placeholder="Enter email address" v-model="receiptemail"/>',
                //       confirmButtonColor: '#28A745',
                //       showCloseButton: true,
                //       confirmButtonText: 'Send',
                //     }).then((result) => {
                //       if (result.isConfirmed) {
                //         const email = this.$swal.getPopup().querySelector('#email').value
                //         this.handleSendReceipt(res.data.data.id, email)
                //       }
                //       else {
                //         this.$store.dispatch('updateCart', []);
                //         // this.cart = this.$store.getters.cart;
                //         this.reload()
                //         // this.$router.push('/walk-in');
                //         // this.$router.dispatch('/')
                //         // location.reload()
                //       }
                //     })
                //   }
                // }
                else if (result.isDenied) {
                  $(".modal").modal("hide");
                  this.$swal
                    .fire({
                      title: "Send Receipt as Email",
                      html: '<input  type="email" id="email" style="font-size: 14px;" class="swal2-input form-control" placeholder="Enter email address" v-model="receiptemail"/>',
                      confirmButtonColor: "#28A745",
                      showCloseButton: true,
                      showDenyButton: true,
                      denyButtonText: "Cancel",
                      confirmButtonText: "Send",
                    })
                    .then((result) => {
                      if (result.isClosed) {
                        this.$store.dispatch("setHistoryStatus", false);
                        this.$router.push("/receipt");
                      }
                      if (result.isConfirmed) {
                        const email = this.$swal
                          .getPopup()
                          .querySelector("#email").value;
                        this.handleSendReceipt(res.data.data.id, email);
                      } else {
                        this.$store.dispatch("updateCart", []);
                        // this.$router.push('/walk-in');
                        // this.cart = this.$store.getters.cart;
                        // location.reload()
                        this.reload();
                      }
                    });
                } else {
                  vm.$store.dispatch("updateCart", []);

                  $("#ordersummary").modal("hide");
                  vm.$router.push("/dispatch");
                  // location.reload()
                }
              });
          }
        })
        .catch((error) => {
          // this.$swal.fire("Error", error, "error");
          this.$toasted.show(error, {
            theme: "bubble",
            type: "error",
            position: "top-right",
            duration: 6000,
          });
          this.is401(error.response.status);
        });
    },
    formatUnique(n) {
      return Number(n) > 9 ? "" + n : "0" + n;
    },
    handleSendReceipt(id, email) {
      let req = {
        useToken: true,
        what: "emailreceipt",
        data: {
          orderID: id,
          customerID: this.customerdetails.id ? this.customerdetails.id : "",
          merchID: this.$store.getters.user.merchID,
          branchID: this.$store.getters.user.branchID,
          emailaddress: email,
        },
      };
      let vm = this;
      this.$request
        .makePostRequest(req)
        .then((res) => {
          let timerInterval;
          this.$swal
            .fire({
              title: "Receipt Sent",
              html: '<div style="height:80px;width:80px;margin: 0 auto;"><img style="width: 100%;" src="https://reveregolf.com/wp-content/uploads/2019/10/Thumbs-Up-icon-2.png" /></div>',
              timer: 2000,
              timerProgressBar: true,
              didOpen: () => {
                this.$swal.showLoading();
                timerInterval = setInterval(() => {
                  const content = this.$swal.getContent();
                  if (content) {
                    const b = content.querySelector("b");
                    if (b) {
                      b.textContent = this.$swal.getTimerLeft();
                    }
                  }
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            })
            .then((result) => {
              this.$store.dispatch("updateCart", []);
              // vm.cart = vm.$store.getters.cart;
              this.$router.push("/dispatch");
              this.reload();
              // location.reload()
            });
        })
        .catch((error) => {
          // this.$swal.fire("Error", error.message, "error");
          this.$toasted.show(error.message, {
            theme: "bubble",
            type: "error",
            position: "top-right",
            duration: 6000,
          });
          // this.is401(error.response.status);
        });
    },
    cancel() {
      this.option = "";
    },
    redeemLoyalty() {
      let req = {
        what: "redeem",
        data: {
          rewardID: this.reward.id,
          points: this.reward.rewarddiscount,
          customerID: this.customerdetails.id,
          merchID: this.$store.getters.user.merchID,
        },
      };
      this.$request
        .makePostRequest(req)
        .then((res) => {
          if (res.type == "redeem") {
            // this.$swal.fire("Success", res.data.message, "success");
            this.$toasted.show(res.data.message, {
              theme: "bubble",
              type: "success",
              position: "top-right",
              duration: 6000,
            });
            this.dispatch_data.discountpayment = this.reward.rewarddiscount;
            $("#loyalty").modal("hide");
          }
        })
        .catch((error) => {
          // this.$swal.fire("Error", error.message, "error");
          this.$toasted.show(error.message, {
            theme: "bubble",
            type: "error",
            position: "top-right",
            duration: 6000,
          });
          this.is401(error.response.status);
        });
    },
    goBack() {
      $(".modal").modal("hide");
      $("#dispatchform").modal("show");
    },
    selectOption(opt) {
      if (this.paymentoption.includes(opt)) {
        var index = this.paymentoption.indexOf(opt);
        this.paymentoption.splice(index, 1);
      } else {
        this.paymentoption.push(opt);
      }
    },
  },
};
</script>
<style scoped>
#app {
  min-width: 1440px;
  overflow: auto;
}
@media screen and (max-width: 1024px) {
  #app {
    min-width: 1440px;
    overflow: auto;
  }
}
#payment-option .options {
  height: 89px;
  background: var(--white-bg) 0% 0% no-repeat padding-box;
  border: 1px solid #dbe3ee;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
}

#payment-option .options p {
  color: var(--title);
  text-align: center;
  font: normal normal normal 14px/20px Source Sans Pro;
  letter-spacing: 0px;
}

#payment-option .options:hover,
#payment-option .options:focus,
#payment-option .options.active {
  border: 1px solid var(--green);
  background: #28a7450d 0% 0% no-repeat padding-box;
  border-radius: 4px;
}

#payment-body h5 {
  color: var(--navy-blue);
  text-align: left;
  font: normal normal 600 14px/18px Source Sans Pro;
}

#payment-body .note {
  color: var(--light-gray);
  text-align: left;
  font: normal normal normal 13px/17px Source Sans Pro;
}

#payment-body input,
.input-group-text {
  text-align: left;
  font: normal normal normal 13px/17px Source Sans Pro;
}

.input-group-prepend {
  height: 48px;
}
.prompt {
  /* text-align: right; */
  color: red;
  font: normal normal normal 13px/24px Source Sans Pro;
}
ul#cus-search {
  border: 1px solid #dbe3ee;
  border-radius: 4px;
  box-shadow: 1px 2px 4px #dbe3ee;
  max-height: 200px;
  overflow-y: auto;
  margin-top: -47px;
}
ul#cus-search li {
  padding: 0 10px;
  font-size: 12px;
  cursor: pointer;
}
ul#cus-search li .dropdown-divider {
  margin: 0;
}
ul#cus-search li:hover,
ul#cus-search li:focus {
  background: var(--green);
}
</style>


