// import { update, index, store, destroy } from '@/api/resource'#
import Request from '@/Request.js'
import Utils from '@/utils.js'
import db from '@/DB.js'
import { stat } from 'fs'
export default {
    namespaced: true,
    state: () => ({
        posts: [],
        failedCount: 0,
        tries: 0,
        active: false
    }),
    mutations: {
        ADD_POST: (state, post) => {
            db.posts.put(post)
                // state.posts.unshift(post);
                // state.posts[post.hash] = post.post;
        },

        set_tries: (state, tries) => {
            state.tries = tries
        },

        set_failedCount: (state, tries) => {
            state.failedCount = tries
        },

        reset: (state, status) => {
            state.active = false;
            state.tries = 0;
            state.failedCount = 0;
            // state.posts[post.hash] = post.post;
        },

        SET_STATUS: (state, status) => {
            state.active = status;
            // state.posts[post.hash] = post.post;
        },

        SYNC_POSTS: (state, id) => {
            // let result = state.posts.map((post) => {
            //     if(JSON.stringify(post).includes(id.oldID)) {
            //         post.post = JSON.parse(JSON.stringify(post.post).replace(id.oldID, id.id))
            //     }
            //     return post
            // })

            db.posts.orderBy('created_at').modify(post => {
                if (JSON.stringify(post).includes(id.oldID)) {
                    post.post = JSON.parse(JSON.stringify(post.post).replace(id.oldID, id.id))
                }
                return post
            })

            // state.posts = result
        },

        REMOVE_POST: (state, hash) => {
            // state.posts.shift()
            db.posts.delete(hash)
                // let index = state.posts.findIndex(post => post.hash === hash)
                // if(index != null) {
                //     state.posts.splice(index, 1)
                // }
        }
    },
    actions: {
        async shouldFetch({ commit }) {
            return (await db.posts.count()) == 0;
        },
        async stressSync({ commit, dispatch }, { post, response }) {
            // console.log(post)
            // console.log(response)

            if (Utils.checkShouldStressSync(post.post)) {
                commit('SYNC_POSTS', {
                    oldID: post.hash,
                    id: response.data.data.id
                });

                if (post.post.what = 'addToQueue') {
                    await dispatch('orders/sync', {
                        oldID: post.hash,
                        id: response.data.data.id
                    }, { root: true })
                } else if (post.post.what = 'createcustomer') {
                    await dispatch('customers/sync', {
                        oldID: post.hash,
                        id: response.data.data.id
                    }, { root: true })
                    await dispatch('orders/sync', {
                        oldID: post.hash,
                        id: response.data.data.id
                    }, { root: true })
                }
            }
            commit('REMOVE_POST', post.hash);
        },


        async shouldSync({ commit }) {
            //   console.log(await db.posts.count())
            return (await db.posts.count()) > 0;
        },
        async makePosts({ commit, state, dispatch }) {

            // return new Promise((resolve, reject) => {
            // state.posts.forEach(post =>
            // let index = state.posts.length - 1; 
            while (true)
            // for (const post of state.posts) 

            {
                let post = await db.posts.orderBy('created_at').first();

                // let post = state.posts[index];
                if (!(await Utils.checkOnlineStatus()) || post == null) {
                    break;
                }
                if (Utils.isPutRequest(post.post)) {
                    await Request.makePutRequest(post.post).then;
                } else {
                    post = await db.posts.orderBy('created_at').toArray();
                    let orderdata = []
                    post.forEach(i => {
                        orderdata.push(i.post.data)
                    })
                    let syncdata = {
                        useToken: true,
                        what: "syncorder",
                        showLoader: false,
                        data: { "data": orderdata }
                    }

                    await Request.makePostRequest(syncdata)
                        .then(async response => {
                            // remove previous request
                            post.forEach(async(i) => {
                                await dispatch('stressSync', { post: i, response: response })
                            })

                            // resolve()
                        }).catch(async error => {
                            console.log(error)
                                // if (error.includes('duplicate')) {
                                //     await dispatch('stressSync', { post: post, response: response })
                                // }

                            let failedCount = state.failedCount
                            let tries = state.tries
                            let count = (await db.posts.count())
                            if (tries <= 2) {
                                if (failedCount == count && count != 0) {
                                    failedCount = 0
                                    tries++
                                    commit('set_tries', tries)
                                    commit('set_failedCount', failedCount)
                                    throw error
                                }
                            }
                            post.forEach(async(i) => {
                                let related = await db.posts.filter(item => JSON.stringify(item.post).includes(i.hash)).toArray();
                                related.forEach((relate) => {
                                    commit('REMOVE_POST', relate.hash);
                                });
                                commit('REMOVE_POST', i.hash);
                                commit('ADD_POST', i);
                                related.forEach((relate) => {
                                    commit('ADD_POST', relate);
                                    // failedCount++
                                });
                            })



                            failedCount++

                            commit('set_failedCount', failedCount)



                            // reject(error)
                        });



                }

                // index--;
                // }
            }
            // });
            // });
        },

        async addPost({ commit }, post) {

            return new Promise(async(resolve, reject) => {
                let hash = await Utils.hashCode(JSON.stringify(post));
                let data = {
                    post: post,
                    hash: hash,
                    created_at: (new Date()).toString()
                }

                commit('ADD_POST', data);
                // return data;
                resolve(data)
            })

        },
        async setStatus({ commit }, status) {
            commit('SET_STATUS', status)
        },
    },
    getters: {
        status: (state) => state.active,
        // posts: (state) => (type) => state.posts.filter( (post) => type != '' ? post.poststatus == type : true),
        // shouldFetch: (state) => state.posts.length == 0,
    }
}