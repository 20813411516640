import Dexie from 'dexie';

const db = new Dexie('ntisa-pos')
db.version(7).stores({
    orders: 'id,created_at,transactiondate,customerID,*oldorderid,[created_at+staffID+paymentstatus+ordertype]',
    products: 'id, productcode, [category+productcode+productname+productdescription], category, deleted, producttype',
    customers: 'id,firstname,lastname,phonenumber,emailaddress',
    posts: 'hash,created_at',
    categories: 'category,header.category',
    staffs: 'id,[lastname+usertoken]',
    date_time: 'updated_at',
    networkstatus: 'status'
});

// db.version(2).stores({})

export default db