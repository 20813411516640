<template >
  <div id="aside">
    <div class="text-center mt-3" @click="$router.push('/orders')">
      <img class="side-img img-fluid" :src="companyLogo" alt="" />
    </div>
    <div id="showsummary" class="modal" tabindex="-1" data-backdrop="false">
      <div class="modal-dialog" style="margin-top: 70px">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title mx-sm-5"></h5>

            <img
              class="btn close"
              data-dismiss="modal"
              aria-label="Close"
              src="../assets/img/close.png"
              alt=""
            />
          </div>
          <div class="modal-body">
            <div class="auth-form mx-auto">
              <div class="heading d-flex justify-content-start">
                <img src="../assets/img/key.svg" alt="" />
                <div class="ml-3">
                  <p>Sign in as an administrator to continue</p>
                </div>
              </div>
              <div>
                <ValidationObserver v-slot="{ handleSubmit }">
                  <form
                    class="mt-4"
                    @submit.prevent="handleSubmit(handleSignin)"
                  >
                    <div class="form-group">
                      <label for="">Enter Your Last Name</label>
                      <validation-provider rules="required" v-slot="{ errors }">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="What’s your Last name?"
                          v-model="username"
                        />
                        <span class="extra">Enter your registered surname</span>
                        <br />
                        <span class="err_msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </div>
                    <div class="form-group">
                      <label for="">Enter Token</label>
                      <validation-provider rules="required" v-slot="{ errors }">
                        <div class="input-wrapper">
                          <PincodeInput
                            :length="6"
                            v-model="passcode"
                            placeholder="0"
                          />
                        </div>
                        <span class="err_msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </div>
                    <div class="form-group">
                      <button type="submit" class="btn">Sign in</button>
                    </div>
                  </form>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="
        $store.getters.businesscategory === 'Food' &&
        $store.getters.user.role &&
        $store.getters.user.role === 'kitchen'
      "
      id="opt-box"
      class="text-center"
    >
      <router-link
        tag="div"
        class="opt mt-4"
        v-bind:class="$route.name == 'Kitchen' ? 'active' : ''"
        v-bind:to="'/kitchen'"
      >
        <img
          v-if="$route.name == 'Kitchen'"
          src="../assets/img/queue-icon-active.png"
          alt=""
        />
        <img v-else src="../assets/img/queue-icon.png" alt="" />
        <p>Kitchen</p>
      </router-link>
    </div>
    <div v-else id="opt-box" class="text-center">
      <router-link
        v-if="$store.getters.businesscategory !== 'Delivery'"
        tag="div"
        class="opt mt-4"
        v-bind:class="$route.name == 'Walk-in' ? 'active' : ''"
        v-bind:to="'/walk-in'"
      >
        <img
          v-if="$route.name == 'Walk-in'"
          src="../assets/img/walkin-icon-active.png"
          alt=""
        />
        <img v-else src="../assets/img/walkin-icon.png" alt="" />
        <p>Walk in</p>
      </router-link>
      <router-link
        v-else
        tag="div"
        class="opt mt-4"
        v-bind:class="$route.name == 'Dispatch' ? 'active' : ''"
        v-bind:to="'/dispatch'"
      >
        <img
          v-if="$route.name == 'Dispatch'"
          src="../assets/img/walkin-icon-active.png"
          alt=""
        />
        <img v-else src="../assets/img/walkin-icon.png" alt="" />
        <p>Walk in</p>
      </router-link>
      <router-link
        class="opt mt-4"
        tag="div"
        v-bind:class="
          $route.name == 'Online Orders' || $route.name == 'OnlineOrder'
            ? 'active'
            : ''
        "
        v-bind:to="'/orders'"
      >
        <img
          v-if="$route.name == 'Online Orders' || $route.name == 'OnlineOrder'"
          src="../assets/img/orders-icon-active.png"
          alt=""
        />
        <img v-else src="../assets/img/orders-icon.png" alt="" />

        <p>Online Orders</p>
      </router-link>
      <!-- <router-link
        v-if="$store.getters.businesscategory ==='Food'"
        tag="div"
        class="opt mt-4"
        v-bind:class="$route.name=='Orders In Queue'? 'active': ''"
        v-bind:to="'/queue'"
      >
        <img
          v-if="$route.name=='Orders In Queue'"
          src="../assets/img/queue-icon-active.png"
          alt=""
        >
        <img
          v-else
          src="../assets/img/queue-icon.png"
          alt=""
        >
        <p>Queued Orders</p>
      </router-link> -->

      <router-link
        v-if="$store.getters.businesscategory === 'Food'"
        tag="div"
        class="opt mt-4"
        v-bind:class="$route.name == 'Kitchen' ? 'active' : ''"
        v-bind:to="'/kitchen'"
      >
        <img
          v-if="$route.name == 'Kitchen'"
          src="../assets/img/kitchen-icon-active.png"
          alt=""
        />
        <img v-else src="../assets/img/kitchen-icon.png" alt="" />
        <p>Kitchen</p>
      </router-link>
      <router-link
        v-if="$store.getters.businesscategory !== 'Delivery' && setStock"
        tag="div"
        class="opt mt-4"
        v-bind:class="$route.name == 'Products' ? 'active' : ''"
        v-bind:to="'/products'"
      >
        <img
          v-if="$route.name == 'Products'"
          src="../assets/img/product-icon-active.png"
          alt=""
        />
        <img v-else src="../assets/img/product-icon.png" alt="" />
        <p>Products</p>
      </router-link>
      <router-link
        tag="div"
        class="opt mt-4"
        v-bind:class="$route.name == 'History' ? 'active' : ''"
        v-bind:to="'/order-history'"
      >
        <img
          v-if="$route.name == 'History'"
          src="../assets/img/history-icon-active.png"
          alt=""
        />
        <img v-else src="../assets/img/history-icon.png" alt="" />
        <p>Order History</p>
      </router-link>
      <router-link
        v-if="display_pagesummary"
        tag="div"
        class="opt mt-4"
        v-bind:class="$route.name == 'Summary' ? 'active' : ''"
        v-bind:to="'/summary'"
      >
        <img
          v-if="$route.name == 'Summary'"
          src="../assets/img/summary-icon-active.png"
          alt=""
        />
        <img v-else src="../assets/img/summary-icon.png" alt="" />
        <p>Sales Summary</p>
      </router-link>
      <router-link
        v-else-if="isSupervisor"
        tag="div"
        class="opt mt-4"
        v-bind:class="$route.name == 'Summary' ? 'active' : ''"
        v-bind:to="'/summary'"
      >
        <img
          v-if="$route.name == 'Summary'"
          src="../assets/img/summary-icon-active.png"
          alt=""
        />
        <img v-else src="../assets/img/summary-icon.png" alt="" />
        <p>Sales Summary</p>
      </router-link>
      <router-link
        v-else
        tag="div"
        class="opt mt-4"
        data-toggle="modal"
        data-target="#showsummary"
        v-bind:class="$route.name == 'Summary' ? 'active' : ''"
        v-bind:to="'/summary'"
      >
        <img
          v-if="$route.name == 'Summary'"
          src="../assets/img/summary-icon-active.png"
          alt=""
        />
        <img v-else src="../assets/img/summary-icon.png" alt="" />
        <p>Sales Summary</p>
      </router-link>
      <div @click.prevent="handleLogout()" class="opt mt-4">
        <p>Log Out</p>
      </div>
    </div>
  </div>
</template>
<script>
import { eventBus } from "../main";
import * as $ from "jquery";
export default {
  name: "Sidenav",
  data() {
    return {
      companyLogo: "",
      username: "",
      passcode: "",
      role: "",
      // user: {}
      isSupervisor: false,
    };
  },
  created() {
    this.isSupervisor = this.$store.getters.isSupervisor;
    this.companyLogo = this.$store.getters.user.branchimage;
    this.role = "store_attendant";
  },
  computed: {
    display_pagesummary() {
      let result = this.$store.getters.user.permissions.filter((permission) =>
        permission.name.toLowerCase().includes("sales_summary")
      );
      return (
        (result !== undefined && result.length > 0) ||
        ["administrator", "business_owner"].includes(
          this.$store.getters.user.role
        )
      );
    },
    setStock() {
      let result = this.$store.getters.user.permissions.filter((permission) =>
        permission.name.toLowerCase().includes("out-of-stock")
      );
      return (
        (result !== null && result.length > 0) ||
        ["administrator", "business_owner"].includes(
          this.$store.getters.user.role
        )
      );
    },
  },
  methods: {
    handleLogout() {
      this.$store.dispatch("logoutUser").then((result) => {
        this.$router.push("/");
        // location.reload()
      });
    },
    showsummary() {
      alert("I show");
      $("#showsummary").modal("hide");
      this.display_pagesummary = true;
    },
    handleSignin() {
      let req = {
        what: "login",
        showLoader: false,
        params: {
          firstname: this.username,
          token: this.passcode,
          role: this.role,
        },
      };

      var loading_html =
        '<div style="height:150px;width:150px;margin: 0 auto;"><img style="width: 100%;" src="https://trails.ca/wp-content/uploads/2020/10/loading-spinner.gif" /></div>';

      this.$swal.fire({
        title: "",
        html: loading_html,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        showCancelButton: false,
      });

      this.$store
        .dispatch("supervisorLogin", req)
        .then(async (response) => {
          let res = response;

          if (["administrator", "business_owner"].includes(res.role)) {
            if (res.branchID.includes(this.$store.getters.getBranchID)) {
              this.$swal.fire(
                "Success",
                "Action Carried Out Successfully",
                "success"
              );
              this.username = "";
              this.passcode = "";
              $("#showsummary").modal("hide");

              this.$store.dispatch("updateisSupervisor", true);
              // console.log('mmm',this.$store.getters.isSupervisor)

              this.$router.push("/summary");
              // this.display_pagesummary = true
              // this.$emit("remove-approved")
            } else {
              this.$swal.fire(
                "Error",
                "This account does not have authority over this branch",
                "error"
              );
              this.username = "";
              this.passcode = "";
              $("#showsummary").modal("hide");
              // this.$emit("cancelLogin")
            }
          } else {
            this.$swal.fire("Error", "Unauthorized access", "error");
            this.username = "";
            this.passcode = "";
            // this.$emit("cancelLogin")
            $("#showsummary").modal("hide");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal.fire("Error", error.message, "error");
          this.username = "";
          this.passcode = "";
        });
    },
  },
  // mounted (){
  //   this.user = this.$store.getters.user
  // }
  // mounted () {
  //   eventBus.$on('fireMethod', () => {
  //     $('#aside').css("transform", function () {
  //       return $('input#openSidebarMenu')[0].checked ? "translateX(0)" : "translateX(-100px)";
  //     });
  //     if (window.innerWidth <= 320) {
  //       $('#main').css("padding-left", function () {
  //         return $('input#openSidebarMenu')[0].checked ? "60px" : "0px";
  //       });
  //     }
  //     else if (window.innerWidth <= 425) {
  //       $('#main').css("padding-left", function () {
  //         return $('input#openSidebarMenu')[0].checked ? "80px" : "0px";
  //       });
  //     }
  //     else {
  //       $('#main').css("padding-left", function () {
  //         return $('input#openSidebarMenu')[0].checked ? "100px" : "0px";
  //       });
  //     }
  //   })
  // },
};
</script>
<style>
.swal-wide {
  width: 250px !important;
}
</style>