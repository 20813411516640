<!-- PWAPrompt.vue -->
<template>
<div class="modal popup" data-backdrop="false" tabindex="-1" id="modal-popup" role="dialog" v-if="shown">
  <div class="modal-dialog mt-0" role="document">
    <div class="modal-content">
      <div class="modal-body" >
        <p>Add app to device?</p>
      
        <div class="row">
          <div class="col-7 mr-0" style="margin-left: auto">
            <button type="button"  class="btn btn-success mr-1"  @click="installPWA">Install</button>
            <button type="button"  class="btn btn-danger" data-dismiss="modal" @click="dismissPrompt">No Thanks</button>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<style scoped>
.popup .modal-content{
  border-radius: 0 0 8px 8px !important;
  box-shadow: 0px 1px 5px #0000000D !important;
}
/* hey */
</style>
<script>

import * as $ from "jquery";
export default {
  name: "PWAPrompt",
  data: () => ({
    shown: false,
  }),

  beforeMount() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      this.installEvent = e
      this.shown = true
    })
  },

  mounted(){

    $(document).ready(function() {
      $('#modal-popup').modal('show');
    });
  },

  methods: {
    dismissPrompt() {
      this.shown = false
    },

    installPWA() {
      this.installEvent.prompt()
      this.installEvent.userChoice.then((choice) => {
        this.dismissPrompt() // Hide the prompt once the user's clicked
        if (choice.outcome === 'accepted') {
          // Do something additional if the user chose to install
        } else {
          // Do something additional if the user declined
        }
      })
    },
  }
}
</script>