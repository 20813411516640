<template>
  <div>
    <div v-if="this.$router.currentRoute.name === 'Login'" id="login-app">
      <PWAPrompt></PWAPrompt>
      <PWAUpdate></PWAUpdate>
      <router-view />
    </div>
    <div
      v-else-if="this.$router.currentRoute.name === 'Receipt'"
      id="receiptapp"
    >
      <router-view />
    </div>
    <div v-else id="app">
      <PWAPrompt></PWAPrompt>
      <PWAUpdate></PWAUpdate>
      <router-view />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
//  const { mapActions, mapGetters } = createNamespacedHelpers('posts')
import Utils from "@/utils.js";
import Sync from "@/workers/Sync";
import checkOnline from "@/checkOnline.js";
import dbFunctions from "@/helpers/dbFunctions.js";
import PWAPrompt from "@/components/PWAPrompt.vue";
import PWAUpdate from "@/components/PWAUpdate.vue";

import Vue from "vue";

export default {
  components: {
    PWAPrompt,
    PWAUpdate,
  },
  data() {
    return {
      status: "",
      type: "",
      icontype: "",
    };
  },
  computed: {
    async isLoggedIn() {
      let isLoggedIn = this.$store.getters.isLoggedIn;
      let user = this.$store.getters.user;

      if (isLoggedIn != undefined && user != undefined) {
        Sync.send({
          checkNetwork: true,
          data: this.$store.getters.user,
          token: this.$store.getters.token,
          updated_at: "",
        });
      }

      return isLoggedIn;
    },
  },

  mounted() {
    this.isLoggedIn;
  },
  created() {
    //check ios
    let vm = this;
    let isIOS = this.ios();
    console.log(isIOS);
    let online_stat;
    let current_network;
    if (!isIOS) {
      if (navigator.connection.rtt > 500) {
        online_stat = "offline";
      } else if (navigator.onLine && navigator.connection.rtt == 0) {
        online_stat = "online";
      } else if (!navigator.onLine && navigator.connection.rtt == 0) {
        online_stat = "offline";
      } else {
        online_stat = "online";
      }
    } else {
      window.addEventListener("online", async function () {
        let prev_network = await checkOnline.checkNetwork();
        online_stat = "online";
        current_network = "online";
        dbFunctions.setNetworkStatus(current_network);
        vm.handleSync(prev_network, current_network);
      });
      window.addEventListener("offline", async function () {
        let prev_network = await checkOnline.checkNetwork();
        online_stat = "offline";
        current_network = "offline";
        dbFunctions.setNetworkStatus(current_network);
        vm.handleSync(prev_network, current_network);
      });
    }

    dbFunctions.setNetworkStatus(online_stat);

    let loading_html =
      '<div style="height:120px;width:120px;margin: 0 auto;"><img style="width: 100%;" src="https://trails.ca/wp-content/uploads/2020/10/loading-spinner.gif" /></div>';

    Sync.worker.onmessage = (event) => {
      let message = event.data;

      if (message.key == true) {
        if (message.value == "Sync Posts") {
          Utils.syncRequests();
        }

        if (message.value == "commit") {
          vm.$store.commit(message.commit.type, message.commit.payload);
        }

        if (message.value == "load") {
          // vm.$wal.showLoading()
          vm.$store.commit("load", true);
          let timerInterval;
        }

        if (message.value == "unload4") {
          vm.$swal.close();
          vm.$store.commit("load", false);

          let updated_at = Utils.getDate().split(" ")[0];
          dbFunctions.setUpdated_at(updated_at);
          vm.$store.commit("set_updated_at", updated_at);

          vm.$store.commit("first", false);

          vm.$toasted.show("System sync operation completed", {
            theme: "bubble",
            type: "success",
            position: "top-right",
            duration: 6000,
            icon: {
              name: "check",
              after: true,
            },
          });
          vm.$root.$emit("download-complete");
        }

        if (message.value == "error") {
          vm.$store.commit("load", false);
          // vm.$swal.fire(
          //   "Download Failed, Please Refresh",
          //   message.error,
          //   "error"
          // );
          vm.$toasted.show(message.error, {
            theme: "bubble",
            type: "error",
            position: "top-right",
            duration: 6000,
            icon: {
              name: "exclamation-triangle",
              after: true,
            },
          });
        }
      }
    };

    // window.addEventListener("online", () => {
    //   this.status = "App is Online";
    //   this.type = "success";
    //   this.icontype = "check";
    // });

    // window.addEventListener("offline", () => {
    //   this.status = "App is Offline";
    //   this.type = "error";
    //   this.icontype = "exclamation-triangle";
    // });

    //listen for internet network change for other devices except ios
    navigator.connection.addEventListener("change", async () => {
      //get previous internet status
      let prev_network = await checkOnline.checkNetwork();

      if (navigator.connection.rtt > 500) {
        this.status = "Internet is slow";
        this.type = "warning";
        this.icontype = "exclamation-triangle";
        current_network = "offline";
      } else if (!navigator.onLine && navigator.connection.rtt == 0) {
        this.status = "App is offline";
        this.type = "error";
        this.icontype = "exclamation-triangle";
        current_network = "offline";
      } else if (navigator.onLine && navigator.connection.rtt == 0) {
        this.status = "App is online";
        this.type = "success";
        this.icontype = "check";
        current_network = "online";
      } else {
        this.status = "App is online";
        this.type = "success";
        this.icontype = "check";
        current_network = "online";
      }

      vm.handleSync(prev_network, current_network);
    });
  },
  watch: {
    status(val) {
      let show_stat = val !== "Internet is slow" ? true : false;
      // if (show_stat) {
      //   this.$toasted.show(val, {
      //     theme: "bubble",
      //     type: this.type,
      //     position: "top-right",
      //     duration: 6000,
      //     icon: {
      //       name: this.icontype,
      //       after: true,
      //     },
      //   });
      // }
    },
  },

  methods: {
    ...mapActions({
      makePosts: "posts/makePosts",
      loginSync: "loginSync",
    }),
    ios() {
      return (
        [
          "iPad Simulator",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod",
        ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      );
    },
    async handleSync(prev_network, current_network) {
      //update internet status in db
      await dbFunctions.setNetworkStatus(current_network);
      //check if should download products
      if (prev_network === "offline" && current_network === "online") {
        let data = {
          user: this.$store.getters.user,
          token: this.$store.getters.token,
        };
        checkOnline.productDownload(data);
        this.reSync();
      }
    },
    async syncRequests() {
      let vm = this;
      let shouldFetch = !(await vm.$store.dispatch("posts/shouldFetch"));
      //&& shouldFetch
      // console.log(shouldFetch)
      if (
        !vm.$store.state.posts.active &&
        vm.$store.getters.isLoggedIn &&
        shouldFetch
      ) {
        vm.$store.commit("posts/SET_STATUS", true);
        var loading_html =
          '<div style="height:50px;width:50px;margin: 0 auto;"><img style="width: 100%;" src="https://trails.ca/wp-content/uploads/2020/10/loading-spinner.gif" /></div>';

        vm.$store
          .dispatch("loginSync")
          .then(() => {
            vm.$swal.fire({
              title: "Sync Has Started, Please hold on till Completed",
              html: loading_html,
              showConfirmButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
              showCancelButton: false,
              width: "150px",
            });
            vm.$store
              .dispatch("posts/makePosts")
              .then((result) => {
                vm.$store.commit("posts/SET_STATUS", false);
                vm.$swal.close();
                // vm.$swal.fire(
                //   "Success",
                //   "Sync Completed Successfully",
                //   "success"
                // );
                vm.$toasted.show("Sync Completed Successfully", {
                  theme: "bubble",
                  type: "success",
                  position: "top-right",
                  duration: 6000,
                  icon: {
                    name: "check",
                    after: true,
                  },
                });
              })
              .catch((error) => {
                vm.$store.commit("posts/SET_STATUS", false);
                vm.$swal.close();

                // vm.$swal.fire("Sync Failed", error, "error");
                vm.$toasted.show("Sync failed, trying again...", {
                  theme: "bubble",
                  type: "error",
                  position: "top-right",
                  duration: 4000,
                  icon: {
                    name: "exclamation-triangle",
                    after: true,
                  },
                });
                // this.status = 'Sync Failed with error: ' + error;
                // this.type = 'error';
                // this.icontype = 'exclamation-triangle'
              });
          })
          .catch((error) => {
            vm.$store.commit("posts/SET_STATUS", false);
            vm.$swal.close();

            // vm.$swal.fire("Sync Failed", error, "error");
            vm.$toasted.show("Sync failed", {
              theme: "bubble",
              type: "error",
              position: "top-right",
              duration: 6000,
              icon: {
                name: "exclamation-triangle",
                after: true,
              },
            });
          });
      }
    },
    async reSync() {
      let result = await Utils.checkOnlineStatus();
      if (result) {
        this.$store.commit("posts/reset", false);
        this.$store.commit("load", false);
        await Utils.syncRequests();
        let shouldFetch = await this.$store.dispatch("posts/shouldFetch");
        if (this.$store.state.load == false) {
          Sync.send({
            load: true,
            data: this.$store.getters.user,
            token: this.$store.getters.token,
            run: shouldFetch,
            updated_at: this.$store.getters.updated_at,
          });
        }
      } else {
        // this.$swal.fire(
        //   "Download Failed",
        //   "No Internet Connection, connect your internet and try again.",
        //   "error"
        // );

        this.$store.commit("load", false);
      }
    },
  },
};
</script>
<style>
#app {
  background: #f1f1f1 0% 0% no-repeat padding-box;
  min-height: 100vh;
  padding-bottom: 20px;
}
#login-app {
  background: #ffffff;
  padding-bottom: 0px;
}
</style>
