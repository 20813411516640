// import { update, index, store, destroy } from '@/api/resource'#
import Request from '@/Request.js'
import db from '@/DB.js'

export default {
    namespaced: true,
    state: () => ({
        customers: [],
    }),
    mutations: {
        SET_CUSTOMERS: (state, customers) => {
            // state.customers = customers
            // db.customers.clear();
            db.customers.bulkPut(customers)
        },

        SET_CUSTOMERS_ASYNC: (state, customers) => {
            // state.customers = customers
            // db.customers.clear();
            db.customers.bulkPut(customers)
        },

        ADD_CUSTOMER: (state, customer_data) => {
            db.customers.put(customer_data)
                // state.customers.unshift(customer_data)
        },

        SYNC_POSTS: (state, id) => {
            db.customers.where('id', id.oldID).modify(customer => {
                    customer.id = id.id
                    return customer
                })
                // let result =  state.customers.map((customer) => {
                //     if(JSON.stringify(customer).includes(id.oldID)){
                //       customer = JSON.parse(JSON.stringify(customer).replace(id.oldID, id.id))
                //     }
                //     return customer
                // })
                // state.customers = result
        },

    },
    actions: {
        sync({ commit }, id) {
            commit('SYNC_POSTS', id)
        },
        index({ commit, state }, req) {
            return new Promise((resolve, reject) => {

                Request.makeGetRequest(req)
                    .then(response => {
                        const { data } = response
                        if (data.status) {
                            if (data.data != undefined) {
                                commit('SET_CUSTOMERS', data.data)
                            }
                        }

                        resolve(data.data)
                    }).catch(error => {
                        console.log(error);
                        reject(error)
                    })
            })
        },

        async searchCustomer({ commit, getters }, search) {
            return await db.customers.where('firstname').startsWithIgnoreCase(search).or('lastname').startsWithIgnoreCase(search).or('phonenumber').startsWithIgnoreCase(search).or('emailaddress').startsWithIgnoreCase(search).toArray()
        },

        async alreadyExists({ commit }, data) {
            return await db.customers.where('phonenumber').startsWithIgnoreCase(data.phonenumber).first()
        },

        async getcustomer({ commit }, id) {
            return await db.customers.where('id').equals(id).first()
        },

        store({ commit, getters }, req) {
            return new Promise((resolve, reject) => {
                Request.makePostRequest(req)
                    .then(response => {
                        const { data } = response
                        let customer = {};
                        // console
                        if (data.status) {
                            if (data.data != undefined) {
                                customer = data.data
                            } else {
                                customer = req.data

                                // console.log(customer)
                            }
                            commit('ADD_CUSTOMER', customer)
                        }
                        resolve(customer)
                    }).catch(error => {
                        reject(error)
                    })
            })
        },
    },
    getters: {
        customersList: (state) => state.customers,
        searchCustomer: (state) => (name) => {

            return state.customers.find((customer) => {
                let lastname = customer.lastname != null ? customer.lastname.trim().toLowerCase() : '';
                // if(lastname.includes(name)){
                //   return true
                // }
                let firstname = customer.firstname != null ? customer.firstname.trim().toLowerCase() : ''
                let emailaddress = customer.emailaddress != null ? customer.emailaddress.trim().toLowerCase() : '';
                let phonenumber = customer.phonenumber != null ? customer.phonenumber.trim().toLowerCase() : '';
                return lastname.includes(name) || firstname.includes(name) || emailaddress.includes(name) || phonenumber.includes(name)
            })
        },
        alreadyExists: (state) => (phonenumber) => state.customers.findIndex((customer) => customer.phonenumber == phonenumber),
        getcustomer: (state) => (id) => state.customers.find((customer) => customer.id == id),
        // getcustomerThroughBarcode: (state) => (barcode) => state.customers.filter( (customer) => customer.customercode == barcode ),
    }
}