<template >
  <div>
    <!-- cart modal toggle -->
    <div
      id="cart-icon"
      data-toggle="modal"
      data-target="#sidecart"
      class="text-center"
    >
      <img src="../assets/img/cart-icon.png" alt="" />
      <p id="cart-count" class="text-center">
        <span> {{ cart.length }} Item(s) </span>
      </p>

      <button v-if="cart.length !== 0" id="cart-price">
        ₦{{ formatPrice(Number(total) + Number(calculateTaxTotal())) }}
      </button>
      <button v-else id="cart-price">₦0</button>
    </div>

    <!-- cart modal for smaller screen sizes -->
    <div id="sidecart" class="modal" data-backdrop="false" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div id="cart" class="">
            <div class="cart-header d-flex justify-content-between">
              <h5 class="mx-4 my-auto">Invoice</h5>
              <div class="mr-4 mt-2">
                <img
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  src="../assets/img/close.png"
                  alt=""
                  @click="
                    resetDiscount;
                    resetPromo;
                  "
                />
              </div>
            </div>

            <div v-if="cart.length == 0" class="cart-body px-4">
              <div class="">
                <img src="../assets/img/cart-img.png" alt="" />
              </div>
              <div class="mt-5">
                <h4>Your Cart is empty</h4>
                <p>Select items from the list on the right</p>
                {{ resetDiscount() }}
                {{ resetPromo() }}
              </div>
            </div>

            <div v-else class="mx-3">
              <div class="cart-body-main">
                <table id="cart-table" class="table">
                  <tbody>
                    <tr v-for="(row, i) in cart" class="items" :key="i">
                      <td class>
                        <img :src="row.image" alt="" class="orderimg" />
                      </td>

                      <td>
                        <h5 class="product">{{ row.name }}</h5>
                        <p class="variance">
                          <span
                            class="mr-1"
                            v-for="(i, k) in row.variance"
                            :key="k"
                            >{{ i.featuretype }} : {{ i.featurename }}</span
                          >
                        </p>
                        <div
                          v-if="row.discountprice"
                          style="text-align: left !important"
                        >
                          <s class="old-price"
                            >₦ {{ formatPrice(row.price) }}</s
                          >
                          <h6 class="discount-price-normal">
                            ₦ {{ formatPrice(row.discountprice) }}
                          </h6>
                        </div>
                        <div v-else style="text-align: left !important">
                          ₦ {{ formatPrice(row.price) }}
                        </div>
                        <div
                          v-if="row.discountfee"
                          style="text-align: left !important"
                        >
                          <s class="old-price mr-1"
                            >₦ {{ formatPrice(row.price) }}</s
                          >
                          <span class="discount-price-normal"
                            >₦
                            {{ formatPrice(row.price - row.discountfee) }}</span
                          >
                        </div>
                        <!-- <span v-if="showinfo" class="text-danger mt-3">Stock limit exceeded</span> -->
                        <!-- <div v-else>  ₦ {{formatPrice(row.price)}} </div> -->
                      </td>

                      <td>
                        <div class="edit-qty d-flex">
                          <p
                            class="qty pt-1"
                            @click="
                              decreaseQty('item' + row.productid, row.productid)
                            "
                          >
                            -
                          </p>

                          <input
                            oninput="validity.valid||(value='');"
                            @change="
                              inputChange('item' + row.productid, row.productid)
                            "
                            :id="'item' + row.productid"
                            type="text"
                            :value="row.quantity"
                            pattern="[-+]?[0-9]*\.?[0-9]*"
                          />

                          <p
                            class="qty pt-1"
                            @click="
                              increaseQty('item' + row.productid, row.productid)
                            "
                          >
                            +
                          </p>
                        </div>
                      </td>

                      <!-- <td class="subtotal" style="width:90px !important">₦ {{formatPrice(row.totalamount)}}</td> -->
                      <td class="subtotal" style="width: 90px !important">
                        ₦
                        {{
                          formatPrice(
                            row.discountfee
                              ? (row.price - row.discountfee) * row.quantity
                              : row.discountprice
                              ? row.discountprice * row.quantity
                              : row.price * row.quantity
                          )
                        }}
                      </td>

                      <td>
                        <img
                          title="remove"
                          v-if="canRemoveCart()"
                          @click="removeItem(row)"
                          src="../assets/img/cancel-circle-2.png"
                          alt=""
                        />
                        <img
                          v-else
                          title="remove"
                          @click="setItem(row)"
                          data-toggle="modal"
                          data-target="#login"
                          src="../assets/img/cancel-circle-2.png"
                          alt=""
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <hr />

              <table class="table table-borderless w-75 float-right">
                <tbody>
                  <tr>
                    <td>Sub total</td>
                    <td class="text-right">₦ {{ formatPrice(subtotal) }}</td>
                  </tr>
                  <tr>
                    <td>
                      Discount<span v-if="discount_percent > 0">
                        ({{ discount_percent }})%</span
                      >
                    </td>
                    <td class="text-right">
                      <div>₦ {{ discount ? formatPrice(discount) : 0 }}</div>
                    </td>
                  </tr>
                  <tr v-for="t in taxes" :key="t.id">
                    <td>
                      {{ t.name
                      }}<span v-if="t.level == 'order'"> ({{ t.value }})%</span>
                    </td>
                    <td class="text-right" v-if="calculateTax(t.value) !== 0">
                      <div>₦ {{ formatPrice(calculateTax(t.value)) }}</div>
                    </td>
                    <td class="text-right" v-else>
                      <div>Tax Inclusive</div>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th scope="col">Total</th>
                    <th scope="col" class="text-right">
                      ₦
                      {{
                        formatPrice(Number(total) + Number(calculateTaxTotal()))
                      }}
                    </th>
                  </tr>
                </tfoot>
              </table>
              <br />
            </div>
            <div
              v-if="cart.length !== 0"
              class="d-flex my-5 cart-footer flex-wrap"
            >
              <button
                type="button"
                data-toggle="modal"
                data-target="#customermodal"
                class="edit-btn btn ml-2 my-auto"
              >
                Add Customer
              </button>
              <button
                v-if="display_discount"
                type="button"
                data-toggle="modal"
                data-target="#discount"
                class="edit-btn btn ml-1 my-auto"
              >
                Apply Discount
              </button>
              <!-- <button
                type="button"
                data-toggle="modal"
                data-target="#promo"
                class="edit-btn btn ml-2 my-auto"
              >Use Promo</button> -->
              <button @click="checkout" type="button" class="btn ml-1 my-auto">
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--search customer modal -->
    <div id="customermodal" class="modal" tabindex="-1" data-backdrop="static">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title mx-sm-5">Search for customer</h5>

            <img
              class="close"
              @click="
                searchresult = [];
                searchcustomer = '';
                customerdetails = {};
              "
              data-dismiss="modal"
              aria-label="Close"
              src="../assets/img/close.png"
              alt=""
            />
          </div>
          <div class="modal-body mx-sm-5">
            <form @submit.prevent="fetchCustomers()">
              <div class="form-group">
                <label for="">Search for customer</label>
                <input
                  type="text"
                  id="searchtext"
                  v-model="searchcustomer"
                  class="form-control"
                  required
                  placeholder="Search for customer"
                />
                <span
                  v-bind:class="searchcustomer == '' ? 'disable-click' : ''"
                  @click="fetchCustomers()"
                  class="fa fa-search"
                ></span>
              </div>
            </form>
            <div v-if="searchresult.length > 0">
              <ul id="cus-search" class="p-0">
                <li
                  @click.prevent="setCustomer(row)"
                  v-for="(row, ix) in searchresult"
                  v-bind:key="ix"
                  class="dropdown-item"
                >
                  {{ row.firstname }} {{ row.lastname }}
                  <div class="dropdown-divider"></div>
                </li>
              </ul>
            </div>
            <div v-if="showLoader">
              <img src="../assets/img/searching.gif" alt="" />
            </div>
            <div
              v-if="
                searchcustomer !== '' && notfound && searchresult.length == 0
              "
              id="not_found"
              class="text-center mt-3"
            >
              <img
                src="../assets/img/notfound.svg"
                class="text-center mt-5"
                alt=""
              />
              <h5 class="mt-3">Customer not found</h5>
              <p>
                Try again with a different term or
                <span
                  data-toggle="modal"
                  data-target="#createcustomer"
                  @click="notfound = false"
                  class="create"
                  >Create customer</span
                >
              </p>
            </div>
          </div>
          <div class="modal-footer mx-5 py-4 border-0">
            <button
              data-toggle="modal"
              data-target="#createcustomer"
              @click="notfound = false"
              type="button"
              class="btn my-auto"
            >
              Create Customer
            </button>
            <button
              @click.prevent="SetOrderType"
              type="button"
              class="cancel-close btn my-auto"
            >
              Skip
            </button>
          </div>
        </div>
      </div>
    </div>

    <div id="login" class="modal" tabindex="-1" data-backdrop="static">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title mx-sm-5"></h5>

            <img
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="cancelItem"
              src="../assets/img/close.png"
              alt=""
            />
          </div>
          <div class="modal-body">
            <inline-login @remove-approved="removeItem"></inline-login>
          </div>
        </div>
      </div>
    </div>

    <!--create customer modal -->
    <div id="createcustomer" class="modal" tabindex="-1" data-backdrop="static">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title mx-sm-5">Create Customer</h5>

            <img
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              src="../assets/img/close.png"
              alt=""
            />
          </div>
          <ValidationObserver v-slot="{ handleSubmit }">
            <div class="modal-body mx-sm-5">
              <div class="form-group">
                <label for="">First Name</label>
                <validation-provider rules="required" v-slot="{ errors }">
                  <input
                    type="text"
                    v-model="newcustomer.firstname"
                    class="form-control"
                    placeholder="Enter customer’s first name"
                  />
                  <span class="err_msg">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <div class="form-group">
                <label for="">Last Name</label>
                <validation-provider rules="required" v-slot="{ errors }">
                  <input
                    type="text"
                    v-model="newcustomer.lastname"
                    class="form-control"
                    placeholder="Enter customer’s last name"
                  />
                  <span class="err_msg">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <div class="form-group">
                <label for="">Email Address</label>
                <validation-provider rules="email" v-slot="{ errors }">
                  <input
                    type="email"
                    v-model="newcustomer.emailaddress"
                    class="form-control"
                    placeholder="Enter customers’s email address"
                  />
                  <span class="err_msg">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <div class="form-group">
                <label for="">Phone Number</label>
                <validation-provider
                  :rules="{
                    regex:
                      /^(\+234|234|0)(701|702|703|704|705|706|707|708|709|802|803|804|805|806|807|808|809|810|811|812|813|814|815|816|817|818|819|909|908|901|902|903|904|905|906|907)([0-9]{7})$/,
                  }"
                  v-slot="{ errors }"
                >
                  <input
                    type="text"
                    class="form-control"
                    v-model="newcustomer.phonenumber"
                    placeholder="Enter the customer’s phone number"
                  />
                  <span class="err_msg">{{ errors[0] }}</span>
                </validation-provider>
              </div>
            </div>
            <div class="modal-footer mx-5 py-4 border-0">
              <button
                data-dismiss="modal"
                type="button"
                class="cancel-close btn my-auto"
              >
                Cancel
              </button>
              <button
                @click.prevent="handleSubmit(createCustomer)"
                type="button"
                class="btn ml-2 my-auto"
              >
                Create Customer
              </button>
            </div>
          </ValidationObserver>
        </div>
      </div>
    </div>

    <!--payment modal -->
    <div id="ordertype" class="modal" tabindex="-1" data-backdrop="static">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header border-0">
            <h5 class="modal-title mx-sm-5">Select Order Type</h5>

            <img
              class="close"
              @click="
                searchresult = [];
                searchcustomer = '';
                customerdetails = {};
                ordermethod = 'pickup';
              "
              data-dismiss="modal"
              aria-label="Close"
              src="../assets/img/close.png"
              alt=""
            />
          </div>

          <ValidationObserver v-slot="{ handleSubmit }">
            <div class="modal-body mx-sm-5">
              <div class="d-flex">
                <label class="form-input-checked-box nosvg">
                  <input
                    type="radio"
                    value="pickup"
                    name="method"
                    v-model="ordermethod"
                  />
                  <span class="form-input__label">Pickup</span>
                </label>

                <label class="form-input-checked-box nosvg">
                  <input
                    type="radio"
                    name="method"
                    value="delivery"
                    v-model="ordermethod"
                  />
                  <span class="form-input__label">Delivery</span>
                </label>
              </div>
              <div v-if="ordermethod == 'delivery'">
                <div class="form-group">
                  <label for="">Enter Delivery Address</label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <input
                      id="map"
                      type="text"
                      ref="search"
                      v-model="deliveryaddress"
                      @change="getAddAddress()"
                      class="form-control"
                      placeholder="Enter delivery address"
                    />
                    <img
                      id="pickup_gif"
                      class="loc_spinner"
                      src="../assets/img/processing.gif"
                      alt=""
                    />

                    <span class="err_msg">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
                <div class="form-group">
                  <label for="">Add Delivery Note <i>(optional)</i></label>
                  <validation-provider>
                    <textarea
                      class="form-control"
                      v-model="deliverynote"
                    ></textarea>
                  </validation-provider>
                </div>
              </div>
            </div>
            <div class="modal-footer mx-sm-5 py-4 border-0">
              <button
                data-dismiss="modal"
                @click="
                  searchresult = [];
                  searchcustomer = '';
                  customerdetails = {};
                  ordermethod = 'pickup';
                "
                type="button"
                class="cancel-close btn my-auto"
              >
                Cancel
              </button>

              <button
                @click.prevent="handleSubmit(makePayment)"
                type="button"
                v-bind:disabled="
                  ordermethod == 'delivery' && deliveryaddress == null
                "
                class="btn ml-2 my-auto"
              >
                Next
              </button>
            </div>
          </ValidationObserver>
        </div>
      </div>
    </div>
    <!-- payment modal without invoice -->
    <div id="payment" class="modal" tabindex="-1" data-backdrop="static">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header border-0">
            <h5 class="modal-title mx-sm-5">
              Select a Payment Option:
              <span style="color: #28a745"
                >Total = ₦{{
                  formatPrice(Number(total) + Number(calculateTaxTotal()))
                }}</span
              >
            </h5>

            <img
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              src="../assets/img/close.png"
              @click="
                resetPaymentOption;
                resetPromo;
              "
              alt=""
            />
          </div>

          <div v-if="ordermethod == 'delivery'" class="modal-header mx-sm-5">
            <div class="col-6">
              <div class="d-flex">
                <h5
                  class="price my-auto"
                  style="font: normal normal 500 15px/28px Source Sans Pro"
                >
                  <strong>Cart Total:</strong>
                  <span class="ml-2" style="font-size: 14px">
                    ₦ {{ formatPrice(subtotal) }}</span
                  >
                </h5>
              </div>
              <div class="d-flex">
                <h5
                  style="font: normal normal 500 15px/28px Source Sans Pro"
                  class="price my-auto"
                >
                  <strong> Delivery Fee:</strong>
                  <span class="ml-2" style="font-size: 14px"
                    >₦ {{ formatPrice(deliveryfee) }}</span
                  >
                </h5>
              </div>
              <div class="d-flex">
                <h5 style="font: normal normal 500 15px/28px Source Sans Pro">
                  <strong>ETA:</strong>
                  <span class="ml-2" style="font-size: 14px">{{ eta }}</span>
                </h5>
              </div>
              <div class="d-flex">
                <h5 style="font: normal normal 500 15px/28px Source Sans Pro">
                  <strong>Delivery Note:</strong>
                  <span class="ml-2" style="font-size: 14px">{{
                    deliverynote
                  }}</span>
                </h5>
              </div>
              <div
                id="payment-option"
                class="my-3 px-3"
                v-if="ordermethod == 'delivery'"
              >
                <div class="row d-none">
                  <div class="form-group">
                    <h5
                      style="font: normal normal 500 15px/28px Source Sans Pro"
                    >
                      <strong>Schedule Delivery Date</strong>
                    </h5>
                    <input
                      type="datetime-local"
                      class="form-control"
                      id="scheduleDelivery"
                      v-model="scheduledelivery"
                    />
                    <p class="mt-3 mb-2" v-if="scheduledelivery !== ''">
                      You have scheduled {{ formatDate(scheduledelivery) }} for
                      your delivery
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-header" style="justify-content: center !important">
            <button
              type="button"
              data-toggle="modal"
              data-target="#promo"
              class="edit-btn btn ml-2 mr-3"
            >
              Use Promo
            </button>
            <button
              v-if="!$store.getters.isQueue && display_paylater"
              data-dismiss="modal"
              @click.prevent="addToQueue"
              type="button"
              class="queue-btn btn ml-2 mr-3"
            >
              Pay Later
            </button>
            <button
              v-if="ordermethod == 'delivery'"
              @click="selectOption('pay-on-delivery')"
              type="button"
              class="edit-btn btn ml-2 mr-3 d-none"
            >
              Pay on Delivery
            </button>
          </div>
          <ValidationObserver
            v-slot="{ handleSubmit }"
            style="height: 60vh; overflow-y: scroll"
          >
            <div class="modal-body mx-sm-5">
              <div
                id="user"
                v-if="Object.keys(customerdetails).length !== 0"
                class="p-4"
              >
                <div class="row">
                  <div class="col-2">
                    <div class="img-box">
                      <img
                        class="img-fluid"
                        src="../assets/img/avatar.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="ml-1 col-5">
                    <div class="user-details">
                      <h5>
                        {{ customerdetails.firstname }}
                        {{ customerdetails.lastname }}
                      </h5>
                      <p class="phone">{{ customerdetails.phonenumber }}</p>
                      <p class="point">
                        <strong>{{ customerdetails["Ntisa Points"] }}</strong>
                        Loyalty Points
                      </p>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <!-- <div
                        id="redeem"
                        data-target="#loyalty"
                        data-toggle="modal"
                        class="d-flex justify-content-around"
                      >
                        <div class="m-auto"> <img
                            src="../assets/img/gift-icon.png"
                            alt=""
                          ></div>
                        <span class="m-auto">Redeem Reward</span>
                      </div> -->
                  </div>
                </div>
              </div>
              <!-- <div class="col-sm-12" v-if="ordermethod=='delivery'"> -->
              <!-- <h5 class="modal-title mx-sm-5 mt-3 mb-2 text-center">Select Pay on Delivery</h5> -->
              <!-- <div
                  title="Select if you want to pay on delivery"
                  class="ordermethod d-flex mb-2"
                  @click="selectOption('pay-on-delivery');"
                  v-bind:class="paymentoption.includes('pay-on-delivery') ?'active':''"
                >
                  <div>
                    <h5 class="topic">Pay on Delivery</h5>
                    <p class="content">Pay for the dispatch after the delivery is Completed</p>
                  </div>
                  <div class="selected-img">
                    <img
                      src="../assets/img/check-sm.png"
                      alt=""
                    >
                  </div> -->
              <!-- </div> -->
              <!-- </div> -->
              <!-- payment options list -->
              <div
                id="payment-option"
                class="my-3 px-3"
                v-if="option.includes('pay-on-delivery') == true"
              >
                <div class="row">
                  <h5 class="modal-body mx-sm-5 mt-3 mb-2">
                    You have selected the Pay on Delivery option, You can now
                    checkout
                  </h5>
                </div>
              </div>
              <div
                id="payment-option"
                class="my-3 px-3"
                v-if="option.includes('pay-on-delivery') == false"
              >
                <div class="row">
                  <div class="col-6 col-sm-6 col-xl-3 mb-1">
                    <div
                      class="options py-4"
                      @click="selectOption('cash')"
                      v-bind:class="option.includes('cash') ? 'active' : ''"
                    >
                      <img src="../assets/img/cash.png" alt="" />
                      <p>Cash</p>
                    </div>
                  </div>
                  <div class="col-6 col-sm-6 col-xl-3 mb-1">
                    <div
                      class="options py-4"
                      @click="selectOption('pos')"
                      v-bind:class="option.includes('pos') ? 'active' : ''"
                    >
                      <img src="../assets/img/pos.png" alt="" />
                      <p>POS</p>
                    </div>
                  </div>
                  <div class="col-6 col-sm-6 col-xl-3 mb-1">
                    <div
                      class="options py-4"
                      @click="selectOption('banktransfer')"
                      v-bind:class="
                        option.includes('banktransfer') ? 'active' : ''
                      "
                    >
                      <img src="../assets/img/transfer.png" alt="" />
                      <p>Bank Transfer</p>
                    </div>
                  </div>
                  <div class="col-6 col-sm-6 col-xl-3 mb-1">
                    <div
                      class="options py-4"
                      @click="selectOption('third_party')"
                      v-bind:class="
                        option.includes('third_party') ? 'active' : ''
                      "
                    >
                      <img src="../assets/img/cash.png" alt="" />
                      <p>3rd Party</p>
                    </div>
                  </div>
                </div>
                <hr />
              </div>

              <!-- payment body -->
              <div
                id="payment-body"
                class="px-4"
                v-if="option.includes('pay-on-delivery') == false"
              >
                <!-- cash payment -->
                <div v-if="option.includes('cash')" class="cash">
                  <h4 class="title text-bold my-3">Cash</h4>
                  <h5>How much did the customer give you?</h5>

                  <div class="form-group">
                    <label class="sr-only" for="inlineFormInputGroup"
                      >Enter amount</label
                    >
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">₦</div>
                      </div>
                      <validation-provider rules="" v-slot="{ errors }">
                        <input
                          type="text"
                          v-model="cash_amountpaid"
                          class="form-control"
                          id="inlineFormInputGroup"
                          placeholder="Enter amount"
                          v-on:keyup="formatInput"
                          @input="setTotalMoney()"
                        />
                        <span class="err_msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </div>
                    <span class="note"
                      >Always count and confirm money from customers</span
                    >
                  </div>
                </div>
                <!-- End cash payment -->
                <!-- pos payment -->
                <div v-if="option.includes('pos')" class="pos">
                  <h4 class="title text-bold my-3">POS</h4>

                  <h5>Receipt number</h5>

                  <div class="form-group">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <input
                        type="text"
                        v-model="receiptnumber"
                        required
                        class="form-control"
                        placeholder="Type receipt number here"
                      />
                      <span class="note"
                        >Enter the receipt number on the POS printout</span
                      >
                      <span class="err_msg">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                  <div class="form-group">
                    <label for="">POS Merchant</label>
                    <validation-provider rules="required" v-slot="{ errors }">
                      <select
                        name=""
                        id=""
                        v-model="posaccountname"
                        class="form-control"
                      >
                        <option value="" selected hidden>
                          select the POS Merchant
                        </option>
                        <option>First Monie</option>
                        <option>Opay</option>
                        <option>Kudi</option>
                        <option>Monie Point</option>
                        <option>Access Closa</option>
                        <option>UBA Monie</option>
                        <option>Palmpay</option>
                        <option>Paycenter</option>
                        <option>Payforce</option>
                        <option>Kashzoo</option>
                        <option>Kolomoni</option>
                        <option>Baxi Box</option>
                      </select>
                      <span class="err_msg">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>

                  <div class="form-group">
                    <label class="sr-only" for="inlineFormInputGroup"
                      >Enter amount</label
                    >
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">₦</div>
                      </div>
                      <validation-provider rules="required" v-slot="{ errors }">
                        <input
                          type="text"
                          v-model="pos_amountpaid"
                          class="form-control"
                          id="inlineFormInputGroup"
                          placeholder="Enter amount"
                          v-on:keyup="formatInputPos"
                          @input="setTotalMoney()"
                        />
                        <span class="err_msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </div>
                  </div>
                </div>
                <!-- end pos -->
                <!-- bank transfer payment -->
                <div v-if="option.includes('banktransfer')" class="transfer">
                  <h4 class="title text-bold my-3">Bank Transfer</h4>

                  <div class="form-group">
                    <label class="sr-only" for="inlineFormInputGroup"
                      >Enter amount</label
                    >
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">₦</div>
                      </div>
                      <validation-provider rules="required" v-slot="{ errors }">
                        <input
                          type="text"
                          v-model="bank_amountpaid"
                          class="form-control"
                          id="inlineFormInputGroup"
                          placeholder="Enter amount"
                          v-on:keyup="formatInputBank"
                          @input="setTotalMoney()"
                        />
                        <span class="err_msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="">Bank</label>
                    <validation-provider rules="required" v-slot="{ errors }">
                      <select
                        name=""
                        id=""
                        v-model="bankaccountname"
                        class="form-control"
                      >
                        <option value="" selected hidden>
                          select the customer's bank
                        </option>
                        <option>Access Bank</option>
                        <option>Access ( Diamond) Bank</option>
                        <option>Citibank</option>
                        <option>Ecobank</option>
                        <option>Fidelity Bank</option>
                        <option>First City Monument Bank (FCMB)</option>
                        <option>First bank</option>
                        <option>FSDH Merchant Bank</option>
                        <option>Guarantee Trust Bank (GTB)</option>
                        <option>Globus bank</option>
                        <option>Heritage Bank</option>
                        <option>Keystone Bank</option>
                        <option>Kuda bank</option>
                        <option>Lotus bank</option>
                        <option>Polaris Bank</option>
                        <option>Providus bank</option>
                        <option>Rand Merchant Bank</option>
                        <option>Stanbic IBTC Bank</option>
                        <option>Standard Chartered Bank</option>
                        <option>Sterling Bank</option>
                        <option>Suntrust Bank</option>
                        <option>Union Bank</option>
                        <option>United Bank for Africa (UBA)</option>
                        <option>Unity Bank</option>
                        <option>Wema Bank</option>
                        <option>Zenith Bank</option>
                      </select>
                      <span class="err_msg">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                  <!-- <div class="form-group">
                      <label for="">Account name</label>
                      <validation-provider
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <input
                          type="text"
                          v-model="bank.accountname"
                          class="form-control"
                          placeholder="Enter the customer’s account name"
                        >
                        <span class="err_msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </div>
                    <div class="form-group">
                      <label for="">Account number</label>
                      <validation-provider
                        rules="required|numeric|length:10"
                        v-slot="{ errors }"
                      >
                        <input
                          type="text"
                          v-model="bank.accountno"
                          class="form-control"
                          placeholder="Enter the customer’s account number"
                        >
                        <span class="err_msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </div>
                    <div class="form-group">
                      <label for="">Bank</label>
                      <validation-provider
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <select
                          name=""
                          id=""
                          v-model="bank.bankname"
                          class="form-control"
                        >
                          <option
                            value=""
                            selected
                            hidden
                          >select the customer's bank</option>
                          <option>Access Bank</option>
                          <option>Citibank</option>
                          <option>Ecobank</option>
                          <option>Fidelity Bank</option>
                          <option>First City Monument Bank (FCMB)</option>
                          <option>FSDH Merchant Bank</option>
                          <option>Guarantee Trust Bank (GTB)</option>
                          <option>Heritage Bank</option>
                          <option>Keystone Bank</option>
                          <option>Polaris Bank</option>
                          <option>Rand Merchant Bank</option>
                          <option>Stanbic IBTC Bank</option>
                          <option>Standard Chartered Bank</option>
                          <option>Sterling Bank</option>
                          <option>Suntrust Bank</option>
                          <option>Union Bank</option>
                          <option>United Bank for Africa (UBA)</option>
                          <option>Unity Bank</option>
                          <option>Wema Bank</option>
                          <option>Zenith Bank</option>
                        </select>
                        <span class="err_msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </div> -->
                </div>

                <!-- Third Party payment -->
                <div v-if="option.includes('third_party')" class="third_party">
                  <h4 class="title text-bold my-3">Third Party</h4>

                  <h5>Thirdparty Gateway</h5>
                  <div class="form-group">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <select
                        name=""
                        id=""
                        class="form-control"
                        v-model="thirdpartyobj.paymentaccountname"
                      >
                        <option>PayStack</option>
                        <option>Flutterwave</option>
                        <option>Remita</option>
                        <option>GlobalPay</option>
                        <option>PayPal</option>
                        <option>PalmPay</option>
                        <option>Opay</option>
                      </select>
                      <span class="err_msg">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>

                  <h5>Amount</h5>
                  <div class="form-group">
                    <label class="sr-only" for="inlineFormInputGroup"
                      >Enter amount</label
                    >
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">₦</div>
                      </div>
                      <validation-provider
                        rules="required"
                        v-slot="{ errors }"
                        style="width: calc(100% - 33px)"
                      >
                        <input
                          type="text"
                          v-model="thirdparty_amountpaid"
                          class="form-control"
                          id="inlineFormInputGroup"
                          placeholder="Enter amount"
                          v-on:keyup="formatInputThirdparty"
                          @input="setTotalMoney()"
                        />
                        <span class="err_msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </div>
                  </div>
                </div>

                <!-- end bank transfer -->
                <!-- change cashback-->
                <div
                  v-show="cash_amountpaid > 0 || option.includes('cash')"
                  class="form-group"
                >
                  <h5>Change</h5>
                  <label class="sr-only" for="inlineFormInputGroup"
                    >Change</label
                  >

                  <div class="input-group mb-2">
                    <div class="input-group-prepend">
                      <div class="input-group-text">₦</div>
                    </div>
                    <validation-provider rules="" v-slot="{ errors }">
                      <input
                        v-if="cash_amountpaid == ''"
                        type="text"
                        disabled
                        :value="0"
                        class="form-control"
                        id="inlineFormInputGroup"
                        placeholder="Enter amount"
                      />
                      <input
                        v-else-if="
                          total_money -
                            (Number(total) + Number(calculateTaxTotal())) >
                          0
                        "
                        type="text"
                        disabled
                        :value="
                          option.includes('cash')
                            ? formatPrice(
                                isNaN(total_money)
                                  ? 0
                                  : total_money -
                                      (Number(total) +
                                        Number(calculateTaxTotal()))
                              )
                            : 0
                        "
                        class="form-control"
                        id="inlineFormInputGroup"
                        placeholder="Enter amount"
                      />
                      <input
                        v-else
                        type="text"
                        disabled
                        :value="0"
                        class="form-control"
                        id="inlineFormInputGroup"
                        placeholder="Enter amount"
                      />
                      <span class="err_msg">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                  <span v-if="cash_amountpaid == ''" class="note"
                    >Customer balance is ₦ 0</span
                  >
                  <span
                    v-else-if="
                      total_money -
                        (Number(total) + Number(calculateTaxTotal())) >
                      0
                    "
                    class="note"
                    >Customer balance is ₦
                    {{
                      option.includes("cash")
                        ? formatPrice(
                            isNaN(total_money)
                              ? 0
                              : total_money -
                                  (Number(total) + Number(calculateTaxTotal()))
                          )
                        : 0
                    }}
                  </span>
                  <span class="note" v-else>Customer balance is ₦ 0</span>
                </div>
                <!-- end change -->
              </div>
            </div>
            <div class="modal-footer mx-4 py-4 border-0">
              <button
                data-dismiss="modal"
                @click="
                  customerdetails = {};
                  resetPaymentOption();
                  resetPromo();
                "
                type="button"
                class="cancel-close btn my-auto"
              >
                Go Back
              </button>

              <button
                @click.prevent="handleSubmit(placeOrder)"
                v-bind:disabled="balancePayment"
                type="button"
                class="btn ml-2 my-auto"
              >
                Checkout
              </button>
            </div>
          </ValidationObserver>
        </div>
      </div>
    </div>
    <!-- end payment modal without invoive -->
    <!--redeem loyalty points modal -->
    <div id="loyalty" class="modal" tabindex="-1" data-backdrop="static">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title mx-sm-5">
              Redeem Loyalty Points
              <small>Showing rewards within the available point range</small>
            </h5>

            <img
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              src="../assets/img/close.png"
              alt=""
            />
          </div>
          <div class="modal-body mx-sm-5">
            <div class="text-center">
              <h3>{{ customerdetails["Ntisa Points"] }}</h3>
              <p class="text-uppercase">Loyalty Points</p>
            </div>

            <ul
              class="nav nav-tabs mt-3 justify-content-center"
              id="myTab"
              role="tablist"
            >
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="pricediscount-tab"
                  data-toggle="tab"
                  href="#pricediscount"
                  role="tab"
                  aria-controls="pricediscount"
                  aria-selected="true"
                  >Price Discount</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="percentdiscount-tab"
                  data-toggle="tab"
                  href="#percentdiscount"
                  role="tab"
                  aria-controls="percentdiscount"
                  aria-selected="false"
                  >Percentage Discount</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="freeitem-tab"
                  data-toggle="tab"
                  href="#freeitem"
                  role="tab"
                  aria-controls="freeitem"
                  aria-selected="false"
                  >Free Item</a
                >
              </li>
            </ul>
            <div
              v-if="rewards !== null"
              class="tab-content mt-3"
              id="myTabContent"
            >
              <!-- price discount -->
              <div
                class="tab-pane fade show active"
                id="pricediscount"
                role="tabpanel"
                aria-labelledby="pricediscount-tab"
              >
                <div class="row" v-if="rewards.priceorderdiscount.length > 0">
                  <div
                    v-for="(row, ix) in rewards.priceorderdiscount"
                    v-bind:key="ix"
                    class="col-md-4 col-sm-6 col-xs-12"
                  >
                    <div
                      class="loyalties p-3"
                      @click="reward = row"
                      v-bind:class="reward.id == row.id ? 'active' : ''"
                    >
                      <h5 class="mt-3">{{ row.title }}</h5>
                      <hr />
                      <p>{{ row.rewarddiscount }} points</p>
                      <div class="checked">
                        <img src="../assets/img/check-sm.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center mt-5" v-else>
                  <img
                    class="img-fluid"
                    src="../assets/img/no-result.png"
                    alt=""
                  />
                </div>
              </div>

              <!-- percentage discount -->
              <div
                class="tab-pane fade"
                id="percentdiscount"
                role="tabpanel"
                aria-labelledby="percentdiscount-tab"
              >
                <div
                  class="row"
                  v-if="rewards.percentageorderdiscount.length > 0"
                >
                  <div
                    v-for="(row, ix) in rewards.percentageorderdiscount"
                    v-bind:key="ix"
                    class="col-md-4 col-sm-6 col-xs-12"
                  >
                    <div
                      class="loyalties p-3"
                      @click="reward = row"
                      v-bind:class="reward.id == row.id ? 'active' : ''"
                    >
                      <h5 class="mt-3">{{ row.title }}</h5>
                      <hr />
                      <p>{{ row.rewarddiscount }} points</p>
                      <div class="checked">
                        <img src="../assets/img/check-sm.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center mt-5" v-else>
                  <img
                    class="img-fluid"
                    src="../assets/img/no-result.png"
                    alt=""
                  />
                </div>
              </div>

              <!-- free item -->
              <div
                class="tab-pane fade"
                id="freeitem"
                role="tabpanel"
                aria-labelledby="freeitem-tab"
              >
                <div class="row" v-if="rewards.freeitemcheckout.length > 0">
                  <div
                    v-for="(row, ix) in rewards.freeitemcheckout"
                    v-bind:key="ix"
                    class="col-md-4 col-sm-6 col-xs-12"
                  >
                    <div
                      class="loyalties p-3"
                      @click="reward = row"
                      v-bind:class="reward.id == row.id ? 'active' : ''"
                    >
                      <h5 class="mt-3">{{ row.title }}</h5>
                      <hr />
                      <p>{{ row.rewarddiscount }} points</p>
                      <div class="checked">
                        <img src="../assets/img/check-sm.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center mt-5" v-else>
                  <img
                    class="img-fluid"
                    src="../assets/img/no-result.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer mx-5 py-4 border-0">
            <button
              data-dismiss="modal"
              type="button"
              class="cancel-close btn my-auto"
            >
              Cancel
            </button>
            <button
              @click.prevent="redeemLoyalty"
              type="button"
              class="btn ml-2 my-auto"
            >
              Done
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--promo code modal -->
    <div id="promo" class="modal" tabindex="-1" data-backdrop="false">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title mx-sm-5">Promo Code</h5>

            <img
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              src="../assets/img/close.png"
              alt=""
            />
          </div>
          <ValidationObserver v-slot="{ handleSubmit }">
            <div class="modal-body mx-sm-5">
              <div class="mx-auto text-center">
                <img src="../assets/img/promo.png" alt="" />
              </div>
              <div class="form-group">
                <label for="">Promo code</label>
                <validation-provider rules="required" v-slot="{ errors }">
                  <input
                    type="text"
                    class="form-control"
                    v-model="promocode"
                    placeholder="Enter gift card number, voucher number or promo code"
                  />
                  <span class="err_msg">{{ errors[0] }}</span>
                </validation-provider>
              </div>
            </div>
            <div class="modal-footer mx-5 py-4 border-0">
              <button
                data-dismiss="modal"
                type="button"
                @click.prevent="handleSubmit(clearPromo)"
                class="cancel-close btn my-auto"
              >
                Clear
              </button>
              <button
                type="button"
                @click.prevent="handleSubmit(handlePromo)"
                class="btn ml-2 my-auto"
              >
                Apply Code
              </button>
            </div>
          </ValidationObserver>
        </div>
      </div>
    </div>

    <!--discount modal -->
    <div id="discount" class="modal" tabindex="-1" data-backdrop="static">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title mx-sm-5">Discount</h5>

            <img
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              src="../assets/img/close.png"
              alt=""
            />
          </div>
          <ValidationObserver v-slot="{ handleSubmit }">
            <div class="modal-body mx-sm-5">
              <div class="mx-auto text-center">
                <img src="../assets/img/promo.png" alt="" />
              </div>
              <div class="form-group">
                <label for="">Discount (%)</label>
                <validation-provider rules="required" v-slot="{ errors }">
                  <input
                    step=".01"
                    id="discount_price"
                    onkeypress="return (document.getElementById('discount_price').value.indexOf('.') > 0) ? (document.getElementById('discount_price').value.substr(document.getElementById('discount_price').value.indexOf('.'), 3).length > 2 ? false : true) : true"
                    v-model="discount_percent_temp"
                    class="form-control"
                    type="number"
                    placeholder="Enter discount in percentage"
                    max="100"
                    min="0"
                  />
                  <!-- <span class="err_msg">{{ errors[0] }}</span> -->
                  <span class="err_msg">{{ error.discount }}</span>
                </validation-provider>
              </div>
            </div>
            <div class="modal-footer mx-5 py-4 border-0">
              <button
                data-dismiss="modal"
                type="button"
                @click.prevent="handleSubmit(clearDiscount)"
                class="cancel-close btn my-auto"
              >
                Clear
              </button>
              <button
                type="button"
                @click.prevent="handleSubmit(handleDiscount)"
                class="btn ml-2 my-auto"
              >
                Save
              </button>
            </div>
          </ValidationObserver>
        </div>
      </div>
    </div>

    <!--queue modal -->
    <div id="queue-modal" class="modal" tabindex="-1" data-backdrop="static">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title mx-sm-5">Pay Later</h5>

            <img
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              src="../assets/img/close.png"
              alt=""
            />
          </div>
          <div class="modal-body mx-sm-5">
            <div class="d-flex align-items-center">
              <button
                data-dismiss="modal"
                @click.prevent="handleQueue('ignore')"
                type="button"
                class="cancel-close btn my-auto"
              >
                Ignore
              </button>
              <button
                @click.prevent="handleQueue('print')"
                type="button"
                class="queue-btn ml-2 btn my-auto"
              >
                <i class="fa fa-print ml-2"></i> Print
              </button>
              <button
                @click.prevent="sendToKitchen()"
                v-if="$store.getters.businesscategory == 'Food'"
                type="button"
                class="btn ml-2 my-auto"
              >
                <i class="fa fa-utensils ml-2"></i> Send To Kitchen
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as $ from "jquery";
import { mapActions, mapGetters } from "vuex";
import InlineLogin from "@/components/login";

export default {
  name: "OlineCart",
  components: {
    InlineLogin,
  },
  data() {
    return {
      thirdpartyobj: {},
      total_money: Number(0),
      // change: 0,
      showLoader: false,
      searchcustomer: "",
      item: "",
      taxes: [],
      user: {},
      order: null,
      receiptnumber: "",
      cash_amountpaid: "",
      pos_amountpaid: "",
      bank_amountpaid: "",
      thirdparty_amountpaid: "",
      posaccountname: "",
      bankaccountname: "",
      showinfo: false,
      scheduledelivery: "",
      discount_amt: 0,
      search: "",
      edit: false,
      cart: [],
      searchresult: [],
      customerdetails: {},
      order_total: 0,
      tax_total: 0,
      notfound: false,
      option: [],
      rewards: null,
      reward: {},
      queue: [],
      discount_percent_temp: 0,
      isTransferPaid: true,
      ordermethod: "pickup",
      deliveryaddress: null,
      deliverynote: "",
      deliveryfee: "",
      eta: "",

      discountprice: 0,
      discount_percent: 0,
      promocode: "",

      dispatch_data: {
        latitude: "",
        longitude: "",
      },
      newcustomer: {
        role: "customer",
        merchID: "",
        branchID: "",
        firstname: "",
        lastname: "",
        phonenumber: "",
        emailaddress: "",
      },
      bank: {
        accountname: "",
        accountno: "",
        bankname: "",
      },
      error: {
        discount: "",
      },
      padding: 0,
      discountpayment: 0,
      columns: ["sn", "ordercode", "customer", "date", "action"],
      options: {
        headings: {
          sn: "s/n",
          customer: "Customer",
        },

        sortable: ["sn", "ordercode", "customer", "date", "action"],
        filterable: ["customer", "ordercode"],
        saveState: true,
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-long-arrow-up",
          down: "fa-long-arrow-down",
        },
      },
    };
  },
  created() {
    this.fetchCart();
    this.fetchTax_Rewards();
    // this.fetchRewards()
  },
  mounted() {
    if (Number(screen.width) > 700) {
      this.padding = $("#main").css("padding-left");
      $(window).resize(function () {
        // console.log((Number(screen.width) - Number($('#sidecart #cart').width())))
        // console.log($("#sidecart").is(':visible'))
        if ($("sidecart").is(":visible")) {
          this.$emit(
            "cartWidthChanged",
            Number(screen.width) -
              Number(
                $("#sidecart #cart").width() ? $("#sidecart #cart").width() : 0
              ) +
              "px"
          );
        }
      });
      $(window).on("shown.bs.modal", (e) => {
        if ($("#sidecart").is(e.target)) {
          $("#main").css("padding-left", 0);
          $("#aside").css("display", "none");
          // console.log($('#sidecart #cart').width())
          this.$emit("isopen", true);
          this.$emit(
            "cartWidthChanged",
            Number(screen.width) - Number($("#sidecart #cart").width()) + "px"
          );
        }
      });

      $(window).on("hidden.bs.modal", (e) => {
        if ($("#sidecart").is(e.target)) {
          this.$emit("isopen", false);
          $("#main").css("padding-left", this.padding);
          $("#aside").css("display", "block");
          this.$emit("cartWidthChanged", "100%");
        }
      });
    }

    this.user = this.$store.getters.user;
    this.newcustomer.merchID = this.$store.getters.user.merchID;
    this.newcustomer.branchID = this.$store.getters.user.branchID;
    const req = {
      what: "getCustomers",
      useToken: true,
      params: {
        merchID: this.$store.getters.user.merchID,
        pagination: false,
      },
    };

    this.getCustomers(req);
    // this.getTaxes();
  },
  watch: {
    cart(val) {
      if (val.length == 0 && this.$store.getters.isQueue) {
        this.$store.dispatch("setQueueStatus", false);
      }
    },
    searchcustomer(val) {
      if (val !== "") this.fetchCustomers();
      else this.searchresult = [];
    },
  },
  computed: {
    // subtotal () {
    //   let sum = 0;
    //   let vm = this;
    //   this.cart.forEach(i => {
    //     sum += Number(i.totalamount);
    //   });
    //   this.order_total = sum;
    //   return sum;
    // },
    subtotal() {
      let sum = 0;
      let tax_sum = 0;
      let vm = this;
      this.react += 1;
      this.cart.forEach((i) => {
        if (i.taxstatus == false) {
          sum += Number(i.totalamount);
        } else {
          tax_sum += Number(i.totalamount);
        }
        // sum += Number(i.totalamount);
      });

      this.order_total = Number(sum) + Number(tax_sum);
      this.tax_total = sum;
      return Number(sum) + Number(tax_sum);
    },
    subtotaltax() {
      return this.tax_total;
    },
    // discount_amt () {
    //   if (this.discount_percent) {
    //     let sum = 0;
    //     let vm = this;
    //     this.cart.forEach(i => {
    //       sum += Number(i.totalamount) * this.discount_percent/100;
    //     });
    //     return sum;
    //   }
    //   else{
    //     return 0;
    //   }
    // },
    discount: {
      get() {
        if (this.discount_percent) {
          return (
            Number(this.discountprice) +
            Number(this.subtotal) * (Number(this.discount_percent) / 100)
          );
        } else {
          return this.discount_amt;
        }
      },

      set(val) {
        this.discount_amt = val;
      },
    },

    display_discount() {
      let result = this.user.permissions.filter((permission) =>
        permission.name.toLowerCase().includes("discount")
      );
      return result != undefined && result.length > 0;
    },
    display_paylater() {
      let result = this.user.permissions.filter((permission) =>
        permission.name.toLowerCase().includes("pay-later")
      );
      return result != undefined && result.length > 0;
    },

    total() {
      let sum = 0;
      let vm = this;
      if (this.cart.length == 0) {
        sum = 0;
      } else {
        let promo = isNaN(this.discount) ? 0 : this.discount;
        sum =
          this.ordermethod == "delivery"
            ? Number(this.order_total) +
              Number(this.deliveryfee) -
              Number(promo)
            : Number(this.order_total) - Number(promo);
      }

      return parseFloat(Number(sum)).toFixed(2);
    },
    balancePayment() {
      let amountpaid = 0;
      if (this.option.includes("cash")) {
        amountpaid += parseFloat(this.cash_amountpaid.replace(/,/g, ""));
      }
      if (this.option.includes("pos")) {
        amountpaid += parseFloat(this.pos_amountpaid.replace(/,/g, ""));
      }
      if (this.option.includes("banktransfer")) {
        amountpaid += parseFloat(this.bank_amountpaid.replace(/,/g, ""));
      }
      if (this.option.includes("third_party")) {
        amountpaid += parseFloat(this.thirdparty_amountpaid.replace(/,/g, ""));
      }

      if (Number(this.total) + Number(this.calculateTaxTotal()) == amountpaid) {
        return false;
      } else if (
        this.option.includes("cash") == true &&
        this.option.length > 1 &&
        Number(this.total) + Number(this.calculateTaxTotal()) <= amountpaid
      ) {
        return false;
      } else if (
        this.option.includes("cash") == true &&
        this.option.length == 1 &&
        amountpaid >= Number(this.total) + Number(this.calculateTaxTotal())
      ) {
        return false;
      } else if (this.option.includes("pay-on-delivery")) {
        return false;
      } else return true;
    },
    ...mapGetters({
      queuedata: "orders/queue",
      // 'customersList' : 'customers/customers',
      // 'findCustomer' : 'customers/searchCustomer',
      // 'getProductThroughBarcode' : 'products/getProductThroughBarcode',
    }),
  },
  methods: {
    formatInput() {
      if (this.cash_amountpaid != "") {
        let num = this.cash_amountpaid.replace(/,/g, ""); //parseFloat(this.cash_amountpaid.replace(/,/g, '')) //remove any currency format;

        if (num.substr(num.length - 1) == ".") {
          this.cash_amountpaid = num;
        } else if (isNaN(num)) {
          this.cash_amountpaid = "";
        } else {
          this.cash_amountpaid = parseFloat(
            this.cash_amountpaid.replace(/,/g, "")
          ).toLocaleString();
        }
      } else if (this.cash_amountpaid == "NaN") {
        this.cash_amountpaid = 0;
      }
    },
    formatInputPos() {
      if (this.pos_amountpaid != "") {
        let num = this.pos_amountpaid.replace(/,/g, ""); //parseFloat(this.cash_amountpaid.replace(/,/g, '')) //remove any currency format;

        if (num.substr(num.length - 1) == ".") {
          this.pos_amountpaid = num;
        } else {
          this.pos_amountpaid = parseFloat(
            this.pos_amountpaid.replace(/,/g, "")
          ).toLocaleString();
        }
      } else if (this.pos_amountpaid == "NaN") {
        this.pos_amountpaid = 0;
      }
    },
    formatInputBank() {
      if (this.bank_amountpaid != "") {
        let num = this.bank_amountpaid.replace(/,/g, ""); //parseFloat(this.cash_amountpaid.replace(/,/g, '')) //remove any currency format;

        if (num.substr(num.length - 1) == ".") {
          this.bank_amountpaid = num;
        } else {
          this.bank_amountpaid = parseFloat(
            this.bank_amountpaid.replace(/,/g, "")
          ).toLocaleString();
        }
      } else if (this.bank_amountpaid == "NaN") {
        this.bank_amountpaid = 0;
      }
    },
    formatInputThirdparty() {
      if (this.thirdparty_amountpaid != "") {
        let num = this.thirdparty_amountpaid.replace(/,/g, "");

        if (num.substr(num.length - 1) == ".") {
          this.thirdparty_amountpaid = num;
        } else {
          this.thirdparty_amountpaid = parseFloat(
            this.thirdparty_amountpaid.replace(/,/g, "")
          ).toLocaleString();
        }
      } else if (this.cash_amountpaid == "NaN") {
        this.cash_amountpaid = 0;
      }
    },

    resetPaymentOption() {
      this.cash_amountpaid = "";
      this.pos_amountpaid = "";
      this.bank_amountpaid = "";
      this.thirdparty_amountpaid = "";
    },
    resetDiscount() {
      this.discountprice = 0;
      this.discount = 0;
      this.promocode = "";
    },
    resetPromo() {
      this.discount = 0;
      this.discount_percent = 0;
      this.discount_percent_temp = 0;
    },
    fetchDiscount(id, qty) {
      let req = {
        what: "caldiscount",
        showLoader: false,
        useToken: true,
        params: {
          merchID: this.$store.getters.user.merchID,
          orderamount: qty,
          productID: id,
        },
      };
      this.$request
        .makeGetRequest(req)
        .then((res) => {
          this.calcdiscount = res.data.data;

          this.cart.forEach((i, index) => {
            if (i.productid == id) {
              i.discountfee = this.calcdiscount.discountfee;
            }
          });
          this.updateCart(id, qty);
        })
        .catch((error) => {});
    },
    setTotalMoney() {
      let total_money = isNaN(this.total_money) ? 0 : this.total_money;

      //let displaycash_amount = isNaN(this.cash_amountpaid) ? 0 :  this.cash_amountpaid.replace(/,/g, '')

      let displaycash_amount = this.cash_amountpaid.replace(/,/g, "");

      let displaypos_amountpaid = this.pos_amountpaid.replace(/,/g, "");

      let displaybank_amountpaid = this.bank_amountpaid.replace(/,/g, "");

      let displaythirdparty_amountpaid = this.thirdparty_amountpaid.replace(
        /,/g,
        ""
      );

      if (!isNaN(total_money)) {
        //this.total_money = parseFloat(this.cash_amountpaid.replace(/,/g, '')) + parseFloat(this.pos_amountpaid.replace(/,/g, '')) + parseFloat(this.bank_amountpaid.replace(/,/g, ''));
        this.total_money =
          Number(displaycash_amount) +
          Number(displaypos_amountpaid) +
          Number(displaybank_amountpaid) +
          Number(displaythirdparty_amountpaid);
      }
      //this.total_money = parseFloat(this.cash_amountpaid.replace(/,/g, '')) + Number(this.pos_amountpaid) + Number(this.bank_amountpaid)+ Number(this.thirdparty_amountpaid);
    },
    calculateTax(value) {
      // let val = (this.subtotaltax) * (Number(value) / 100)
      // return Number(parseFloat(val).toFixed(2))
      let val = 0;
      this.cart.forEach((i) => {
        if (i.taxstatus == false) {
          val = this.subtotaltax * (Number(value) / 100);
        } else {
          val = 0;
        }
        // sum += Number(i.totalamount);
      });

      return Number(parseFloat(val).toFixed(2));
    },
    calculateTaxTotal() {
      if (this.taxes.length > 0) {
        let val = this.taxes
          .map((x) => Number(x.value))
          .reduce((p, c) => {
            return Number(p) + Number(this.calculateTax(c));
          }, 0);

        return parseFloat(val).toFixed(2);
      }
      return 0;
    },
    //to fetch both taxes and loyalty rewards
    fetchTax_Rewards() {
      let req = {
        what: "tax_rewards",
        useToken: true,
        showLoader: false,
        params: {
          merchID: this.$store.getters.user.merchID,
          branchID: this.$store.getters.user.branchID,
          rewardtype: "percentageorderdiscount",
        },
      };
      this.$request
        .makeGetRequest(req)
        .then((res) => {
          if (res.status == "true") {
            this.$store.commit("setTax", res.data.tax);
            this.rewards = res.data.reward;
            this.taxes = this.$store.getters.taxes;
            if (req.showLoader == undefined || req.showLoader) {
              this.$swal.close();
            }
          } else {
            // this.$swal.fire("Error", res.data.message, "error");
            this.$toasted.show(res.data.message, {
              theme: "bubble",
              type: "error",
              position: "top-right",
              duration: 4000,
            });
          }
        })
        .catch((error) => {
          // this.$swal.fire("Error", error.message, "error");
          this.$toasted.show(error.message, {
            theme: "bubble",
            type: "error",
            position: "top-right",
            duration: 4000,
          });
        });
    },
    getTaxes() {
      let req = {
        what: "tax",
        useToken: true,
        params: {
          merchID: this.$store.getters.user.merchID,
        },
      };
      this.$request
        .makeGetRequest(req)
        .then((res) => {
          if (res.status == "true") {
            this.$store.commit("setTax", res.data.data);
            this.taxes = this.$store.getters.taxes;
            if (req.showLoader == undefined || req.showLoader) {
              this.$swal.close();
            }
          } else {
            this.$swal.fire("Error", res.data.message, "error");
          }
        })
        .catch((error) => {
          this.$swal.fire("Error", error.message, "error");
        });
    },
    handlePromo() {
      let req = {
        what: "verifypromocode",
        useToken: true,
        params: {
          merchID: this.$store.getters.user.merchID,
          // branchID: this.$store.getters.user.branchID,
          promocode: this.promocode,
        },
      };
      this.$request
        .makeGetRequest(req)
        .then((res) => {
          if (res.status == "true") {
            if (res.data.data.amount == null) {
              this.$swal.fire("Error", "Promocode Not Valid", "error");
              this.discountprice = 0;
              this.discount = 0;
              $("#promo").modal("hide");
              return;
            }

            this.discount_percent = 0;
            this.discountprice = Number(res.data.data.amount);
            this.discount = this.discountprice;
            this.discount_percent_temp = 0;
            if (req.showLoader == undefined || req.showLoader) {
              this.$swal.close();
            }
          } else {
            this.$swal.fire("Error", res.data.message, "error");
            this.discountprice = 0;
            this.discount_percent_temp = 0;
            this.discount = 0;
          }

          $("#promo").modal("hide");
        })
        .catch((error) => {
          this.discountprice = 0;
          this.discount = 0;
          this.discount_percent_temp = 0;
          this.$swal.fire("Error", error.message, "error");
        });
    },
    handleDiscount() {
      if (this.discount_percent_temp < 0 || this.discount_percent_temp > 100) {
        this.error.discount = "The discount can only be between 0 to 100";
        return;
      }
      this.discount_percent = this.discount_percent_temp;
      this.discountprice = 0;
      this.promocode = "";
      this.discount =
        Number(this.order_total) * (Number(this.discount_percent) / 100);
      $("#discount").modal("hide");
    },

    clearPromo() {
      this.discountprice = 0;
      this.discount = 0;
      this.promocode = "";
      $("#promo").modal("hide");
    },

    clearDiscount() {
      this.discount_percent = 0;
      this.discount_percent_temp = 0;
      this.discount =
        Number(this.order_total) * (Number(this.discount_percent) / 100);
      $("#discount").modal("hide");
    },
    reset() {
      this.showLoader = false;
      this.searchcustomer = "";
      this.user = {};
      this.order = null;
      this.receiptnumber = "";
      this.cash_amountpaid = "";
      this.pos_amountpaid = "";
      this.bank_amountpaid = "";
      this.thirdparty_amountpaid = "";
      this.thirdpartyobj = {};
      this.discount = 0;
      this.search = "";
      this.item = {};
      this.edit = false;
      // this.cart= []
      this.customerdetails = {};
      this.order_total = 0;
      this.notfound = false;
      this.option = [];
      this.rewards = null;
      this.reward = {};
      this.queue = [];
      this.newcustomer = {
        role: "customer",
        merchID: "",
        branchID: "",
        firstname: "",
        lastname: "",
        phonenumber: "",
        emailaddress: "",
      };
      this.bank = {
        accountname: "",
        accountno: "",
        bankname: "",
      };
      (this.discountpayment = 0),
        (this.columns = ["sn", "ordercode", "customer", "date", "action"]),
        (this.options = {
          headings: {
            sn: "s/n",
            customer: "Customer",
          },

          sortable: ["sn", "ordercode", "customer", "date", "action"],
          filterable: ["customer", "ordercode"],
          saveState: true,
          sortIcon: {
            base: "fa",
            is: "fa-sort",
            up: "fa-long-arrow-up",
            down: "fa-long-arrow-down",
          },
        });
    },
    refresh() {
      this.reset();
      this.user = this.$store.getters.user;
      this.taxes = this.$store.getters.taxes;
      this.newcustomer.merchID = this.$store.getters.user.merchID;
      this.newcustomer.branchID = this.$store.getters.user.branchID;
      this.fetchCart();
      this.fetchRewards();
    },
    ...mapActions({
      addOrderToQueue: "orders/addToQueue",
      makeQueueOrder: "orders/completeQueueOrder",
      mergeQueue: "orders/mergeQueue",
      makeOrder: "orders/placeOrder",
      getCustomers: "customers/index",
      saveCustomers: "customers/store",
      alreadyExists: "customers/alreadyExists",
      findCustomer: "customers/searchCustomer",
    }),
    reload() {
      // this.$forceUpdate();
      this.refresh();
      $(".modal").modal("hide");
      $("#ordertype").modal("hide");
      $("#payment").modal("hide");
    },
    fetchCart() {
      this.cart = this.$store.getters.cart;
    },

    async fetchCustomers() {
      this.showLoader = true;
      try {
        let res = await this.findCustomer(this.searchcustomer.toLowerCase());
        if (res == null || res.length == 0) {
          this.notfound = true;
          this.searchresult = [];
        } else {
          this.notfound = false;
          this.searchresult = res;
        }
        s;
        this.showLoader = false;
        // this.searchcustomer = '';
        // }
      } catch (error) {
        this.notfound = true;
        this.showLoader = false;
      }
    },
    setCustomer(row) {
      this.customerdetails = row;
      this.searchresult = [];
      this.searchcustomer = "";
      $(".modal").modal("hide");
      $("#sidecart").modal("show");
      $("#ordertype").modal("show");
    },
    fetchRewards() {
      let req = {
        what: "getRewards",
        showLoader: false,
        useToken: true,
        params: {
          merchID: this.$store.getters.user.merchID,
          branchID: this.$store.getters.user.branchID,
          rewardtype: "percentageorderdiscount",
        },
      };
      this.$request
        .makeGetRequest(req)
        .then((res) => {
          if (res.type == "getRewards") {
            this.rewards = res.data.data;
            if (req.showLoader == undefined || req.showLoader) {
              this.$swal.close();
            }
          }
        })
        .catch((error) => {});
    },
    getAddAddress() {
      $("#pickup_gif").show();
      let service = new google.maps.places.PlacesService(
        document.getElementById("map")
      );
      const request = {
        query: this.deliveryaddress,
        fields: ["name", "formatted_address", "geometry"],
      };
      let vm = this;
      service.findPlaceFromQuery(request, function (response, status) {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          vm.dispatch_data.latitude =
            response[0].geometry.location.toJSON().lat;
          vm.dispatch_data.longitude =
            response[0].geometry.location.toJSON().lng;
          vm.deliveryaddress = response[0].formatted_address;
          $("#pickup_gif").hide();
        }
        return;
      });
    },
    SetOrderType() {
      this.searchcustomer = "";
      this.searchresult = [];
      $(".modal").modal("hide");
      $("#sidecart").modal("show");
      $("#ordertype").modal("show");
    },
    makePayment() {
      if (this.ordermethod == "delivery") {
        let req = {
          what: "onlinedeliveryfee",
          useToken: true,
          showLoader: true,
          data: {
            deliverylog: this.dispatch_data.longitude,
            deliverylat: this.dispatch_data.latitude,
            merchID: this.$store.getters.user.merchID,
            branchID: this.$store.getters.user.branchID,
          },
        };
        this.$request
          .makePostRequest(req)
          .then((res) => {
            if (res.type == "onlinedeliveryfee") {
              this.deliveryfee = res.data.data.deliveryfee;
              this.eta = res.data.data.ETA;
              $(".modal").modal("hide");
              $("#sidecart").modal("show");
              $("#payment").modal("show");
            }
          })
          .catch((error) => {
            this.$swal.fire(
              "Delivery fee is not configured for this address",
              error.message,
              "error"
            );
          });
      } else {
        this.deliveryfee = "";
        this.eta = "";
        $(".modal").modal("hide");
        $("#sidecart").modal("show");
        $("#payment").modal("show");
      }
    },
    addToQueue() {
      $("#queue-modal").modal("show");
    },
    checkout() {
      if (this.discount_percent < 0 || this.discount_percent > 100) {
        this.$swal.fire(
          "Error",
          "Discount Percentage has to be between 0 and 100",
          "error"
        );
        return;
      }
      if (this.discount < 0 || this.discount > this.ordertotal) {
        this.$swal.fire(
          "Error",
          "Discount cannot be greater than order total or less than the 0",
          "error"
        );
        return;
      }

      if (Number(this.total) + Number(this.calculateTaxTotal()) < 0) {
        this.$swal.fire("Error", "Cart total cannot be less than 0", "error");
        return;
      }

      this.searchcustomer = "";
      this.searchresult = [];
      $(".modal").modal("hide");
      $("#sidecart").modal("show");
      $("#ordertype").modal("show");
      // $("#customermodal").modal('show');
    },
    async createCustomer() {
      let req = {
        what: "createcustomer",
        data: this.newcustomer,
      };
      let result = await this.alreadyExists(req.data);
      if (result == undefined) {
        this.saveCustomers(req)
          // this.$request.makePostRequest(req)
          .then((res) => {
            // if (res.type == 'createcustomer') {
            this.$swal.fire(
              "Success",
              "Customer Created SuccessFully",
              "success"
            );
            this.setCustomer(res);
            // }
          })
          .catch((error) => {
            this.$swal.fire("Error", error, "error");
          });
      } else {
        this.$swal.fire(
          "Error",
          "The customer record cannot be created because of duplicate phonenumber",
          "error"
        );
      }
    },
    // formatPrice (price, count = 2) {
    //   price = price ? price : 0;
    //   return parseFloat(price).toFixed(count);
    // },
    formatDate(date, format) {
      if (date !== null) {
        let d = new Date(date);
        if (format == "time") {
          return d.toLocaleTimeString("en-US");
        } else if (format == "date") {
          return d.toDateString();
        } else {
          return d.toDateString() + ", " + d.toLocaleTimeString("en-US");
        }
      } else {
        return null;
      }
    },

    setItem(item) {
      this.item = item;
    },

    cancelItem() {
      this.item = {};
      $(".modal").modal("hide");
    },

    canRemoveCart() {
      return (
        this.removeCart() ||
        ["administrator", "business_owner"].includes(
          this.$store.getters.user.role
        )
      );
    },

    removeCart() {
      let result = this.user.permissions.filter((permission) =>
        permission.name.toLowerCase().includes("cart_removal")
      );
      return result !== undefined && result.length > 0;
    },

    removeItem(item) {
      if (!this.canRemoveCart()) {
        item = this.item;
        $(".modal").modal("hide");
      }

      this.cart.forEach((i, index) => {
        if (i.name == item.name) {
          this.cart.splice(index, 1);
        }
      });
      this.$store.dispatch("updateCart", this.cart);
    },
    inputChange(id, item) {
      var value = document.getElementById(id).value;
      if (value == "" || value < 1) {
        document.getElementById(id).value = 1;
        value = 1;
      }
      this.updateCart(item, value);
      this.fetchDiscount(item, value);
    },

    increaseQty(id, item) {
      var value = document.getElementById(id).value;
      var v = Number(value) + 1;
      document.getElementById(id).value = v;
      this.updateCart(item, v);

      this.fetchDiscount(item, v);
    },

    decreaseQty(id, item) {
      var value = document.getElementById(id).value;
      if (value > 1) {
        var v = Number(value) - 1;
        document.getElementById(id).value = v;
        this.updateCart(item, v);
        this.fetchDiscount(item, v);
      }
    },
    updateCart(item, value) {
      this.cart.forEach((i, index) => {
        if (i.productid == item) {
          if (
            i.quantity > i.stockqty &&
            i.producttype !== "nonstocked_product"
          ) {
            this.showinfo = true;
            this.$swal
              .fire({
                title: "Stock limit exceeded",
                showCloseButton: true,
              })
              .then((result) => {
                if (result.isClosed) {
                  this.$router.push("/products");
                }
              });
            i.quantity = i.stockqty;
          } else {
            this.showinfo = false;
            i.quantity = value;
          }
          if (i.discountfee) {
            i.totalamount =
              Number(i.quantity) *
              (Number(i.discountprice)
                ? Number(i.discountprice) - Number(i.discountfee)
                : Number(i.price) - Number(i.discountfee));
          } else {
            i.totalamount =
              Number(i.quantity) *
              (Number(i.discountprice)
                ? Number(i.discountprice)
                : Number(i.price));
          }
        }
      });
      this.$store.dispatch("updateCart", this.cart);
    },
    selectOption(opt) {
      if (this.option.includes(opt)) {
        var index = this.option.indexOf(opt);
        this.option.splice(index, 1);
      } else {
        this.option.push(opt);
      }
    },
    scheduleDelivery() {},
    formatUnique(n) {
      return Number(n) > 9 ? "" + n : "0" + n;
    },
    placeOrder() {
      // this.$swal.fire({
      //   title: 'Are you sure?',
      //   text: "You won't be able to revert this!",
      //   icon: 'warning',
      //   showCancelButton: true,
      //   confirmButtonColor: 'green',
      //   cancelButtonColor: '#d33',
      //   confirmButtonText: 'Checkout'
      // }).then((result) => {
      //   if (result.isConfirmed) {
      let current_time = new Date();
      let current_second = current_time.getTime() / 1000;
      if (this.$store.getters.isQueue) {
        this.completeQueueOrder();
      } else if (this.$store.getters.mergeorders.length > 0) {
        this.handleMergeOrder();
      } else {
        let ordercart = this.$store.getters.cart;
        let ordercode =
          this.formatUnique(this.$store.getters.user.merchID) +
          Math.floor(10000 + Math.random() * 90000);
        let data = {
          customerID: this.customerdetails.id,
          ordercode: ordercode,
          ordertotal: this.order_total,
          vat: this.calculateTaxTotal(),
          merchID: this.$store.getters.user.merchID,
          branchID: this.$store.getters.user.branchID,
          orderstatus: "pending",
          paymentstatus: "PAID",
          paymentmethod: "multiple",
          ordertype: "online",
          ordermethod: this.ordermethod,
          ordercategory: this.$store.getters.businesscategory,
          orders: ordercart,
          paymentreference: "",
          paymentaccountname: "",
          paymentaccountnumber: "",
          paymentaccountbank: "",
          discountpayment:
            isNaN(this.discount) || !this.discount ? 0 : this.discount,
          staffID: this.$store.getters.user.id,
          multiple: [],
          tax_total: this.tax_total,
          // scheduledelivery:this.scheduledelivery
        };
        data.customerID =
          Object.keys(this.customerdetails).length > 0
            ? this.customerdetails.id
            : "";
        if (this.ordermethod == "delivery") {
          data.deliverycharge = this.deliveryfee;
          data.ETA = this.eta;
          data.address = this.deliveryaddress;
          data.area = "";
          data.state = "";
          data.deliveryinstruction = this.deliverynote;
          data.landmark = "";
          data.pickupinfo = "";
          data.latitude = this.dispatch_data.latitude;
          data.city = "";
          data.scheduletime = "";
          // data.scheduletime = this.scheduledelivery
          data.longitude = this.dispatch_data.longitude;
        }
        if (this.option.includes("cash")) {
          let cash_amount = parseFloat(this.cash_amountpaid.replace(/,/g, ""));
          data.multiple.push({
            paymentreference: current_second,
            paymentaccountname: "",
            paymentamount:
              cash_amount -
              (Number(this.total_money) -
                (Number(this.total) + Number(this.calculateTaxTotal()))),
            paymentaccountnumber: "",
            paymentaccountbank: "",
            paymentmethod: "cash",
            // balance:  Number(this.total_money) - (Number(this.total) + Number(this.calculateTaxTotal()))
          });
        }
        if (this.option.includes("pos")) {
          let pos_amount = parseFloat(this.pos_amountpaid.replace(/,/g, ""));
          data.multiple.push({
            paymentreference: current_second,
            paymentaccountname: this.posaccountname,
            paymentamount: pos_amount,
            paymentaccountnumber: "",
            paymentaccountbank: "",
            paymentmethod: "pos",
          });
        }
        if (this.option.includes("banktransfer")) {
          let bank_amount = parseFloat(this.bank_amountpaid.replace(/,/g, ""));
          data.multiple.push({
            paymentreference: current_second,
            paymentaccountname: this.bankaccountname,
            paymentamount: bank_amount,
            paymentaccountnumber: this.bank.accountno,
            paymentaccountbank: this.bank.bankname,
            paymentmethod: "banktransfer",
          });
        }
        if (this.option.includes("third_party")) {
          let thd_amount = parseFloat(
            this.thirdparty_amountpaid.replace(/,/g, "")
          );
          data.multiple.push({
            paymentreference: current_second,
            paymentaccountname: this.thirdpartyobj.paymentaccountname,
            paymentamount: thd_amount,
            paymentaccountnumber: "",
            paymentaccountbank: "",
            paymentmethod: "thirdparty",
          });
        }
        // if (this.option.includes('pay-on-delivery')) {
        //   data.paymentstatus = "pending"
        //   data.multiple.push(
        //     {
        //       paymentreference: current_second,
        //       paymentaccountname: '',
        //       paymentamount: 0,
        //       paymentaccountnumber: '',
        //       paymentaccountbank: '',
        //       paymentmethod: "pay-on-delivery"
        //     }
        //   )
        // }
        let req = {
          useToken: true,
          what:
            this.ordermethod == "delivery" ? "onlinedeliveryorder" : "order",
          data,
        };
        this.order = req.data;
        this.$store.dispatch("setOrder", this.order);
        this.makeOrder(req)
          // this.$request.makePostRequest(req)
          .then((res) => {
            // if (res.type == 'order') {
            this.$store.dispatch("setOrderCode", res.ordercode);
            this.$swal
              .fire({
                title: "Payment Completed",
                html: '<div style="height:80px;width:80px;margin: 0 auto;"><img style="width: 100%;" src="https://reveregolf.com/wp-content/uploads/2019/10/Thumbs-Up-icon-2.png" /></div>',
                confirmButtonColor: "#28A745",
                showCloseButton: true,
                showDenyButton: true,
                denyButtonText: "Send receipt",
                confirmButtonText: "Print receipt",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  $(".modal").modal("hide");
                  this.$store.dispatch("setHistoryStatus", false);
                  this.$router.push("/receipt");
                } else if (result.isDenied) {
                  if (this.order.customerID !== "") {
                    this.handleSendReceipt(res.id, "");
                  } else {
                    $(".modal").modal("hide");
                    this.$swal
                      .fire({
                        title: "Send Receipt as Email",
                        html: '<input  type="email" id="email" style="font-size: 14px;" class="swal2-input form-control" placeholder="Enter email address" v-model="receiptemail"/>',
                        confirmButtonColor: "#28A745",
                        showCloseButton: true,
                        showDenyButton: true,
                        denyButtonText: "Cancel",
                        confirmButtonText: "Send",
                      })
                      .then((result) => {
                        if (result.isClosed) {
                          this.$store.dispatch("setHistoryStatus", false);
                          this.$router.push("/receipt");
                        }
                        if (result.isConfirmed) {
                          const email = this.$swal
                            .getPopup()
                            .querySelector("#email").value;
                          this.handleSendReceipt(res.id, email);
                        } else {
                          // this.$store.dispatch('updateCart', []);
                          // this.reload()
                          this.$store.dispatch("setHistoryStatus", false);
                          this.$router.push("/receipt");

                          // this.cart = this.$store.getters.cart;
                          // this.$router.push('/walk-in');
                          // this.$router.dispatch('/')
                          // location.reload()
                        }
                      });
                  }
                } else {
                  this.$store.dispatch("updateCart", []);
                  // this.cart = this.$store.getters.cart;
                  // this.$router.push('/walk-in');
                  this.reload();
                  // location.reload()
                }
              });
            // }
          })
          .catch((error) => {
            this.$swal.fire("Error", error, "error");
          });
      }
      // }
      // })
    },
    completeQueueOrder() {
      let current_time = new Date();
      let current_second = current_time.getTime() / 1000;
      let order = this.$store.getters.order;
      let data = {
        orderID: order.id,
        customerID: this.customerdetails.id,
        ordercode: order.ordercode,
        ordertotal: this.order_total,
        vat: this.calculateTaxTotal(),
        merchID: this.$store.getters.user.merchID,
        branchID: this.$store.getters.user.branchID,
        orderstatus: "pending",
        paymentstatus: "PAID",
        paymentmethod: "multiple",
        ordertype: "online",
        ordermethod: "pickup",
        ordercategory: this.$store.getters.businesscategory,
        orders: this.$store.getters.cart,
        paymentreference: "",
        paymentaccountname: "",
        paymentaccountnumber: "",
        paymentaccountbank: "",
        discountpayment:
          isNaN(this.discount) || !this.discount ? 0 : this.discount,
        staffID: this.$store.getters.user.id,
        multiple: [],
        tax_total: this.tax_total,
      };
      data.customerID =
        Object.keys(this.customerdetails).length > 0
          ? this.customerdetails.id
          : "";
      let cash_amount = parseFloat(this.cash_amountpaid.replace(/,/g, ""));
      if (this.option.includes("cash")) {
        data.multiple.push({
          paymentreference: current_second,
          paymentaccountname: "",
          paymentamount:
            cash_amount -
            (Number(this.total_money) -
              (Number(this.total) + Number(this.calculateTaxTotal()))),
          paymentaccountnumber: "",
          paymentaccountbank: "",
          paymentmethod: "cash",
          // balance:  Number(this.total_money) - (Number(this.total) + Number(this.calculateTaxTotal()))
        });
      }
      if (this.option.includes("pos")) {
        let pos_amount = parseFloat(this.pos_amountpaid.replace(/,/g, ""));
        data.multiple.push({
          paymentreference: current_second,
          paymentaccountname: this.posaccountname,
          paymentamount: pos_amount,
          paymentaccountnumber: "",
          paymentaccountbank: "",
          paymentmethod: "pos",
        });
      }
      if (this.option.includes("banktransfer")) {
        let bank_amount = parseFloat(this.bank_amountpaid.replace(/,/g, ""));
        data.multiple.push({
          paymentreference: current_second,
          paymentaccountname: this.bankaccountname,
          paymentamount: bank_amount,
          paymentaccountnumber: this.bank.accountno,
          paymentaccountbank: this.bank.bankname,
          paymentmethod: "banktransfer",
        });
      }
      if (this.option.includes("third_party")) {
        let thd_amount = parseFloat(
          this.thirdparty_amountpaid.replace(/,/g, "")
        );
        data.multiple.push({
          paymentreference: current_second,
          paymentaccountname: this.thirdpartyobj.paymentaccountname,
          paymentamount: thd_amount,
          paymentaccountnumber: "",
          paymentaccountbank: "",
          paymentmethod: "thirdparty",
        });
      }
      // if (this.option.includes('pay-on-delivery')) {
      //         data.paymentstatus = "pending"
      //         data.multiple.push(
      //           {
      //             paymentreference: current_second,
      //             paymentaccountname: '',
      //             paymentamount: 0,
      //             paymentaccountnumber: '',
      //             paymentaccountbank: '',
      //             paymentmethod: "pay-on-delivery"
      //           }
      //         )
      //       }
      let req = {
        useToken: true,
        what: "completequeueorder",
        data,
      };
      this.order = req.data;
      this.$store.dispatch("setOrder", this.order);

      this.makeQueueOrder(req)
        // this.$request.makePostRequest(req)
        .then((res) => {
          // if (res.type == 'completequeueorder') {
          this.$store.dispatch("setQueueStatus", false);
          this.$store.dispatch("setOrderCode", order.ordercode);
          this.$swal
            .fire({
              title: "Payment Completed",
              html: '<div style="height:80px;width:80px;margin: 0 auto;"><img style="width: 100%;" src="https://reveregolf.com/wp-content/uploads/2019/10/Thumbs-Up-icon-2.png" /></div>',
              confirmButtonColor: "#28A745",
              showCloseButton: true,
              showDenyButton: true,
              denyButtonText: "Send receipt",
              confirmButtonText: "Print receipt",
            })
            .then((result) => {
              if (result.isConfirmed) {
                $(".modal").modal("hide");
                this.$store.dispatch("setHistoryStatus", false);
                this.$router.push("/receipt");
              } else if (result.isDenied) {
                $(".modal").modal("hide");
                this.$swal
                  .fire({
                    title: "Send Receipt as Email",
                    html: '<input  type="email" id="email" style="font-size: 14px;" class="swal2-input form-control" placeholder="Enter email address" v-model="receiptemail"/>',
                    confirmButtonColor: "#28A745",
                    showCloseButton: true,
                    showDenyButton: true,
                    denyButtonText: "Cancel",
                    confirmButtonText: "Send",
                  })
                  .then((result) => {
                    if (result.isClosed) {
                      this.$store.dispatch("setHistoryStatus", false);
                      this.$router.push("/receipt");
                    }
                    if (result.isConfirmed) {
                      const email = this.$swal
                        .getPopup()
                        .querySelector("#email").value;
                      this.handleSendReceipt(res.id, email);
                    } else {
                      this.$store.dispatch("updateCart", []);
                      // this.cart = this.$store.getters.cart;
                      // this.$router.push('/walk-in');
                      this.reload();
                      // location.reload()
                    }
                  });
              } else {
                this.$store.dispatch("updateCart", []);
                // this.cart = this.$store.getters.cart;
                // this.$router.push('/walk-in');
                this.reload();
                // location.reload()
              }
            });
          // }
        })
        .catch((error) => {
          this.$swal.fire("Error", error, "error");
        });
    },
    handleMergeOrder() {
      let current_time = new Date();
      let current_second = current_time.getTime() / 1000;
      let ordercart = this.$store.getters.cart;
      let data = {
        customerID: this.customerdetails.id,
        ordercode: "",
        oldorderid: this.$store.getters.mergeorders,
        ordertotal: this.order_total,
        vat: this.calculateTaxTotal(),
        merchID: this.$store.getters.user.merchID,
        branchID: this.$store.getters.user.branchID,
        orderstatus: "pending",
        paymentstatus: "PAID",
        paymentmethod: "multiple",
        ordertype: "online",
        ordermethod: "pickup",
        ordercategory: this.$store.getters.businesscategory,
        orders: ordercart,
        paymentreference: "",
        paymentaccountname: "",
        paymentaccountnumber: "",
        paymentaccountbank: "",
        discountpayment:
          isNaN(this.discount) || !this.discount ? 0 : this.discount,
        staffID: this.$store.getters.user.id,
        multiple: [],
        tax_total: this.tax_total,
      };
      data.customerID =
        Object.keys(this.customerdetails).length > 0
          ? this.customerdetails.id
          : "";
      if (this.option.includes("cash")) {
        let cash_amount = parseFloat(this.cash_amountpaid.replace(/,/g, ""));
        data.multiple.push({
          paymentreference: current_second,
          paymentaccountname: "",
          paymentamount:
            cash_amount -
            (Number(this.total_money) -
              (Number(this.total) + Number(this.calculateTaxTotal()))),
          paymentaccountnumber: "",
          paymentaccountbank: "",
          paymentmethod: "cash",
        });
      }
      if (this.option.includes("pos")) {
        let pos_amount = parseFloat(this.pos_amountpaid.replace(/,/g, ""));
        data.multiple.push({
          paymentreference: current_second,
          paymentaccountname: this.posaccountname,
          paymentamount: pos_amount,
          paymentaccountnumber: "",
          paymentaccountbank: "",
          paymentmethod: "pos",
          // balance:  Number(this.total_money) - (Number(this.total) + Number(this.calculateTaxTotal()))
        });
      }
      if (this.option.includes("banktransfer")) {
        let bank_amount = parseFloat(this.bank_amountpaid.replace(/,/g, ""));
        data.multiple.push({
          paymentreference: current_second,
          paymentaccountname: this.bankaccountname,
          paymentamount: bank_amount,
          paymentaccountnumber: this.bank.accountno,
          paymentaccountbank: this.bank.bankname,
          paymentmethod: "banktransfer",
        });
      }
      if (this.option.includes("third_party")) {
        let thd_amount = parseFloat(
          this.thirdparty_amountpaid.replace(/,/g, "")
        );
        data.multiple.push({
          paymentreference: current_second,
          paymentaccountname: this.thirdpartyobj.paymentaccountname,
          paymentamount: thd_amount,
          paymentaccountnumber: "",
          paymentaccountbank: "",
          paymentmethod: "thirdparty",
        });
      }
      // if (this.option.includes('pay-on-delivery')) {
      //         data.paymentstatus = "pending"
      //         data.multiple.push(
      //           {
      //             paymentreference: current_second,
      //             paymentaccountname: '',
      //             paymentamount: 0,
      //             paymentaccountnumber: '',
      //             paymentaccountbank: '',
      //             paymentmethod: "pay-on-delivery"
      //           }
      //         )
      //       }
      let req = {
        useToken: true,
        what: "mergequeue",
        data,
      };
      this.order = req.data;
      this.$store.dispatch("setOrder", this.order);

      this.mergeQueue(req)
        // this.$request.makePostRequest(req)
        .then((res) => {
          // if (res.type == 'mergequeue') {
          this.$store.dispatch("setMergeOrders", []);
          this.$store.dispatch("setOrderCode", res.ordercode);
          this.$swal
            .fire({
              title: "Payment Completed",
              html: '<div style="height:80px;width:80px;margin: 0 auto;"><img style="width: 100%;" src="https://reveregolf.com/wp-content/uploads/2019/10/Thumbs-Up-icon-2.png" /></div>',
              confirmButtonColor: "#28A745",
              showCloseButton: true,
              showDenyButton: true,
              denyButtonText: "Send receipt",
              confirmButtonText: "Print receipt",
            })
            .then((result) => {
              if (result.isConfirmed) {
                $(".modal").modal("hide");
                this.$store.dispatch("setHistoryStatus", false);
                this.$router.push("/receipt");
              } else if (result.isDenied) {
                $(".modal").modal("hide");
                this.$swal
                  .fire({
                    title: "Send Receipt as Email",
                    html: '<input  type="email" id="email" style="font-size: 14px;" class="swal2-input form-control" placeholder="Enter email address" v-model="receiptemail"/>',
                    confirmButtonColor: "#28A745",
                    showCloseButton: true,
                    showDenyButton: true,
                    denyButtonText: "Cancel",
                    confirmButtonText: "Send",
                  })
                  .then((result) => {
                    if (result.isClosed) {
                      this.$store.dispatch("setHistoryStatus", false);
                      this.$router.push("/receipt");
                    }
                    if (result.isConfirmed) {
                      const email = this.$swal
                        .getPopup()
                        .querySelector("#email").value;
                      this.handleSendReceipt(res.id, email);
                    } else {
                      this.$store.dispatch("updateCart", []);
                      // this.$router.push('/walk-in');
                      // this.cart = this.$store.getters.cart;
                      // location.reload()
                      this.reload();
                    }
                  });
              } else {
                this.$store.dispatch("updateCart", []);
                this.reload();
                // this.cart = this.$store.getters.cart;
                // this.$router.push('/walk-in');
                // location.reload()
              }
            });
          // }
        })
        .catch((error) => {
          this.$swal.fire("Error", error.message, "error");
        });
    },
    handleSendReceipt(id, email) {
      let req = {
        useToken: true,
        what: "emailreceipt",
        data: {
          orderID: id,
          customerID: this.order.customerID,
          merchID: this.user.merchID,
          branchID: this.user.branchID,
          emailaddress: email,
        },
      };
      let vm = this;
      this.$request
        .makePostRequest(req)
        .then((res) => {
          let timerInterval;
          this.$swal
            .fire({
              title: "Receipt Sent",
              html: '<div style="height:80px;width:80px;margin: 0 auto;"><img style="width: 100%;" src="https://reveregolf.com/wp-content/uploads/2019/10/Thumbs-Up-icon-2.png" /></div>',
              timer: 2000,
              timerProgressBar: true,
              didOpen: () => {
                this.$swal.showLoading();
                timerInterval = setInterval(() => {
                  const content = this.$swal.getContent();
                  if (content) {
                    const b = content.querySelector("b");
                    if (b) {
                      b.textContent = this.$swal.getTimerLeft();
                    }
                  }
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            })
            .then((result) => {
              vm.$store.dispatch("updateCart", []);
              // vm.cart = vm.$store.getters.cart;
              // this.$router.push('/walk-in');
              vm.reload();
              // location.reload()
            });
        })
        .catch((error) => {
          this.$swal.fire("Error", error.message, "error");
        });
    },
    redeemLoyalty() {
      let req = {
        what: "redeem",
        data: {
          rewardID: this.reward.id,
          points: this.reward.rewarddiscount,
          customerID: this.customerdetails.id,
          merchID: this.$store.getters.user.merchID,
        },
      };
      this.$request
        .makePostRequest(req)
        .then((res) => {
          if (res.type == "redeem") {
            this.$swal.fire("Success", res.data.message, "success");
            this.discountpayment = this.reward.rewarddiscount;
            $("#loyalty").modal("hide");
          }
        })
        .catch((error) => {
          this.$swal.fire("Error", error.message, "error");
        });
    },
    handleQueue(action) {
      let ordercart = this.$store.getters.cart;
      let ordercode =
        this.formatUnique(this.$store.getters.user.merchID) +
        Math.floor(10000 + Math.random() * 90000);
      let data = {
        customerID: this.customerdetails.id,
        ordercode: ordercode,
        ordertotal: this.order_total,
        vat: this.calculateTaxTotal(),
        merchID: this.$store.getters.user.merchID,
        branchID: this.$store.getters.user.branchID,
        orderstatus: "paylater",
        paymentstatus: "pending",
        paymentmethod: "pending",
        ordertype: "online",
        ordermethod: this.ordermethod,
        ordercategory: this.$store.getters.businesscategory,
        orders: ordercart,
        paymentreference: "",
        paymentaccountname: "",
        paymentaccountnumber: "",
        paymentaccountbank: "",
        discountpayment:
          isNaN(this.discount) || !this.discount ? 0 : this.discount,
        staffID: this.$store.getters.user.id,
      };
      data.customerID =
        Object.keys(this.customerdetails).length > 0
          ? this.customerdetails.id
          : "";

      let req = {
        useToken: true,
        what: "addToQueue",
        showLoader: true,
        data,
      };

      this.order = req.data;
      this.$store.dispatch("setOrder", this.order);

      let vm = this;

      this.addOrderToQueue(req)
        // this.$request.makePostRequest(req)
        .then((res) => {
          // if (res.type == 'addToQueue') {
          this.$store.dispatch("setOrderCode", res.ordercode);
          // this.$store.dispatch('setOrderCode', res.data.data.ordercode);
          if (action == "print") {
            if (req.showLoader == undefined || req.showLoader) {
              this.$swal.close();
            }
            $(".modal").modal("hide");
            this.$router.push("/kitchen-order");
          } else {
            let timerInterval;
            this.$swal
              .fire({
                title: "Order Sent To Queue ",
                html: '<div style="height:80px;width:80px;margin: 0 auto;"><img style="width: 100%;" src="https://reveregolf.com/wp-content/uploads/2019/10/Thumbs-Up-icon-2.png" /></div>',
                timer: 200,
                timerProgressBar: true,
                didOpen: () => {
                  this.$swal.showLoading();
                  timerInterval = setInterval(() => {
                    const content = this.$swal.getContent();
                    if (content) {
                      const b = content.querySelector("b");
                      if (b) {
                        b.textContent = this.$swal.getTimerLeft();
                      }
                    }
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                },
              })
              .then((result) => {
                vm.$store.dispatch("updateCart", []);
                // vm.cart = vm.$store.getters.cart;
                // this.$router.push('/walk-in');
                vm.reload();
                $(".modal").modal("hide");
                // location.reload()
              });
          }
          // }
        })
        .catch((error) => {
          this.$swal.fire("Error", error.message, "error");
        });
    },
    sendToKitchen() {
      let ordercart = this.$store.getters.cart;
      let ordercode =
        this.formatUnique(this.$store.getters.user.merchID) +
        Math.floor(10000 + Math.random() * 90000);
      let data = {
        customerID: this.customerdetails.id,
        ordercode: ordercode,
        ordertotal: this.order_total,
        vat: this.calculateTaxTotal(),
        merchID: this.$store.getters.user.merchID,
        branchID: this.$store.getters.user.branchID,
        orderstatus: "assigned_to_kitchen",
        paymentstatus: "pending",
        paymentmethod: "pending",
        ordertype: "online",
        ordermethod: ordermethod,
        ordercategory: this.$store.getters.businesscategory,
        orders: ordercart,
        paymentreference: "",
        paymentaccountname: "",
        paymentaccountnumber: "",
        paymentaccountbank: "",
        discountpayment:
          isNaN(this.discount) || !this.discount ? 0 : this.discount,
        staffID: this.$store.getters.user.id,
      };
      data.customerID =
        Object.keys(this.customerdetails).length > 0
          ? this.customerdetails.id
          : "";

      let req = {
        useToken: true,
        what: "addToQueue",
        showLoader: true,
        data,
      };
      this.order = req.data;
      let vm = this;
      this.$request
        .makePostRequest(req)
        .then((res) => {
          if (res.type == "addToQueue") {
            this.$store.dispatch("setOrderCode", res.data.data.ordercode);
            let timerInterval;
            this.$swal
              .fire({
                title: "Order Sent To Kitchen ",
                html: '<div style="height:80px;width:80px;margin: 0 auto;"><img style="width: 100%;" src="https://reveregolf.com/wp-content/uploads/2019/10/Thumbs-Up-icon-2.png" /></div>',
                timer: 200,
                timerProgressBar: true,
                didOpen: () => {
                  this.$swal.showLoading();
                  timerInterval = setInterval(() => {
                    const content = this.$swal.getContent();
                    if (content) {
                      const b = content.querySelector("b");
                      if (b) {
                        b.textContent = this.$swal.getTimerLeft();
                      }
                    }
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                },
              })
              .then((result) => {
                vm.$store.dispatch("updateCart", []);
                // vm.cart = vm.$store.getters.cart;

                $(".modal").modal("hide");
                this.$router.push("/kitchen");
              });
          }
        })
        .catch((error) => {
          this.$swal.fire("Error", error.message, "error");
        });
    },
    printCart() {},
    toggle() {
      this.isTransferPaid = !this.isTransferPaid;
    },
    // handlePromo () { }
  },
};
</script>
<style scoped>
input.form-control {
  background: var(--off-white) 0% 0% no-repeat padding-box;
}
.tr-btn {
  height: 35px;
  border-radius: 4px 0px 0px 0px;
  color: 000;
  text-align: center;
  font: normal normal 600 14px/18px Source Sans Pro;
  padding: 0px 10px;
  border: 0;
}
.tr-btn.active {
  background: var(--green);
  color: var(--white-bg);
}
.disable-click {
  pointer-events: none;
  color: grey;
}
.queue-btn {
  background: slateblue;
}
button[disabled],
input[disabled] {
  cursor: not-allowed;
}
ul#cus-search {
  border: 1px solid #dbe3ee;
  border-radius: 4px;
  box-shadow: 1px 2px 4px #dbe3ee;
  max-height: 200px;
  overflow-y: auto;
  margin-top: -15px;
}
ul#cus-search li {
  padding: 0 10px;
  font-size: 12px;
  cursor: pointer;
}
ul#cus-search li .dropdown-divider {
  margin: 0;
}
#payment.modal.show {
  padding-right: 352px !important;
}
#discount.modal.show,
#customermodal.modal.show,
#createcustomer.modal.show,
#ordertype.modal.show {
  padding-right: 60px !important;
}
@media screen and (max-width: 768px) {
  #discount.modal.show,
  #customermodal.modal.show,
  #createcustomer.modal.show,
  #payment.modal.show,
  #ordertype.modal.show {
    padding-right: 0px !important;
  }
}
ul#cus-search li:hover,
ul#cus-search li:focus {
  background: var(--green);
}
</style>