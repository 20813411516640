<template>
  <!-- SOURCE -->
  <div
    id="printSummary"
    class="p-3 bg-white"
    style="margin-left:10px; max-width: 300px;"
  >
    <div class=" mb-2">

      <h3
        class="title"
        style="font-family: Source Sans Pro;font-size: 24px;font-weight: 900;"
      >{{$store.getters.user.businessname}}</h3>
      <h5 style="font: normal normal 500 20px/24px Source Sans Pro;">{{$store.getters.user.branchname}}</h5>
      <h4
        class="mb-2"
        style="font: normal normal 900 20px/24px Source Sans Pro;margin-top: 14px;"
      ><u>DAILY SALES SUMMARY</u></h4>
      <h5
        class="title"
        style=" font: normal normal 500 20px/24px Source Sans Pro;"
      >Staff Name : <strong style="font: normal normal 900 20px/24px Source Sans Pro;">{{$store.getters.user.firstname}} {{$store.getters.user.lastname}}</strong></h5>
      <h5
        class="title"
        style=" font: normal normal 500 20px/24px Source Sans Pro;"
      >Date : <strong style="font: normal normal 900 20px/24px Source Sans Pro;">{{formatDate(new Date(), 'date')}}</strong>
      </h5>
      <hr>
    </div>

    <div class=" mb-4">
      <div class="d-flex mb-3">
        <h5 style="width:150px;font: normal normal 500 20px/24px Source Sans Pro;">Total Orders</h5>
        <h5 style="margin-left:10px;"><strong style="font: normal normal 900 20px/24px Source Sans Pro;">{{summarydetails.totalorder}}</strong></h5>
      </div>
      <div class="d-flex mb-3">
        <h5 style="width:150px;font: normal normal 500 20px/24px Source Sans Pro;">Total Cash</h5>
        <h5 style="margin-left:10px"><strong style="font: normal normal 900 20px/24px Source Sans Pro;">₦{{formatPrice(summarydetails.totalcash)}}</strong></h5>
      </div>
      <div class="d-flex mb-3">
        <h5 style="width:150px;font: normal normal 500 20px/24px Source Sans Pro;">Total Pos (Card)</h5>
        <h5 style="margin-left:10px"><strong style="font: normal normal 900 20px/24px Source Sans Pro;">₦{{formatPrice(summarydetails.totalpos)}}</strong></h5>
      </div>
      <div class="d-flex mb-3">
        <h5 style="width:150px;font: normal normal 500 20px/24px Source Sans Pro;">Total Bank <br> Transfer</h5>
        <h5 style="margin-left:10px"><strong style="font: normal normal 900 20px/24px Source Sans Pro;">₦{{formatPrice(summarydetails.totaltransfer)}}</strong></h5>
      </div>
      <div class="d-flex mb-3">
        <h5 style="width:150px;font: normal normal 500 20px/24px Source Sans Pro;">Total Third <br> Party Payment</h5>
        <h5 style="margin-left:10px"><strong style="font: normal normal 900 20px/24px Source Sans Pro;">₦{{formatPrice(summarydetails.totalthirdparty)}}</strong></h5>
      </div>
      <div class="d-flex mb-3">
        <h5 style="width:150px;font: normal normal 500 20px/24px Source Sans Pro;">Total Taxes</h5>
        <h5 style="margin-left:10px"><strong style="font: normal normal 900 20px/24px Source Sans Pro;">₦{{formatPrice(summarydetails.totaltax)}}</strong></h5>
      </div>
      <hr>
      <div class="d-flex mb-3">
        <h5 style="width:150px;font: normal normal 500 20px/24px Source Sans Pro;">Total Sales</h5>
        <h5 style="margin-left:10px;"><strong style="font: normal normal 900 22px/24px Source Sans Pro;">₦{{formatPrice(total)}}</strong></h5>
      </div>

    </div>
  </div>
  <!-- OUTPUT -->
</template>

<script>
export default {
  data () {
    return {
      summarydetails: {
        totalorders: '0',
        totalpos: '0',
        totalcash: '0',
        totaltransfer: '0'
      },
      printer_off: true
    }
  },
  created () {
    this.summarydetails = this.$store.getters.sales_summary
  },
  mounted () {
    this.printer_off = false;
  },
  watch: {
    printer_off (val) {
      let vm = this
      if (val == false) {
        setTimeout(() => {
          window.print();
        }, 2000)
        let isMobile = this.detectMob()
        if (isMobile) {
          if (window.matchMedia) {
            var mediaQueryList = window.matchMedia('print');
            mediaQueryList.addEventListener("change", (mql) => {
              if (mql.matches) {
              } else {
                // vm.$store.dispatch('updateCart', []);
              }
            });
          }
        }
        else {
          window.onafterprint = function (event) {
            // vm.$store.dispatch('updateCart', []);
            vm.$router.push('/summary')
          };

        }

      }
    }
  }
  ,
  computed: {
    total () {
      return Number(this.summarydetails.totalcash) + Number(this.summarydetails.totalpos) + Number(this.summarydetails.totaltransfer)+ Number(this.summarydetails.totalthirdparty)
    }
  },
  methods: {

    detectMob () {
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
      ];

      return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
      });
    },
    formatPrice (price) {
      if (price == null) {
        return '0.00';
      }
      else {

        var str = Number(price).toFixed(2).toString().split(".");
        if (str[0].length >= 3) {
          str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
        }
        if (!str[1]) {
          str[1] = "00";
        }
        return str.join(".");
      }


    },
    formatDate (date, format) {
      if (date !== null) {
        let d = new Date(date)
        if (format == 'time') {

          return d.toLocaleTimeString('en-US');
        }
        else if (format == 'date') {
          return d.toDateString().replace(' ', ', ');
        }
        else {
          return d.toDateString().replace(' ', ', ') + ' ' + d.toLocaleTimeString('en-US');
        }
      }
      else {
        return null
      }
    },
  }
};
</script>
<style scoped>
#summary .card {
  height: 140px;
  border: 0;
}
#summary h4 {
  font: normal normal 900 23px/24px Source Sans Pro;
  margin-top: 14px;
}
#summary h5 {
  font: normal normal 500 18px/24px Source Sans Pro;
}

#summary h5 strong {
  font: normal normal 900 18px/24px Source Sans Pro;
}
</style>

