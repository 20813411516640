<template >
  <div
    id="account"
    class=""
  >
    <div class="header py-4 border-bottom">
      <h4>Personalize your account</h4>
    </div>
    <div class="page-body my-5">
      <div
        id="profileseth"
        class="row mt-5 "
      >
        <div class="col-sm-6">
          <div class="user-details">
            <h5>Change Profile Picture</h5>
            <p>Choose a new avatar to be used across Ntisa</p>
          </div>
        </div>
        <div class="col-sm-6">
          <div>
            <div id="img-wrapper">
              <img
                :src="profileImg"
                alt=""
              >
            </div>
            <input
              id="image"
              type="file"
              @change="onFileChange"
            >
          </div>
        </div>
      </div>
      <div class="row mt-5 ">
        <div class="col-sm-6">
          <div class="user-details">
            <h5>Full Name</h5>
            <!-- <p>Customize your account name</p> -->
          </div>
        </div>
        <div class="col-sm-6">
          <p class="">{{user.title}} {{user.firstname}} {{user.lastname}}</p>
          <!-- <div class="form-row">
            <div class="form-group col-xl-2 col-lg-3 col-sm-12">
              <label for="">Title</label>
              <input
                v-model="user.title"
                type="text"
                disabled
                class="form-control"
              >
              <select
                name=""
                id=""
                class="form-control"
                v-model="user.title"
              >
                <option
                  hidden
                  selected
                >Enter tile</option>
                <option>Mr</option>
                <option>Mrs</option>
                <option>Miss</option>
              </select>
            </div>
            <div class="form-group  col-xl-5 col-lg-4 col-sm-12">
              <label for="">First name</label>
              <input
                v-model="user.firstname"
                type="text"
                disabled
                class="form-control"
              >
            </div>
            <div class="form-group  col-xl-5 col-lg-4 col-sm-12">
              <label for="">Last name</label>
              <input
                v-model="user.lastname"
                type="text"
                disabled
                class="form-control"
              >
            </div>
          </div> -->
        </div>
      </div>
      <!-- <div class="row mt-5 ">
        <div class="col-sm-6">
          <div class="user-details">
            <h5>Email</h5>
          </div>
        </div>
        <div class="col-sm-6">
          <div>
            <p class="email">{{user.emailaddress}}</p>
          </div>
        </div>
      </div> -->
      <div class="row mt-5 ">
        <div class="col-sm-6">
          <div class="user-details">
            <h5>Phone Number</h5>
          </div>
        </div>
        <div class="col-sm-6">
          <div v-if="!editphone">
            <p>{{user.phonenumber}}</p>
            <!-- <a
              @click.prevent='editphone=true'
              class="change-phone"
            >Change</a> -->
          </div>
          <div v-else>
            <div class="form-row">
              <div class="form-group">
                <label
                  class="d-none d-sm-block"
                  for=""
                >Phone</label>
                <vue-tel-input
                  v-model="user.phonenumber"
                  placeholder="Phone Number"
                ></vue-tel-input>
              </div>
            </div>
            <!-- <button class="btn mt-2">Change Phone number</button> -->
          </div>
        </div>
      </div>
      <!-- <div class="row mt-5 ">
        <div class="col-sm-6">
          <div class="user-details">
            <h5>Date of Birth</h5>
          </div>
        </div>
        <div class="col-sm-6">
          <p class="">{{formatDate(user.dob, "date")}}</p>
        
        </div>
      </div>  -->
      <div class="row my-5 ">
        <!-- <div class="col-sm-6">
          <div class="user-details">
            <h5>Gender</h5>
            <p>Change your Gender</p>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="profile-info-details">
            <p class="text-capitalize">{{user.gender}}</p>
            <div class="d-flex">
              <label class="form-input-checked-box nosvg">
                <input
                  type="radio"
                  value="male"
                  disabled
                  name="gender"
                  v-model="user.gender"
                >
                <span class="form-input__label">Male</span>
              </label>

              <label class="form-input-checked-box nosvg">
                <input
                  type="radio"
                  name="gender"
                  disabled
                  value="female"
                  v-model="user.gender"
                >
                <span class="form-input__label">Female</span>
              </label>

              <label class="form-input-checked-box nosvg">
                <input
                  type="radio"
                  name="gender"
                  disabled
                  value="neither"
                  v-model="user.gender"
                >
                <span class="form-input__label">Neither</span>
              </label>
            </div>
          </div>
          -->
        <div class="my-5">
          <button
            @click.prevent="updateProfile()"
            type="button"
            class="btn"
          >Save Changes</button>
        </div>
      </div>
    </div>
  </div>

  </div>
</template>
<script>
import * as $ from "jquery";
export default {
  name: 'Account',
  mounted () {
    // console.log(this.$route.name)
  },
  data () {
    return {
      user: {},
      profileImg: '',
      editphone: false,
    }
  },
  created () {
    this.user = this.$store.getters.user;
    this.profileImg = this.user.profilelink;
  },
  methods: {
    onFileChange (e) {
      let file = e.target.files[0]
      var reader = new FileReader();
      reader.readAsDataURL(file);
      let vm = this;
      reader.onload = function (e) {
        vm.user.profilelink = reader.result.slice(23, reader.result.length);
        vm.profileImg = reader.result;
      };

      reader.onerror = function (error) {
        // console.log('Error: ', error);
      };
    },
    updateProfile () {
      this.user.role = "store_attendant"
      let req = {
        what: "profile",
        data: this.user,
        useToken: true
      }
      this.$request.editItem(req)
        .then(res => {
          //console.log(res.data.data)
          this.$store.dispatch("setUser", res.data.data);
          // this.$swal.fire("Success", res.data.message, "success");
          this.$swal.fire({
            title: 'Success',
            html: res.data.message,
            willClose: () => {
              // location.reload()
            }
          })
        })
        .catch(error => {

          this.$swal.fire("Error", error.message, "error");
        });
    },
    formatDate (date, format) {
      if (date !== null) {
        let d = new Date(date)
        if (format == 'time') {

          return d.toLocaleTimeString('en-US');
        }
        else if (format == 'date') {
          return d.toDateString();
        }
        else {
          return d.toDateString() + ', ' + d.toLocaleTimeString('en-US');
        }
      }
      else {
        return null
      }
    },
  }

}
</script>
<style scoped>
input#image {
  position: absolute;
  opacity: 0;
  top: 41px;
  left: 0;
}
#img-wrapper {
  width: 114px;
  height: 114px;
  border: 1px solid #e7e6ea;
  border-radius: 50%;
  padding: 5px;
}
#img-wrapper img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
#img-wrapper img:hover,
#img-wrapper img:focus {
  opacity: 0.5;
}
</style>