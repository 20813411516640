<template >
  <div id="topnav">
    <nav id="menu" class="navbar">
      <auto-logout />
      <!-- <socket /> -->

      <div id="sidemenu-toggler" v-if="windowWidth <= 700" class="mr-4">
        <input
          @click="toggleMenu"
          type="checkbox"
          class="openSidebarMenu"
          id="openSidebarMenu"
        />
        <label for="openSidebarMenu" class="sidebarIconToggle">
          <div class="spinner diagonal part-1"></div>
          <div class="spinner horizontal"></div>
          <div class="spinner diagonal part-2"></div>
        </label>
      </div>
      <button class="mt-0 btn btn-primary" @click="reSync">
        Download Data For Offline Mode
      </button>

      <countdown></countdown>
      <ul class="nav flex-nowrap my-lg-0">
        <li class="nav-item py-2">
          <h5 class="internet_stat">
            <span v-if="status == 'Offline'" class="badge badge-danger">{{
              status
            }}</span>
            <span
              v-else-if="status == 'Slow Network'"
              class="badge badge-warning text-white"
              >{{ status }}</span
            >
            <span v-else class="badge badge-success">{{ status }}</span>
          </h5>
        </li>
        <li class="nav-item">
          <h5 class="text-capitalize">
            {{ user.firstname }} {{ user.lastname }}
          </h5>
          <p>User ID : {{ user.id }}</p>
        </li>
        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            data-toggle="dropdown"
            href="#"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img
              :src="user.profilelink"
              alt=""
              style="width: 32px; height: 32px; border-radius: 50%"
              class="img-fluid"
          /></a>
          <div class="dropdown-menu">
            <a
              href="javascript:void(0)"
              style="pointer-events: none"
              class="dropdown-item text-uppercase text-muted"
              >{{ user.firstname }} {{ user.lastname }}</a
            >
            <a class="dropdown-item" href="/profile">Profile</a>
            <!-- <a
              class="dropdown-item"
              href="#"
            >Give us feedback</a>
            <a
              class="dropdown-item"
              href="#"
            >Help</a> -->
            <div class="dropdown-divider"></div>
            <a class="dropdown-item text-danger" @click.prevent="handleLogout()"
              >Logout</a
            >
          </div>
        </li>
      </ul>
    </nav>
  </div>
</template>
<script>
import Sync from "@/workers/Sync";
import Utils from "@/utils.js";
import { eventBus } from "../main";
import countdown from "./countdown.vue";
import AutoLogout from "./AutoLogout.vue";
import socket from "./socket.vue";
export default {
  components: { countdown, AutoLogout, socket },
  name: "Topnav",
  data() {
    return {
      user: {},
      width: "",
      status: "",
    };
  },
  created() {
    this.user = this.$store.getters.user;
    window.addEventListener("resize", () => {
      this.width = window.innerWidth;
    });
    if (navigator.connection.rtt > 500) {
      this.status = "Slow Network";
    } else if (!navigator.onLine && navigator.connection.rtt == 0) {
      this.status = "Offline";
    } else if (navigator.onLine && navigator.connection.rtt == 0) {
      this.status = "Online";
    } else {
      this.status = "Online";
    }
    navigator.connection.addEventListener("change", () => {
      if (navigator.connection.rtt > 500) {
        this.status = "Slow Network";
      } else if (!navigator.onLine && navigator.connection.rtt == 0) {
        this.status = "Offline";
      } else if (navigator.onLine && navigator.connection.rtt == 0) {
        this.status = "Online";
      } else {
        this.status = "Online";
      }
    });
  },
  computed: {
    windowWidth() {
      return this.width;
    },
  },
  methods: {
    handleLogout() {
      this.$store.dispatch("logoutUser").then((result) => {
        this.$router.push("/");
        // location.reload()
      });
    },
    async reSync() {
      let result = await Utils.checkOnlineStatus();
      if (result) {
        this.$store.commit("posts/reset", false);
        this.$store.commit("load", false);
        await Utils.syncRequests();
        let shouldFetch = await this.$store.dispatch("posts/shouldFetch");
        if (this.$store.state.load == false) {
          Sync.send({
            load: true,
            data: this.$store.getters.user,
            token: this.$store.getters.token,
            run: shouldFetch,
            updated_at: this.$store.getters.updated_at,
          });
          this.$swal.fire({
            title: "Downloading Data, Please do not disconnect",
            html: loading_html,
            timer: 3000,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            showCancelButton: false,
          });
        }
      } else {
        // this.$swal.fire(
        //   "Download Failed",
        //   "No Internet Connection, connect your internet and try again.",
        //   "error"
        // );
        this.$toasted.show(
          "Download failed, connect your internet and try again.",
          {
            theme: "bubble",
            type: "error",
            position: "top-right",
            duration: 5000,
          }
        );
        this.$store.commit("load", false);
      }
    },
    toggleMenu() {
      eventBus.$emit("fireMethod");
    },
  },
};
</script>
<style scoped>
h5.internet_stat {
  font-size: 18px !important;
}
</style>