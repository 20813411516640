<template>
  <div>
    <!-- dispatch receipt -->
    <div
      id="invoice-POS"
      v-if="$store.getters.businesscategory == 'Delivery' && order !== null"
    >
      <center id="top">
        <div>
          <img
            :src="user.branchimage"
            alt=""
            style="height: 50px"
            class="img-fluid mb-2"
          />
        </div>
        <div class="info">
          <h2>{{ user.businessname }}</h2>
          <h5>
            {{ user.branchname }}
            <br />
            {{ user.branchaddress }}
          </h5>
        </div>
        <!--End Info-->
      </center>
      <!--End InvoiceTop-->
      <div id="mid">
        <div class="info my-2">
          <p>Ordercode : {{ order.ordercode }}</p>
          <p>
            Phone : {{ user.phonenumber }}<br />
            Cashier : {{ user.firstname }} {{ user.lastname }}
          </p>
        </div>
      </div>
      <!--End Invoice Mid-->
      <div id="bot my-2">
        <div class="row">
          <div class="col-6">
            <p>Item Name</p>
          </div>
          <div class="col-6">
            <p>{{ order.itemname }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <p>Weight</p>
          </div>
          <div class="col-6" v-if="order.weight">
            <p>{{ order.weight }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <p>Item Quantity</p>
          </div>
          <div class="col-6">
            <p>{{ order.itemquantity }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <p>Pickup Address</p>
          </div>
          <div class="col-6">
            <p>{{ order.pickupaddress }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <p>Delivery Address</p>
          </div>
          <div class="col-6">
            <p>{{ order.deliveryaddress }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <p>ETA</p>
          </div>
          <div class="col-6">
            <p>{{ order.ETA }}</p>
          </div>
        </div>
        <div id="table" class="mt-2">
          <table>
            <tr>
              <td v-for="t in taxes" :key="t.id" class="Rate">
                <h2>{{ t.name }} ({{ t.value }})%</h2>
              </td>
              <td v-for="t in taxes" :key="t.id" class="payment">
                <h2 class="text-right">
                  ₦ {{ formatPrice(calculateTax(t.value)) }}
                </h2>
              </td>
            </tr>
            <tr class="">
              <td></td>

              <td class="Rate">
                <h2>Total</h2>
              </td>
              <td class="payment">
                <h2 class="text-right">
                  ₦{{
                    formatPrice(
                      Number(order.ordertotal) +
                        (order.vat ? Number(order.vat) : 0)
                    )
                  }}
                </h2>
              </td>
            </tr>
            <tr class="" v-if="payment_method.includes('cash')">
              <td></td>
              <td class="Rate"></td>
              <td class="payment">
                <p class="text-right">₦{{ formatPrice(cash) }} on Cash</p>
              </td>
            </tr>
            <tr class="" v-if="payment_method.includes('pos')">
              <td></td>
              <td class="Rate"></td>
              <td class="payment">
                <p class="text-right">₦{{ formatPrice(pos) }} on Pos</p>
              </td>
            </tr>
            <tr class="" v-if="payment_method.includes('banktransfer')">
              <td></td>
              <td class="Rate"></td>
              <td class="payment">
                <p class="text-right" style="font-size: 12px">
                  ₦{{ formatPrice(transfer) }} on Bank Transfer
                </p>
              </td>
            </tr>
            <tr class="" v-if="payment_method.includes('thirdparty')">
              <td></td>
              <td class="Rate"></td>
              <td class="payment">
                <p class="text-right" style="font-size: 12px">
                  ₦{{ formatPrice(thirdParty) }} on {{ thirdPartyOpt }}
                </p>
              </td>
            </tr>
            <!-- <tr
            >
              <td></td>
              <td class="Rate">
              </td>
              <td class="payment">
                <p
                  class="text-right"
                  style="font-size:12px;"
                > Customer Balance ₦{{formatPrice(order.balance)}}</p>
              </td>
            </tr> -->
          </table>
        </div>
        <!--End Table-->
        <div id="legalcopy">
          <p class="text-center">
            Thank you for shopping with us. <br />
            {{ formatDate(Date()) }}<br />
          </p>
        </div>
      </div>
      <!--End InvoiceBot-->
    </div>
    <!-- other categories receipt -->

    <div v-else id="invoice-POS">
      <center id="top">
        <div>
          <img
            :src="user.BranchImage"
            alt=""
            style="height: 50px"
            class="img-fluid mb-2"
          />
        </div>
        <div></div>
        <div class="info">
          <h2>{{ user.businessname }}</h2>
          <h5>
            {{ user.branchname }} <br />
            {{ user.branchaddress }} <br />
            {{ user.phonenumber }}
          </h5>
        </div>
      </center>
      <!--End InvoiceTop-->

      <div id="mid">
        <div class="info my-2">
          <p>Ordercode : {{ order.ordercode }}</p>
          <p>Cashier: {{ user.firstname }} {{ user.lastname }}</p>
        </div>
        <p>
          Items : {{ cart.length }} item<span v-if="cart.length > 1">s</span>
        </p>
      </div>

      <!--End Invoice Mid-->
      <div id="bot my-2">
        <div id="table">
          <table>
            <tbody>
              <tr
                class="service"
                v-for="(item, index) in cart"
                v-bind:key="index"
              >
                <td class="tableitem" style="width: 35px">
                  <p class="itemtext">{{ item.quantity }}x</p>
                </td>
                <td class="tableitem">
                  <p class="itemtext">{{ item.name }}</p>
                </td>
                <td class="tableitem">
                  <p class="itemtext float-right">
                    ₦{{ formatPrice(item.totalamount) }}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <table class="mt-3">
            <tbody>
              <tr>
                <td></td>
                <td class="Rate">
                  <p class="text-right">Subtotal</p>
                </td>
                <td class="payment">
                  <p class="text-right">₦{{ formatPrice(subtotal) }}</p>
                </td>
              </tr>
              <tr v-if="order.ordermethod == 'delivery'" class="">
                <td></td>
                <td class="Rate">
                  <p class="text-right">Delivery Fee</p>
                </td>
                <td class="payment">
                  <p class="text-right">
                    ₦{{ formatPrice(order.deliverycharge) }}
                  </p>
                </td>
              </tr>
              <tr class="">
                <td></td>
                <td class="Rate">
                  <p class="text-right">Discount</p>
                </td>
                <td class="payment">
                  <p class="text-right">
                    - ₦{{ formatPrice(order.discountpayment) }}
                  </p>
                </td>
              </tr>
              <tr v-for="t in taxes" :key="t.id">
                <td></td>
                <td class="Rate">
                  <p class="text-right">{{ t.name }}({{ t.value }})%</p>
                </td>
                <td class="payment">
                  <p class="text-right">
                    ₦ {{ formatPrice(calculateTax(t.value)) }}
                  </p>
                </td>
              </tr>
              <tr class="">
                <td></td>
                <td class="Rate">
                  <h3 class="text-right">Total</h3>
                </td>
                <td class="payment" v-if="isNaN(order.ordercode)">
                  <h3 class="text-right" v-if="$store.getters.isHistory">
                    ₦{{
                      formatPrice(
                        Number(order.ordertotal) +
                          (order.deliverycharge
                            ? Number(order.deliverycharge)
                            : 0)
                      )
                    }}
                  </h3>
                  <h3 class="text-right" v-else>
                    ₦{{
                      formatPrice(
                        Number(order.ordertotal) +
                          (order.deliverycharge
                            ? Number(order.deliverycharge)
                            : 0) +
                          (order.vat ? Number(order.vat) : 0)
                      )
                    }}
                  </h3>
                </td>
                <td class="payment" v-else>
                  <h3 class="text-right">
                    ₦{{
                      formatPrice(
                        Number(order.ordertotal) +
                          (order.deliverycharge
                            ? Number(order.deliverycharge)
                            : 0) +
                          (order.vat ? Number(order.vat) : 0)
                      )
                    }}
                  </h3>
                </td>
              </tr>
              <tr class="" v-if="payment_method.includes('cash')">
                <td></td>
                <td class="Rate"></td>
                <td class="payment">
                  <p class="text-right" style="font-size: 12px">
                    ₦{{ formatPrice(cash) }} on Cash
                  </p>
                </td>
              </tr>
              <tr class="" v-if="payment_method.includes('pos')">
                <td></td>
                <td class="Rate"></td>
                <td class="payment">
                  <p class="text-right" style="font-size: 12px">
                    ₦{{ formatPrice(pos) }} on Pos
                  </p>
                </td>
              </tr>
              <tr class="" v-if="payment_method.includes('banktransfer')">
                <td></td>
                <td class="Rate"></td>
                <td class="payment">
                  <p class="text-right" style="font-size: 12px">
                    ₦{{ formatPrice(transfer) }} on Bank Transfer
                  </p>
                </td>
              </tr>
              <tr class="" v-if="payment_method.includes('thirdparty')">
                <td></td>
                <td class="Rate"></td>
                <td class="payment">
                  <p class="text-right" style="font-size: 12px">
                    ₦{{ formatPrice(thirdParty) }} on {{ thirdPartyOpt }}
                  </p>
                </td>
              </tr>
              <!-- <tr
              >
              <td></td>
              <td class="Rate">
              </td>
              <td class="payment">
                <p
                  class="text-right"
                  style="font-size:12px;"
                > Customer Balance ₦{{formatPrice(order.balance)}} </p>
              </td>
            </tr> -->
            </tbody>
          </table>
        </div>
        <!--End Table-->
        <div id="legalcopy">
          <p class="text-center">
            Thank you for shopping with us. <br />
            {{ formatDate(Date()) }}
          </p>
        </div>
      </div>
      <!--End InvoiceBot-->
    </div>
  </div>
</template>

<script>
import "../assets/css/receipt.css";
export default {
  data() {
    return {
      user: null,
      order: {},
      cart: [],
      taxes: [],
      order_total: "",
      discount: "0",
      ordercode: "",
      printer_off: true,
      payment_method: [],
      ordertest: this.$store.getters.order,
    };
  },
  created() {
    let vm = this;
    vm.user = vm.$store.getters.user;
    vm.user.branches.forEach((i) => {
      if (i.id == vm.user.branchID) {
        vm.user.branchaddress = i.branchaddress;
        vm.user.branchimage = i.branchimage;
        vm.user.branchname = i.branchname;
      }
    });
    this.order = this.$store.getters.order;
    // console.log('order')
    // console.log(this.order)
    // this.ordercode = this.$store.getters.ordercode;
  },
  mounted() {
    if (this.$store.getters.isHistory) {
      let vm = this;
      let items = vm.order.orderitems;
      this.order.multiple = this.order.paymentdetails;
      for (let x of items) {
        vm.cart.push({
          name: x.productname,
          price: x.unitprice,
          productID: x.productID,
          quantity: x.quantity,
          totalamount: x.totalprice,
        });
      }
    } else {
      let cart = this.$store.getters.cart;
      this.cart = cart;
      // let vm = this
      // let cart = this.$store.getters.cart;
      // this.cart = cart;

      // for (let x of cart) {
      //   let price = x.discountfee ? x.price - x.discountfee : x.price;
      //   vm.cart.push({
      //     name: x.name,
      //     price: price,
      //     productID: x.productID,
      //     quantity: x.quantity,
      //     totalamount: x.totalamount
      //   })
      // }
    }

    this.printer_off = false;
    for (let i of this.order.multiple) {
      this.payment_method.push(i.paymentmethod);
    }

    this.getTaxes();
  },
  watch: {
    printer_off(val) {
      let vm = this;
      if (val == true) {
        // this.$store.dispatch('updateCart', []);
        // this.$router.go(-1);
      } else {
        setTimeout(() => {
          window.print();
        }, 2500);
        let isMobile = this.detectMob();
        if (isMobile) {
          if (window.matchMedia) {
            var mediaQueryList = window.matchMedia("print");
            mediaQueryList.addEventListener("change", (mql) => {
              if (mql.matches) {
                // console.log('before')
              } else {
                vm.$store.dispatch("updateCart", []);
              }
            });
          }
        } else {
          window.onafterprint = function (event) {
            vm.$store.dispatch("updateCart", []);
            vm.$router.go(-1);

            // if (vm.$store.getters.businesscategory !== 'Delivery') {
            //   vm.$router.push('/walk-in')
            // }
            // else {
            //   vm.$router.push('/dispatch')
            // }
          };
        }
      }
    },
  },
  computed: {
    subtotal() {
      let sum = 0;
      let vm = this;
      this.cart.forEach((i) => {
        sum += Number(i.totalamount);
      });
      this.order_total = sum;
      return sum;
    },

    total() {
      let sum = 0;
      let vm = this;
      this.cart.forEach((i) => {
        sum += Number(i.totalamount);
      });
      this.order_total = sum;
      sum =
        this.order.ordermethod == "delivery"
          ? Number(this.order_total) +
            Number(this.order.deliverycharge) -
            Number(this.discount)
          : Number(this.order_total) - Number(this.discount);
      return sum;
    },
    cash() {
      for (let i of this.order.multiple) {
        if (i.paymentmethod == "cash") return i.paymentamount;
      }
    },
    pos() {
      for (let i of this.order.multiple) {
        if (i.paymentmethod == "pos") return i.paymentamount;
      }
    },
    transfer() {
      for (let i of this.order.multiple) {
        if (i.paymentmethod == "banktransfer") return i.paymentamount;
      }
    },
    thirdParty() {
      for (let i of this.order.multiple) {
        if (i.paymentmethod == "thirdparty") return i.paymentamount;
      }
    },
    thirdPartyOpt() {
      for (let i of this.order.multiple) {
        if (i.paymentmethod == "thirdparty") return i.paymentaccountname;
      }
    },
  },
  methods: {
    calculateTax(value) {
      // console.log(this.order)
      let total = 0;
      if (isNaN(this.order.ordercode)) {
        if (this.$store.getters.isHistory) {
          total =
            Number(this.order.ordertotal) -
            (this.order.vat ? Number(this.order.vat) : 0) +
            Number(this.order.discountpayment);
        } else {
          total =
            Number(this.order.ordertotal) + Number(this.order.discountpayment);
        }
      } else {
        total =
          Number(this.order.ordertotal) + Number(this.order.discountpayment);
      }

      let val = Number(total) * (Number(value) / 100);
      return Number(parseFloat(val).toFixed(2));
    },
    detectMob() {
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
      ];

      return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
      });
    },
    formatPrice(price) {
      var str = price.toString().split(".");
      if (str[0].length >= 3) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
      }
      if (!str[1]) {
        str[1] = "00";
      }
      return str.join(".");
    },
    formatDate(date, format) {
      if (date !== null) {
        let d = new Date(date);
        if (format == "time") {
          return d.toLocaleTimeString("en-US");
        } else if (format == "date") {
          return d.toDateString().replace(" ", ", ");
        } else {
          return (
            d.toDateString().replace(" ", ", ") +
            " " +
            d.toLocaleTimeString("en-US")
          );
        }
      } else {
        return null;
      }
    },
    getTaxes() {
      // let req = {
      //   what: "tax",
      //   useToken: true,
      //   params: {
      //     merchID: this.$store.getters.user.merchID
      //   }
      // }
      // this.$request.makeGetRequest(req)
      // .then(res => {
      //   if (res.status == 'true') {
      //     this.$store.commit('setTax', res.data.data)
      this.taxes = this.$store.getters.taxes;
      //   } else {
      //     this.$swal.fire("Error", res.data.message, "error");
      //   }
      // })
      // .catch(error => {
      //   this.$swal.fire("Error", error.message, "error");
      // });
    },
  },
};
</script>
<style scoped>
.Rate h4 {
  font-size: 1.5em;
}
</style>

