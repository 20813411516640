<template>
  <div id="orders">
    <Sidemenu />
    <div id="main">
      <Topmenu />

      <div class="filter-container">
        <div class="row h-100 mx-1">
          <div class="col-sm-4 my-auto filter-title">
            <div class="d-flex">
              <h5 class="title mr-2">Orders list</h5>
              <p class="subtitle pl-2">{{ orders.length }} Online Orders</p>
            </div>
          </div>
          <div class="col-sm-8 my-auto filter-form">
            <div class="d-flex justify-content-end flex-wrap">
              <!-- <div
              id="search"
              class="my-auto"
            >
              <input
                v-model="search"
                class="form-control"
                type="text"
                placeholder="Search by customer’s name and order code"
              >
              <span class="fa fa-search"></span>
            </div> -->
              <select
                name=""
                id=""
                v-model="filterText"
                class="form-control my-auto"
              >
                <option value="" selected hidden>Filter by Order Status</option>
                <option value="">All</option>
                <option value="pending">Pending</option>
                <option value="cancel">Cancelled</option>
                <option value="ready_for_dispatch">Ready for Dispatch</option>
                <option value="assigned">Assigned</option>
                <option value="in_transit">In Transit</option>
                <option value="completed">Completed</option>
              </select>

              <button
                v-if="$store.getters.user.businesscategory !== 'Delivery'"
                class="btn mt-0 ml-2"
                @click="$router.push('/online-order')"
              >
                New Order <i class="fa fa-plus ml-2"></i>
              </button>
              <div
                class="dropdown dropleft"
                v-if="$store.getters.user.businesscategory !== 'Delivery'"
              >
                <button
                  id="q-btn"
                  class="btn btn-danger dropdown-toggle mt-0"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  title="Click to view orders queue"
                >
                  {{ queuedata.length }} Order(s) in Queue
                </button>

                <div v-if="queuedata.length > 0" class="dropdown-menu">
                  <ul class="p-0">
                    <li
                      v-for="(row, ix) in queuedata"
                      v-bind:key="ix"
                      class="dropdown-item"
                    >
                      <div>
                        <div>
                          <p class="code">
                            {{ row.ordercode }}

                            <input
                              v-model="mergeorders"
                              class="form-check float-right"
                              type="checkbox"
                              name=""
                              :value="row.id"
                            />
                          </p>
                          <p class="date">
                            {{ formatDate(row.transactiondate) }}
                          </p>
                          <h5 class="price">
                            ₦{{ formatPrice(row.ordertotal) }}
                          </h5>
                        </div>

                        <div class="d-flex">
                          <button
                            @click.prevent="viewQueueOrder(row)"
                            data-toggle="modal"
                            data-target="#queuemodal"
                            type="button"
                            class="btn-sm btn-primary ml-2 my-auto"
                          >
                            View Order
                          </button>
                          <button
                            data-toggle="modal"
                            data-target="#sidecart"
                            @click.prevent="processQueueOrder(row)"
                            type="button"
                            class="btn-sm btn-success ml-2 my-auto"
                          >
                            Process Order
                          </button>
                        </div>
                      </div>
                      <div class="dropdown-divider"></div>
                    </li>
                  </ul>
                  <div v-if="mergeorders.length > 1" class="text-center">
                    <button
                      @click.prevent="mergeOrder()"
                      type="button"
                      class="btn btn-success my-auto"
                    >
                      Merge Orders
                    </button>
                  </div>
                </div>
                <div id="no-data" class="text-center dropdown-menu" v-else>
                  <img
                    class="img-fluid"
                    src="../assets/img/no-result.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Order redesigned by Oluwakemi 02/03/2022 -->
      <!-- <div class="mt-4"></div> -->
      <div
        v-for="(order, index) in orders"
        :key="index"
        class="order-container shadow-sm"
      >
        <div class="order-details">
          <h2 class="order-id order-h2">
            {{
              order.ordermethod == "pickup" ? "PickUp Order" : "Delivery Order"
            }}: {{ order.ordercode }}
          </h2>
          <p class="order-time order-p">
            {{ formatDate(order.transactiondate) }}
          </p>

          <p
            class="badge badge-scheduled view-order"
            @click="displayOrder(order)"
          >
            <i
              class="fa fa-eye mr-1"
              aria-hidden="true"
              style="top: 1px; position: relative"
            ></i>
            view
          </p>

          <div
            v-if="
              order.orderstatus == 'completed' ||
              order.orderstatus == 'COMPLETED'
            "
          >
            <p class="order-p badge badge-delivered">
              <i class="fa fa-check mr-2" aria-hidden="true"></i>
              {{ order.orderstatus }}
            </p>
          </div>

          <div v-else-if="order.orderstatus == 'pending'">
            <p class="order-p badge badge-pending">
              <i class="fa fa-clock mr-2" aria-hidden="true"></i>
              {{ order.orderstatus }}
            </p>
          </div>

          <div v-else-if="order.orderstatus == 'assigned'">
            <p class="order-p badge badge-scheduled">
              <i class="fa fa-tasks mr-2" aria-hidden="true"></i>
              {{ order.orderstatus }}
            </p>
          </div>

          <div v-else-if="order.orderstatus == 'ready_for_dispatch'">
            <p class="order-p badge badge-delayed">
              <i class="fa fa-clock mr-2" aria-hidden="true"></i> Ready For
              Dispatch
            </p>
          </div>

          <div v-else-if="order.orderstatus == 'cancel'">
            <p class="order-p badge badge-cancelled">
              <i class="fa fa-times mr-2" aria-hidden="true"></i> Cancelled
            </p>
          </div>

          <div v-else-if="order.orderstatus == 'in_transit'">
            <p class="order-p badge badge-accepted">
              <i class="fa fa-check mr-2" aria-hidden="true"></i>In Transit
            </p>
          </div>

          <div v-else>
            <p class="order-p badge">
              <i class="fa fa-spinner mr-2" aria-hidden="true"></i>
              {{ order.orderstatus }}
            </p>
          </div>
        </div>

        <div class="order-cum-info mt-2 row">
          <div class="customer col-sm-3">
            <div>
              <p class="order-p">Customer</p>
              <!-- <h2 class="order-h2"  v-if="order.sendername !== null">{{ order.sendername }}</h2>
              <h2  class="order-h2" v-else></h2> -->
              <h2 class="order-h2" v-if="order.firstname">
                {{ order.firstname }} {{ order.lastname }}
              </h2>
              <h2 class="order-h2" v-else>-</h2>
            </div>
            <div>
              <p class="order-p">Amount</p>
              <h2 class="order-h2" v-if="businesscategory !== 'Delivery'">
                ₦{{
                  formatPrice(
                    order.ordertotal +
                      (order.deliverycharge != undefined
                        ? Number(order.deliverycharge)
                        : 0)
                  )
                }}<i class="fab fa-whatsapp d-none" style="color: green"></i>
              </h2>
              <h2 class="order-h2" v-else>
                ₦{{ formatPrice(order.ordertotal)
                }}<i class="fab fa-whatsapp d-none" style="color: green"></i>
              </h2>
            </div>
          </div>

          <div class="delivery col-sm-3">
            <div>
              <p class="order-p">Fulfillment</p>
              <h2 class="order-h2">{{ order.ordermethod }}</h2>
            </div>
            <!-- <div>
                <p class="order-p">Scheduled Fulfillment Date</p>
                <h2 class="order-h2" v-if="order.scheduletime">{{formatDate(order.scheduletime)}}</h2>
                <h2 class="order-h2" v-else>-</h2>
              </div> -->
            <div>
              <p class="order-p">Items</p>
              <h2 class="order-h2">{{ order.orderitems.length }}</h2>
            </div>
          </div>

          <div
            class="action col-sm-6 d-flex justify-content-end"
            v-if="Object.keys(order).length !== 0"
          >
            <button
              v-if="order.orderstatus == 'pending'"
              data-dismiss="modal"
              data-toggle="modal"
              data-target="#cancelmodal"
              id="cancel"
              style="cursor: pointer"
              class="reject btn btn-icon"
              @click="activeorderID = order.id"
            >
              <!-- <img src="../../src/assets/img/cancel-circle.png" alt="" srcset=""> -->
              <i class="fa fa-times" aria-hidden="true"></i>
              cancel
            </button>
            <!-- <button
                v-else
                data-dismiss="modal"
                data-toggle="modal"
                data-target="#cancelmodal"
                class="reject btn btn-icon"
              > -->
            <!-- <img src="../../src/assets/img/cancel-circle.png" alt="" srcset=""> -->
            <!-- <i class="fa fa-times" aria-hidden="true"></i>
                Cancel
              </button> -->
            <button
              v-if="
                order.orderstatus.toLowerCase() == 'pending' &&
                order.ordermethod.toLowerCase() !== 'pickup' &&
                (riderId === '' || riderId === null)
              "
              @click.prevent="orderProcessOrder(order)"
              class="process btn btn-icon mr-3"
            >
              <!-- <img src="../../src/assets/img/check.png" alt="" srcset=""> -->
              <i class="fa fa-check" aria-hidden="true"></i>
              Process
            </button>
            <button
              v-if="
                order.orderstatus.toLowerCase() != 'completed' &&
                order.orderstatus.toLowerCase() != 'cancel'
              "
              @click.prevent="orderProcessPickup(order)"
              type="button"
              class="accept btn btn-icon"
            >
              <!-- <img src="../../src/assets/img/check.png" alt="" srcset=""> -->
              <i class="fa fa-check" aria-hidden="true"></i>
              Complete
            </button>
          </div>
        </div>
      </div>

      <!-- <div class="main-content mr-3 my-4 d-none"> -->

      <!-- <div class="dash row mx-sm-2 ml-2  my-5">
        <div
          v-for="(row, ix) in filteredList"
          v-bind:key=ix
          class="col-xl-3 col-lg-4 col-sm-6"
        >
          <div
            @click="order=row;edit=false;"
            class="card order"
            data-toggle="modal"
            data-target="#ordermodal"
          >
            <div class="card-body">
              <div class="row">

                <div class=" col-3 m-auto ">
                  <img
                    v-if="row.profilelink ==null ||row.profilelink ==''"
                    src="../assets/img/avatar.png"
                    alt=""
                  >
                  <img
                    v-else
                    :src="row.profilelink"
                    alt=""
                    class="profile-img"
                  >
                </div>
                <div class="col-9  pr-0">
                  <h5 class="name">{{row.firstname}} {{row.lastname}}</h5>
                  <p class="phone">{{row.phonenumber}} </p>
                  <p class="id">
                    Order ID:
                    <span>{{row.ordercode}}</span>
                    <b class="bg-secondary text-white p-1 rounded-pill ml-2">{{row.ordermethod[0].toUpperCase()}}</b>
                  </p>
                  <p class="price">₦ {{formatPrice(row.ordertotal)}}
                    <span
                      v-if="row.orderstatus == 'completed'"
                      class="badge badge-pill badge-delivered float-right"
                    > {{row.orderstatus}}</span>
                    <span
                      v-else-if="row.orderstatus == 'in_transit'"
                      class="badge badge-pill badge-accepted float-right"
                    > {{row.orderstatus}}</span>
                    <span
                      v-else-if="row.orderstatus == 'ready_for_dispatch'"
                      class="badge badge-pill badge-delayed"
                    > {{row.orderstatus}}</span>
                    <span
                      v-else-if="row.orderstatus == 'pending'"
                      class="badge badge-pill badge-pending float-right"
                    > {{row.orderstatus}}</span>
                    <span
                      v-else-if="row.orderstatus == 'cancel'"
                      class="badge badge-pill badge-cancelled float-right"
                    > {{row.orderstatus}}</span>
                    <span
                      v-else
                      class="badge badge-pill float-right"
                    > {{row.orderstatus}}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div> -->

      <!-- <div class="card ml-4 p-2">

          <v-client-table
            :data="orders"
            :columns="columns"
            :options="options"
            slots="slots"
            @row-click="viewOrder"
          >
            <span
              slot="img"
              slot-scope="props"
              class="d-flex cus-img flex-wrap"
            >
              <img
                v-if="props.row.profilelink ==null || props.row.profilelink ==''"
                class="mr-2 img-fluid img-responsive"
                src="../assets/img/customer-avatar.png"
                alt=""
              >
              <img
                v-else
                class="mr-2 img-fluid img-responsive"
                :src="props.row.profilelink"
                alt=""
              >

            </span>

            <span
              slot="customer"
              slot-scope="props"
              class="d-flex flex-wrap"
            >
              <span>{{props.row.firstname}} {{props.row.lastname}}</span>
            </span>

            <span
              slot="ordertotal"
              slot-scope="props"
            >{{formatPrice(props.row.ordertotal)}}</span>

            <p
              slot="orderstatus"
              slot-scope="props"
            >
              <span
                v-if="props.row.orderstatus == 'completed' || props.row.orderstatus == 'COMPLETED'"
                class="badge badge-pill badge-delivered"
              > {{props.row.orderstatus}}</span>
              <span
                v-else-if="props.row.orderstatus == 'pending'"
                class="badge badge-pill badge-pending"
              > {{props.row.orderstatus}}</span>
              <span
                v-else-if="props.row.orderstatus == 'assigned'"
                class="badge badge-pill badge-scheduled"
              > {{props.row.orderstatus}}</span>
              <span
                v-else-if="props.row.orderstatus == 'ready_for_dispatch'"
                class="badge badge-pill badge-delayed"
              > {{props.row.orderstatus}}</span>
              <span
                v-else-if="props.row.orderstatus == 'cancel'"
                class="badge badge-pill badge-cancelled"
              > {{props.row.orderstatus}}</span>
              <span
                v-else-if="props.row.orderstatus == 'in_transit'"
                class="badge badge-pill badge-accepted"
              > {{props.row.orderstatus}}</span>
              <span
                v-else
                class="badge badge-pill"
              > {{props.row.orderstatus}}</span>

            </p>

            <span
              slot="transactiondate"
              slot-scope="props"
            >{{formatDate(props.row.transactiondate)}}</span>
            <span
              class="d-flex m-auto openorder"
              slot="view"
              slot-scope="props"
              title="View Order Details"
              @click="order=props.row;viewOrder"
            >
              <i class="fa fa-eye m-auto"></i>
            </span>
          </v-client-table>

        </div>
      </div>-->
    </div>

    <!-- order modal -->
    <div
      v-if="Object.keys(order).length !== 0"
      id="ordermodal"
      class="modal"
      tabindex="-1"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header mx-lg-5">
            <h5 class="modal-title">
              Order Details

              <span
                v-if="
                  order.orderstatus == 'completed' ||
                  order.orderstatus == 'COMPLETED'
                "
                class="badge badge-pill badge-delivered float-right"
              >
                {{ order.orderstatus }}</span
              >
              <span
                v-else-if="order.orderstatus == 'in_transit'"
                class="badge badge-pill badge-accepted float-right"
              >
                {{ order.orderstatus }}</span
              >
              <span
                v-else-if="order.orderstatus == 'pending'"
                class="badge badge-pill badge-pending float-right"
              >
                {{ order.orderstatus }}</span
              >
              <span
                v-else-if="order.orderstatus == 'assigned'"
                class="badge badge-pill badge-scheduled float-right"
              >
                {{ order.orderstatus }}</span
              >
              <span
                v-else-if="order.orderstatus == 'ready_for_dispatch'"
                class="badge badge-pill badge-delayed"
              >
                {{ order.orderstatus }}</span
              >
              <span
                v-else-if="order.orderstatus == 'cancel'"
                class="badge badge-pill badge-cancelled float-right"
              >
                {{ order.orderstatus }}</span
              >
              <span v-else class="badge badge-pill float-right">
                {{ order.orderstatus }}</span
              >
            </h5>

            <img
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              src="../assets/img/close.png"
              alt=""
            />
          </div>
          <div class="modal-body mx-lg-5">
            <div
              class="orderinfo d-flex justify-content-between flex-wrap my-4"
            >
              <div>
                <h5 class="heading">Order ID</h5>
                <p class="id">{{ order.ordercode }}</p>
                <p class="status">{{ order.ordermethod }}</p>
              </div>
              <div class="d-flex ml-3">
                <div class="m-auto cus-img">
                  <img
                    v-if="order.profilelink == null || order.profilelink == ''"
                    src="../assets/img/avatar.png"
                    alt=""
                  />
                  <img v-else :src="order.profilelink" alt="" />
                </div>
                <div class="ml-2">
                  <h5 class="heading">Customer info</h5>
                  <p class="value">
                    {{ order.firstname }} {{ order.lastname }}
                  </p>
                  <p class="phone">{{ order.customerIdentifier }}</p>
                </div>
              </div>
              <div>
                <h5 class="heading">Delivery address</h5>
                <p class="value">{{ order.deliveryaddress }}</p>
              </div>
            </div>
            <div
              v-if="order.deliveryinstruction !== ''"
              class="orderinfo d-flex justify-content-between flex-wrap my-4"
            >
              <div>
                <h5 class="heading">Delivery Note</h5>
                <p class="value">{{ order.deliveryinstruction }}</p>
              </div>
            </div>
            <!-- <div v-if="order.scheduletime !== ''" class="orderinfo d-flex justify-content-between flex-wrap my-4 d-none">
              <div>
                <h5 class="heading">Scheduled Delivery Date</h5>
                <p class="value">{{formatDate(order.scheduletime)}}</p>
              </div>
            </div> -->
            <div
              v-if="businesscategory == 'Delivery'"
              class="orderinfo d-flex justify-content-between flex-wrap my-4"
            >
              <div>
                <h5 class="heading">Sender</h5>
                <p class="value">{{ order.sendername }}</p>
                <p class="value">{{ order.senderphone }}</p>
              </div>
              <div>
                <h5 class="heading">Receiver</h5>
                <p class="value">{{ order.receivername }}</p>
                <p class="value">{{ order.receiverphonenumber }}</p>
              </div>
              <div>
                <h5 class="heading">Pickup address</h5>
                <p class="value">{{ order.pickupaddress }}</p>
              </div>
            </div>
            <div
              v-if="
                order.orderstatus == 'in_transit' ||
                (order.orderstatus == 'pending' &&
                  businesscategory == 'Delivery' &&
                  (riderId !== '' || riderId !== null))
              "
              id="assignedrider"
            >
              <h5 class="heading">Assigned Rider</h5>
              <div class="row mt-3 px-sm-5 py-2">
                <div class="col-8">
                  <div class="d-flex">
                    <div class="my-auto">
                      <div class="riderimg">
                        <img :src="getRiderPhoto" alt="" class="img-fluid" />
                      </div>

                      <p class="rating">
                        <span>{{ order.riderrate }}</span>
                        <!-- <img
                          src="../assets/img/star.png"
                          alt=""
                        > -->
                      </p>
                    </div>
                    <div class="ml-4">
                      <h5 class="text-bold value">
                        {{ order.riderfirstname }} {{ order.riderlastname }}
                      </h5>
                      <p>{{ order.riderphonenumber }}</p>
                      <p>{{ order.registrationnumber }}</p>
                    </div>
                  </div>
                </div>
                <div class="col-4 my-auto d-flex">
                  <!-- <p class="stat">Rider is 5 min away</p> -->
                </div>
              </div>
            </div>
            <table class="table my-5">
              <tbody v-if="order.orderitems.length > 0">
                <tr
                  v-for="(item, index) in order.orderitems"
                  class="items"
                  v-bind:key="index"
                >
                  <td class>
                    <img
                      v-lazy="item.photo"
                      alt=""
                      class="orderimg img-fluid"
                    />
                  </td>
                  <td>
                    <h5 class="product">{{ item.productname }}</h5>
                    <div v-if="item.discountprice">
                      <s class="old-price"
                        >₦ {{ formatPrice(item.unitprice) }}</s
                      >
                      <h6 class="discount-price">
                        ₦ {{ formatPrice(item.discountprice) }}
                      </h6>
                    </div>
                    <div v-else>
                      <p class="price">₦ {{ formatPrice(item.unitprice) }}</p>
                    </div>
                  </td>
                  <td>
                    <div class="edit-qty d-flex" v-if="edit">
                      <p
                        class="qty"
                        @click="decreaseQty('item' + item.id, item.id)"
                      >
                        -
                      </p>
                      <input
                        oninput="validity.valid||(value='');"
                        @change="inputChange('item' + item.id, item.id)"
                        :id="'item' + item.id"
                        type="number"
                        :value="item.quantity"
                      />
                      <p
                        class="qty"
                        @click="increaseQty('item' + item.id, item.id)"
                      >
                        +
                      </p>
                    </div>
                    <p v-else class="qty">{{ item.quantity }}</p>
                  </td>
                  <td class="subtotal">₦ {{ formatPrice(item.totalprice) }}</td>
                  <td v-if="edit">
                    <img
                      @click="removeItem(item)"
                      src="../assets/img/cancel-circle-2.png"
                      alt=""
                      class="img-fluid"
                    />
                  </td>
                </tr>
                <!-- <tr
                  v-if="addItem"
                  id="new"
                  class="items"
                >
                  <td colspan="2">
                    <select
                      name=""
                      id=""
                      class="form-control"
                    >
                      <option
                        selected
                        hidden
                      >What item are you looking for?</option>
                      <option>Fried Chicken</option>
                    </select>
                  </td>
                  <td>
                    <div class="edit-qty d-flex">
                      <p class="qty">-</p>
                      <input
                        type="number"
                        value="1"
                      >
                      <p class="qty">+</p>
                    </div>

                  </td>
                  <td class="subtotal">₦ 0.00</td>
                  <td v-if="edit">
                    <img
                      src="../assets/img/cancel-circle-2.png"
                      alt=""
                    >
                  </td>
                </tr> -->
              </tbody>
            </table>
            <hr />

            <table class="table table-borderless w-sm-50">
              <tbody v-if="businesscategory !== 'Delivery'">
                <tr>
                  <td></td>
                  <td></td>
                  <td>Sub total</td>
                  <td class="text-right">₦ {{ formatPrice(subtotal) }}</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td>Delivery Fee</td>
                  <td class="text-right">
                    ₦ {{ formatPrice(order.deliverycharge) }}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td>Discount</td>
                  <td class="text-right">
                    - ₦ {{ formatPrice(order.discountpayment) }}
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td>Total tax</td>
                  <td class="text-right">
                    + ₦ {{ formatPrice(order.vat ? order.vat : 0) }}
                  </td>
                </tr>
              </tbody>
              <tfoot v-if="businesscategory !== 'Delivery'">
                <tr>
                  <th></th>

                  <th></th>
                  <th scope="col">Total</th>
                  <th scope="col" class="text-right">
                    ₦
                    {{
                      formatPrice(
                        Number(order.ordertotal) + Number(order.deliverycharge)
                      )
                    }}
                  </th>
                </tr>
              </tfoot>
              <tfoot v-else>
                <tr>
                  <th></th>

                  <th></th>
                  <th scope="col">Total</th>
                  <th scope="col" class="text-right">
                    ₦ {{ formatPrice(Number(order.ordertotal)) }}
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
          <div class="modal-footer mx-sm-5 py-4">
            <a
              v-if="order.orderstatus == 'pending'"
              data-dismiss="modal"
              data-toggle="modal"
              data-target="#cancelmodal"
              id="cancel"
              class="my-auto d-none"
              style="cursor: pointer"
            >
              <img src="../assets/img/cancel-circle.png" alt="" />
              <span>Cancel Order</span>
            </a>
            <button
              v-else
              data-dismiss="modal"
              data-toggle="modal"
              data-target="#cancelmodal"
              class="btn my-auto cancel-btn ml-3 d-none"
            >
              Cancel Order
            </button>
            <button
              v-if="
                order.orderstatus.toLowerCase() == 'pending' &&
                order.ordermethod.toLowerCase() !== 'pickup' &&
                (riderId === '' || riderId === null)
              "
              @click.prevent="processOrder"
              type="button"
              class="btn ml-3 my-auto d-none"
            >
              Process Order
            </button>
            <button
              v-if="order.orderstatus.toLowerCase() != 'completed'"
              @click.prevent="processPickup"
              type="button"
              class="btn ml-2 my-auto d-none"
            >
              Complete Order
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- queueorder modal -->
    <div
      v-if="Object.keys(queueorder).length > 0"
      id="queuemodal"
      class="modal"
      tabindex="-1"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header mx-sm-5">
            <h5 class="modal-title">
              Order Details

              <span class="badge badge-pill badge-pending">
                {{ queueorder.orderstatus }}</span
              >
            </h5>

            <img
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              src="../assets/img/close.png"
              alt=""
            />
          </div>
          <div class="modal-body mx-sm-5">
            <div class="orderinfo d-flex justify-content-left flex-wrap my-3">
              <h5 class="heading">Order Code</h5>
              <p class="id ml-2">{{ queueorder.ordercode }}</p>
            </div>
            <div class="orderinfo d-flex justify-content-left flex-wrap my-3">
              <h5 class="heading">Order Time</h5>
              <p class="id ml-2">
                {{ formatDate(queueorder.transactiondate) }}
              </p>
            </div>

            <table class="table my-3">
              <tbody>
                <tr
                  v-for="(row, ix) in queueorder.orderitems"
                  v-bind:key="ix"
                  class="items"
                >
                  <td class>
                    <img
                      v-lazy="row.photo"
                      alt=""
                      class="orderimg img-fluid img-responsive"
                    />
                  </td>
                  <td>
                    <h5 class="product">{{ row.productname }}</h5>
                    <p class="price">₦ {{ formatPrice(row.unitprice) }}</p>
                  </td>
                  <td>
                    <p class="qty">{{ row.quantity }}</p>
                  </td>
                  <td class="subtotal">₦ {{ formatPrice(row.totalprice) }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th></th>
                  <th></th>
                  <th scope="col">Total</th>
                  <th scope="col" class="text-right">
                    ₦ {{ formatPrice(queueorder.ordertotal) }}
                  </th>
                </tr>
              </tfoot>
            </table>
            <button
              data-toggle="modal"
              data-target="#sidecart"
              @click.prevent="processQueueOrder(queueorder)"
              type="button"
              class="btn ml-2 my-auto float-right"
            >
              Process Order
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- cancel modal -->
    <div
      id="cancelmodal"
      class="modal fade"
      data-backdrop="static"
      data-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header border-0 mx-5">
            <img
              src="../assets/img/cancel-icon.png"
              alt=""
              class="cancel-icon"
            />
            <h5 class="">Cancel Order?</h5>
            <img
              data-dismiss="modal"
              @click="undoCancel"
              aria-label="Close"
              src="../assets/img/close.png"
              alt=""
            />
          </div>
          <div class="modal-body mx-5">
            <p class="subtitle mb-3">
              You’re about to delete this order. This action cannot be undone.
            </p>
            <h5 class="statement my-3">
              Why do you want to cancel this order?
            </h5>
            <ValidationObserver v-slot="{ handleSubmit }">
              <div class="form-group">
                <validation-provider rules="required" v-slot="{ errors }">
                  <textarea
                    class="form-control"
                    cols="30"
                    v-model="cancelreason"
                    rows="10"
                    placeholder="Enter your reason here"
                  ></textarea>
                  <span class="err_msg">{{ errors[0] }}</span>
                </validation-provider>
              </div>

              <div class="mt-4 float-right">
                <button
                  type="button"
                  class="cancel-close btn"
                  data-dismiss="modal"
                  @click.prevent="undoCancel"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  @click.prevent="handleSubmit(cancelOrder(order))"
                  class="btn cancel-btn ml-3"
                >
                  Cancel Order
                </button>
              </div>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>

    <!-- drivers modal -->
    <div
      v-if="riders.length > 0"
      id="driversmodal"
      class="modal"
      tabindex="-1"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title mx-sm-5">Select a Rider</h5>

            <img
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              src="../assets/img/close.png"
              alt=""
            />
          </div>
          <div class="modal-body">
            <h5 class="text-bold title mx-sm-5">
              We found {{ riders.length }} available riders near you
            </h5>
            <div class="mt-5 rider-wrap">
              <div
                v-for="(row, index) in riders"
                v-bind:key="index"
                @click="
                  rider = row;
                  selectedrider = row.id;
                "
                class="row mt-3 riders px-sm-5 py-2"
                v-bind:class="selectedrider == row.id ? 'active' : ''"
              >
                <div class="col-8">
                  <div class="d-flex">
                    <div class="my-auto">
                      <div class="riderimg">
                        <img :src="row.riderphoto" alt="" class="img-fluid" />
                      </div>

                      <p class="rating">
                        <span>{{ row.riderrate }}</span>
                        <!-- <img
                          src="../assets/img/star.png"
                          alt=""
                        > -->
                      </p>
                    </div>
                    <div class="ml-4">
                      <h5 class="text-bold">
                        {{ row.riderfirstname }} {{ row.riderlastname }}
                      </h5>
                      <p>{{ row.riderphonenumber }}</p>
                      <p>{{ row.registrationnumber }}</p>
                    </div>
                  </div>
                </div>
                <div class="col-4 my-auto d-flex">
                  <!-- <p class="stat">Rider is 5 min away</p> -->
                  <img
                    class="ml-3 selected"
                    src="../assets/img/check-sm.png"
                    alt=""
                  />
                </div>
                <hr class="col-12" />
              </div>
            </div>
          </div>
          <div class="modal-footer mx-sm-5 py-4 border-0">
            <button
              data-dismiss="modal"
              type="button"
              class="cancel-close btn my-auto"
            >
              Cancel
            </button>
            <button
              @click.prevent="assignOrder"
              type="button"
              class="btn ml-2 my-auto"
            >
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as $ from "jquery";
import Sidemenu from "@/components/sidemenu";
import Topmenu from "@/components/topmenu";
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("orders");
export default {
  name: "Orders",
  components: {
    Sidemenu,
    Topmenu,
  },
  data() {
    return {
      activeorderID: "",
      loading: false,
      businesscategory: "",
      edit: false,
      nowdate: "",
      addItem: false,
      filterText: "",
      search: "",
      riderId: "",
      all_orders: [],
      // orders: [],
      riders: [],
      mergeorders: [],
      queueorder: {},
      selectedrider: "",
      rider: {},
      order: {},
      cancelreason: "",
      columns: [
        "img",
        "customer",
        "phonenumber",
        "ordercode",
        "ordermethod",
        "ordertotal",
        "orderstatus",
        "transactiondate",
        "view",
      ],
      options: {
        headings: {
          img: "",
          customer: "Customer",
          phonenumber: "Mobile",
          ordercode: "OrderID",
          ordermethod: "Order Method",
          ordertotal: "Amount(₦)",
          orderstatus: "Order Status",
          transactiondate: "Order Date",
          view: "Action",
        },

        sortable: [
          "customer",
          "phonenumber",
          "ordercode",
          "ordermethod",
          "ordertotal",
          "orderstatus",
          "transactiondate",
        ],
        filterable: [
          "firstname",
          "lastname",
          "phonenumber",
          "ordercode",
          "ordermethod",
          "ordertotal",
          "orderstatus",
          "transactiondate",
        ],
        saveState: true,
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-long-arrow-up",
          down: "fa-long-arrow-down",
        },
      },
    };
  },
  created() {
    this.fetchOrders();
    this.businesscategory = this.$store.getters.user.businesscategory;
  },
  mounted() {
    // this.fetchRiders();
  },
  computed: {
    scheduleDel() {
      let date = new Date();

      this.nowdate = date;

      if ((this.nowdate = this.order.scheduletime)) {
        return false;
      } else return true;
    },
    subtotal() {
      let sum = 0;
      this.order.orderitems.forEach((i) => {
        sum += i.totalprice;
      });
      return sum;
    },
    filteredList() {
      return this.orders.filter((order) => {
        if (order.firstname.toLowerCase().includes(this.search.toLowerCase())) {
          return order.firstname
            .toLowerCase()
            .includes(this.search.toLowerCase());
        } else if (
          order.lastname.toLowerCase().includes(this.search.toLowerCase())
        ) {
          return order.lastname
            .toLowerCase()
            .includes(this.search.toLowerCase());
        } else {
          return order.ordercode
            .toLowerCase()
            .includes(this.search.toLowerCase());
        }
      });
    },
    getRiderPhoto() {
      for (let i of this.riders) {
        if (i.id == this.riderId) {
          return i.riderphoto;
        } else return "";
      }
    },

    ...mapGetters({
      ordersList: "orders",
      queuedata: "onlinequeue",
      // 'riders' : 'riders'
      // 'host' : 'host'
    }),

    orders() {
      return this.ordersList(this.filterText);
    },
  },
  // watch: {
  //   filterText: function (val) {
  //     // this.orders = []
  //     this.fetchOrders()
  //   }
  // },
  methods: {
    ...mapActions([
      "getOnlineOrders",
      "assignRider",
      // 'getRiders',
      "removeOrderItem",
      "updateOrderItem",
      "cancel",
      "complete",
    ]),
    viewQueueOrder(row) {
      this.queueorder = row;
    },
    processQueueOrder(order) {
      let cart = [];
      for (let row of order.orderitems) {
        cart.push({
          name: row.productname != undefined ? row.productname : row.name,
          price: row.unitprice,
          productid: row.productID,
          quantity: row.quantity,
          image: row.photo != undefined ? row.photo : row.image,
          totalamount: row.totalprice,
        });
      }
      this.$store.dispatch("setQueueStatus", true);
      this.$store.dispatch("updateCart", cart).then(() => {
        this.$store.dispatch("setOrder", order);
        $(".modal").modal("hide");
        this.$router.push("/online-order");
      });
    },
    mergeOrder() {
      let cart = [];
      for (let order of this.queuedata) {
        for (let i of this.mergeorders) {
          if (i == order.id) {
            for (let row of order.orderitems) {
              cart.push({
                name: row.productname != undefined ? row.productname : row.name,
                price: row.unitprice,
                productid: row.productID,
                quantity: row.quantity,
                image: row.photo != undefined ? row.photo : row.image,
                totalamount: row.totalprice,
              });
            }
          }
        }
      }
      var new_data = {};
      var uniqueArr = [];
      for (var i = 0; i < cart.length; i++) {
        if (new_data[cart[i].productid] == undefined) {
          new_data[cart[i].productid] = [];
          new_data[cart[i].productid] = cart[i];
        } else {
          new_data[cart[i].productid].totalamount += cart[i].totalamount;
          new_data[cart[i].productid].quantity += cart[i].quantity;
        }
      }
      for (let x in new_data) {
        uniqueArr.push(new_data[x]);
      }
      this.$store.dispatch("setMergeOrders", this.mergeorders);
      this.$store.dispatch("setQueueStatus", false);
      this.$store.dispatch("updateCart", uniqueArr).then(() => {
        $(".modal").modal("hide");
        this.$router.push("/online-order");
      });
    },
    fetchOrders() {
      let req = {
        what: "getOrders",
        useToken: true,
        showLoader: true,
        params: {
          merchID: this.$store.getters.user.merchID,
          branchID: this.$store.getters.user.branchID,
          orderstatus: this.filterText,
        },
      };
      this.getOnlineOrders(req)
        .then((res) => {
          this.fetchRiders();
          if (req.showLoader == undefined || req.showLoader) {
            this.$swal.close();
          }
          // if (res.type == 'getOrders') {
          //   let result = res.data.data;
          //   // this.all_orders.push(...result);
          //   this.orders = result;
          // }
          // this.$swal.close()
        })
        .catch((error) => {
          if (error.message !== "Request failed with status code 404") {
            this.$swal.fire("Error", error.message, "error");
          }
          this.is401(error.response.status);
        });
    },
    fetchRiders() {
      let req = {
        what: "getRiders",
        useToken: true,
        params: {
          merchID: this.$store.getters.user.merchID,
          branchID: this.$store.getters.user.branchID,
        },
      };
      this.$request
        .makeGetRequest(req)
        .then((res) => {
          if (res.type == "getRiders") {
            this.riders = res.data.data.data;
          }
          if (req.showLoader == undefined || req.showLoader) {
            this.$swal.close();
          }
        })
        .catch((error) => {
          this.is401(error.response.status);
          this.$swal.fire("Error", error.message, "error");
        });
    },
    editOrder() {
      this.edit = true;
    },
    processOrder() {
      $(".modal").modal("hide");
      var loading_html =
        '<div style="margin: 0 auto;"><p class="swal-text">Searching for ntisa riders near you…</p></div>';
      let vm = this;
      vm.$swal
        .fire({
          title: "",
          html: loading_html,
          timer: 1000,
          timerProgressBar: false,
          onBeforeOpen: () => {
            vm.$swal.showLoading();
          },
          willClose: () => {
            clearInterval(
              setInterval(() => {
                const content = vm.$swal.getContent();
                if (content) {
                  const b = content.querySelector("b");
                  if (b) {
                    b.textContent = vm.$swal.getTimerLeft();
                  }
                }
              }, 100)
            );
          },
        })
        .then((result) => {
          $("#driversmodal").modal("show");
        });
    },
    assignOrder() {
      let req = {
        what: "assignrider",
        useToken: true,
        data: {
          merchID: this.$store.getters.user.merchID,
          branchID: this.$store.getters.user.branchID,
          riderID: this.rider.id,
          orderID: this.order.id,
        },
      };

      this.$request
        .makePostRequest(req)
        .then((res) => {
          if (res.type == "assignrider") {
            // this.$swal.fire("Success", res.data.data, "success");
            this.$swal
              .fire({
                icon: "success",
                text: "Order assigned successfully",
              })
              .then((result) => {
                $(".modal").modal("hide");
                this.loading = false;
                this.fetchOrders();
              });
          }
        })
        .catch((error) => {
          this.is401(error.response.status);
          this.$swal.fire("Error", error.message, "error");
        });
    },
    addNew() {
      let newItem = {
        product: "",
        qty: "",
        price: "",
      };
      this.addItem = true;
    },
    removeItem(item) {
      this.removeOrderItem(item);
    },
    // formatPrice (price) {
    //   var str = price.toString().split(".");
    //   if (str[0].length >= 3) {
    //     str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
    //   }
    //   if (!str[1]) {
    //     str[1] = "00";
    //   }
    //   return str.join(".");
    // },
    formatDate(date, format) {
      if (date !== null) {
        let d = new Date(date);
        if (format == "time") {
          return d.toLocaleTimeString("en-US");
        } else if (format == "date") {
          return d.toDateString().replace(" ", ", ");
        } else {
          return (
            d.toDateString().replace(" ", ", ") +
            " " +
            d.toLocaleTimeString("en-US")
          );
        }
      } else {
        return null;
      }
    },
    viewOrder(event) {
      if (event.row) this.order = event.row;
      this.riderId = this.order.riderID;
      this.edit = false;
      $("#ordermodal").modal("show");
    },
    displayOrder(data) {
      if (data) this.order = data;
      this.riderId = this.order.riderID;
      this.edit = false;
      $("#ordermodal").modal("show");
    },

    orderProcessOrder(event) {
      if (event) this.order = event;
      this.riderId = this.order.riderID;
      this.edit = false;
      this.processOrder();
    },
    orderProcessPickup(event) {
      if (event) this.order = event;
      this.riderId = this.order.riderID;
      this.edit = false;
      this.processPickup();
    },
    inputChange(id, itemid) {
      var value = document.getElementById(id).value;
      if (value == "") {
        document.getElementById(id).value = 1;
        value = 1;
      }
      this.updateOrder(itemid, value);
    },
    increaseQty(id, itemid) {
      var value = document.getElementById(id).value;
      var v = Number(value) + 1;
      document.getElementById(id).value = v;
      this.updateOrder(itemid, v);
    },
    decreaseQty(id, itemid) {
      var value = document.getElementById(id).value;
      if (value > 1) {
        var v = Number(value) - 1;
        document.getElementById(id).value = v;
        this.updateOrder(itemid, v);
      }
    },
    updateOrder(id, value) {
      this.updateOrderItem(id, value);
      // this.order.orderitems.forEach((i, index) => {
      //   if (i.id == id) {
      //     i.quantity = value;
      //     i.totalprice = Number(i.quantity) * Number(i.unitprice)
      //   }
      // })
    },
    cancelOrder(event) {
      let req = {
        what: "cancelorder",
        useToken: true,
        showLoader: true,
        data: {
          orderID: this.activeorderID,
          orderstatus: "cancel",
          reason: this.cancelreason,
        },
      };
      this.$request
        .makePostRequest(req)
        .then((res) => {
          if (res.type == "cancelorder") {
            this.$swal
              .fire({
                icon: "success",
                text: res.data.message,
              })
              .then((result) => {
                $(".modal").modal("hide");
                this.loading = false;
                this.fetchOrders();
              });
          }
        })
        .catch((error) => {
          this.is401(error.response.status);
          this.$swal.fire("Error", error.message, "error");
        });
    },
    processPickup() {
      let req = {
        what: "processpickup",
        useToken: true,
        data: {
          orderID: this.order.id,
          orderstatus: "completed",
          reason: "",
        },
      };
      this.$request
        .makePostRequest(req)
        .then((res) => {
          if (res.type == "processpickup") {
            this.$swal
              .fire({
                icon: "success",
                text: res.data.message,
              })
              .then((result) => {
                $(".modal").modal("hide");
                this.loading = false;
                this.fetchOrders();
              });
          }
        })
        .catch((error) => {
          this.is401(error.response.status);
          this.$swal.fire("Error", error.message, "error");
        });
    },

    undoCancel() {
      $(".modal").modal("hide");
      // $("#ordermodal").modal("show");
    },
  },
};
</script>
<style scoped>
.cus-img {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  border: 1px solid #dee2e6;
  /* padding: 3px; */
}
.cus-img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.profile-img {
  height: 60px;
  width: 60px;
  border-radius: 50%;
}
.openorder i {
  font-size: 22px;
  color: #28a745;
  cursor: pointer;
}
#q-btn {
  margin-top: 0;
}
</style>
<style>
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fff !important;
}
.table-bordered td,
.table-bordered th {
  border: 0 !important;
  border-bottom: 1px solid #dee2e6 !important;
  /* font-size: 14px !important; */
}

.table-hover tbody tr:hover,
.table-striped tbody tr:nth-of-type(odd):hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075) !important;
  cursor: pointer;
}
#orders .form-inline label,
#history .form-inline label {
  justify-content: start !important;
}
.VueTables .col-md-12 {
  display: flex;
  justify-content: space-between;
}
.order-container {
  width: 97%;
  display: flex;
  flex-direction: column;
  height: auto;
  border-radius: 5px;
  border-top: 3px solid var(--green);
  /* margin-top: 20px; */
  margin-bottom: 15px;
  background: #fff;
  /* align-items: center; */
  margin: 15px auto;
  padding: 7px 15px 15px;
}
.order-details {
  /* width: 95%; */
  /* height: 40px; */
  border-bottom: 1px solid var(--green);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 7px;
}
.order-details .view-order {
  cursor: pointer;
  line-height: 15px;
  position: relative;
  right: 8px;
  background: #28a745;
  color: #fff;
}
.order-details .order-confirm {
  background: var(--green) 0% 0% no-repeat padding-box;
  padding: 5px;
  border-radius: 5px;
  color: #fff;
}

.order-cum-info {
  /* width: 95%; */
  /* height: 140px; */
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.order-id {
  font-weight: bolder;
}
.order-time {
  /* flex:0 1 73%; */
  /* padding-left: 15px; */
  flex: 0 2 67%;
}
.customer,
.delivery {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* align-content: center; */
  /* width: 50%; */
}
.order-cum-info .delivery {
  /* flex: 0 1 35%; */
}
.order-cum-info .action {
  display: flex;
  align-items: center;
}
.order-cum-info .action button {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.order-cum-info .action > .reject {
  background: #c82333;
  height: 90px;
  color: #ffffff;
  margin-right: 15px;
  padding: 15px;
  border-radius: 10px;
  margin-top: 0px !important;
}
.order-cum-info .action > .accept {
  background: var(--green) 0% 0% no-repeat padding-box;
  height: 90px;
  color: #fff;
  padding: 15px;
  border-radius: 10px;
  margin-top: 0px !important;
}
.order-cum-info .action > .process {
  background: #0069d9;
  height: 90px;
  color: #fff;
  padding: 15px;
  border-radius: 10px;
  margin-top: 0px !important;
}

.order-p {
  margin: 0;
  color: #666d92;
  /* font-size: 12px; */
  font-weight: 400;
  font: normal normal normal 14px/18px Source Sans Pro;
}
.order-h2 {
  margin-bottom: 0.48rem;
  font-weight: 600;
  line-height: 1.3925;
  font-family: "Source Sans Pro", sans-serif;
  color: #161919;
  font-size: 18px;
}

@media screen and (max-width: 800px) {
  .order-container,
  .order-details,
  .order-cum-info {
    height: auto;
  }
  .action {
    margin: 0 auto;
    padding-bottom: 20px;
  }
  .accept,
  .reject {
    height: 30px;
    margin-top: 10px;
  }
  .order-details .view-order {
    right: -10px;
  }
}
@media screen and (max-width: 1219px) {
  .order-details .order-confirm {
    margin-bottom: 10px;
  }
  .order-details .view-order {
    right: -2px;
  }
}
@media screen and (max-width: 500px) {
  .btn,
  button.btn {
    padding: 10px 25px !important;
  }
}
</style>