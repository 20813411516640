<template>
<div role="alert" id="pwa-update-toast" aria-live="assertive" aria-atomic="true" class="toast" data-autohide="false"> 
  <div class="toast-body">
    New version available! <a href="javascript:void(0)" @click="refreshApp" data-dismiss="toast" aria-label="Close">Click</a> to update
  </div>
</div>
</template>
<style scoped>
.popup .modal-content{
  border-radius: 0 0 8px 8px !important;
  box-shadow: 0px 1px 5px #0000000D !important;
}
.toast{
z-index: 10000;
position: fixed;
left: 0;
right: 0;
margin:0 auto;
}
</style>

<script>
import { version } from "../../package.json";
import * as $ from "jquery";

export default {
  name: "PWAUpdate",
  data() {
    return {
      refreshing: false,
      worker: null,
      updateExists: false,
      appVersion: version
    };
  },

  watch:{

    updateExists (new_, old_) {
        if (new_){
            $('#pwa-update-toast').toast('show')
        }
    }
  },

//   mounted(){
//       $('#pwa-update-toast').toast('show')
//   },

  created() {
      
    // Listen for swUpdated event and display refresh snackbar as required.
    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    // Refresh all open app tabs when a new service worker is installed.
    navigator.serviceWorker &&
      navigator.serviceWorker.addEventListener(
        //triggered by registration.claim
        "controllerchange",
        () => {
          if (this.refreshing) return;
          this.refreshing = true;
          console.log("controllerchange triggered, -> auto refresh!!");
          window.location.reload();
        }
      );
  },

  methods: {
    showRefreshUI(e) {
      // Display a button inviting the user to refresh/reload the app due
      // to an app update being available.
      // The new service worker is installed, but not yet active.
      // Store the ServiceWorkerRegistration instance for later use.
      if (e.detail) {
        this.worker = e.detail;
        this.updateExists = true;
        console.log("Showing refresh button.");
        console.log(this.worker);
      } else {
        console.warn("No worker data found!");
      }
    },
    refreshApp() {
      console.log("skipWaiting started");
      console.log(this.worker);
      // Handle a user tap on the update app button.
      this.updateExists = false;
      // Protect against missing registration.waiting.
      if (!this.worker) {
        console.warn("No worker data found when trying to refresh!");
        return;
      }
      this.worker.postMessage({ type: "SKIP_WAITING" });
      console.log("skipWaiting finished");
    }
  }
};
</script>

<style>
#pwa-update-toast {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e51;
}

</style>
