<template>
  <div id="walk-in">
    <div class>
      <div class="p-0">
        <Sidemenu />
        <div id="main">
          <Topmenu />
          <div class="filter-container">
            <div class="row h-100 mx-1">
              <div class="col-xl-5 col-lg-4 my-auto filter-title">
                <div class="d-flex">
                  <h5 class="title mr-2">Menu</h5>
                  <p class="subtitle pl-2">{{ filteredList.length }} items</p>
                </div>
              </div>
              <div class="col-xl-7 col-lg-8 my-auto filter-form">
                <div class="d-flex justify-content-between flex-wrap">
                  <div id="search" class="my-auto">
                    <input
                      v-model="search"
                      id="searchinput"
                      ref="searchInput"
                      @keydown.space.prevent
                      class="form-control"
                      type="text"
                      placeholder="Search by product name and product SKU"
                    />
                    <span class="fa fa-search"></span>
                  </div>
                  <div class="dropdown dropleft">
                    <button
                      id="q-btn"
                      class="btn dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      title="Click to view orders queue"
                    >
                      {{ queuedata.length }} Orders in Queue
                    </button>

                    <div v-if="queuedata.length > 0" class="dropdown-menu">
                      <ul class="p-0">
                        <li
                          v-for="(row, ix) in queuedata"
                          v-bind:key="ix"
                          class="dropdown-item"
                        >
                          <div>
                            <div>
                              <p class="code">
                                {{ row.ordercode }}
                                <input
                                  v-model="mergeorders"
                                  class="form-check float-right"
                                  type="checkbox"
                                  name
                                  :value="row.id"
                                />
                              </p>
                              <p class="date">
                                {{ formatDate(row.transactiondate) }}
                              </p>
                              <h5 class="price">
                                ₦{{ formatPricePay(row.ordertotal) }}
                              </h5>
                            </div>

                            <div class="d-flex">
                              <button
                                @click.prevent="viewOrder(row)"
                                data-toggle="modal"
                                data-target="#ordermodal"
                                type="button"
                                class="btn-sm btn-primary ml-2 my-auto"
                              >
                                View Order
                              </button>
                              <button
                                data-toggle="modal"
                                data-target="#sidecart"
                                data-backdrop="false"
                                @click.prevent="processOrder(row)"
                                type="button"
                                class="btn-sm btn-success ml-2 my-auto"
                              >
                                Process Order
                              </button>
                            </div>
                          </div>
                          <div class="dropdown-divider"></div>
                        </li>
                      </ul>
                      <div v-if="mergeorders.length > 1" class="text-center">
                        <button
                          data-toggle="modal"
                          data-target="#sidecart"
                          data-backdrop="false"
                          @click="mergeOrder"
                          type="button"
                          class="btn btn-success my-auto"
                        >
                          Merge Orders
                        </button>
                      </div>
                    </div>
                    <div id="no-data" class="text-center dropdown-menu" v-else>
                      <img
                        class="img-fluid"
                        src="../assets/img/no-result.png"
                        alt
                      />
                    </div>
                  </div>

                  <!-- <div class="my-auto">
                  <p class="pagination"> Showing 1-20 of 48</p>

                </div>
                <div class="my-auto d-inline">
                  <span class="fa fa-chevron-left"></span>
                  <span class="fa fa-chevron-right ml-4"></span>
                  </div>-->
                </div>
              </div>
            </div>
          </div>
          <Menu
            :categories="categories"
            v-on:childToParent="activeCategory"
          ></Menu>

          <div class="main-content my-3" :style="'width: ' + minusCartWidth">
            <div v-if="filteredList.length !== 0" class="row mx-2">
              <div
                v-for="(pro, index) in filteredList"
                v-bind:key="index"
                @click="showCart()"
                :class="
                  isCartOpen
                    ? 'col-xl-3 col-lg-4 col-md-6 col-sm-6  mt-2'
                    : 'col-xl-2 col-lg-3 col-md-4 col-sm-6 mt-2'
                "
              >
                <div
                  class="pro p-2"
                  v-bind:disabled="pro.outofstock == true"
                  v-bind:class="pro.outofstock == true ? 'stock-out' : ''"
                  @click="addToCart(pro)"
                >
                  <div class="text-center">
                    <img v-lazy="pro.photo" alt class="img-fluid" />
                  </div>
                  <div class="pro-details mt-3">
                    <p class="name">{{ pro.productname }}</p>
                    <div
                      :class="
                        pro.discountprice
                          ? 'd-flex justify-content-between mt-1'
                          : 'd-flex justify-content-between my-2'
                      "
                    >
                      <div v-if="pro.discountprice">
                        <s class="old-price"
                          >₦ {{ formatPrice(pro.sellingprice) }}</s
                        >
                        <h6 class="discount-price">
                          ₦ {{ formatPrice(pro.discountprice) }}
                        </h6>
                        <!--<span class="discount-percentage">-{{pro.discount}}%</span> -->
                      </div>
                      <div v-else>
                        <h5 class="price">
                          ₦ {{ formatPrice(pro.sellingprice) }}
                        </h5>
                      </div>
                      <p
                        v-if="
                          pro.outofstock !== true &&
                          !(pro.producttype && pro.producttype.includes('non'))
                        "
                        class="qty my-auto"
                      >
                        {{ formatPrice(pro.stockqty, "count") }}
                        <span>left</span>
                      </p>
                      <p
                        class="qty my-auto"
                        v-else-if="
                          pro.outofstock !== true &&
                          pro.producttype &&
                          pro.producttype.includes('non') &&
                          !(pro.stockqty < 0)
                        "
                      >
                        {{ formatPrice(pro.stockqty, "count") }}
                        <span>left</span>
                      </p>
                      <p
                        class="qty my-auto"
                        v-else-if="
                          pro.outofstock !== true &&
                          pro.producttype &&
                          pro.producttype.includes('non') &&
                          pro.stockqty < 0
                        "
                      >
                        0
                      </p>
                      <!-- <p
                        v-else
                        class="qty text-danger my-auto"
                      > Out of Stock</p>-->
                    </div>
                  </div>
                </div>
              </div>
              <mugen-scroll
                :handler="fetchProducts"
                :should-handle="shouldLoad"
              ></mugen-scroll>
            </div>
            <div class="text-center mt-5" v-else>
              <img class="img-fluid" src="../assets/img/no-result.png" alt />
            </div>
          </div>
        </div>
      </div>
      <Cart
        ref="cart"
        @ordered="refetchProduct"
        @isopen="updateCartVisibility"
        @cartWidthChanged="updateCartWidth"
      ></Cart>
    </div>

    <!-- features modal -->
    <div
      id="features"
      class="modal fade"
      data-backdrop="static"
      data-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header border-0 mx-5">
            <h5 class>Select Variation</h5>
            <img
              data-dismiss="modal"
              aria-label="Close"
              src="../assets/img/close.png"
              alt
            />
          </div>
          <div class="modal-body mx-5">
            <div v-for="(row, ix) in product.variance" v-bind:key="ix">
              <div class="form-group">
                <label for>Select {{ Object.keys(row)[0] }}</label>
                <select v-model="features[ix]" class="form-control">
                  <option :value="va" v-for="va in Object.values(row)[0]">
                    {{ va.featurename }}
                  </option>
                </select>
              </div>
            </div>

            <div class="mt-4 float-right">
              <button class="cancel-close btn" data-dismiss="modal">
                Cancel
              </button>
              <button @click.prevent="AddFeatures" class="btn cancel-btn ml-3">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- order modal -->
    <div
      v-if="Object.keys(order).length > 0"
      id="ordermodal"
      class="modal"
      tabindex="-1"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header mx-sm-5">
            <h5 class="modal-title">
              Order Details
              <span
                v-if="order.orderstatus == 'completed'"
                class="badge badge-pill badge-delivered"
                >{{ order.orderstatus }}</span
              >
              <span
                v-else-if="order.orderstatus == 'paylater'"
                class="badge badge-pill badge-pending"
                >{{ order.orderstatus }}</span
              >
              <span
                v-else-if="order.orderstatus == 'prepared'"
                class="badge badge-pill badge-delayed"
                >{{ order.orderstatus }}</span
              >

              <span v-else class="badge badge-pill">{{
                order.orderstatus
              }}</span>
            </h5>

            <img
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              src="../assets/img/close.png"
              alt
            />
          </div>
          <div class="modal-body mx-sm-5">
            <div class="orderinfo d-flex justify-content-left flex-wrap my-3">
              <h5 class="heading">Order Code</h5>
              <p class="id ml-2">{{ order.ordercode }}</p>
            </div>
            <div class="orderinfo d-flex justify-content-left flex-wrap my-3">
              <h5 class="heading">Order Time</h5>
              <p class="id ml-2">{{ formatDate(order.transactiondate) }}</p>
            </div>

            <table class="table my-3">
              <tbody>
                <tr
                  v-for="(row, ix) in order.orderitems"
                  v-bind:key="ix"
                  class="items"
                >
                  <td class>
                    <img
                      v-lazy="row.photo != undefined ? row.photo : row.image"
                      alt
                      class="orderimg img-fluid img-responsive"
                    />
                  </td>
                  <td>
                    <h5 class="product">
                      {{
                        row.productname != undefined
                          ? row.productname
                          : row.name
                      }}
                    </h5>
                    <!-- <p class="price">₦ {{formatPrice(row.unitprice)}}</p> -->
                    <div v-if="row.discountprice">
                      <s class="old-price"
                        >₦ {{ formatPrice(row.unitprice) }}</s
                      >
                      <h6 class="discount-price">
                        ₦ {{ formatPrice(row.discountprice) }}
                      </h6>
                    </div>
                    <div v-else>
                      <p class="price">₦ {{ formatPrice(row.unitprice) }}</p>
                    </div>
                  </td>
                  <td>
                    <p class="qty">{{ row.quantity }}</p>
                  </td>
                  <td class="subtotal">₦ {{ formatPrice(row.totalprice) }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th></th>
                  <th></th>
                  <th scope="col">Discount</th>
                  <th scope="col" class="text-right">
                    ₦ {{ formatPrice(order.discountpayment) }}
                  </th>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th scope="col">Total</th>
                  <th scope="col" class="text-right">
                    ₦ {{ formatPricePay(order.ordertotal) }}
                  </th>
                </tr>
              </tfoot>
            </table>
            <button
              data-toggle="modal"
              data-target="#sidecart"
              data-backdrop="false"
              @click.prevent="processOrder(order)"
              type="button"
              class="btn ml-2 my-auto float-right"
            >
              Process Order
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as $ from "jquery";
import Sidemenu from "@/components/sidemenu";
import Topmenu from "@/components/topmenu";
import Menu from "@/components/menu";
import Cart from "@/components/cart";
// import { createNamespacedHelpers } from 'vuex'
import { mapActions, mapGetters } from "vuex";
// const { mapActions, mapGetters } = createNamespacedHelpers('orders')

//barcode scanner
export default {
  name: "Walk-in",
  components: {
    Sidemenu,
    Topmenu,
    Menu,
    Cart,
  },
  data() {
    return {
      search: "",
      isCartOpen: false,
      minusCartWidth: 0,
      page: 0,
      loading: false,
      index: 0,
      edit: false,
      order: {},
      all_products: [],
      cat_products: [],
      product: {},
      categories: [],
      features: [],
      length: 0,
      // queuedata: [],
      mergeorders: [],
    };
  },
  created() {
    this.minusCartWidth = "100%";
    this.loading = true;
    this.fetchProducts(this.index);
    this.fetchQueue();
    this.$barcodeScanner.init(this.onBarcodeScanned);
  },
  mounted() {
    // window.addEventListener("keypress", (e) => {
    //   if (!$('.modal').is(':visible')) {
    //     document.querySelector("#searchinput").focus()
    //   }
    // });
    this.$root.$on("download-complete", () => {
      // your code goes here
      // this.fetchProducts(this.index);
    });
  },
  destroyed() {
    // Remove listener when component is destroyed
    this.$barcodeScanner.destroy();
  },
  watch: {
    index(val) {
      this.page = 0;
      this.fetchProducts(val);
    },
    search(val) {
      this.cat_products = [];
      this.page = 0;
      // if (val == '') {
      //   this.$toasted.show('loading, Please wait... ', {
      //     theme: "toasted-primary",
      //     type: "info",
      //     position: "top-right",
      //     icon: {
      //       name: 'hourglass',
      //     }
      //   });
      // }
      this.fetchProducts();
    },
  },
  computed: {
    filteredList() {
      return this.cat_products;

      // .filter(pro => {
      //   if (pro.outofstock !== true && pro.productname.toLowerCase().includes(this.search.toLowerCase())) {
      //     return pro.productname.toLowerCase().includes(this.search.toLowerCase());
      //   }

      //   if (pro.outofstock !== true && pro.productcode !== null && pro.productcode.includes(this.search.toLowerCase())) {
      //     return pro.productcode && pro.productcode.includes(this.search.toLowerCase());
      //   }

      // });
    },
    shouldLoad() {
      // if (this.page !== 0) {
      //   this.$toasted.show('loading, Please wait... ', {
      //     theme: "toasted-primary",
      //     type: "info",
      //     position: "top-right",
      //     icon: {
      //       name: 'hourglass',
      //     }
      //   });
      // }
      return this.length != this.cat_products.length && !this.loading;
    },
    subtotal() {
      let sum = 0;
      this.order.orderitems.forEach((i) => {
        sum += i.totalprice;
      });
      return sum;
    },
    ...mapGetters({
      queuedata: "orders/queue",
    }),
  },
  methods: {
    fetchDiscount(id, qty) {
      let req = {
        what: "caldiscount",
        showLoader: false,
        useToken: true,
        params: {
          merchID: this.$store.getters.user.merchID,
          orderamount: qty,
          productID: id,
        },
      };
      this.$request
        .makeGetRequest(req)
        .then((res) => {
          this.calcdiscount = res.data;

          this.cart = this.$store.getters.cart;
          this.cart.forEach((i, index) => {
            if (i.productid == id) {
              i.discountfee = this.calcdiscount.discountfee;
            }
            if (i.discountfee) {
              i.totalamount =
                Number(i.quantity) *
                (Number(i.discountprice)
                  ? Number(i.discountprice) - Number(i.discountfee)
                  : Number(i.price) - Number(i.discountfee));
            } else {
              i.totalamount =
                Number(i.quantity) *
                (Number(i.discountprice)
                  ? Number(i.discountprice)
                  : Number(i.price));
            }
          });

          this.$store.dispatch("updateCart", this.cart);
        })
        .catch((error) => {
          console.log(error);
          this.is401(error.response.status);
        });
    },
    ...mapActions({
      getProducts: "products/index",
      // 'getCustomers' : 'customers/index',
      getInstoreOrders: "orders/getInstoreOrders",
      getProductThroughBarcode: "products/getProductThroughBarcode",
    }),

    updateCartVisibility(val) {
      // print(val)
      this.isCartOpen = val;
      //
      // if (val) {
      //   document.getElementById('category-menu').style.width = '65%'
      // } else {
      //   document.getElementById('category-menu').style.width = '100%'
      // }
    },

    updateCartWidth(val) {
      this.minusCartWidth = val;
    },

    refetchProduct() {
      this.page -= 1;
      this.fetchProducts(this.index);
    },

    fetchQueue() {
      let req = {
        what: "queue",
        showLoader: false,
        useToken: true,
        params: {
          merchID: this.$store.getters.getMerchID,
          branchID: this.$store.getters.getBranchID,
          orderstatus: "paylater",
        },
      };
      this.getInstoreOrders(req)
        .then((res) => {
          // if (res.type == 'queue') {
          //   this.queuedata = res.data.data
          // }
          // this.$swal.close()
        })
        .catch((error) => {
          this.$toasted.show(error.message, {
            theme: "bubble",
            type: "error",
            position: "top-right",
            duration: 6000,
          });
          // this.$swal.fire("Error", error.message, "error");
        });
    },
    async fetchProducts(index) {
      let vm = this;
      this.page += 1;
      index = this.index;
      this.availability = "0";
      let req = {
        what: "getProducts",
        useToken: true,
        showLoader: this.loading,
        params: {
          merchID: this.$store.getters.getMerchID,
          branchID: this.$store.getters.getBranchID,
          page: this.page,
          search: this.search,
          available: true,
        },
      };

      if (this.page !== 1) {
        req.params.categoryID = vm.categories[index].id;
      }
      await this.getProducts(req)
        .then((res) => {
          this.$toasted.clear();
          let products = res;
          let cats = [];

          products.forEach((i) => {
            cats.push({
              name: i.category,
              id: i.header.category,
              active: false,
            });
            let urlParams = new URLSearchParams(i.header.Next);
            let next = urlParams.get("page");
          });
          if (this.page == 1) {
            vm.categories = cats;
          }
          this.all_products.push(...products);
          if (this.page == 1) {
            if (this.search == "") {
              products[index].header.data.forEach((product) => {
                let index = vm.cat_products.findIndex(
                  (val) => val.id == product.id
                );
                if (index == -1) {
                  vm.cat_products.push(product);
                } else {
                  vm.cat_products[index] = product;
                }
              });
            } else {
              if (index >= products.length) {
                index = 0;
              }
              if (products.length == 0) {
                vm.cat_products = [];
              } else {
                vm.cat_products = products[index].header.data;
              }
            }
            this.length = products[index].header.Count;
          } else {
            // if(this.search == ''){

            products[0].header.data.forEach((product) => {
              let index = vm.cat_products.findIndex(
                (val) => val.id == product.id
              );
              if (index == -1) {
                vm.cat_products.push(product);
              } else {
                vm.cat_products[index] = product;
              }
            });
            // }

            // vm.cat_products = vm.cat_products.concat(products[0].header.data);
            this.length = products[0].header.Count;
          }
          vm.categories[index].active = true;

          // }
          this.$swal.close();
        })
        .catch((error) => {
          // this.$swal.fire("Error", error.message, "error");
        });
      this.loading = false;
    },
    // fetchQueue () {
    //   let req = {
    //     what: "queue",
    //     useToken: true,
    //     params: {
    //       merchID: this.$store.getters.user.merchID,
    //       branchID: this.$store.getters.user.branchID,
    //       orderstatus: 'paylater'
    //     }
    //   }
    //   this.$request.makeGetRequest(req)
    //     .then(res => {
    //       if (res.type == 'queue') {
    //         this.queuedata = res.data.data
    //       }

    //     })
    //     .catch(error => {
    //       this.$swal.fire("Error", error.message, "error");
    //     });
    // },

    formatPrice(price, count) {
      var str = price.toString().split(".");
      if (str[0].length >= 3) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
      }
      if (!count) {
        if (!str[1]) {
          str[1] = "00";
        }
      }
      return str.join(".");
    },
    formatPricePay(price) {
      var str = price.toString().split(".");
      if (str[0].length >= 3) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
      }
      // if (!count) {
      if (!str[1]) {
        str[1] = "00";
      }
      // }
      let l = str[1].length;

      let c = l > 1 ? str[1] : str[1] + "0";
      return str[0] + "." + c.substring(0, 2);
    },
    formatDate(date, format) {
      if (date !== null) {
        let d = new Date(date);
        if (format == "time") {
          return d.toLocaleTimeString("en-US");
        } else if (format == "date") {
          return d.toDateString().replace(" ", ", ");
        } else {
          return (
            d.toDateString().replace(" ", ", ") +
            " " +
            d.toLocaleTimeString("en-US")
          );
        }
      } else {
        return null;
      }
    },
    activeCategory(value, index) {
      let vm = this;
      this.index = index;
      this.all_products.forEach((i) => {
        if (i.category === value.name) {
          vm.cat_products = i.header.data;
        }
      });
    },
    addToCart(row) {
      if (
        this.$store.getters.businesscategory == "Fashion" &&
        row.productfeatures.length !== 0
      ) {
        const grouped = this.groupBy(
          row.productfeatures,
          (item) => item.featuretype
        );
        row.variance = [];
        grouped.forEach((value, key) => {
          let data = {};
          data[key] = value;
          row.variance.push(data);
        });

        $("#features").modal("show");

        this.product = row;
      } else {
        let order = {
          name: row.productname,
          price: row.sellingprice,
          discountprice: row.discountprice,
          productid: row.id,
          quantity: 1,
          image: row.photo,
          totalamount:
            Number(row.discountprice) > 0
              ? Number(row.discountprice)
              : Number(row.sellingprice),
          discountfee: row.discountfee,
          stockqty: row.stockqty,
          taxstatus: row.taxinclusive,
          producttype: row.producttype,
        };

        let cart = this.$store.getters.cart;
        let contain = [];
        cart.forEach((i) => {
          if (i.productid == order.productid) {
            contain.push(true);
          } else {
            contain.push(false);
          }
        });
        if (contain.includes(true)) {
          cart.forEach((i) => {
            if (
              i.productid == order.productid &&
              (order.stockqty > 0 || order.producttype == "nonstocked_product")
            ) {
              if (
                i.quantity == order.stockqty &&
                order.producttype !== "nonstocked_product"
              ) {
                this.$swal
                  .fire({
                    title: "Stock limit exceeded",
                    showCloseButton: true,
                  })
                  .then((result) => {
                    if (result.isClosed) {
                      // this.$router.push('/products');
                    }
                  });
                i.quantity = order.stockqty;
              } else {
                i.quantity++;
              }
              if (i.discountfee) {
                i.totalamount =
                  Number(i.quantity) *
                  (Number(i.discountprice)
                    ? Number(i.discountprice) - Number(i.discountfee)
                    : Number(i.price) - Number(i.discountfee));
              } else {
                i.totalamount =
                  Number(i.quantity) *
                  (Number(i.discountprice)
                    ? Number(i.discountprice)
                    : Number(i.price));
              }
              // i.totalamount = Number(i.quantity) * (Number(i.discountprice) > 0 ? Number(i.discountprice) : Number(i.price));

              this.fetchDiscount(i.productid, i.quantity);
            }
          });
          this.$store.dispatch("updateCart", cart);
        } else {
          if (order.stockqty <= 0 && order.producttype == "stocked_product") {
            this.$swal
              .fire({
                title:
                  "0 or less items left, update your inventory to be able to sell",
                showCloseButton: true,
              })
              .then((result) => {
                if (result.isClosed) {
                  this.$router.push("/products");
                }
              });
          } else {
            this.$store.dispatch("addToCart", order).then(() => {
              // this.$cart.fetchCart()
            });
            this.fetchDiscount(order.productid, order.quantity);
          }
        }
      }
    },

    showCart() {
      let isMobile = /Mobi|Android/i.test(navigator.userAgent);
      let cart = this.$store.getters.cart;
      cart.forEach((i) => {
        if (
          !isMobile &&
          (i.stockqty > 0 || i.producttype == "nonstocked_product")
        ) {
          $("#sidecart").modal("show");
        }
      });
      // if (!isMobile) {
      //   $("#sidecart").modal("show");
      // }
    },

    processOrder(order) {
      let cart = [];
      for (let row of order.orderitems) {
        cart.push({
          name: row.productname != undefined ? row.productname : row.name,
          price: row.unitprice,
          productid: row.productID,
          discountprice: row.discountprice,
          quantity: row.quantity,
          image: row.photo != undefined ? row.photo : row.image,
          totalamount: row.totalprice,
        });
      }
      this.$refs.cart.discount = order.discountpayment;
      this.$store.dispatch("setQueueStatus", true);
      this.$store.dispatch("setOrderDiscount", this.$refs.cart.discount);
      this.$store
        .dispatch("updateCart", cart)

        .then(() => {
          this.$store.dispatch("setOrder", order);
          this.$refs.cart.fetchCart();
          $(".modal").modal("hide");
        });
    },
    mergeOrder() {
      let cart = [];
      let discount = isNaN(this.$refs.cart.discount)
        ? 0
        : Number(this.$refs.cart.discount);

      for (let order of this.queuedata) {
        for (let i of this.mergeorders) {
          if (i == order.id) {
            for (let row of order.orderitems) {
              cart.push({
                name: row.productname != undefined ? row.productname : row.name,
                price: row.unitprice,
                productid: row.productID,
                quantity: row.quantity,
                discountprice: row.discountprice,
                image: row.photo != undefined ? row.photo : row.image,
                totalamount: row.totalprice,
              });
            }

            discount += order.discountpayment
              ? Number(order.discountpayment)
              : 0;
          }
        }
      }
      var new_data = {};
      var uniqueArr = [];
      for (var i = 0; i < cart.length; i++) {
        if (new_data[cart[i].productid] == undefined) {
          new_data[cart[i].productid] = [];
          new_data[cart[i].productid] = cart[i];
        } else {
          new_data[cart[i].productid].totalamount += cart[i].totalamount;
          new_data[cart[i].productid].quantity += cart[i].quantity;
        }
      }
      for (let x in new_data) {
        uniqueArr.push(new_data[x]);
      }

      this.$store.dispatch("setMergeOrders", this.mergeorders);
      this.$store.dispatch("setQueueStatus", false);

      this.$store.dispatch("setOrderDiscount", discount);
      this.$refs.cart.discount = discount;
      this.$store.dispatch("updateCart", uniqueArr).then(() => {
        this.$refs.cart.fetchCart();
        $(".modal").modal("hide");
      });
    },
    viewOrder(row) {
      this.order = row;
    },
    groupBy(list, keyGetter) {
      const map = new Map();
      list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
          map.set(key, [item]);
        } else {
          collection.push(item);
        }
      });
      return map;
    },
    AddFeatures() {
      let row = this.product;
      let order = {
        name: row.productname,
        price: row.sellinprice,
        productid: row.id,
        quantity: 1,
        image: row.photo,
        totalamount: Number(row.sellinprice),
        variance: this.features,
      };
      this.features.forEach((i) => {
        if (
          i.hasOwnProperty("price") &&
          Object.values(i).indexOf("price").length != 0
        ) {
          order.price = i.price;
          order.totalamount = Number(i.price);
        }
      });
      let cart = this.$store.getters.cart;
      let contain = [];
      cart.forEach((i) => {
        if (i.name == order.name) {
          contain.push(true);
        } else {
          contain.push(false);
        }
      });
      if (contain.includes(true)) {
        cart.forEach((i) => {
          if (i.name == order.name) {
            i.quantity++;
            i.total = Number(i.quantity) * Number(i.price);
          }
        });
        this.$store.dispatch("updateCart", cart);
      } else {
        this.$store.dispatch("addToCart", order).then(() => {
          // this.$cart.fetchCart()
        });
      }
      $(".modal").modal("hide");
    },
    // Create callback function to receive barcode when the scanner is already done
    onBarcodeScanned(barcode) {
      // if (!$('input#searchtext').val()) {
      this.getProductThroughBarcode(barcode)
        .then((product) => {
          this.addToCart(product);
        })
        .catch((err) => {});

      //   let req = {
      //     what: "barcodescan",
      //     useToken: true,
      //     params: {
      //       barcode,
      //       merchID: this.$store.getters.user.merchID,
      //       branchID: this.$store.getters.user.branchID

      //     }
      //   }
      //   this.$request.makeGetRequest(req)
      //     .then(res => {
      //       if (res.type == 'barcodescan') {
      //         this.addToCart(res.data.data)
      //       }
      //     })
      //     .catch(error => {
      //       this.$swal.fire("Error", error.message, "error");
      //     });
      // }
    },
  },
};
</script>
<style scoped>
#app {
  min-width: 1440px;
  overflow: auto;
}
@media screen and (max-width: 1024px) {
  #app {
    min-width: 1440px;
    overflow: auto;
  }
  .col-lg-4,
  .col-lg-3,
  .col-md-6,
  .col-md-4,
  .col-sm-6,
  .col-xl-2,
  .col-xl-3 {
    padding: 3px !important;
  }
}
@media screen and (max-width: 768px) {
  .pro {
    width: 190px;
    padding: 20px;
  }
}
#q-btn {
  margin-top: 0;
}
</style>


