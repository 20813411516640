<template >
  <div
    id="branches"
    class="modal"
    tabindex="-1"
    data-backdrop='static'
  >
    <div class="modal-dialog ">
      <div class="modal-content">
        <div class="modal-header  ">
          <h5 class="modal-title mx-sm-5">Branch Selector
          </h5>

          <img
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            src="../assets/img/close.png"
            alt=""
          >
        </div>
        <div class="modal-body mx-sm-5">
          <div class="form-group">
            <label for="">Branch</label>

            <select
              v-model="branch"
              class="form-control"
            >
              <option value="">Select a Branch</option>
              <option
                v-for="branch in branches"
                :key="branch.id"
                :value="branch.id"
              >
                {{ branch.branchname }}
              </option>
            </select>
            <span
              v-if="branch == ''"
              class="err_msg"
            >Please Select A Branch</span>

          </div>
        </div>
        <div class="modal-footer mx-5 py-4 border-0">
          <button
            data-dismiss="modal"
            type="button"
            @click.prevent="clearBranch"
            class="cancel-close btn my-auto"
          >Clear</button>
          <button
            type="button"
            @click.prevent="setBranch"
            class="btn ml-2 my-auto"
          >Proceed</button>
        </div>
      </div>
    </div>

  </div>

</template>
<script>

import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'BranchSelector',
  data () {
    return {
      //   branches : [],
      branch: ''
    }
  },

  computed: {
    ...mapGetters({
      'user': 'user',
    }),

    branches () {
      return this.user ? this.user.branches : []
    }
  },

  methods: {
    setBranch () {
      if (this.branch != '') {
        if (this.branch != this.$store.getters.getBranchID) {

          this.$store.commit('first', true)
          this.$store.commit('set_updated_at', '')
        }
        this.$store.commit('setBranchIDAfter', this.branch)

        this.$emit('selected')
      }
    },

    clearBranch () {
      this.branch = ''
    },

    ...mapActions({
      'setUser': 'setUser'
    })
  },

  mounted () {

  }
}

</script>