<template >
  <div id="signin">
    <div class="row p-sm-3">
      <div class="col-sm-7">
        <div class="logo ml-3">
          <img src="../assets/img/logo.png" alt="" />
        </div>
        <div class="auth-form mx-auto">
          <div class="heading d-flex justify-content-start">
            <img src="../assets/img/key.svg" alt="" />
            <div class="ml-3">
              <h3>Welcome Back!</h3>
              <p>Sign in to continue</p>
            </div>
          </div>
          <div>
            <ValidationObserver v-slot="{ handleSubmit }">
              <form class="mt-4" @submit.prevent="handleSubmit(handleSignin)">
                <div class="form-group">
                  <label for="">Enter Your Last Name</label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="What’s your Last name?"
                      v-model="username"
                    />
                    <span class="extra">Enter your registered surname</span>
                    <br />
                    <span class="err_msg">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
                <!-- <div class="form-group ">
                    <a
                      class="forgot"
                      @click="$router.push('/forgot-password')"
                      style="cursor:pointer;"
                      href="/forgot-password"
                    >Forgot Password?</a>
                  </div> -->
                <div class="form-group">
                  <label for="">Enter Token</label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <!-- <input
                      type="password"
                      class="form-control"
                      placeholder="Please enter your password"
                      v-model="password"
                    > -->
                    <div class="input-wrapper">
                      <PincodeInput
                        :length="6"
                        v-model="passcode"
                        placeholder="0"
                      />
                    </div>
                    <span class="err_msg">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
                <div class="form-group">
                  <button type="submit" class="btn">Sign in</button>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
      <div class="col-sm-5 d-none d-sm-block">
        <div class="auth-details px-3">
          <div class="mr-3 title text-right">
            <img src="../assets/img/logo-mini.png" alt="" />
            <h4>
              Welcome To <span style="color: green">n</span>tisa POS <br />
            </h4>
          </div>
          <div class="login-bg">
            <img src="../assets/img/login-bg.svg" alt="" />
          </div>
        </div>
      </div>
      <branch-selector @selected="login"></branch-selector>
    </div>
  </div>
</template>

<script>
import Sync from "@/workers/Sync";
import Utils from "@/utils.js";
import BranchSelector from "../components/branch-selector.vue";
import * as $ from "jquery";
export default {
  name: "Login",
  components: {
    BranchSelector,
  },
  data() {
    return {
      username: "",
      passcode: "",
      role: "store_attendant",
    };
  },

  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("sessionexpire");

    if (myParam) {
      this.$swal.fire(
        "Session Alert",
        "Your session has expired, please login again!.",
        "success"
      );
    }
  },

  methods: {
    getUsers() {
      let req = {
        what: "getUsers",
        useToken: true,
        params: {
          merchID: this.$store.getters.getMerchID,
        },
      };

      this.$store.dispatch("getUsers", req);
    },
    async login() {
      $("#branches").modal("hide");
      this.getUsers();

      let result = await Utils.checkOnlineStatus();

      if (result) {
        if (this.$store.getters.user.role == "kitchen") {
          this.$swal.close();
          this.$router.push({ name: "Kitchen" });
        } else {
          if (this.$store.state.first) {
            let shouldFetch = await this.$store.dispatch("posts/shouldFetch");
            if (this.$store.state.load == false) {
              Sync.send({
                load: true,
                data: this.$store.getters.user,
                token: this.$store.getters.token,
                run: shouldFetch,
                updated_at: "",
              });
              // this.$store.getters.updated_at
            }
          }
          if (this.$store.getters.businesscategory !== "Delivery") {
            this.$swal.close();
            this.$router.push({ name: "Walk-in" });
          } else {
            this.$swal.close();
            this.$router.push({ name: "Dispatch" });
          }
        }
      } else {
        if (this.$store.getters.businesscategory !== "Delivery") {
          this.$swal.close();
          this.$router.push({ name: "Walk-in" });
        } else {
          this.$swal.close();
          this.$router.push({ name: "Dispatch" });
        }
      }
    },
    handleSignin() {
      let req = {
        what: "login",
        showLoader: false,
        params: {
          firstname: this.username,
          token: this.passcode,
          role: this.role,
        },
      };

      // console.log(this.$store.state.users)

      var loading_html =
        '<div style="height:50px;width:50px;margin: 0 auto;"><img style="width: 100%;" src="https://trails.ca/wp-content/uploads/2020/10/loading-spinner.gif" /></div>';

      this.$swal.fire({
        title: "",
        html: loading_html,
        showConfirmButton: false,
        allowOutsideClick: true,
        allowEscapeKey: false,
        showCancelButton: false,
        width: "150px",
      });

      this.$store
        .dispatch("login", req)
        .then(async (res) => {
          this.$swal.close();
          let branchID = null;
          if (Array.isArray(res.branchID)) {
            branchID = res.branchID;
          } else {
            branchID = [res.branchID];
          }

          if (branchID.length > 1) {
            $("#branches").modal("show");
          } else {
            if (branchID[0] != this.$store.getters.getBranchID) {
              this.$store.commit("first", true);
              this.$store.commit("set_updated_at", "");
            }
            this.$store.commit("setBranchIDAfter", branchID[0]);

            this.login();
          }
        })
        .catch((error) => {
          console.log(error);
          // this.$swal.fire("Error", error, "error");
          this.$swal.close();
          this.$toasted.show(error, {
            theme: "bubble",
            type: "error",
            position: "top-right",
            duration: 6000,
          });
        });
    },
  },
};
</script>
<style>
.vue-pincode-input {
  margin-right: 7px !important;
  padding: 8px !important;
  max-width: 40px !important;
  text-align: center;
  font-size: 1.2rem !important;
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 3px rgb(0 0 0 / 50%) !important;
}
.vue-pincode-input:focus {
  box-shadow: 0 0 3px rgb(0 0 0 / 50%) !important;
}
</style>