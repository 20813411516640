import db from '@/DB.js'

//  contains functions to write the downloaded data to the db

export default {
    name: "dbFunctions",

    async syncProducts(products) {

        let products_all = products.map((category) => {
            // category.header.data.category = category.category
            return category.header.data.map((product) => {
                product.category = category.category;
                return product
            })
        }).reduce((acc, curr) => { acc.push(...curr); return acc }, [])

        // db.products.clear();
        await db.products.bulkPut(products_all)

        // db.categories.clear();
        let categories = products.map((category) => {
            category.header.data = null
            return category
        });

        await db.categories.where('category').noneOf(categories.map((category) => category.category)).delete();
        await db.categories.bulkPut(categories);

        await db.products.where('deleted').notEqual('').delete();

        // console.log(state.products)
    },


    async setProducts(products) {
        let products_all = products.map((category) => {
            return category.header.data.map((product) => {
                product.category = category.category;
                return product
            })
        }).reduce((acc, curr) => { acc.push(...curr); return acc }, [])

        db.products.clear();
        await db.products.bulkPut(products_all)

        db.categories.clear();
        await db.categories.bulkPut(products.map((category) => {
            category.header.data = null
            return category
        }));

        await db.products.where('deleted').notEqual('').delete();
    },

    async setOrders(orders) {
        db.orders.clear();
        db.orders.bulkPut(orders)
    },

    async setCustomers(state, customers) {
        db.customers.clear();
        db.customers.bulkPut(customers)
    },

    async setUpdated_at(date_) {
        db.date_time.clear();
        db.date_time.put({ updated_at: date_ })
    },

    async getUpdated_at() {
        let updated = await db.date_time.orderBy('updated_at').first();
        return updated ? updated.updated_at : ''
    },
    async setNetworkStatus(_status) {
        db.networkstatus.clear();
        db.networkstatus.put({ status: _status })
    },
    async getNetworkStatus() {
        let prev = await db.networkstatus.orderBy('status').first()
        return prev.status
    }

}