 import asyncRequest from "@/asyncRequest.js"
 import dbFunctions from "@/helpers/dbFunctions.js"
 export default {
     name: "checkOnline",

     getDate() {
         let date_ob = new Date();

         // adjust 0 before single digit date
         let date = ("0" + date_ob.getDate()).slice(-2);

         // current month
         let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);

         // current year
         let year = date_ob.getFullYear();

         // current hours
         let hours = date_ob.getHours();

         // current minutes
         let minutes = date_ob.getMinutes();

         // current seconds
         let seconds = date_ob.getSeconds();

         // prints date & time in YYYY-MM-DD HH:MM:SS format
         return year + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds;
     },

     async hashCode(message) {
         const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
         const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8); // hash the message
         const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
         const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
         return hashHex;
     },

     async productDownload(data) {
         //  let user = event.data.message.data;
         //  let token = event.data.message.token;
         let user = data.user;
         let token = data.token;
         asyncRequest.makeGetRequest({
             what: "getOfflineProducts",
             useToken: true,
             showLoader: false,
             params: {
                 merchID: user.merchID,
                 branchID: user.branchID,
                 page: 1,
                 updated_at: await dbFunctions.getUpdated_at(),
             }
         }, token).then(({ data }) => {

             // postMessage({ key: true, value: 'commit', commit: { type: 'products/SET_PRODUCTS_ASYNC', payload: data.data } });
             dbFunctions.syncProducts(data.data)
             let result = data.data
             result.map((category) => category.header.data).reduce((acc, curr) => { acc.push(...curr); return acc }, []).forEach((product) => {

                 if (product.photo != '') {
                     fetch(product.photo, {
                         mode: 'no-cors' // 'cors' by default
                     });
                 } else if (product.photo2 != '') {
                     fetch(product.photo2, {
                         mode: 'no-cors' // 'cors' by default
                     });
                 } else if (product.photo3 != '') {
                     fetch(product.photo3, {
                         mode: 'no-cors' // 'cors' by default
                     });
                 } else if (product.photo4 != '') {
                     fetch(product.photo4, {
                         mode: 'no-cors' // 'cors' by default
                     });
                 }
             })

             // postMessage({ key: true, value: 'short-sync-done' });
             dbFunctions.setUpdated_at(this.getDate().split(' ')[0])
         }).catch((error) => {
             // postMessage({ key: true, value: 'error', error: error.message });
         });


     },


     async download(event) {
         let i = 1;
         // setTimeout( async () => {
         var date = new Date();
         var res = new Date(date.toISOString().replace("Z", "-01:00"))
             .toISOString()
             .split("T");
         let user = event.data.message.data;
         let run = event.data.message.run;
         let token = event.data.message.token;
         let onlineStatus = await this.checkOnlineStatus()
         let isLoggedIn = user != undefined;
         if (onlineStatus && isLoggedIn && run) {
             postMessage({ key: true, value: 'load' });
             let params = {
                 merchID: user.merchID,
                 branchID: user.branchID,
                 filterDate: res[0],
                 orderstatus: ''
             }
             if (user.role == 'store-attendant') {
                 params.staffID = user.id;
             }
             asyncRequest.makeGetRequest({
                 what: "getHistory",
                 useToken: true,
                 showLoader: false,
                 params
             }, token).then(({ data }) => {
                 //   commit('SET_ALL_ORDERS', data.data)
                 dbFunctions.setOrders(data.data)
                     // postMessage({ key: true, value: 'commit', commit: { type: 'orders/SET_ALL_ORDERS_ASYNC', payload: data.data } });
                 postMessage({ key: true, value: 'unload' + i++ });
             }).catch((error) => {
                 postMessage({ key: true, value: 'error', error: error.message });
             });



             asyncRequest.makeGetRequest({
                 what: 'getCustomers',
                 useToken: true,
                 showLoader: false,
                 params: {
                     merchID: user.merchID,
                     pagination: false
                 }
             }, token).then(({ data }) => {
                 // postMessage({ key: true, value: 'commit', commit: { type: 'customers/SET_CUSTOMERS_ASYNC', payload: data.data } });
                 dbFunctions.setCustomers(data.data)
                 postMessage({ key: true, value: 'unload' + i++ });
             }).catch((error) => {
                 postMessage({ key: true, value: 'error', error: error.message });
             });

             asyncRequest.makeGetRequest({
                 what: "queue",
                 useToken: true,
                 showLoader: false,
                 params: {
                     merchID: user.merchID,
                     branchID: user.branchID,
                     orderstatus: 'paylater'
                 }
             }, token).then(({ data }) => {
                 postMessage({ key: true, value: 'commit', commit: { type: 'orders/SET_ORDERS', payload: data.data } });
                 postMessage({ key: true, value: 'unload' + i++ });
             }).catch((error) => {
                 postMessage({ key: true, value: 'error', error: error.message });
             });


             asyncRequest.makeGetRequest({
                 what: "getProducts",
                 useToken: true,
                 showLoader: false,
                 params: {
                     merchID: user.merchID,
                     branchID: user.branchID,
                     page: 1,
                     updated_at: event.data.message.updated_at,
                 }
             }, token).then(({ data }) => {
                 if (event.data.message.updated_at != '') {
                     dbFunctions.syncProducts(data.data)
                         // postMessage({ key: true, value: 'commit', commit: { type: 'products/SET_PRODUCTS_ASYNC', payload: data.data } });
                 } else {
                     dbFunctions.setProducts(data.data)
                         // postMessage({ key: true, value: 'commit', commit: { type: 'products/SET_PRODUCTS_ALL', payload: data.data } });
                 }
                 let result = data.data
                 result.map((category) => category.header.data).reduce((acc, curr) => { acc.push(...curr); return acc }, []).forEach((product) => {

                     if (product.photo != '') {
                         fetch(product.photo, {
                             mode: 'no-cors' // 'cors' by default
                         });
                     } else if (product.photo2 != '') {
                         fetch(product.photo2, {
                             mode: 'no-cors' // 'cors' by default
                         });
                     } else if (product.photo3 != '') {
                         fetch(product.photo3, {
                             mode: 'no-cors' // 'cors' by default
                         });
                     } else if (product.photo4 != '') {
                         fetch(product.photo4, {
                             mode: 'no-cors' // 'cors' by default
                         });
                     }
                 })
                 postMessage({ key: true, value: 'unload' + i++ });
             }).catch((error) => {
                 postMessage({ key: true, value: 'error', error: error.message });
             });


         }
     },


     async checkOnlineStatus() {
         return new Promise(async(resolve, reject) => {
             let isIOS = this.ios();
             if (!isIOS) {
                 if (navigator.connection.rtt > 500) {
                     resolve(false); // slow internet
                 } else if (!navigator.onLine && navigator.connection.rtt == 0) {
                     resolve(false); // definitely offline
                 } else if (navigator.onLine && navigator.connection.rtt == 0) {
                     resolve(true); // definitely offline
                 } else {
                     resolve(true);
                 }
             } else {
                 try {
                     //  const online = await fetch('https://wajeloyalty.website/API/networkstatus/');
                     const online = await fetch('https://api.ntisa.com.ng/API/networkstatus/');
                     resolve(online.status >= 200 && online.status < 300); // either true or false
                 } catch (err) {
                     resolve(false); // definitely offline
                 }

             }


         });
     },
     ios() {
         return (
             [
                 "iPad Simulator",
                 "iPhone Simulator",
                 "iPod Simulator",
                 "iPad",
                 "iPhone",
                 "iPod",
             ].includes(navigator.platform) ||
             // iPad on iOS 13 detection
             (navigator.userAgent.includes("Mac") && "ontouchend" in document)
         );
     },
     async checkNetwork() {
         let prev_network_status = await dbFunctions.getNetworkStatus()
         return prev_network_status;

     },
     shouldGo(what) {
         // emailreceipt: 'emailreceipt/'
         return ['password',
             'deliveryorder', 'assignrider', 'calculatedelivery',
             'cancelorder', 'processpickup', 'getRiders', 'getRewards', 'emailreceipt', 'redeem'
         ].includes(what);
         // return this.checkOnlineStatus && 
     },


 }