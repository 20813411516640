import store from "./store";
import Request from "./Request";
import Vue from "vue";
import Sync from '@/workers/Sync'
import checkOnline from "@/checkOnline.js";

export default {
    name: "Utils",

    async hashCode(message) {
        const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
        const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8); // hash the message
        const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
        const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
        return hashHex;
    },

    checkShouldStressSync(request_store) {
        if (['createcustomer', 'addToQueue', 'order', 'mergequeue'].includes(request_store.what) && request_store.data != undefined) {
            return request_store.what;
        }
        return false;
    },

    async setCustomer(order) {
        let customer = null;
        if (order.customerID != null && order.customerID != "") {

            customer = await store.dispatch('customers/getcustomer', order.customerID);
            order.firstname = customer.firstname
            order.lastname = customer.lastname
            order.phonenumber = customer.phonenumber
        }

        return order
    },

    getDate() {
        let date_ob = new Date();

        // adjust 0 before single digit date
        let date = ("0" + date_ob.getDate()).slice(-2);

        // current month
        let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);

        // current year
        let year = date_ob.getFullYear();

        // current hours
        let hours = date_ob.getHours();

        // current minutes
        let minutes = date_ob.getMinutes();

        // current seconds
        let seconds = date_ob.getSeconds();

        // prints date & time in YYYY-MM-DD HH:MM:SS format
        return year + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds;
    },

    setOrderItems(order) {
        if (order.orders != undefined) {
            order.orderitems = order.orders

            let res = order.orderitems.map(e => {
                if (e.price != undefined) {
                    e.unitprice = e.price
                    e.productID = e.productid
                    e.totalprice = e.totalamount
                    e.discountprice = e.discountprice
                        //   e.name = e.productname
                    if (e.name != undefined) {
                        e.itemname = e.name
                    }
                    if (e.itemname != undefined) {
                        e.name = e.itemname
                    }
                    //   e.image = e.photo
                }
                return e
            });

            order.orderitems = res
        }

        //   console.log(order);
        return order;
    },

    async updateProductQuantity(order) {
        for (const item of order.orderitems) {
            await store.dispatch('products/reduceQuantity', item);
        }
    },

    isPutRequest(req) {
        return ['editProduct'].includes(req.what)
    },

    isDeleteRequest(req) {
        return ['addresses'].includes(req.what)
    },

    async checkOnlineStatus() {
        let isIOS = this.ios();
        if (!isIOS) {
            if (navigator.connection.rtt > 500) {
                return false; // slow internet
            } else if (!navigator.onLine && navigator.connection.rtt == 0) {
                return false; // definitely offline
            } else if (navigator.onLine && navigator.connection.rtt == 0) {
                return true; // definitely offline
            } else {
                return true;
            }
        } else {
            try {
                const online = await fetch('https://api.ntisa.com.ng/API/networkstatus/');
                // const online = await fetch('https://wajeloyalty.website/API/networkstatus/');
                return online.status >= 200 && online.status < 300; // either true or false
            } catch (err) {

                return false; // definitely offline
            }
        }


    },
    ios() {
        return (
            [
                "iPad Simulator",
                "iPhone Simulator",
                "iPod Simulator",
                "iPad",
                "iPhone",
                "iPod",
            ].includes(navigator.platform) ||
            // iPad on iOS 13 detection
            (navigator.userAgent.includes("Mac") && "ontouchend" in document)
        );
    },
    shouldGo(what) {
        // emailreceipt: 'emailreceipt/'
        return ['password',
            'deliveryorder', 'assignrider', 'calculatedelivery',
            'cancelorder', 'processpickup', 'getRiders', 'getRewards', 'emailreceipt', 'redeem'
        ].includes(what);
        // return this.checkOnlineStatus && 
    },

    async syncRequests() {

        let shouldFetch = !(await store.dispatch('posts/shouldFetch'));
        if (!store.state.posts.active && store.getters.isLoggedIn && shouldFetch) {
            store.commit('posts/SET_STATUS', true)
            var loading_html =
                '<div style="height:150px;width:150px;margin: 0 auto;"><img style="width: 100%;" src="https://trails.ca/wp-content/uploads/2020/10/loading-spinner.gif" /></div>';

            try {
                await store.dispatch('loginSync')

                Vue.prototype.$swal.fire({
                    title: "Sync Has Started, Hold On till Complete",
                    html: loading_html,
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    showCancelButton: false
                });

                await store.dispatch('posts/makePosts')
                store.commit('posts/SET_STATUS', false)
                Vue.prototype.$swal.fire("Success", "Sync Completed Successfully", "success");
                Vue.prototype.$swal.close()


                if (store.state.load == false) {
                    Sync.send({ load: true, data: store.getters.user, token: store.getters.token, run: shouldFetch, updated_at: store.getters.updated_at })
                }
                // await checkOnline.download({ data: { message: { load: true, data: store.getters.user, token: store.getters.token, run: shouldFetch, updated_at: store.getters.updated_at } } })

            } catch (error) {
                store.commit('posts/SET_STATUS', false)
                Vue.prototype.$swal.close()
                console.log(error)

                // Vue.prototype.$swal.fire("Sync Failed", error, "error");
                Vue.prototype.$toasted.show("Sync Failed, will try again later.", {
                    theme: "bubble",
                    type: "error",
                    position: "top-right",
                    duration: 6000,
                    icon: {
                        name: 'exclamation-triangle',
                        after: true
                    }
                });

            }


        }


    },


}