/*
	This script make requests through a NodeJS proxy.
	The other variation of this script makes requests directly to the URL defined.

*/

import Vue from "vue";
import store from "./store";
import router from './router'
import axios from 'axios'
import Utils from '@/utils.js'
axios.defaults.timeout = 180000000000000000000000000000;
//test
// const URL = "http://68.65.121.57:8080/API/";
const URL = "https://wajeloyalty.website/API/";

//deployment
// const URL = "http://162.0.233.96/API/";
// const URL = "http://127.0.0.1:8000/API/";
// live
// const URL = "https://api.ntisa.com.ng/API/";


//report server
const url_for_order_history = "https://ntisareportserver.website/"


axios.interceptors.request.use(function(config) {
    // Do something before request is sent
    return config;
}, function(error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
// axios.interceptors.response.use(function(response) {
//     return response;
// }, async function(error) {
//     console.log(error)
//     if (401 === error.response.status) {
//         await store.dispatch('logoutUser').catch((error) => { console.log(error) })

//         this.$router.push('/')
//     } else {
//         return Promise.reject(error);
//     }
// });

export default {
    name: "Request",

    closeLoader() {
        if (Vue.prototype.$swal.isVisible()) {
            Vue.prototype.$swal.close()
        }
    },

    makeGetRequest: (request, request_token) => {
        if ((request.showLoader || request.showLoader == undefined) && Vue.prototype.$swal != undefined) {
            if (!Vue.prototype.$swal.isVisible()) {
                var loading_html =
                    '<div style="height:50px;width:50px;margin: 0 auto;"><img style="width: 100%;" src="https://trails.ca/wp-content/uploads/2020/10/loading-spinner.gif" /></div>';

                Vue.prototype.$swal.fire({
                    title: "",
                    html: loading_html,
                    showConfirmButton: false,
                    showCancelButton: false,
                    allowOutsideClick: true,
                    allowEscapeKey: true,
                    showCancelButton: false,
                    width: "150px",

                });
            }
        }


        var request_urls = {
            login: 'user/',
            endday: 'endday/',
            is_endday_active: 'is_endday_active/',
            getOrders: 'onlineplaceorder/',
            getProducts: 'listmenu/',
            getHistory: 'placeorder/',
            getRiders: 'rider/',
            getCustomers: 'offlinecustomer/',
            searchCustomer: 'customerrecord/',
            barcodescan: 'barcodesearch/',
            summary: 'storeattendantdailyreport/',
            getUsers: 'merchantusers/',
            queue: 'queueorder/',
            merchantsubscription: 'merchantsubscription/',
            networkstatus: 'networkstatus/',
            verifypromocode: 'verifypromocode/',
            tax_rewards: 'tax-and-reward/',
            tax: 'taxrate/',
            getRewards: 'rewardloyalty/',
            caldiscount: 'orderdiscountfee/'

        };
        if (request.params !== undefined) {
            var obj = request.params;
            var str = Object.keys(obj)
                .map(key => {
                    return key + "=" + obj[key];
                })
                .join("&");
            // if (request_urls[request.what] == 'getHistory'){
            //     var request_url = url_for_order_history + request_urls[request.what] + "?" + str
            // }
            // else{
            //     var request_url = URL + request_urls[request.what] + "?" + str
            // }
            var request_url = URL + request_urls[request.what] + "?" + str;
        }
        //  else if (request_urls[request.what] == 'getHistory') {
        //     var request_url = url_for_order_history + request_urls[request.what] + "" + request.id;
        // }
        else if (request.id) {
            var request_url = URL + request_urls[request.what] + "" + request.id;
        } else {
            var request_url = URL + request_urls[request.what];
        }

        // request.headers = { 'Cache-Control': 'no-cache' };
        request.headers = {}
        if (request.formData || request.useToken) {
            if (request.formData) {
                request.headers = {
                    'Content-Type': 'multipart/form-data'
                }
            }
            if (request.useToken || request.useToken == undefined) {
                var token;
                if (request_token != undefined) {
                    token = request_token;
                } else {
                    token = store.getters.token;
                }
                request.headers.Authorization = "Bearer " + token
            }
        }
        var config = {};



        if (request.headers !== undefined) {
            config.headers = request.headers;
        }
        return new Promise(async(resolve, reject) => {
            let online = await Utils.checkOnlineStatus();
            if (request.what == 'login') online = navigator.onLine;
            if (online || Utils.shouldGo(request.what) || (request.what == 'getOrders') || (request.what == 'queue' && request.params.orderstatus == 'assigned_to_kitchen')) {

                axios
                    .get(request_url, config)
                    .then(response => {

                        if (response.status == "true" || response.status) {
                            var response = {
                                data: response.data,
                                type: request.what,
                                status: "true"
                            };
                            // if (Vue.prototype.$swal != undefined && store.state.load == false && store.getters['posts/status'] == false) {
                            //     Vue.prototype.$swal.close()
                            // }
                            resolve(response);
                        } else {
                            reject(response.data.message);
                        }
                    })
                    .catch(err => {

                        console.log(err)

                        // if (Vue.prototype.$swal != undefined) {
                        //     Vue.prototype.$swal.close()
                        // }
                        if (!err.response || err.code === 'ECONNABORTED') {
                            // vm.closeLoader();
                            // resolve({data: { status: true}}) 
                            reject({ message: "Please check your internet connection. This page does not work without internet" });
                        } else {
                            // vm.closeLoader();
                            reject(err);
                        }
                    });
            } else {
                if (Vue.prototype.$swal != undefined) {
                    Vue.prototype.$swal.close()
                }

                // vm.closeLoader(); 
                if (request.what == 'login') {
                    if (store.getters.token !== null) {
                        resolve({
                            data: {
                                status: true,
                                token: store.getters.token,
                            }
                        })
                    } else {
                        reject('Please check your internet connection and try again');
                    }

                } else {
                    resolve({ data: { status: true } })
                }
            }


        });
    },
    makeRequest: (request, request_token) => {
        if ((request.showLoader || request.showLoader == undefined) && Vue.prototype.$swal != undefined) {
            if (!Vue.prototype.$swal.isVisible()) {
                var loading_html =
                    '<div style="height:50px;width:50px;margin: 0 auto;"><img style="width: 100%;" src="https://trails.ca/wp-content/uploads/2020/10/loading-spinner.gif" /></div>';

                Vue.prototype.$swal.fire({
                    title: "",
                    html: loading_html,
                    showConfirmButton: false,
                    showCancelButton: false,
                    allowOutsideClick: true,
                    allowEscapeKey: true,
                    showCancelButton: false,
                    width: "150px",

                });
            }
        }


        var request_urls = {
            endday: 'endday/',
            is_endday_active: 'is_endday_active/',
            getOrders: 'onlineplaceorder/',
            getProducts: 'listmenu/',
            getHistory: 'placeorder/',
            getRiders: 'rider/',
            getCustomers: 'offlinecustomer/',
            searchCustomer: 'customerrecord/',
            tax_rewards: 'tax-and-reward/',
            getRewards: 'rewardloyalty/',
            barcodescan: 'barcodesearch/',
            summary: 'storeattendantdailyreport/',
            getUsers: 'merchantusers/',
            queue: 'queueorder/',
            merchantsubscription: 'merchantsubscription/',
            verifypromocode: 'verifypromocode/',
            tax: 'taxrate/',
            caldiscount: 'orderdiscountfee/'
        };
        if (request.params !== undefined) {
            var obj = request.params;
            var str = Object.keys(obj)
                .map(key => {
                    return key + "=" + obj[key];
                })
                .join("&");
            // if (request_urls[request.what] == 'getHistory'){
            //     var request_url = url_for_order_history + request_urls[request.what] + "?" + str
            // }
            // else{
            //     var request_url = URL + request_urls[request.what] + "?" + str
            // }
            var request_url = url_for_order_history + request_urls[request.what] + "?" + str;
        }
        //  else if (request_urls[request.what] == 'getHistory') {
        //     var request_url = url_for_order_history + request_urls[request.what] + "" + request.id;
        // }
        else if (request.id) {
            var request_url = url_for_order_history + request_urls[request.what] + "" + request.id;
        } else {
            var request_url = url_for_order_history + request_urls[request.what];
        }

        // request.headers = { 'Cache-Control': 'no-cache' };
        request.headers = {}
        if (request.formData || request.useToken) {
            if (request.formData) {
                request.headers = {
                    'Content-Type': 'multipart/form-data'
                }
            }
            if (request.useToken || request.useToken == undefined) {
                var token;
                if (request_token != undefined) {
                    token = request_token;
                } else {
                    token = store.getters.token;
                }
                request.headers.Authorization = "Bearer " + token
            }
        }
        var config = {};



        if (request.headers !== undefined) {
            config.headers = request.headers;
        }
        return new Promise(async(resolve, reject) => {
            let online = await Utils.checkOnlineStatus();
            let vm = this;

            // console.log(online);
            if (online || Utils.shouldGo(request.what)) {
                // console.log(request.what)
                axios
                    .get(request_url, config)
                    .then(response => {

                        if (response.status == "true" || response.status) {
                            var response = {
                                data: response.data,
                                type: request.what,
                                status: "true"
                            };
                            // if (Vue.prototype.$swal != undefined && store.state.load == false && store.getters['posts/status'] == false) {
                            //     Vue.prototype.$swal.close()
                            // }
                            resolve(response);
                        } else {
                            reject(response.data.message);
                        }
                    })
                    .catch(err => {

                        // console.log(err)
                        // if (Vue.prototype.$swal != undefined) {
                        //     Vue.prototype.$swal.close()
                        // }
                        if (!err.response || err.code === 'ECONNABORTED') {
                            // vm.closeLoader();
                            // resolve({data: { status: true}})


                            reject({ message: "Please check your internet connection. This page does not work without internet" });
                        } else {
                            // vm.closeLoader();
                            reject(err);
                        }
                    });
            } else {
                if (Vue.prototype.$swal != undefined) {
                    Vue.prototype.$swal.close()
                }

                // vm.closeLoader();

                resolve({ data: { status: true } })
            }

            // vm.closeLoader()



        });
    },

    async makePostRequest(request, request_token) {
        let request_store = Object.assign({}, request);

        if ((request.showLoader || request.showLoader == undefined) && Vue.prototype.$swal != undefined) {
            var loading_html =
                '<div style="height:50px;width:50px;margin: 0 auto;"><img style="width: 100%;" src="https://trails.ca/wp-content/uploads/2020/10/loading-spinner.gif" /></div>';


            Vue.prototype.$swal.fire({
                title: "",
                width: 200,
                html: loading_html,
                showConfirmButton: false,
                showCancelButton: false,
                width: "100px",

            });
        }
        var request_urls = {
            password: 'userpassword/',
            endday: 'endday/',
            createcustomer: 'customerrecord/',
            order: 'placeweborder/',
            onlinedeliveryorder: 'placeorder/',
            deliveryorder: 'placewebdeliveryorder/',
            assignrider: 'assignorder/',
            calculatedelivery: 'deliveryfeecal/',
            onlinedeliveryfee: 'otherdeliveryfeecal/',
            redeem: 'redeemreward/',
            addstock: 'addstocks/',
            cancelorder: 'deliverystatus/',
            setProduct: 'setproduct/',
            processpickup: 'deliverystatus/',
            addToQueue: 'queueorder/',
            completequeueorder: 'changeorderstatus/',
            mergequeue: 'mergeorderstatus/',
            emailreceipt: 'emailreceipt/',
            syncorder: 'place-web-order/'

        };
        // let hash = await Utils.hashCode(JSON.stringify(request_store));


        if (request.key) {
            var request_url = URL + request_urls[request.what] + request.key;
        } else {
            var request_url = URL + request_urls[request.what];
            request_url += request.id == undefined ? "" : "" + request.id;
        }
        // request.headers = { 'Cache-Control': 'no-cache' };
        request.headers = {}
        if (request.formData || request.useToken) {
            if (request.formData) {
                request.headers = {
                    'Content-Type': 'multipart/form-data'
                }
            }
            if (request.useToken || request.useToken == undefined) {
                var token;
                if (request_token != undefined) {
                    token = request_token;
                } else {
                    token = store.getters.token;
                }
                request.headers.Authorization = "Bearer " + token
            }
        }
        var config = {};

        if (request.headers !== undefined) {
            config.headers = request.headers;
        }

        let vm = this;


        return new Promise(async(resolve, reject) => {
            let online = await Utils.checkOnlineStatus();
            if (request.what == 'endday') online = navigator.onLine;
            if (online || Utils.shouldGo(request.what)) {
                axios
                    .post(request_url, request.data, config)
                    .then(response => {
                        if (Vue.prototype.$swal != undefined && request.showLoader) {
                            Vue.prototype.$swal.close()
                        }

                        if (response.data.status == "true" || response.data.status || response.success == "true") {
                            response = {
                                data: response.data,
                                type: request.what,
                                status: "true"
                            };
                            // vm.closeLoader();
                            resolve(response);
                        } else {
                            // vm.closeLoader();
                            reject(response.data.message);
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        if (Vue.prototype.$swal != undefined) {
                            Vue.prototype.$swal.close()
                        }
                        // console.log(err)
                        if (!err.response || err.code === 'ECONNABORTED') {
                            // vm.closeLoader();
                            // resolve({data: { status: true}})


                            reject({ message: "Please check your internet connection. This function does not work without internet" });
                        } else {
                            // vm.closeLoader();
                            reject(err);
                        }

                        // vm.closeLoader();
                        // reject(err);
                    });
            } else {
                // if (Vue.prototype.$swal.isVisible()) {
                if (Vue.prototype.$swal != undefined) {
                    Vue.prototype.$swal.close()
                }
                // }
                request_store.showLoader = false;

                var date = new Date();
                var res = new Date(date.toISOString().replace("Z", "+00:00")).toISOString().replace('T', ' ').replace(".000", "");
                request_store.data.created_at = res;
                store.dispatch('posts/addPost', request_store).then((hash_data) => {
                    if (Utils.checkShouldStressSync(request_store)) {
                        request_store.data.id = hash_data.hash;
                    }
                    // vm.closeLoader();

                    resolve({ data: { status: true, req: request_store }, })
                }).catch(err => {
                    // console.log(err)
                    // vm.closeLoader();
                    reject(err);
                });
            }

            // vm.closeLoader()

            // this.closeLoader();

        });
    },



    editItem: (request, request_token) => {

        let request_store = request;
        let vm = this;

        if ((request.showLoader || request.showLoader == undefined) && Vue.prototype.$swal != undefined) {
            var loading_html =
                '<div style="height:50px;width:50px;margin: 0 auto;"><img style="width: 100%;" src="https://trails.ca/wp-content/uploads/2020/10/loading-spinner.gif" /></div>';


            Vue.prototype.$swal.fire({
                title: "",
                html: loading_html,
                showConfirmButton: false,
                showCancelButton: false,
                width: "150px",

            });
        }
        var request_urls = {
            profile: 'merchantusers/',
            editProduct: 'product/',
            editStatus: 'changeorderstatus/'
        };
        var request_url = URL + request_urls[request.what];
        request_url += request.id == undefined ? "" : "" + request.id + "/";

        // request.headers = { 'Cache-Control': 'no-cache' };
        request.headers = {}
        if (request.formData || request.useToken) {
            if (request.formData) {
                request.headers = {
                    'Content-Type': 'multipart/form-data'
                }
            }
            if (request.useToken || request.useToken == undefined) {
                var token;
                if (request_token != undefined) {
                    token = request_token;
                } else {
                    token = store.getters.token;
                }
                request.headers.Authorization = "Bearer " + token
            }
        }
        var config = {
            headers: request.headers
        };

        return new Promise(async(resolve, reject) => {
            let online = await Utils.checkOnlineStatus();
            if (!online && request.what == 'editProduct') {
                if (Vue.prototype.$swal != undefined) {
                    Vue.prototype.$swal.close()
                }
                request_store.showLoader = false;
                store.dispatch('posts/addPost', request_store).then(() => {
                    // vm.closeLoader();

                    resolve({ data: { status: true } })
                }).catch(err => {
                    // vm.closeLoader();
                    reject(err);
                });
            } else {
                axios
                    .put(request_url, request.data, config)
                    .then(response => {
                        // vm.closeLoader();
                        if (Vue.prototype.$swal != undefined) {
                            Vue.prototype.$swal.close()
                        }
                        if (response.data.status == "true" || response.data.status) {
                            response = {
                                data: response.data,
                                type: request.what,
                                status: "true"
                            };
                            resolve(response)
                        } else {
                            reject('error')
                        }
                    })
                    .catch(err => {
                        if (Vue.prototype.$swal != undefined) {
                            Vue.prototype.$swal.close()
                        }
                        // console.log(err)
                        if (!err.response || err.code === 'ECONNABORTED') {
                            // vm.closeLoader();
                            // resolve({data: { status: true}})


                            reject({ message: "Please check your internet connection. This function does not work without internet" });
                        } else {
                            // vm.closeLoader();
                            reject(err);
                        }
                        // reject(err)
                    });
            }

            // vm.closeLoader()

            // this.closeLoader();

        });
    },
    // deleteItem: (request) => {

    //     let request_store = request;

    //     let vm = this;


    //     if ((request.showLoader || request.showLoader == undefined) && Vue.prototype.$swal != undefined) {
    //         if (!Vue.prototype.$swal.isVisible()) {
    //             var loading_html = '<div style="height:150px;width:150px;margin: 0 auto;"><img style="width: 100%;" src="https://i.ya-webdesign.com/images/minimalist-transparent-loading-gif-6.gif" /></div>'

    //             Vue.prototype.$swal.fire({
    //                 title: "",
    //                 html: loading_html,
    //                 showConfirmButton: false,
    //                 showCancelButton: false
    //             })
    //         }
    //     }

    //     var request_urls = {
    //         deleteaddress: 'addresses',
    //     }

    //     var request_url = URL + request_urls[request.what]

    //     request_url += (request.id == undefined) ? "" : "/" + request.id

    //     var config = {
    //         headers: {

    //         },
    //         data: request.data
    //     }

    //     return new Promise(async(resolve, reject) => {

    //         // let online = await Utils.checkOnlineStatus();
    //         // if(online) {
    //         axios.delete(request_url, config)
    //             .then((response) => {
    //                 if (Vue.prototype.$swal != undefined) {
    //                     Vue.prototype.$swal.close()
    //                 }

    //                 // this.closeLoader();


    //                 if (response.data.status == 'true' || response.data.status) {

    //                     response = {
    //                             data: response.data,
    //                             type: request.what,
    //                             status: 'true'
    //                         }
    //                         // vm.closeLoader();

    //                     resolve(response)
    //                 } else {
    //                     // vm.closeLoader();

    //                     reject(response)
    //                 }
    //             })
    //             .catch((err) => {
    //                 if (Vue.prototype.$swal != undefined) {
    //                     Vue.prototype.$swal.close()
    //                 }
    //                 // console.log(err)
    //                 if (!err.response || err.code === 'ECONNABORTED') {
    //                     // vm.closeLoader();
    //                     // resolve({data: { status: true}})


    //                     reject({ message: "Please check your internet connection. This function does not work without internet" });
    //                 } else {
    //                     // vm.closeLoader();
    //                     reject(err);
    //                 }
    //                 // vm.closeLoader();
    //                 reject(err)
    //             })
    //             // } else {
    //             //     request_store.showLoader = false;
    //             //     // if (Vue.prototype.$swal.isVisible()) {
    //             //         if(Vue.prototype.$swal != undefined) {
    //             //             Vue.prototype.$swal.close()
    //             //         }
    //             //     // }
    //             //     store.dispatch('posts/addPost', request_store).then(() => {
    //             //         // vm.closeLoader();
    //             //         resolve({data: { status : true}})
    //             //     }).catch(err => {
    //             //         // vm.closeLoader();
    //             //         reject(err);
    //             //     });
    //             // }

    //         // vm.closeLoader()

    //     })


    // }
};