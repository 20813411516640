<template>
  <div id="history">
    <Sidemenu />
    <div id="main">
      <Topmenu />

      <div class="filter-container">
        <div class="row h-100 mx-1">
          <div class="col-xl-5 col-lg-4 my-auto filter-title">
            <div class="d-flex">
              <h5 class="title mr-2">Orders list</h5>
              <p class="subtitle pl-2 mr-2">{{ historydata.length }} Orders</p>
              <p class="subtitle pl-2">This page shows a list of the 30 recent orders</p>
            </div>
          </div>
          <div
            v-if="network"
            class="col-xl-7 col-lg-7 my-auto filter-form text-right"
          >
            <date-picker
              @change="getReport"
              type="datetime"
              v-model="date"
              :placeholder="'Today'"
              timeTitleFormat="YYYY-MM-DD hh:mm a"
              format="YYYY-MM-DD hh:mm a"
              value-type="format"
              :show-second="false"
              :show-time-panel="showTimeRangePanel"
              @close="handleRangeClose"
            >
              <template v-slot:footer>
                <button
                  class="mx-btn mx-btn-text"
                  @click="toggleTimeRangePanel"
                >
                  {{ showTimeRangePanel ? "select date" : "select time" }}
                </button>
              </template>
            </date-picker>
            <!-- <div class="d-flex justify-content-between flex-wrap">
            <div
              id="search"
              class="my-auto "
            >
              <input
                v-model="search"
                class="form-control "
                type="text"
                placeholder="Search by customer’s name and order code"
              >
              <span class="fa fa-search"></span>
            </div>
            <select
              name=""
              id=""
              v-model="filter"
              class="form-control  my-auto"
            >
              <option
                value=""
                selected
                hidden
              >Filter by status</option>
              <option value="">All</option>
              <option value="PENDING">Pending</option>
              <option>Dispatched</option>
              <option>Scheduled</option>
              <option>Order Accepted</option>
              <option value="COMPLETED">Completed</option>
            </select>
         
          </div> -->
          </div>
        </div>
      </div>

      <div class="main-content mr-sm-3 my-4">
        <div class="card m-sm-4 p-2">
          <v-client-table
            :data="historydata"
            :columns="columns"
            :options="options"
            slots="slots"
            @row-click="viewOrder"
          >
            <!-- 
            <span
              slot="id"
              slot-scope="props"
              class="d-flex cus-img flex-wrap"
            >
              <img
                v-if="props.row.profilelink ==null || props.row.profilelink ==''"
                class="mr-2 img-fluid img-responsive"
                src="../assets/img/customer-avatar.png"
                alt=""
              >
              <img
                v-else
                class="mr-2 img-fluid img-responsive"
                :src="props.row.profilelink"
                alt=""
              >
            </span> -->

            <span
              slot="customer"
              v-if="props.row.firstname !== ''"
              slot-scope="props"
              >{{ props.row.firstname }} {{ props.row.lastname }}</span
            >
            <span slot="customer" v-else>N/A</span>
            <!-- <span
              slot="phonenumber"
              slot-scope="props"
            >
              <span v-if="props.row.phonenumber !== ''">
                {{props.row.phonenumber}}</span>
              <span v-else>N/A</span>
            </span> -->
            <span
              v-if="businesscategory !== 'Delivery'"
              slot="ordertotal"
              slot-scope="props"
            >
              <span v-if="!isNaN(props.row.ordercode)"
                >₦
                {{
                  formatPrice(
                    Number(props.row.ordertotal) +
                      (props.row.vat ? Number(props.row.vat) : 0)
                  )
                }}</span
              >
              <span v-else
                >₦
                {{
                  formatPrice(
                    Number(props.row.ordertotal) +
                      (props.row.deliverycharge != undefined
                        ? Number(props.row.deliverycharge)
                        : 0)
                  )
                }}</span
              >
            </span>
            <span v-else slot="ordertotal" slot-scope="props">
              <span
                >₦
                {{
                  formatPrice(
                    Number(props.row.ordertotal) +
                      (props.row.vat ? Number(props.row.vat) : 0)
                  )
                }}</span
              >
            </span>
            <span
              v-if="
                props.row.paymentdetails != undefined &&
                props.row.paymentdetails.length > 0
              "
              slot="payment_option"
              slot-scope="props"
            >
              <p v-for="(i, index) in props.row.paymentdetails" :key="index">
                <span v-if="i.paymentmethod == 'thirdparty'">{{
                  i.paymentaccountname
                }}</span>
                <span v-else>{{ i.paymentmethod }}</span> <br />
                <!-- <span class="value" v-if="i.paymentmethod !== 'banktransfer' && i.paymentmethod !== 'pos' && i.paymentmethod !== 'cash'">{{i.paymentaccountname}}</span>
              <span class="value" v-else>{{i.paymentmethod}}</span><br> -->
              </p>
            </span>
            <span slot="payment_option" v-else>N/A</span>
            <span slot="rider" slot-scope="props">
              <span v-if="props.row.riderID !== null">
                {{ props.row.riderfirstname }}
                {{ props.row.riderlastname }}</span
              >
              <span v-else>N/A</span>
            </span>

            <p slot="orderstatus" slot-scope="props">
              <span
                v-if="
                  props.row.orderstatus == 'completed' ||
                  props.row.orderstatus == 'COMPLETED'
                "
                class="badge badge-pill badge-delivered"
              >
                {{ props.row.orderstatus }}</span
              >
              <span
                v-else-if="
                  props.row.orderstatus == 'pending' ||
                  props.row.orderstatus == 'paylater'
                "
                class="badge badge-pill badge-pending"
              >
                {{ props.row.orderstatus }}</span
              >
              <span
                v-else-if="props.row.orderstatus == 'ready_for_dispatch'"
                class="badge badge-pill badge-delayed"
              >
                {{ props.row.orderstatus }}</span
              >
              <span
                v-else-if="props.row.orderstatus == 'in_transit'"
                class="badge badge-pill badge-accepted"
              >
                {{ props.row.orderstatus }}</span
              >
              <span
                v-else-if="props.row.orderstatus == 'cancel'"
                class="badge badge-pill badge-cancelled"
              >
                {{ props.row.orderstatus }}</span
              >
              <span
                v-else-if="
                  props.row.orderstatus == 'assigned' ||
                  props.row.orderstatus == 'assigned_to_kitchen'
                "
                class="badge badge-pill badge-scheduled"
              >
                {{ props.row.orderstatus }}</span
              >
              <span v-else class="badge badge-pill">
                {{ props.row.orderstatus }}</span
              >
            </p>

            <span slot="transactiondate" slot-scope="props">{{
              formatDate(props.row.transactiondate)
            }}</span>
            <span
              class="d-flex m-auto openorder"
              slot="action"
              slot-scope="props"
              title="View Order Details"
            >
              <i
                style="font-size: 18px"
                class="fa fa-eye m-auto text-success"
              ></i>
            </span>
          </v-client-table>
        </div>
      </div>
    </div>

    <!-- order modal -->

    <div
      v-if="Object.keys(order).length > 0"
      id="ordermodal"
      class="modal"
      tabindex="-1"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header mx-sm-5">
            <h5 class="modal-title">
              Order Details
              <span
                v-if="
                  order.orderstatus == 'completed' ||
                  order.orderstatus == 'COMPLETED'
                "
                class="badge badge-pill badge-delivered"
              >
                {{ order.orderstatus }}</span
              >
              <span
                v-else-if="
                  order.orderstatus == 'pending' ||
                  order.orderstatus == 'paylater'
                "
                class="badge badge-pill badge-pending"
              >
                {{ order.orderstatus }}</span
              >
              <span
                v-else-if="order.orderstatus == 'ready_for_dispatch'"
                class="badge badge-pill badge-delayed"
              >
                {{ order.orderstatus }}</span
              >
              <span
                v-else-if="order.orderstatus == 'in_transit'"
                class="badge badge-pill badge-accepted"
              >
                {{ order.orderstatus }}</span
              >
              <span
                v-else-if="order.orderstatus == 'cancel'"
                class="badge badge-pill badge-cancelled"
              >
                {{ order.orderstatus }}</span
              >
              <span
                v-else-if="
                  order.orderstatus == 'assigned' ||
                  order.orderstatus == 'assigned_to_kitchen'
                "
                class="badge badge-pill badge-scheduled"
              >
                {{ order.orderstatus }}</span
              >

              <span v-else class="badge badge-pill">
                {{ order.orderstatus }}</span
              >
            </h5>
            <img
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              src="../assets/img/close.png"
              alt=""
            />
          </div>
          <div class="modal-header mx-sm-5">
            <button @click.prevent="handleReceipt" class="btn my-auto">
              Print Receipt <i class="fa fa-print"></i>
            </button>

            <span class="value">{{ formatDate(order.transactiondate) }}</span>
          </div>

          <div class="modal-body mx-sm-5">
            <div
              class="orderinfo d-flex justify-content-between flex-wrap my-4"
            >
              <div>
                <h5 class="heading">Order ID</h5>
                <p class="id">{{ order.ordercode }}</p>
                <p class="status">{{ order.ordertype }}</p>
              </div>
              <div class="d-flex ml-3">
                <div class="m-auto cus-img">
                  <img
                    v-if="order.profilelink == null"
                    src="../assets/img/avatar.png"
                    alt=""
                    class="img-fluid img-responsive"
                  />
                  <img
                    v-else
                    :src="order.profilelink"
                    alt=""
                    class="img-fluid img-responsive"
                  />
                </div>
                <div class="ml-2">
                  <h5 class="heading">Customer info</h5>
                  <p class="value">
                    {{ order.firstname }} {{ order.lastname }}
                  </p>
                  <p class="phone">
                    {{
                      order.customerIdentifier != undefined
                        ? order.customerIdentifier
                        : order.phonenumber
                    }}
                  </p>
                </div>
              </div>
              <div>
                <h5 class="heading">Order Method</h5>
                <p class="value">{{ order.ordermethod }}</p>
              </div>
            </div>
            <div
              class="orderinfo d-flex justify-content-between flex-wrap my-4"
            >
              <div v-if="order.deliveryaddress">
                <h5 class="heading">Delivery address</h5>
                <p class="value">{{ order.deliveryaddress }}</p>
              </div>

              <div
                v-if="
                  order.paymentdetails !== undefined &&
                  order.paymentdetails.length > 0
                "
              >
                <!-- <div> -->
                <h5 class="heading">Payment Details</h5>
                <p v-for="i in order.paymentdetails">
                  <!-- <span class="value" v-if="i.paymentmethod == 'cash'">{{i.paymentmethod}}</span> -->
                  <span
                    class="value"
                    v-if="
                      i.paymentmethod == 'banktransfer' ||
                      i.paymentmethod == 'pos' ||
                      i.paymentmethod == 'thirdparty'
                    "
                    >{{ i.paymentmethod }} ({{ i.paymentaccountname }})</span
                  >
                  <span class="value" v-else>{{ i.paymentmethod }}</span> -
                  &nbsp;&nbsp;&nbsp;
                  <span>₦ {{ formatPrice(i.paymentamount) }}</span>
                </p>
                <!-- </div> -->
              </div>

              <div>
                <h5 class="heading">Cashier</h5>
                <p class="value">{{ order.staffname }}</p>
              </div>
              <div v-if="order.deliveryinstruction !== ''">
                <h5 class="heading">Delivery Note</h5>
                <p class="value">{{ order.deliveryinstruction }}</p>
              </div>
              <!-- <div v-if="order.scheduledelivery !==''">
                <h5 class="heading">Scheduled delivery Time</h5>
                <p class="value">{{formatDate(order.scheduletime)}}</p>
              </div> -->
            </div>
            <div
              v-if="businesscategory == 'Delivery'"
              class="orderinfo d-flex justify-content-between flex-wrap my-4"
            >
              <div>
                <h5 class="heading">Sender</h5>
                <p class="value">{{ order.sendername }}</p>
                <p class="value">{{ order.senderphone }}</p>
              </div>
              <div>
                <h5 class="heading">Receiver</h5>
                <p class="value">{{ order.receivername }}</p>
                <p class="value">{{ order.receiverphonenumber }}</p>
              </div>
              <div>
                <h5 class="heading">Pickup address</h5>
                <p class="value">{{ order.pickupaddress }}</p>
              </div>
            </div>
            <table class="table my-5">
              <tbody>
                <tr
                  v-for="(row, ix) in order.orderitems"
                  v-bind:key="ix"
                  class="items"
                >
                  <td class>
                    <img
                      src="row.photo"
                      v-lazy="row.photo"
                      alt=""
                      class="orderimg img-fluid img-responsive"
                    />
                  </td>
                  <td>
                    <h5 class="product">{{ row.productname }}</h5>
                    <div v-if="row.discountprice">
                      <s class="old-price"
                        >₦ {{ formatPrice(row.unitprice) }}</s
                      >
                      <h6 class="discount-price-normal">
                        ₦ {{ formatPrice(row.discountprice) }}
                      </h6>
                    </div>
                    <div v-else>
                      <p class="price">₦ {{ formatPrice(row.unitprice) }}</p>
                    </div>
                  </td>
                  <td>
                    <p class="qty">{{ row.quantity }}</p>
                  </td>
                  <td class="subtotal">₦ {{ formatPrice(row.totalprice) }}</td>
                </tr>
              </tbody>
            </table>
            <hr />

            <table class="table table-borderless w-sm-50 float-right">
              <tbody v-if="businesscategory !== 'Delivery'">
                <tr>
                  <td>Sub total</td>
                  <td v-if="isNaN(order.ordercode)" class="text-right">
                    ₦
                    {{
                      formatPrice(
                        Number(order.ordertotal) -
                          (order.vat ? Number(order.vat) : 0) +
                          Number(order.discountpayment)
                      )
                    }}
                  </td>
                  <td v-else class="text-right">
                    ₦
                    {{
                      formatPrice(
                        Number(order.ordertotal) + Number(order.discountpayment)
                      )
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Discount</td>
                  <td class="text-right">
                    - ₦ {{ formatPrice(order.discountpayment) }}
                  </td>
                </tr>
                <tr>
                  <td>Total Tax</td>
                  <td class="text-right">
                    + ₦ {{ formatPrice(order.vat ? order.vat : 0) }}
                  </td>
                </tr>
                <tr>
                  <td>Delivery Charge</td>
                  <td class="text-right">
                    + ₦
                    {{
                      order.deliverycharge != undefined
                        ? formatPrice(order.deliverycharge)
                        : 0
                    }}
                  </td>
                </tr>
              </tbody>
              <hr />
              <tfoot v-if="businesscategory !== 'Delivery'">
                <tr>
                  <th scope="col">Total</th>
                  <th
                    v-if="isNaN(order.ordercode)"
                    scope="col"
                    class="text-right"
                  >
                    ₦
                    {{
                      formatPrice(
                        Number(order.ordertotal) +
                          (order.deliverycharge != undefined
                            ? Number(order.deliverycharge)
                            : 0)
                      )
                    }}
                  </th>
                  <th v-else scope="col" class="text-right">
                    ₦ {{ formatPrice(Number(order.ordertotal)) }}
                  </th>
                </tr>
              </tfoot>
              <tfoot v-else>
                <th scope="col">Total</th>
                <th scope="col" class="text-right">
                  ₦ {{ formatPrice(Number(order.ordertotal)) }}
                </th>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as $ from "jquery";
import Sidemenu from "@/components/sidemenu";
import Topmenu from "@/components/topmenu";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "History",
  components: {
    Sidemenu,
    Topmenu,
  },
  data() {
    return {
      search: "",
      taxes: [],
      filter: "",
      date: "",
      network: true,
      filter_date: "",
      businesscategory: "",
      all_history: [],
      historydata: [],
      filterText: "",
      showTimePanel: false,
      showTimeRangePanel: false,
      order: {},
      columns: [
        "customer",
        "ordercode",
        "ordertype",
        "ordertotal",
        "payment_option",
        "rider",
        "orderstatus",
        "transactiondate",
        "action",
      ],
      options: {
        headings: {
          customer: "Customer",
          ordercode: "OrderCode",
          ordertype: "OrderType",
          ordertotal: "Amount",
          rider: "Rider",
          payment_option: "Payment Method",
          orderstatus: "Order Status",
          transactiondate: "Order Date",
        },

        sortable: [
          "id",
          "customer",
          "ordercode",
          "ordertype",
          "ordertotal",
          "payment_option",
          "rider",
          "orderstatus",
          "transactiondate",
        ],
        filterable: [
          "id",
          "firstname",
          "lastname",
          "ordercode",
          "ordertype",
          "ordertotal",
          "payment_option",
          "rider",
          "orderstatus",
          "transactiondate",
        ],
        saveState: true,
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-long-arrow-up",
          down: "fa-long-arrow-down",
        },
      },
    };
  },
  created() {
    var date = new Date();
    var res = new Date(date.toISOString().replace("Z", "-01:00"))
      .toISOString()
      .split("T");
    this.fetchHistory(res[0]);
    this.businesscategory = this.$store.getters.businesscategory;
    this.getTaxes();
  },
  mounted() {
    let vm = this;
    vm.network =
      navigator.connection.rtt < 500 && navigator.connection.rtt !== 0
        ? true
        : false;
    navigator.connection.addEventListener("change", async () => {
      if (navigator.connection.rtt > 500) {
        vm.network = false;
      } else if (!navigator.onLine && navigator.connection.rtt == 0) {
        vm.network = false;
      } else if (navigator.onLine && navigator.connection.rtt == 0) {
        vm.network = true;
      } else {
        vm.network = true;
      }
    });
  },
  computed: {
    ...mapGetters({
      // 'all_history' : 'orderHistory',
      queue: "queue",
      // 'host' : 'host'
    }),
    filteredList() {
      return this.historydata.filter((order) => {
        if (
          new Date(order.transactiondate).getTime() >
            new Date(this.date[0]).getTime() &&
          new Date(order.transactiondate).getTime() <
            new Date(this.date[1]).getTime()
        ) {
        } else if (
          order.firstname.toLowerCase().includes(this.search.toLowerCase())
        ) {
          return order.firstname
            .toLowerCase()
            .includes(this.search.toLowerCase());
        } else if (
          order.lastname.toLowerCase().includes(this.search.toLowerCase())
        ) {
          return order.lastname
            .toLowerCase()
            .includes(this.search.toLowerCase());
        } else {
          return order.ordercode
            .toLowerCase()
            .includes(this.search.toLowerCase());
        }
      });
    },
  },
  watch: {
    filter: function (val) {
      let filteredHistory = [];
      if (val == "") {
        filteredHistory = this.all_history;
      } else {
        this.all_history.forEach((i) => {
          if (i.orderstatus == val) {
            filteredHistory.push(i);
          }
          // else
        });
      }
      this.historydata = filteredHistory;
      // console.log(filteredHistory)
    },
  },
  methods: {
    ...mapActions({
      index: "index",
      getHistory: "orders/getHistory",
      updateHistory: "orders/saveHistory",
      // 'host' : 'host'
    }),
    getTaxes() {
      let req = {
        what: "tax",
        useToken: true,
        params: {
          merchID: this.$store.getters.user.merchID,
        },
      };
      this.$request
        .makeGetRequest(req)
        .then((res) => {
          if (res.status == "true") {
            this.$store.commit("setTax", res.data.data);
            // this.taxes = this.$store.getters.taxes
          } else {
            this.$toasted.show(res.data.message, {
              theme: "bubble",
              type: "error",
              position: "top-right",
              duration: 6000,
            });
          }
        })
        .catch((error) => {
          this.is401(error.response.status);
          this.$toasted.show(error.message, {
            theme: "bubble",
            type: "error",
            position: "top-right",
            duration: 6000,
          });
        });
    },
    getReport() {
      if (this.date !== "" && this.date[0] != null) {
        this.filter_date = this.date.split(" ")[0];

        this.fetchHistory(this.filter_date);
      } else {
        this.filter_date = [];
      }
    },
    toggleTimePanel() {
      this.showTimePanel = !this.showTimePanel;
    },
    toggleTimeRangePanel() {
      this.showTimeRangePanel = !this.showTimeRangePanel;
      if (this.showTimeRangePanel) {
        // document.querySelector('.mx-table.mx-table-date thead').style.display = 'none'
        document.querySelectorAll(".mx-table.mx-table-date").forEach((el) => {
          el.style.display = "none";
        });
      } else {
        // document.querySelector('.mx-table.mx-table-date thead').style.display = 'none'
        document.querySelectorAll(".mx-table.mx-table-date").forEach((el) => {
          el.style.display = "";
        });
      }
    },
    handleOpenChange() {
      this.showTimePanel = false;
    },
    handleRangeClose() {
      this.showTimeRangePanel = false;
      const all = document.querySelectorAll(".mx-table.mx-table-date");
      if (all != null) {
        // all.style.display = 'none'
        all.forEach((el) => {
          el.style.display = "";
        });
      }
    },

    calculateBalance() {
      this.balance =
        Number(this.total_money) -
        (Number(this.total) + Number(this.calculateTaxTotal()));
      order.push(this.balance);
      // return this.balance
    },
    calculateTax(value, subtotal, discount) {
      let val = (this.discount + this.subtotal) * (Number(value) / 100);
      return Number(parseFloat(val).toFixed(2));
    },
    calculateTaxTotal() {
      if (this.taxes.length > 0) {
        let val = this.taxes
          .map((x) => Number(x.value))
          .reduce((p, c) => {
            return Number(p) + Number(this.calculateTax(c));
          }, 0);

        return Number(parseFloat(val).toFixed(2));
      }
      return 0;
    },

    async fetchHistory(date) {
      let req = {
        what: "getHistory",
        useToken: true,
        params: {
          merchID: this.$store.getters.user.merchID,
          branchID: this.$store.getters.user.branchID,
          filterDate: date,
          orderstatus: "",
        },
      };
      //add staffID for store attendant
      if (this.$store.getters.user.role == "store_attendant") {
        req.params.staffID = this.$store.getters.user.id;
      }
      this.$request
        .makeGetRequest(req)
        .then(async (res) => {
          if (res.type == "getHistory") {
            this.historydata = res.data.data;
            if (res.data.headers.Prev == "") {
              this.updateHistory(res.data.data);
            }
            this.$swal.close();
          } else {
            if (this.network) {
              this.fetchHistory(res[0]);
            } else {
              this.historydata = await this.getHistory();
            }
          }
        })
        .catch((error) => {
          this.$swal.fire("Error", error.message, "error");
        });
    },
    viewOrder(event) {
      $("#ordermodal").modal("show");
      this.order = event.row;
    },
    // formatPrice (price) {
    //   var str = price.toString().split(".");
    //   if (str[0].length >= 3) {
    //     str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
    //   }
    //   if (!str[1]) {
    //     str[1] = "00";
    //   }
    //   return str.join(".");
    // },
    formatPrice(price) {
      var str = price.toString().split(".");
      if (str[0].length >= 3) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
      }
      // if (!count) {
      if (!str[1]) {
        str[1] = "00";
      }
      // }
      let l = str[1].length;

      let c = l > 1 ? str[1] : str[1] + "0";
      return str[0] + "." + c.substring(0, 2);
    },
    formatDate(date, format) {
      date = date.replace("00:00", "01:00");
      if (date !== null) {
        let d = new Date(date);
        if (format == "time") {
          return d.toLocaleTimeString("en-US");
        } else if (format == "date") {
          return d.toDateString().replace(" ", ", ");
        } else {
          return (
            d.toDateString().replace(" ", ", ") +
            " " +
            d.toLocaleTimeString("en-US")
          );
        }
      } else {
        return null;
      }
    },
    handleReceipt() {
      $(".modal").modal("hide");
      this.$store.dispatch("setHistoryStatus", true);
      this.$store.dispatch("setOrderCode", this.order.ordercode);
      this.$store.dispatch("setOrder", this.order).then(() => {
        this.$router.push("/receipt");
      });
    },
  },
};
</script>
<style scoped>
.cus-img {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  border: 1px solid #dee2e6;
  /* padding: 3px; */
}
.cus-img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
@media screen and (max-width: 425px) {
  .main-content .card {
    margin: 0 10px;
  }
}
tr.VueTables__row {
  cursor: pointer;
}
.VueTables table.table-striped tbody tr:nth-of-type(odd) {
  background-color: transparent;
}
</style>
