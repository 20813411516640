<template>
    <div>
    <p v-if="isConnected">We're connected to the server!</p>
    <p>Message from server: "{{socketMessage}}"</p>
    <button @click="pingServer()">Ping Server</button>
  </div>
</template>

<script>
export default {
    name: 'socket',
    data () {
        return {
            isConnected: false,
            socketMessage: ''
        }
    },
    sockets: {
        connect() {
        // Fired when the socket connects.
        this.isConnected = true;
        },

        disconnect() {
        this.isConnected = false;
        },

    // Fired when the server sends something on the "messageChannel" channel.
        messageChannel(data) {
        this.socketMessage = data
        }
    },
    methods: {
        pingServer() {


        Notification.permission
        Notification.requestPermission(function (permission) {
                if (permission === "granted") {
                         new Notification('You forgot to click end of day')
                }
                    });
      // Send the "pingServer" event to the server.
        // this.$socket.emit('pingServer', 'PING!')
        }
    },
   
}
</script>