// import { update, index, store, destroy } from '@/api/resource'#
import Request from '@/Request.js'
import Utils from '@/utils.js'
import db from '@/DB.js'

export default {
    namespaced: true,
    state: () => ({
        ordersList: [],
        onlineOrders: [],
        allOrders: [],
        ridersList: [],
    }),
    mutations: {
        // SET_ORDER: (state, order) => {
        //   state.order = order
        // },
        SET_RIDERS: (state, riders) => {
            state.ridersList = riders
        },
        SET_ORDERS: (state, orders) => {
            state.ordersList = orders
                // console.log(state.ordersList)
        },

        SET_ONLINE_ORDERS: (state, orders) => {
            state.onlineOrders = orders
                // console.log(state.ordersList)
        },

        SET_ALL_ORDERS: (state, orders) => {
            // state.allOrders = orders
            // db.orders.clear();
            db.orders.bulkPut(orders)
                // console.log(state.ordersList)
        },

        SET_ALL_ORDERS_ASYNC: (state, orders) => {
            // state.allOrders = orders
            db.orders.clear();
            db.orders.bulkPut(orders)
                // console.log(state.ordersList)
        },

        ASSIGN_RIDER: (state, formData) => {
            let rider = state.ridersList.find((rider) => rider.id == formData.riderID);
            state.onlineOrders.find((order) => order.id == formData.orderID);
            order.riderfirstname = rider.riderfirstname
            order.riderphonenumber = rider.riderphonenumber
            order.riderID = rider.riderID
            order.riderlastname = rider.riderlastname
            order.riderphoto = rider.riderphoto
        },
        UPDATE_ORDER: (state, order_data) => {
            db.orders.update(order_data.id, order_data).then(function(updated) {
                // if (updated)
                //     console.log("Orders updated");
                // else
                //     console.log("Order " + order_data.id);
            });
            // if( state.ordersList.length !== 0 ) {
            //   Object.assign(state.ordersList[state.ordersList.findIndex(order => order.id === order_data.id)], order_data)
            // } else {
            //   state.ordersList.unshift(order_data)
            // }
        },
        UPDATE_ONLINE_ORDER: (state, order_data) => {
            if (state.onlineOrders.length !== 0) {
                Object.assign(state.onlineOrders[state.onlineOrders.findIndex(order => order.id === order_data.id)], order_data)
            } else {
                state.onlineOrders.unshift(order_data)
            }
        },

        ADD_TO_ALL_ORDER: (state, order_data) => {
            var date = new Date();
            var res = date.toISOString();
            order_data.created_at = res.substring(0, res.indexOf('T'));
            if (order_data.transactiondate == undefined) {
                order_data.transactiondate = Utils.getDate()
            }
            db.orders.put(order_data).then(() => {
                let index = state.ordersList.findIndex(order => order.id === order_data.id)
                if (index != -1) {
                    state.ordersList.splice(index, 1)
                }
            })
        },
        ADD_ORDER: (state, order_data) => {

            var date = new Date();
            var res = date.toISOString();
            order_data.created_at = res.substring(0, res.indexOf('T'));
            if (order_data.transactiondate == undefined) {
                order_data.transactiondate = Utils.getDate()
            }
            // db.orders.put(order_data, [order_data.id])
            state.ordersList.unshift(order_data)
        },
        DELETE_ORDER: (state, id) => {
            // db.orders.delete(id)
            let index = state.ordersList.findIndex(order => order.id === id)
            if (index != null) {
                state.ordersList.splice(index, 1)
            }
        },
        REMOVE_ORDER_ITEM: (state, id) => {
            // db.orders.get(id).then( (order) => {
            //   order.orderitems.splice(order.orderitems.findIndex(o => o.id == id), 1)
            //   db.orders.put(id, order)
            // })
            state.ordersList.orderitems.splice(state.ordersList.orderitems.findIndex(order => order.id === id), 1)
        },

        SYNC_POSTS: (state, id) => {
            let deleteIndex = -1;
            // db.orders.get(id.oldID).then((order) => {
            //   if(order != undefined) {
            //     order.id = id.id
            //     db.orders.delete(id.oldID)
            //     db.orders.put(order)
            //   }
            // })
            db.orders.where('id').equals(id.oldID).or('customerID').equals(id.oldID).or('oldorderid').equals(id.oldID).modify(order => {
                    if (order.id == id.oldID) {
                        order.id = id.id
                    } else if (order.customerID == id.oldID) {
                        order.customerID = id.id
                    } else if (order.oldorderid.includes(id.oldID)) {
                        order.oldorderid = order.oldorderid.map(o => o == id.oldID ? id.id : o)
                    }

                    return order
                })
                // db.orders.where('oldorderid', id.oldID).modify({customerID: id.id})
                // let result = state.ordersList.map((order, index) => {
                //   if(JSON.stringify(order).includes(id.oldID)){

            //     order = JSON.parse(JSON.stringify(order).replace(id.oldID, id.id))
            //     // if(id.id == )
            //   }
            //   if(id.id == order.id && order.orderstatus == 'completed'){
            //     allOrders.unshift(order);
            //     deleteIndex = index;
            //   }
            //     return order
            // })

            // if(deleteIndex > -1) {
            //   result.splice(index , 1)
            // }

            // state.ordersList = result

        },


        UPDATE_ORDER_ITEM: (state, id, value) => {
            let i = state.onlineOrders.orderitems.find(order => order.id === id);
            i.quantity = value;
            i.totalprice = Number(i.quantity) * Number(i.unitprice)

            // forEach((i, index) => {
            //   if (i.id == id) {
            //     i.quantity = value;
            //     i.totalprice = Number(i.quantity) * Number(i.unitprice)
            //   }
            // })
            // if( state.ordersList.length !== 0 ) {
            //   Object.assign(state.ordersList[state.ordersList.findIndex(order => order.order_id === order_data.order_id)], order_data)
            // } else {
            //   state.ordersList.push(order_data)
            // }
        },
    },
    actions: {
        // get Asset Condition
        index({ commit, state }, req) {

            return new Promise((resolve, reject) => {

                Request.makeGetRequest(req)
                    .then(response => {
                        const { data } = response
                        if (data.status) {
                            if (data.data != undefined) {
                                commit('SET_ALL_ORDERS_ASYNC', data.data)
                            }
                        }
                        resolve(state.ordersList)
                    }).catch(error => {
                        reject(error)
                    })
            })
        },

        getOnlineOrders({ commit, state }, req) {

            return new Promise((resolve, reject) => {

                Request.makeGetRequest(req)
                    .then(response => {
                        const { data } = response
                        // console.log(data.data)
                        if (data.status) {
                            if (data.data != undefined) {
                                commit('SET_ONLINE_ORDERS', data.data)
                            }
                        }
                        resolve(state.ordersList)
                    }).catch(error => {
                        reject(error)
                    })
            })
        },

        getInstoreOrders({ commit, state }, req) {

            return new Promise((resolve, reject) => {

                Request.makeGetRequest(req)
                    .then(response => {
                        const { data } = response
                        // console.log(data.data)
                        if (data.status) {
                            if (data.data != undefined) {
                                commit('SET_ORDERS', data.data)
                            }
                        }
                        resolve(state.ordersList)
                    }).catch(error => {
                        reject(error)
                    })
            })
        },

        removerOrderItem({ commit }, id) {
            commit('REMOVE_ORDER_ITEM', id)
        },

        sync({ commit }, id) {
            commit('SYNC_POSTS', id)
        },

        updateOrderItem({ commit }, id, value) {
            commit('UPDATE_ORDER_ITEM', id, value)
        },

        getRiders({ commit, state }, req) {

            return new Promise((resolve, reject) => {

                Request.makeGetRequest(req)
                    .then(response => {
                        const { data } = response
                        if (data.status) {
                            if (data.data != undefined) {
                                commit('SET_RIDERS', data.data.data)
                            }
                        }
                        resolve(state.ridersList)
                    }).catch(error => {
                        reject(error)
                    })
            })
        },

        assignRider({ commit }, req) {

            return new Promise((resolve, reject) => {

                Request.makePostRequest(req)
                    .then(response => {
                        const { data } = response
                        if (data.status) {
                            if (data.data != undefined) {

                            } else {
                                commit('ASSIGN_RIDER', req.data)
                            }
                        }
                        resolve(data.data)
                    }).catch(error => {
                        reject(error)
                    })
            })
        },

        // completeQueueOrder

        completeQueueOrder({ commit, getters }, req) {

            return new Promise((resolve, reject) => {
                Request.makePostRequest(req)
                    .then(async response => {
                        const { data } = response
                        let order = {};
                        if (data.status) {
                            // if(data.data != undefined){
                            order = getters.getOrder(req.data.orderID)
                                // order = data.data           
                                // } else {
                                //   order = getters.getOrder(req.data.orderID)

                            // }

                            order.orderstatus = req.data.orderstatus;
                            order.paymentstatus = req.data.paymentstatus;
                            order.paymentdetails = req.data.multiple;

                            order = Utils.setOrderItems(order)

                            order = await Utils.setCustomer(order)

                            Utils.updateProductQuantity(order);

                            commit('UPDATE_ORDER', order)
                            commit('ADD_TO_ALL_ORDER', order)
                        }
                        resolve(order)
                    }).catch(error => {
                        reject(error)
                    })
            })
        },

        addToQueue({ commit, dispatch }, req) {

            return new Promise((resolve, reject) => {
                Request.makePostRequest(req)
                    .then(async response => {

                        const { data } = response
                        let order = {};
                        if (data.status) {
                            if (data.data != undefined) {
                                order = req.data
                                order.id = data.data.id
                                order.ordercode = data.data.ordercode
                                order.transactiondate = data.data.created_at
                            } else {
                                order = data.req.data
                            }


                            order = await Utils.setCustomer(order)

                            order = Utils.setOrderItems(order)
                                // dispatch('setOrderItems', order).then((res) => {
                                //   order = res;
                                // });

                            commit('ADD_ORDER', order)
                        }
                        resolve(order)
                    }).catch(error => {
                        reject(error)
                    })
            })
        },

        async getSummary({ commit, state }, id) {
            var date = new Date();
            let res = date.toISOString()
            let created_at = res.substring(0, res.indexOf('T'));
            let orders = await db.orders.where('[created_at+staffID+paymentstatus+ordertype]').equals([created_at, id, 'PAID', 'instore']).toArray()

            if (orders != undefined) {
                return orders
            } else {
                return [];
            }
        },

        async getHistory({ commit, state }) {
            let index = null;
            let orders = await db.orders.orderBy('transactiondate').reverse().toArray()
            if (orders != undefined) {
                state.ordersList.forEach((order) => {
                        index = orders.findIndex((all_order) => all_order.id == order.id)
                        if (index !== -1) {
                            order.ordercode = orders[index].ordercode
                            order.riderID = orders[index].riderID
                            orders[index] = order;
                        } else {
                            orders.unshift(order);
                        }
                    })
                    // orders.unshift(...state.ordersList)
                return orders
            } else {
                return [];
            }
        },

        placeOrder({ commit, dispatch }, req) {
            return new Promise((resolve, reject) => {
                Request.makePostRequest(req)
                    .then(async response => {
                        const { data } = response
                        let order = {};
                        if (data.status) {
                            if (data.data != undefined) {
                                order = req.data
                                order.id = data.data.id
                                order.ordercode = data.data.ordercode
                                order.transactiondate = data.data.created_at

                            } else {
                                order = data.req.data
                            }
                            order = Utils.setOrderItems(order)
                            order = await Utils.setCustomer(order)
                            Utils.updateProductQuantity(order);

                            // commit('ADD_ORDER', order)
                            commit('ADD_TO_ALL_ORDER', order)
                        }
                        resolve(order)
                    }).catch(error => {
                        reject(error)
                    })
            })
        },

        store({ commit }, order, req) {
            return new Promise((resolve, reject) => {
                Request.makePostRequest(req)
                    .then(response => {
                        const { data } = response
                        commit('ADD_ORDER', order)
                        resolve()
                    }).catch(error => {
                        reject(error)
                    })
            })
        },

        mergeQueue({ commit }, req) {

            return new Promise((resolve, reject) => {
                Request.makePostRequest(req)
                    .then(async response => {
                        const { data } = response
                        let order = {};
                        if (data.status) {
                            if (data.data != undefined) {
                                order = req.data
                                order.id = data.data.id
                                order.ordercode = data.data.ordercode
                                order.transactiondate = data.data.created_at
                            } else {
                                order = data.req.data
                            }

                            if (order.oldorderid != undefined) {
                                order.oldorderid.forEach((id) => {
                                    commit('DELETE_ORDER', id);
                                });

                                // delete order.oldorderid
                            }

                            order = Utils.setOrderItems(order)
                            order = await Utils.setCustomer(order)
                            Utils.updateProductQuantity(order);
                            commit('ADD_TO_ALL_ORDER', order)
                                // commit('ADD_ORDER', order)
                        }
                        resolve(order)
                    }).catch(error => {
                        reject(error)
                    })
            })
        },
        cancel({ commit }, order, req) {
            return new Promise((resolve, reject) => {
                Request.makePostRequest(req)
                    .then(response => {
                        // const { data } = response
                        order.orderstatus = 'cancel'
                        commit('UPDATE_ORDER', order)
                        resolve()
                    }).catch(error => {
                        reject(error)
                    })
            })
        },
        complete({ commit }, order, req) {
            return new Promise((resolve, reject) => {
                Request.makePostRequest(req)
                    .then(response => {
                        // const { data } = response
                        order.orderstatus = 'complete'
                        commit('UPDATE_ONLINE_ORDER', order)
                        resolve()
                    }).catch(error => {
                        reject(error)
                    })
            })
        },
        //save orderhistory
        saveHistory({ commit }, req) {
            commit('SET_ALL_ORDERS_ASYNC', req)
        },
    },
    getters: {
        queue: (state) => state.ordersList.filter((order) => order.orderstatus == 'paylater').filter((order) => order.ordertype == 'instore'),
        onlinequeue: (state) => state.ordersList.filter((order) => order.orderstatus == 'paylater').filter((order) => order.ordertype == 'online'),
        orders: (state) => (type) => state.onlineOrders.filter((order) => type != '' ? order.orderstatus == type : true),
        // orders: (state) => (type) => state.onlineOrders.filter((order) => type != '' ? order.orderstatus == type : true).filter((order) => order.ordertype == 'online').filter((order) => (new Date(order.created_at)).getMonth() == (new Date()).getMonth()),
        orderHistory(state) {
            let orders = state.allOrders;
            let index = null;
            // db.orders
            if (orders != undefined) {
                state.ordersList.forEach((order) => {
                    index = orders.findIndex((all_order) => all_order.id == order.id)
                    if (index !== -1) {
                        order.ordercode = orders[index].ordercode
                        order.riderID = orders[index].riderID
                        orders[index] = order;
                    } else {
                        orders.unshift(order);
                    }
                })

                return orders;
            } else {
                return []
            }



        },
        getOrder: (state) => (id) => state.ordersList.find((order) => order.id == id),
        offlineOrders: (state) => (type) => state.ordersList.filter((order) => type != '' ? order.orderstatus == type : true).filter((order) => order.ordertype == 'instore'),
        riders: (state) => state.ridersList,
    }
}