<template >
  <div id="signin">
    <div class="row p-sm-3">
      <div class="col-sm-7 ">
        </div>
        <div class="auth-form  mx-auto">
          <div class="heading d-flex justify-content-start ">
            <img
              src="../assets/img/key.svg"
              alt=""
            >
            <div class="ml-3">
              <p>Sign in as an administrator to continue</p>
            </div>
          </div>
          <div>
            <ValidationObserver v-slot="{ handleSubmit }">
              <form
                class="mt-4"
                @submit.prevent='handleSubmit(handleSignin)'
              >
                <div class="form-group">
                  <label for="">Enter Your Last Name</label>
                  <validation-provider
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <input
                      type="text"
                      class="form-control"
                      placeholder="What’s your Last name?"
                      v-model="username"
                    >
                    <span class="extra">Enter your registered surname</span>
                    <br />
                    <span class="err_msg">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
                <div class="form-group">
                  <label for="">Enter Token</label>
                  <validation-provider
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <div class="input-wrapper">
                      <PincodeInput
                        :length="6"
                        v-model="passcode"
                        placeholder="0"
                      />
                    </div>
                    <span class="err_msg">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
                <div class="form-group">
                  <button
                    type="submit"
                    class="btn"
                  >
                    Sign in
                  </button>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Utils from "@/utils.js";
import * as $ from "jquery";
export default {
  name: "InlineLogin",
  data() {
    return {
      username: "",
      passcode: "",
      role: "store_attendant",
    };
  },

  methods: {
    handleSignin() {
      let req = {
        what: "login",
        showLoader: false,
        params: {
          firstname: this.username,
          token: this.passcode,
          role: this.role,
        },
      };

      var loading_html =
        '<div style="height:150px;width:150px;margin: 0 auto;"><img style="width: 100%;" src="https://trails.ca/wp-content/uploads/2020/10/loading-spinner.gif" /></div>';

      this.$swal.fire({
        title: "",
        html: loading_html,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        showCancelButton: false,
      });

      this.$store
        .dispatch("supervisorLogin", req)
        .then(async (response) => {
          let res = response;

          if (
            ["administrator", "business_owner"].includes(res.role) ||
            res.permissions.filter((permission) =>
              permission.name.toLowerCase().includes("cart_removal")
            )
          ) {
            if (res.branchID.includes(this.$store.getters.getBranchID)) {
              this.$swal.fire(
                "Succes",
                "Action Carried Out Successfully",
                "success"
              );
              this.username = "";
              this.passcode = "";
              this.$emit("remove-approved");
            } else {
              this.$swal.fire(
                "Error",
                "This account does not have authority over this branch",
                "error"
              );
              this.username = "";
              this.passcode = "";
              this.$emit("cancelLogin");
            }
          } else {
            this.$swal.fire("Error", "Unauthoized access", "error");
            this.username = "";
            this.passcode = "";
            this.$emit("cancelLogin");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal.fire("Error", error.message, "error");
          this.username = "";
          this.passcode = "";
        });
    },
  },
};
</script>
<style>
.vue-pincode-input {
  margin-right: 7px !important;
  padding: 8px !important;
  max-width: 40px !important;
  text-align: center;
  font-size: 1.2rem !important;
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 3px rgb(0 0 0 / 50%) !important;
}
.vue-pincode-input:focus {
  box-shadow: 0 0 3px rgb(0 0 0 / 50%) !important;
}
</style>