<template >
  <div>
    <div id="signin">
      <div class="row p-sm-3">
        <div class="col-sm-7 ">
          <div class="logo mb-5 mt-3 ml-3">
            <img
              src="../assets/img/logo-hoz.png"
              alt=""
            >
          </div>
          <div class="back">
            <a
              href="/"
              class="d-flex"
            >
              <i class="fa fa-chevron-left mt-1 mr-2"></i>
              <p>Back to Sign in</p>
            </a>
          </div>
          <div class="auth-form pt-5 mx-auto">
            <div class="heading d-flex justify-content-start ">
              <img
                src="../assets/img/lock.svg"
                alt=""
              >
              <div class="ml-3">
                <h3> Forgot Password?</h3>
                <p>Restore access to your account</p>
              </div>
            </div>
            <div>
              <ValidationObserver v-slot="{ handleSubmit }">
                <form
                  class="mt-4"
                  @submit.prevent='handleSubmit(handleForgot)'
                >
                  <div class="form-group">
                    <label for="">We’ll send a recovery link to</label>
                    <validation-provider
                      rules="required|email"
                      v-slot="{ errors }"
                    >
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Your email address"
                      >
                      <span class="extra">Enter email used to create your Ntisa account</span>
                      <span class="err_msg">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>

                  <div class="form-group">
                    <button
                      type="submit"
                      class="btn"
                    >
                      Reset Password
                    </button>
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </div>
        <div class="col-sm-5 d-none d-sm-block">
          <div class="auth-details px-3">
            <div class="mr-3 title  text-right">
              <img
                src="../assets/img/logo-mini.png"
                alt=""
              >
              <h4>Don't Worry <br>
                It happens to the best of us</h4>
            </div>
            <div class="login-bg">
              <img
                src="../assets/img/forgot-bg.svg"
                alt=""
              >
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  name: "ForgotPassword",
  data () {
    return {
      username: '',
      password: '',
      role: 'store_attendant'
    }
  },
  methods: {
    handleForgot () {
      let req = {
        what: "login",
        params: {
          username: this.username,
          password: this.password,
          role: this.role
        }
      }
      this.$request.makeGetRequest(req)
        .then(res => {
          if (res.type == 'login') {
            
            // console.log(res.data)


          }
          if (req.showLoader == undefined || req.showLoader){
              this.$swal.close()
            }

        })
        .catch(error => {
          // this.$swal.fire("Error", error.message, "error");
          console.log(error)
          this.$toasted.show(error.message, {
            theme: "bubble",
            type: "error",
            position: "top-right",
            duration: 6000,
          });
          
          this.is401(error.response.status);
        });
    },
  }
}
</script>
<style scoped>
#signin {
  background: #ffffff;
}
.back a {
  color: var(--light-gray);
  text-align: left;

  letter-spacing: 0px;
}
.back a p {
  font: normal normal 600 17px/22px Source Sans Pro;
}
</style>