/*
	This script make requests through a NodeJS proxy.
	The other variation of this script makes requests directly to the URL defined.

*/

import axios from 'axios'
import checkOnline from "./checkOnline";
// import Utils from "./utils";
axios.defaults.timeout = 18000000000000000000000;
//test
// const URL = "http://68.65.121.57:8080/API/";
// const URL = "https://wajeloyalty.website/API/"
// const URL = "https://c38a-168-253-114-161.ngrok.io/API/";
//deployment
// const URL = "http://162.0.233.96/API/";
// live
// const URL = "https://api.ntisa.com.ng/API/";
const url_for_order_history = "https://ntisareportserver.website/"

export default {
    name: "asyncRequest",

    makeGetRequest: (request, request_token) => {
        var request_urls = {
            login: 'user/',
            getOrders: 'onlineplaceorder/',
            getProducts: 'listmenuwithoutpagination/',
            getHistory: 'placeorder/',
            getRiders: 'rider/',
            getCustomers: 'offlinecustomer/',
            searchCustomer: 'customerrecord/',
            tax_rewards: 'tax-and-reward/',
            getRewards: 'rewardloyalty/',
            getOfflineProducts: 'offlineproductupdate/',
            barcodescan: 'barcodesearch/',
            summary: 'storeattendantdailyreport/',
            getUsers: 'merchantusers/',
            queue: 'queueorder/',
            merchantsubscription: 'merchantsubscription/',
            networkstatus: 'networkstatus/'

        };
        if (request.params !== undefined) {
            var obj = request.params;
            var str = Object.keys(obj)
                .map(key => {
                    return key + "=" + obj[key];
                })
                .join("&");
            var request_url = URL + request_urls[request.what] + "?" + str;
        } else if (request.id) {
            var request_url = URL + request_urls[request.what] + "" + request.id;
        } else {
            var request_url = URL + request_urls[request.what];
        }
        if (request.formData || request.useToken) {
            request.headers = {};
            if (request.formData) {
                request.headers = {
                    'Content-Type': 'multipart/form-data'
                }
            }
            if (request.useToken || request.useToken == undefined) {
                var token;
                if (request_token != undefined) {
                    token = request_token;
                }
                request.headers.Authorization = "Bearer " + token
            }
        }
        var config = {};



        if (request.headers !== undefined) {
            config.headers = request.headers;
        }
        return new Promise(async(resolve, reject) => {
            // let online = await checkOnline.checkOnlineStatus();
            let vm = this;
            axios
                .get(request_url, config)
                .then(response => {
                    if (response.status == "true" || response.status) {
                        var response = {
                            data: response.data,
                            type: request.what,
                            status: "true"
                        };

                        resolve(response);
                    } else {
                        reject(response.data.message);
                    }
                })
                .catch(err => {
                    if (!err.response || err.code === 'ECONNABORTED') {
                        // vm.closeLoader();
                        // resolve({data: { status: true}})


                        reject(err);
                    } else {
                        // vm.closeLoader();
                        reject(err);
                    }
                });
        });
    },
    makeRequest: (request, request_token) => {
        if ((request.showLoader || request.showLoader == undefined) && Vue.prototype.$swal != undefined) {
            if (!Vue.prototype.$swal.isVisible()) {
                var loading_html =
                    '<div style="height:50px;width:50px;margin: 0 auto;"><img style="width: 100%;" src="https://trails.ca/wp-content/uploads/2020/10/loading-spinner.gif" /></div>';

                Vue.prototype.$swal.fire({
                    title: "",
                    html: loading_html,
                    showConfirmButton: false,
                    showCancelButton: false,
                    allowOutsideClick: true,
                    allowEscapeKey: true,
                    showCancelButton: false,
                    width: "150px",

                });
            }
        }


        var request_urls = {
            getHistory: 'placeorder/',
        };
        if (request.params !== undefined) {
            var obj = request.params;
            var str = Object.keys(obj)
                .map(key => {
                    return key + "=" + obj[key];
                })
                .join("&");
            // if (request_urls[request.what] == 'getHistory'){
            //     var request_url = url_for_order_history + request_urls[request.what] + "?" + str
            // }
            // else{
            //     var request_url = URL + request_urls[request.what] + "?" + str
            // }
            var request_url = url_for_order_history + request_urls[request.what] + "?" + str;
        }
        //  else if (request_urls[request.what] == 'getHistory') {
        //     var request_url = url_for_order_history + request_urls[request.what] + "" + request.id;
        // }
        else if (request.id) {
            var request_url = url_for_order_history + request_urls[request.what] + "" + request.id;
        } else {
            var request_url = url_for_order_history + request_urls[request.what];
        }

        // request.headers = { 'Cache-Control': 'no-cache' };
        request.headers = {}
        if (request.formData || request.useToken) {
            if (request.formData) {
                request.headers = {
                    'Content-Type': 'multipart/form-data'
                }
            }
            if (request.useToken || request.useToken == undefined) {
                var token;
                if (request_token != undefined) {
                    token = request_token;
                } else {
                    token = store.getters.token;
                }
                request.headers.Authorization = "Bearer " + token
            }
        }
        var config = {};



        if (request.headers !== undefined) {
            config.headers = request.headers;
        }
        // return new Promise(async(resolve, reject) => {
        //     let online = await Utils.checkOnlineStatus();
        //     let vm = this;

        //     // console.log(online);
        //     if (online || Utils.shouldGo(request.what)) {
        //         // console.log(request.what)
        //         axios
        //             .get(request_url, config)
        //             .then(response => {

        //                 if (response.status == "true" || response.status) {
        //                     var response = {
        //                         data: response.data,
        //                         type: request.what,
        //                         status: "true"
        //                     };
        //                     // if (Vue.prototype.$swal != undefined && store.state.load == false && store.getters['posts/status'] == false) {
        //                     //     Vue.prototype.$swal.close()
        //                     // }
        //                     resolve(response);
        //                 } else {
        //                     reject(response.data.message);
        //                 }
        //             })
        //             .catch(err => {

        //                 // console.log(err)
        //                 // if (Vue.prototype.$swal != undefined) {
        //                 //     Vue.prototype.$swal.close()
        //                 // }
        //                 if (!err.response || err.code === 'ECONNABORTED') {
        //                     // vm.closeLoader();
        //                     // resolve({data: { status: true}})


        //                     reject({ message: "Please check your internet connection. This page does not work without internet" });
        //                 } else {
        //                     // vm.closeLoader();
        //                     reject(err);
        //                 }
        //             });
        //     } else {
        //         if (Vue.prototype.$swal != undefined) {
        //             Vue.prototype.$swal.close()
        //         }

        //         // vm.closeLoader();
        //         resolve({ data: { status: true } })
        //     }

        //     // vm.closeLoader()



        // });
    },
};