import Vue from 'vue'
import Vuex from 'vuex';
// import createPersistedState from "vuex-persistedstate";
// import Cookies from 'js-cookie'
// import localStoragePlugin from '../plugins/localStorage';
import orders from './modules/orders'
import posts from './modules/posts'
import products from './modules/products'
import Request from '@/Request.js'
import localForage from 'localforage'
import VuexPersistence from 'vuex-persist'
import { stat } from 'fs';


Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
    key: 'ntisa',
    supportCircular: true,
    storage: window.localStorage,
    reducer: (state) => ({
        posts: {
            active: state.posts.active
        },
        orders: {
            ordersList: state.orders.ordersList
        },
        endday: state.endday,
        isEnddayActive: state.isEnddayActive,
        users: state.users,
        user: state.user,
        cart: state.cart,
        token: state.token,
        discountpayment: state.discountpayment,
        order: state.order,
        updated_at: state.updated_at,
        ordercode: state.ordercode,
        isLoggedIn: state.isLoggedIn,
        isHistory: state.isHistory,
        // load: state.load,
        first: state.first,
        category: state.category,
        businesscategory: state.businesscategory,
        isQueue: state.isQueue,
        isSupervisor: state.isSupervisor,
        mergeorders: state.mergeorders,
        merchID: state.merchID,
        branchID: state.branchID,
        sales_summary: state.sales_summary,
        lincense_expiration_date: state.lincense_expiration_date,

    })
})


// https://webpack.js.org/guides/dependency-management/#requirecontext
const modulesFiles = require.context('./modules', true, /\.js$/)

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
    // set './app.js' => 'app'
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
    const value = modulesFiles(modulePath)
    modules[moduleName] = value.default
    return modules
}, {})

export default new Vuex.Store({
    // modules: {
    //     orders,
    //     posts,
    //     products
    // },
    modules,
    state: {
        isLoggedIn: false,
        cart: [],
        user: null,
        id: null,
        load: false,
        users: [],
        user: {},
        discountpayment: 0,
        category: null,
        token: null,
        // products: [],
        updated_at: '',
        first: true,
        order: null,
        ordercode: "",
        isQueue: false,
        isHistory: false,
        businesscategory: null,
        mergeorders: [],
        merchID: '',
        taxes: [],
        branchID: '',
        endday: '',
        isEnddayActive: false,
        sales_summary: {},
        lincense_expiration_date: '',
        issupervisor: false,
    },
    mutations: {
        set_endday(state, data) {
            state.endday = data
        },
        set_endday_active(state, data) {
            state.isEnddayActive = data
        },
        load(state, data) {
            state.load = data
        },
        first(state, data) {
            state.first = data
        },
        addCart(state, data) {
            state.cart.push(data)
        },
        updateCart(state, data) {
            state.cart = data
        },
        setUser: (state, payload) => {
            state.user = payload;
            state.isLoggedIn = true;
        },
        setUsers: (state, payload) => {
            state.users = payload;
        },
        setCategory: (state, data) => {
            state.businesscategory = data;
        },

        setToken: (state, payload) => {
            state.token = payload;
        },

        setTax: (state, payload) => {
            state.taxes = payload;
        },

        setMerchID: (state, payload) => {
            state.merchID = payload;
        },
        setUserID: (state, payload) => {
            state.id = payload;
        },
        setBranchID: (state, payload) => {
            state.branchID = payload;
        },
        setBranchIDAfter: (state, payload) => {
            state.branchID = payload;
            state.user.branchID = payload
        },
        logoutUser: (state) => {
            state.user = null;
            state.token = null;
            state.cart = [];
            state.isLoggedIn = false;
        },
        // setProducts: (state, data) => {
        //     state.products = data;
        // },
        setOrder: (state, payload) => {
            state.order = payload;
        },
        setOrderCode: (state, payload) => {
            state.ordercode = payload;
        },
        initializeStore() {
            // const data = localStorage.getItem('boardState');

            // if (data) {
            //     this.replaceState(Object.assign(this.state, JSON.parse(data)));
            // }
        },
        setLicenseExpirationDate(state, data) {
            state.lincense_expiration_date = data
        },
        updateQueue(state, status) {
            state.isQueue = status;
        },
        updateisSupervisor(state, status) {
            state.issupervisor = status;
        },
        setOrderDiscount(state, discount) {
            if (state.cart.length > 0) {
                state.discountpayment = (discount ? Number(discount) : 0) + Number(state.discountpayment);
            } else {
                state.discountpayment = (discount ? Number(discount) : 0)
            }
        },
        setMerge(state, data) {
            state.mergeorders = data
        },
        setSummary(state, data) {
            state.sales_summary = data
        },
        updateHistoryStatus(state, status) {
            state.isHistory = status
        },
        set_updated_at(state, updated_at) {
            state.updated_at = updated_at
        },

    },
    actions: {
        supervisorLogin({ commit, getters, dispatch }, req) {

            return new Promise((resolve, reject) => {
                Request.makeGetRequest(req)
                    .then(response => {
                        const { data } = response
                        let staff = {};
                        if (data.status) {
                            if (data.data != undefined) {
                                staff = data.data

                            } else {
                                if (getters.checkLicense) {
                                    staff = getters.getUser(req.params);

                                } else {
                                    reject('Your Licence has Expired please purchase a new licence and connect to the internet')
                                }
                            }
                        } else {
                            reject(response.data.message)
                        }
                        resolve(staff)
                    }).catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })

        },
        login({ commit, getters, dispatch }, req) {
            // console.log(req)
            // commit('load', true)
            return new Promise((resolve, reject) => {
                Request.makeGetRequest(req)
                    .then(response => {
                        const { data } = response
                        let staff = {};
                        if (data.status) {
                            if (data.data != undefined) {
                                staff = data.data
                                commit('setMerchID', staff.merchID)
                                commit('setToken', data.token)
                                dispatch('getLicense', {
                                    what: 'merchantsubscription',
                                    useToken: true,
                                    params: {
                                        merchID: getters.getMerchID
                                    }
                                })

                                commit('setMerchID', staff.merchID)
                                commit('setCategory', staff.businesscategory)
                            } else {
                                if (getters.checkLicense) {
                                    staff = getters.getUser(req.params);
                                    commit('setMerchID', staff.merchID)
                                        // commit('setBranchID', staff.branchID)
                                        // if (staff.branchID != getters.getBranchID) {
                                        //     commit('first', true)
                                        // }
                                } else {
                                    reject('Your Licence has Expired please purchase a new licence and connect to the internet')
                                }
                            }
                            commit('setUser', staff)
                        } else {
                            reject(response.data.message)
                        }
                        resolve(staff)
                    }).catch(error => {

                        reject(error)
                    })
            })
        },
        loginSync({ commit, getters, dispatch }) {
            let req = {
                    what: "login",
                    showLoader: false,
                    params: {
                        firstname: getters.user.lastname,
                        token: getters.user.usertoken,
                        role: getters.user.role
                    }
                }
                // console.log(req)
            return new Promise((resolve, reject) => {
                Request.makeGetRequest(req)
                    .then(async response => {
                        const { data } = response
                        let staff = {};
                        if (data.status) {
                            // if(data.data != undefined){
                            staff = data.data
                            commit('setMerchID', staff.merchID)
                                // commit('setBranchID', staff.branchID)
                            commit('setToken', data.token)
                            staff.branchID = getters.getBranchID
                            commit('setUser', staff)

                            commit('setCategory', staff.businesscategory)
                                // } else {
                                // reject('Failed to sync, User account login failed')
                                // if(getters.checkLicense){
                                //     staff = getters.getUser(req.params);
                                //     commit('setMerchID', staff.merchID)
                                // } else {
                                //     reject('Your Licence has Expired please purchase a new licence and connect to the internet')
                                // }
                                // }
                            await dispatch('getLicense', {
                                what: 'merchantsubscription',
                                useToken: true,
                                showLoader: false,
                                params: {
                                    merchID: getters.getMerchID
                                }
                            })


                        } else {
                            reject(response.data.message)
                        }
                        resolve(staff)
                    }).catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
        getUsers({ commit, state }, req) {
            return new Promise((resolve, reject) => {
                Request.makeGetRequest(req)
                    .then(response => {
                        const { data } = response
                        if (data.status) {
                            if (data.data != undefined) {
                                commit('setUsers', data.data)
                            }
                        } else {
                            vue.$swal.fire("Error", res.data.message, "error");
                        }
                        resolve(state.users)
                    }).catch(error => {

                        reject(error)
                    })
            })
        },
        async getLicense({ commit, state }, req) {
            return new Promise(async(resolve, reject) => {
                await Request.makeGetRequest(req)
                    .then(response => {
                        const { data } = response
                        if (data.status) {
                            if (data.data != undefined) {
                                // console.log(data.data[0].expirationdate)
                                commit('setLicenseExpirationDate', data.data[data.data.length - 1].expirationdate)
                            }
                        } else {
                            // vue.$swal.fire("Error", res.data.message, "error");
                            console.log(res.data.message)
                        }
                        resolve(state.users)
                    }).catch(error => {

                        reject(error)
                    })
            })
        },
        addToCart({ commit }, data) {
            commit("addCart", data)
        },
        setOrderDiscount({ commit }, data) {
            commit("setOrderDiscount", data)
        },
        updateCart({ commit, dispatch }, data) {

            if (data.length == 0) {

                commit('setOrderDiscount', 0)
                commit('setOrder', {})
                commit('setMerge', [])
            }
            commit("updateCart", data)
        },
        setHistoryStatus({ commit }, status) {
            commit("updateHistoryStatus", status)
        },
        setUser: (context, payload) => {
            context.commit("setUser", payload);
        },

        updateisSupervisor: (context, payload) => {

            context.commit("updateisSupervisor", payload);

        },

        setCategory: (context, payload) => {
            context.commit("setCategory", payload);
        },

        setToken: (context, payload) => {
            context.commit("setToken", payload);
        },
        logoutUser: (context) => {
            context.commit("logoutUser");
        },
        // setProducts: (context, payload) => {
        //     context.commit("setProducts", payload)
        // },
        setOrder: (context, payload) => {
            context.commit("setOrder", payload);
        },
        setOrderCode: (context, payload) => {
            context.commit("setOrderCode", payload);
        },
        setQueueStatus: (context, data) => {
            context.commit('updateQueue', data)
        },
        setMergeOrders: (context, data) => {
            context.commit('setMerge', data)
        },

        first: (context, data) => {
            context.commit('first', data)
        },

        summary: (context, data) => {
            context.commit('setSummary', data)
        },
        updated_at: (context, data) => {
            context.commit('set_updated_at', data)
        },

    },
    // modules: {},
    getters: {

        cart: state => state.cart,
        isEnddayActive: state => state.isEnddayActive,
        user: (state) => state.user,
        updated_at: (state) => state.updated_at,
        first: (state) => state.first,
        endday: (state) => state.endday,
        businesscategory: (state) => state.businesscategory,
        token: (state) => state.token,
        isLoggedIn: state => state.isLoggedIn && !isNaN(state.user.branchID),
        getUserId: state => state.id,
        should_load: state => state.load,
        // products: state => state.products,
        order: (state) => state.order,
        getLicense: (state) => state.lincense_expiration_date,
        checkLicense: (state) => state.lincense_expiration_date != null ? Date.parse(state.lincense_expiration_date.split('.')[0]) >= (new Date()).getTime() : false,
        getUser: (state) => (params) => {
            let res = state.users.find((user) => user.lastname.toLowerCase().trim() == params.firstname.toLowerCase().trim() && user.usertoken == params.token);

            return res
        },
        ordercode: (state) => state.ordercode,
        isQueue: state => state.isQueue,
        isSupervisor: state => state.issupervisor,
        isMerge: state => state.mergeorders.length > 0,
        mergeorders: state => state.mergeorders,
        getMerchID: state => state.merchID,
        getBranchID: state => state.branchID,
        sales_summary: state => state.sales_summary,
        isHistory: state => state.isHistory,
        discountpayment: state => state.discountpayment,
        taxes: state => state.taxes
    },
    plugins: [vuexLocal.plugin] //createPersistedState(), localStoragePlugin, ]
})